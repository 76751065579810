import * as React from 'react';
import Segment from './Segment';

const decoratorStyle = {
  border: '5px solid red',
  padding: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export default {
  title: 'Library/Segment',
  component: Segment,
  argTypes: {
    children: {
      name: 'children',
      type: {name: 'string', required: false},
      defaultValue:
        'Lorem ipsum dolor sit amet. Est doloremque sint aut placeat excepturi eum necessitatibus aliquid eos itaque dolor in animi dolorum qui voluptatem vero. Non molestias Quis ea totam dolore non laboriosam labore et harum repudiandae qui incidunt. Ut dolorem tempora ab saepe quasi aut quisquam dignissimos cum accusantium aperiam.',
      description: 'Button content',
    },
  },
};

const defaultArgs = {
  fluid: false,
};

const Template = (args) => (
  <div style={decoratorStyle}>
    <Segment {...defaultArgs} {...args} />
  </div>
);

export const Default = Template.bind({});
Default.args = {};

export const Fluid = Template.bind({});
Fluid.args = {
  fluid: true,
};
