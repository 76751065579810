import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Google = (props) => {
  const {color = '#1b5c96', ...rest} = props;

  return (
    <SvgIcon
      id="Group_7790"
      data-name="Group 7790"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        id="Path_2562"
        data-name="Path 2562"
        d="M0,0H16V16H0Z"
        fill="none"
      />
      <path
        id="google-brands"
        d="M15.742,16.187A7.429,7.429,0,0,1,8,24,8,8,0,0,1,8,8a7.693,7.693,0,0,1,5.365,2.094l-2.177,2.094C8.339,9.439,3.042,11.5,3.042,16A5.011,5.011,0,0,0,8,21.052,4.326,4.326,0,0,0,12.542,17.6H8V14.852h7.616a7.013,7.013,0,0,1,.126,1.335Z"
        transform="translate(0.129 -8)"
        fill={color}
      />
    </SvgIcon>
  );
};

export default Google;
