import React from 'react';
import { Modal as MUIModal } from '@mui/material';
import Box from './Box';
import Segment from './Segment';
import { spacing, styles, uiColors } from './theme';
import CloseIcon from '@mui/icons-material/Close';
import Title from './Title';

const modalContainerSx = {
  overflowY: 'auto !important',
};
const modalSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: spacing.m,
  paddingBottom: spacing.m,
};
const headerSx = {
  padding: spacing.s,
  borderBottom: `1px solid ${uiColors.border}`,
  display: 'flex',
  justifyContent: 'space-between',
};
const contentSx = {
  padding: 0,
  width: 400,
  maxWidth: '90%',
};
const contentWrapperStyle = {
  padding: spacing.m,
};
const titleSx = {
  paddingBottom: 0,
  paddingLeft: spacing.m,
};
const iconSx = {
  cursor: 'pointer',
};

export default function Modal(props) {
  const { children, title, sx, ...rest } = props;
  return (
    <MUIModal sx={{ ...modalContainerSx, ...sx }} {...rest}>
      <Box sx={modalSx}>
        <Segment sx={contentSx}>
          <Box sx={headerSx}>
            <Title sx={titleSx}>{title}</Title>
            {props.onClose && <CloseIcon onClick={props.onClose} sx={iconSx} />}
          </Box>
          <div style={contentWrapperStyle}>{children}</div>
        </Segment>
      </Box>
    </MUIModal>
  );
}
