import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import RevenueListItem from './RevenueListItem';
import {
  getConfigById,
  getCurrencyById,
} from '../../selectors/configSelectors';
import { deleteStatement } from '../../processes/statementProcesses';
import { getCurrentUserCompany } from '../../selectors/userSelectors';

function RevenueListItemContainer(props) {
  const { revenue, dispatch } = props;

  const onEdit = useCallback(
    () => dispatch(push(`/statements/revenues/${revenue.id}/edit`)),
    [revenue.id, dispatch],
  );
  const onDelete = useCallback(
    () => deleteStatement(dispatch, revenue),
    [revenue],
  );

  return <RevenueListItem onEdit={onEdit} onDelete={onDelete} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);

  return {
    statementCategory: getConfigById(
      state,
      'statementCategories',
      ownProps.revenue.statementCategoryId,
    )?.name,
    currency,
  };
}

export default connect(mapStateToProps)(RevenueListItemContainer);
