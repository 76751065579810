import React from 'react';
import { connect } from 'react-redux';
import { getLastStatementsByType } from '../../selectors/statementSelectors';
import ExpensesData from './ExpensesData';
import { getCurrentUserCompany } from '../../selectors/userSelectors';
import { getCurrencyById } from '../../selectors/configSelectors';

function ExpensesDataContainer(props) {
  const { expenses } = props;

  return expenses?.length ? <ExpensesData {...props} /> : null;
}

function mapStateToProps(state) {
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);

  return {
    expenses: getLastStatementsByType(state, 'expense'),
    currency,
  };
}

export default connect(mapStateToProps)(ExpensesDataContainer);
