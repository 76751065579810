import React from 'react';
import Page from '../page/Page';
import ExpenseFormContainer from './ExpenseFormContainer';

export default function ExpensePage(props) {
  const expenseId = props?.match?.params?.statementId;
  return (
    <Page
      back="/statements/expenses"
      headerType="secondary"
      title="Pagamentos"
      offsetHeader={0}
    >
      <ExpenseFormContainer statementId={expenseId} />
    </Page>
  );
}
