import React, { useCallback, useEffect } from 'react';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import {
  getFetchedStatementsByType,
  getStatementPageInfo,
  getFutureRevenues,
} from '../../selectors/statementSelectors';
import RevenuesPage from './RevenuesPage';
import { fetchCustomers } from '../../processes/customerProcessess';
import {
  fetchStatements,
  fetchFutureRevenues,
} from '../../processes/statementProcesses';
import { getCurrentUserCompany } from '../../selectors/userSelectors';
import { getCurrencyById } from '../../selectors/configSelectors';

function RevenuesPageContainer(props) {
  const { dispatch, filters } = props;

  useEffect(() => {
    filters?.typeEq == 'revenue' && fetchStatements(dispatch, { filters });
  }, [dispatch, filters]);

  useEffect(() => {
    fetchCustomers(dispatch);
  }, [dispatch]);

  useEffect(() => {
    fetchFutureRevenues(dispatch);
  }, [dispatch]);

  const onAdd = useCallback(() => {
    dispatch(replace('/statements/revenues/new'));
  }, [dispatch]);

  return <RevenuesPage onAdd={onAdd} {...props} />;
}

function mapStateToProps(state) {
  const { filters, loading } = getStatementPageInfo(state);
  const futureRevenues = getFutureRevenues(state);
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);

  return {
    revenues: getFetchedStatementsByType(state, 'revenue'),
    futureRevenues,
    futureRevenuesAmount: futureRevenues?.reduce(
      (sum, item) => sum + item?.amount,
      0,
    ),
    currency,
    loading,
    filters,
  };
}

export default connect(mapStateToProps)(RevenuesPageContainer);
