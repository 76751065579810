import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Arrow = (props) => {
  const {color = '#fff', size = 16, position = 'right', ...rest} = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      sx={{
        transform: position == 'left' && 'rotate(180deg) !important',
        height: size,
        width: size,
      }}
      {...rest}
    >
      <g
        id="Group_7761"
        data-name="Group 7761"
        transform="translate(-1779 -284)"
      >
        <rect
          id="Rectangle_407"
          data-name="Rectangle 407"
          width="16"
          height="16"
          transform="translate(1779 284)"
          fill="none"
          opacity="0"
        />
        <g id="chev-right-thick" transform="translate(1777 284)">
          <path
            id="Path_291"
            data-name="Path 291"
            d="M7.6,16,6,14.4,12.4,8,6,1.6,7.6,0l8,8Z"
            fill={color}
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Arrow;
