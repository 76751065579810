const isYoutubeCode = (lesson) => {
  return lesson?.videoUrl?.indexOf("youtu") > -1;
};

const getYoutubeId = (videoUrl) => {
  return videoUrl?.match(
    /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
  )[1];
};

const getYoutubeChatUrl = (lesson) => {
  return lesson?.chatUrl
    ? lesson?.chatUrl
    : `https://www.youtube.com/live_chat?v=${getYoutubeId(
        lesson?.videoUrl
      )}&embed_domain=${window.location.hostname}&theme=light`;
};

const getVimeoId = (videoUrl) => {
  return videoUrl?.match(/vimeo.*\/(\d+)/i)[1];
};

const getVimeoChatUrl = (lesson) => {
  return lesson?.chatUrl
    ? lesson?.chatUrl
    : `https://vimeo.com/live-chat/${getVimeoId(lesson?.videoUrl)}#extern}`;
};

export const getChatUrl = (lesson) => {
  return isYoutubeCode(lesson)
    ? getYoutubeChatUrl(lesson)
    : getVimeoChatUrl(lesson);
};
