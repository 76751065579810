import moment from 'moment-timezone';
import React from 'react';
import { capitalize, toCurrency } from '../../utils';
import Box from '../library/Box';
import Title from '../library/Title';
import StatementIncomeChartData from './StatementIncomeChartData';
import TopStatements from './TopStatements';
import HorizontalItems from '../library/HorizontalItems';
import { Card, Separator } from '../library';
import StatementsCardAmount from './StatementsCardAmount';
import PizzaChart from '../library/PizzaChart';
import Spinner from '../library/Spinner';

const titleSx = {
  fontSize: 14,
  color: 'black',
};
const descriptionSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  fontSize: 12,
};

const loadingSx = {
  textAlign: 'center',
};

export default function GeneralStatementsData(props) {
  const {
    revenues,
    expenses,
    totalExpenses,
    totalRevenues,
    topExpenses,
    topRevenues,
    topCustomers,
    topProducts,
    productsCategoryRanking,
    ordersReasonRanking,
    ordersAverageAmount,
    totalProductsStockAmount,
    ordersAverageProfitMargin,
    startDate,
    endDate,
    currency,
    loadingCustomers,
    loadingProducts,
    loadingGeneralData,
    openProductsDetails,
  } = props;

  const monthStart = capitalize(moment(startDate).format('MMMM'));
  const monthEnd = capitalize(moment(endDate).format('MMMM'));

  const yearStart = moment(startDate).year();
  const yearEnd = moment(endDate).year();

  const lowRange = monthStart == monthEnd && yearStart == yearEnd;
  const title = lowRange
    ? `${monthStart} de ${yearStart}`
    : `${monthStart}/${yearStart} - ${monthEnd}/${yearEnd}`;

  return (
    <Box>
      <Title>{title}</Title>
      <StatementIncomeChartData
        totalExpenses={totalExpenses}
        totalRevenues={totalRevenues}
        currency={currency}
      />
      {loadingCustomers && (
        <Box pt={6} sx={loadingSx}>
          <Spinner inline />
        </Box>
      )}
      {topCustomers?.length > 0 && !loadingCustomers && (
        <>
          <Separator />
          <Card title="Clientes mais valiosos">
            <Box pt={2}>
              <HorizontalItems
                items={topCustomers}
                titleStyle={titleSx}
                descriptionStyle={descriptionSx}
              />
            </Box>
          </Card>
        </>
      )}
      {loadingProducts && (
        <Box pt={6} sx={loadingSx}>
          <Spinner inline />
        </Box>
      )}
      {topProducts?.length > 0 && !loadingProducts && (
        <>
          <Card title="Top 3 produtos">
            <Box pt={2}>
              <HorizontalItems
                items={topProducts}
                titleStyle={titleSx}
                descriptionStyle={descriptionSx}
              />
            </Box>
          </Card>
        </>
      )}
      {loadingGeneralData ? (
        <Box pt={6} sx={loadingSx}>
          <Spinner inline />
        </Box>
      ) : (
        <>
          {ordersAverageAmount > 0 && (
            <StatementsCardAmount
              title="Ticket médio de pedidos"
              amount={toCurrency(ordersAverageAmount, currency?.code)}
            />
          )}
          {ordersAverageProfitMargin > 0 && (
            <StatementsCardAmount
              title="Margem de lucro média por pedido"
              amount={`${ordersAverageProfitMargin}%`}
            />
          )}
          {totalProductsStockAmount > 0 && (
            <StatementsCardAmount
              title="Valor de produtos em estoque"
              amount={toCurrency(totalProductsStockAmount, currency?.code)}
            />
          )}
        </>
      )}
      {productsCategoryRanking?.length > 0 && (
        <PizzaChart
          title="Categoria de produtos vendidos"
          data={productsCategoryRanking}
          valueName="productsSales"
          action={openProductsDetails}
          actionLabel="Informações de produtos"
        />
      )}
      {ordersReasonRanking?.length > 0 && (
        <PizzaChart
          title="Motivo dos pedidos"
          data={ordersReasonRanking}
          valueName="ordersSales"
        />
      )}
      {revenues.length > 5 && (
        <TopStatements
          statements={topRevenues}
          type={'revenue'}
          currency={currency}
        />
      )}
      {expenses.length > 5 && (
        <TopStatements
          statements={topExpenses}
          type={'expense'}
          currency={currency}
        />
      )}
    </Box>
  );
}
