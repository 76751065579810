import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchTasks } from '../../processes/taskProcesses';
import { getEvents } from '../../selectors/eventSelectors';
import WeeklyEventsCalendar from './WeeklyEventsCalendar';
import moment from 'moment-timezone';
import { fetchOrders } from '../../processes/orderProcesses';
// import { fetchStatements } from '../../processes/statementProcesses';

function WeeklyEventsCalendarContainer(props) {
  const { dispatch } = props;

  const today = moment();

  const sixDaysLater = today.clone().add(6, 'days');
  const betweenDates = [today.format(), sixDaysLater.format()];

  useEffect(() => {
    fetchOrders(dispatch, { filters: { betweenDates, isDeletedEq: false } });
    // fetchStatements(dispatch, {
    //   filters: { betweenDates, isDeletedEq: false },
    // });
    fetchTasks(dispatch);
  }, [dispatch]);

  return <WeeklyEventsCalendar {...props} />;
}

function mapStateToProps(state) {
  return {
    events: getEvents(state),
  };
}

export default connect(mapStateToProps)(WeeklyEventsCalendarContainer);
