import { createSelector } from 'reselect';
import orderByDate from '../utils/orderByDate';

const getTasks = (state) => state.entities.tasks;
const getId = (_, id) => id;
const getTasksList = (state) => state.tasksList;
const getFetchedIds = (state) => state.tasksList.taskIds;

export const getTaskPageInfo = createSelector(getTasksList, (tasksList) => ({
  currentPage: tasksList.currentPage,
  totalPages: tasksList.totalPages,
  filters: tasksList.filters,
  loading: tasksList.loading,
}));

export const getAllTasks = createSelector(getTasks, (tasks) =>
  Object.values(tasks).sort(orderByDate('scheduleAt', 'asc')),
);

export const getTask = createSelector(
  getTasks,
  getId,
  (tasks, id) => tasks[id],
);

export const getTasksByFetchedIds = createSelector(
  getAllTasks,
  getFetchedIds,
  (tasks, ids) =>
    tasks
      .filter((task) => ids.includes(task.id))
      .sort(orderByDate('scheduleAt')),
);
