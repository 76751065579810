import React from 'react';
import { spacing, uiColors } from './theme';

const separator = {
  marginTop: spacing.m,
  marginBottom: spacing.m,
  border: `1px solid ${uiColors.border}`,
};

export default function Separator({ style }) {
  return <hr style={{ ...separator, ...(style && style) }} />;
}
