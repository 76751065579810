import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Eye = (props) => {
  const {color = '#1b5c96', ...rest} = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="13.333"
      viewBox="0 0 20 13.333"
      {...rest}
    >
      <path
        id="eye-regular"
        d="M10,66.778a3.852,3.852,0,0,0-1.085.174,1.924,1.924,0,0,1,.251.937,1.944,1.944,0,0,1-1.944,1.944,1.924,1.924,0,0,1-.938-.251A3.879,3.879,0,1,0,10,66.778ZM19.88,70.16A11.137,11.137,0,0,0,10,64,11.138,11.138,0,0,0,.121,70.16a1.123,1.123,0,0,0,0,1.014A11.137,11.137,0,0,0,10,77.333a11.138,11.138,0,0,0,9.879-6.16A1.123,1.123,0,0,0,19.88,70.16ZM10,75.667a9.446,9.446,0,0,1-8.261-5,9.445,9.445,0,0,1,8.261-5,9.445,9.445,0,0,1,8.261,5A9.445,9.445,0,0,1,10,75.667Z"
        transform="translate(-0.001 -64)"
        fill={color}
      />
    </SvgIcon>
  );
};

export default Eye;
