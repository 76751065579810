import * as React from 'react';
import Box from '@mui/material/Box';
import WomanWithLaptop from './WomanWithLaptop';
import DecoratingCake from './DecoratingCake';
import WithDoubt from './WithDoubt';
import MailSent from './MailSent';
import RelaxedCook from './RelaxedCook';
import BlueHairCookWoman from './BlueHairCookWoman';
import BlueHairCookMan from './BlueHairCookMan';
import AvatarInput from './AvatarInput';
import OrangeFigure from './OrangeFigure';
import ManSittingAtBlueHat from './ManSittingAtBlueHat';
import ListingIngredients from './ListingIngredients';
import Cake from './Cake.png';
import Cream from './Cream.png';
import ImageInput from './ImageInput.png';

export default {
  title: 'Library/Images',
  component: Box,
};

const defaultArgs = {
  sx: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const Template = (args) => <Box {...defaultArgs} {...args} />;

export const WomanWithLaptopImage = Template.bind({});
WomanWithLaptopImage.args = {
  children: <WomanWithLaptop />,
};

export const DecoratingCakeImage = Template.bind({});
DecoratingCakeImage.args = {
  children: <DecoratingCake />,
};

export const WithDoubtImage = Template.bind({});
WithDoubtImage.args = {
  children: <WithDoubt />,
};

export const MailSentImage = Template.bind({});
MailSentImage.args = {
  children: <MailSent />,
};

export const RelaxedCookImage = Template.bind({});
RelaxedCookImage.args = {
  children: <RelaxedCook />,
};

export const BlueHairCookWomanImage = Template.bind({});
BlueHairCookWomanImage.args = {
  children: <BlueHairCookWoman />,
};

export const BlueHairCookManImage = Template.bind({});
BlueHairCookManImage.args = {
  children: <BlueHairCookMan />,
};

export const AvatarInputImage = Template.bind({});
AvatarInputImage.args = {
  children: <AvatarInput />,
};

export const OrangeFigureImage = Template.bind({});
OrangeFigureImage.args = {
  children: <OrangeFigure />,
};

export const ManSittingAtBlueHatImage = Template.bind({});
ManSittingAtBlueHatImage.args = {
  children: <ManSittingAtBlueHat />,
};

export const ListingIngredientsImage = Template.bind({});
ListingIngredientsImage.args = {
  children: <ListingIngredients />,
};

export const CakeImage = Template.bind({});
CakeImage.args = {
  children: <img src={Cake} />,
};

export const CreamImage = Template.bind({});
CreamImage.args = {
  children: <img src={Cream} />,
};

export const DefaultImageInput = Template.bind({});
DefaultImageInput.args = {
  children: <img src={ImageInput} />,
};
