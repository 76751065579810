import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import { orderByAlphabetic } from '../utils/orderByAlphabetic';

const getRecipesConnections = (state) => state.entities.recipesConnections;
const getRecipes = (state) => state.entities.recipes;
const getProps = (_, props) => props;

const getAllRecipes = createSelector(getRecipes, (recipes) =>
  Object.values(recipes).sort(orderByAlphabetic),
);

function formState(state) {
  return getFormValues('recipe')(state);
}

const getAllRecipesConnections = createSelector(
  getRecipesConnections,
  (recipesConnections) => Object.values(recipesConnections),
);

export const getRecipesConnectionsByRecipe = createSelector(
  getAllRecipesConnections,
  getProps,
  (recipesConnections, { recipeId }) => {
    return recipesConnections.filter(
      (recipeConnections) => recipeConnections.parentRecipeId == recipeId,
    );
  },
);

export const getRecipeConnections = createSelector(formState, (form) =>
  form?.parentConnectionsAttributes?.filter((rC) => !!!rC?._destroy),
);

export const getRecipeConnectionsCostErrors = createSelector(
  getRecipeConnections,
  getAllRecipes,
  (recipeConnections, recipes) => {
    const recipesIds = recipeConnections?.map(
      (recipeConnection) => recipeConnection?.childRecipeId,
    );
    const result = recipes?.filter(
      (recipe) => recipe?.cost <= 0 && recipesIds?.includes(recipe?.id),
    );

    return !!result?.length ? result : false;
  },
);

export const getRecipesConnectionsTotalCost = createSelector(
  getRecipeConnections,
  getAllRecipes,
  (recipeConnections, recipes) => {
    const getRecipe = (id) => recipes.find((p) => p.id == id);

    return recipeConnections
      ?.map((item) => {
        const recipe = getRecipe(item?.childRecipeId);
        return recipe?.cost * (item?.quantity / recipe?.estimatedProduce);
      })
      .reduce((acc, num) => (acc += num), 0);
  },
);
