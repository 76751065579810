import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import StatementsProducts from './StatementsProducts';
import moment from 'moment-timezone';
import { fetchProducts } from '../../processes/productProcessess';
import { getProductsRankedByCategory } from '../../selectors/productSelectors';

function StatementsProductsContainer(props) {
  const { dispatch } = props;
  const [loading, setLoading] = useState(false);

  const { dates } = props?.match?.params;
  const [startDateString, endDateString] = dates
    ? dates.split(',')
    : [null, null];

  const startDate = useMemo(
    () => (startDateString ? moment(startDateString) : null),
    [startDateString],
  );
  const endDate = useMemo(
    () => (endDateString ? moment(endDateString) : null),
    [endDateString],
  );

  const betweenDates = useMemo(
    () => [startDate, endDate],
    [startDate, endDate],
  );

  useEffect(() => {
    if (startDate && endDate && betweenDates) {
      setLoading(true);
      fetchProducts(dispatch, { betweenDates }).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, startDate, endDate, betweenDates]);

  return (
    <StatementsProducts
      startDate={startDate}
      endDate={endDate}
      loading={loading}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  return {
    products: getProductsRankedByCategory(state),
  };
}

export default connect(mapStateToProps)(StatementsProductsContainer);
