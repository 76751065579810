import React, { useEffect } from 'react';
import Statements from './Statements';
import {
  getStatementPageInfo,
  getStatementsByFetchedIds,
} from '../../selectors/statementSelectors';
import { fetchStatements } from '../../processes/statementProcesses';
import { connect } from 'react-redux';

function StatementsContainer(props) {
  const { dispatch, filters } = props;

  useEffect(() => {
    filters && !filters?.typeEq && fetchStatements(dispatch, { filters });
  }, [dispatch, filters]);

  return <Statements {...props} />;
}

function mapStateToProps(state) {
  const statements = getStatementsByFetchedIds(state);
  const { filters, loading } = getStatementPageInfo(state);

  return {
    statements,
    empty: !statements?.length,
    loading,
    filters,
  };
}

export default connect(mapStateToProps)(StatementsContainer);
