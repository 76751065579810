import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Location = (props) => {
  const {color = '#fff', ...rest} = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...rest}
    >
      <g id="Group_8811" data-name="Group 8811" transform="translate(-56 -97)">
        <rect
          id="Rectangle_8773"
          data-name="Rectangle 8773"
          width="16"
          height="16"
          transform="translate(56 97)"
          fill="none"
        />
        <path
          id="map-marker-alt-solid"
          d="M4.71,13.718C.737,7.958,0,7.367,0,5.25a5.25,5.25,0,0,1,10.5,0c0,2.117-.737,2.708-4.71,8.468a.657.657,0,0,1-1.079,0Zm.54-6.28A2.187,2.187,0,1,0,3.062,5.25,2.187,2.187,0,0,0,5.25,7.437Z"
          transform="translate(59 98)"
          fill="#1b5c96"
        />
      </g>
    </SvgIcon>
  );
};

export default Location;
