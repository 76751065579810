import React from 'react';

const WomanWithLaptop = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="174.846"
      height="205.94"
      viewBox="0 0 174.846 205.94"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_8025"
            data-name="Rectangle 8025"
            width="157.658"
            height="150.84"
            fill="none"
          />
        </clipPath>
        <clipPath id="clipPath-3">
          <rect
            id="Rectangle_8020"
            data-name="Rectangle 8020"
            width="17.053"
            height="6.496"
            fill="none"
          />
        </clipPath>
        <clipPath id="clipPath-4">
          <rect
            id="Rectangle_8021"
            data-name="Rectangle 8021"
            width="5.436"
            height="24.469"
            fill="none"
          />
        </clipPath>
        <clipPath id="clipPath-5">
          <rect
            id="Rectangle_8022"
            data-name="Rectangle 8022"
            width="10.763"
            height="13.461"
            fill="none"
          />
        </clipPath>
        <clipPath id="clipPath-6">
          <rect
            id="Rectangle_8023"
            data-name="Rectangle 8023"
            width="12.498"
            height="15.811"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Group_8902"
        data-name="Group 8902"
        transform="translate(-99.659 -81)"
      >
        <path
          id="Path_2684"
          data-name="Path 2684"
          d="M184.255,69.431c0,35.839-41.262,82.453-87.423,64.892C53.864,117.978,9.409,105.27,9.409,69.431S56.514-15.18,96.832,4.539c42.889,20.976,87.423,29.053,87.423,64.892"
          transform="translate(90.25 148.793)"
          fill="#ef9456"
        />
        <g id="Group_7828" data-name="Group 7828" transform="translate(109 81)">
          <g
            id="Group_7821"
            data-name="Group 7821"
            transform="translate(-0.001)"
            clipPath="url(#clipPath)"
          >
            <g id="Group_7820" data-name="Group 7820">
              <g
                id="Group_7819"
                data-name="Group 7819"
                clipPath="url(#clipPath)"
              >
                <path
                  id="Path_2767"
                  data-name="Path 2767"
                  d="M501.605,530.723l5.506-58.293A7.922,7.922,0,0,0,500,463.775q-.308-.03-.617-.036l-61.309-1.217a7.926,7.926,0,0,0-8.061,7.287l-4.7,60.888Z"
                  transform="translate(-349.491 -380.066)"
                  fill="#1b5c96"
                />
                <path
                  id="Path_2768"
                  data-name="Path 2768"
                  d="M766.592,535.064a1.132,1.132,0,0,0,.035-.2c0-.148.039-.348.07-.608.056-.543.143-1.325.248-2.329.2-2.034.5-4.958.869-8.569.708-7.252,1.686-17.247,2.764-28.288.821-8.6,1.586-16.569,2.2-23.052q0-.487-.026-.965a3.871,3.871,0,0,0-.1-.947,2.906,2.906,0,0,0-1.012-1.525,2.869,2.869,0,0,0-1.634-.6l-1.612-.031-5.375-.083-3.437-.044h-1.208s.1,0,.309.022l.9.035,3.437.1,5.371.13,1.608.043a2.681,2.681,0,0,1,2.468,2,3.632,3.632,0,0,1,.091.908c0,.313,0,.63.022.952-.63,6.483-1.408,14.448-2.247,23.052-1.052,11.042-2,21.04-2.69,28.293-.334,3.615-.6,6.518-.791,8.578-.087,1-.152,1.79-.2,2.333-.022.261-.035.461-.048.608a1.518,1.518,0,0,0-.013.2"
                  transform="translate(-623.177 -384.42)"
                  fill="#263238"
                />
                <path
                  id="Path_2769"
                  data-name="Path 2769"
                  d="M398.495,268.64a2.831,2.831,0,0,0-1.151-.713h.083a2.121,2.121,0,0,0-.239-.035,1.117,1.117,0,0,0-.869.126,2.073,2.073,0,0,0-1.112,1.008,2.451,2.451,0,0,0,.343,2.516,1.873,1.873,0,0,0,3.42-.717,2.334,2.334,0,0,0-.474-2.186"
                  transform="translate(-324.585 -220.104)"
                  fill="#455a64"
                />
                <path
                  id="Path_2770"
                  data-name="Path 2770"
                  d="M352.785,37.969a9.386,9.386,0,0,1-6.357-5.888,9.56,9.56,0,0,1,1.256-8.617c-1.8.461-3.589-1.212-3.959-3.042a9.865,9.865,0,0,1,.978-5.467c1.591-3.976,3.581-7.965,6.9-10.638s8.256-3.689,11.837-1.391c.03-1.56,1.86-2.342,3.381-2.607A24.063,24.063,0,0,1,385.771,5.24a22.244,22.244,0,0,1,8.1,17.873,7.888,7.888,0,0,1-2.9,10.785,18.086,18.086,0,0,1-2.173,11.88,17.761,17.761,0,0,1-9.195,7.713"
                  transform="translate(-282.361 -0.001)"
                  fill="#1b5c96"
                />
                <path
                  id="Path_2771"
                  data-name="Path 2771"
                  d="M403.982,134.808c.4-5.81,4.3-12.91,10.086-12.258l18.042,4.693a3.411,3.411,0,0,1,3.042,3.476L432.9,174.3l.126.026s-4.176,12.254-16.078,8.447c-2.368-.756-3.437-3.35-3.911-6.018h-.122v-.913a28.655,28.655,0,0,1-.043-5.984v-4.928c0,.035-7.413-1.3-8.886-10.976-.752-4.806-.456-12.68,0-19.15"
                  transform="translate(-331.588 -100.668)"
                  fill="#ef9456"
                />
                <path
                  id="Path_2772"
                  data-name="Path 2772"
                  d="M424.143,220.914a1.238,1.238,0,0,0,1.191,1.26,1.186,1.186,0,0,0,1.3-1.061c0-.017,0-.034,0-.051a1.243,1.243,0,0,0-1.186-1.26,1.2,1.2,0,0,0-1.3,1.074c0,.013,0,.026,0,.039"
                  transform="translate(-348.53 -180.612)"
                  fill="#263238"
                />
                <path
                  id="Path_2773"
                  data-name="Path 2773"
                  d="M415.379,208.657c.152.165,1.1-.526,2.438-.513s2.308.687,2.455.526c.07-.074-.082-.365-.508-.678a3.384,3.384,0,0,0-1.964-.621,3.281,3.281,0,0,0-1.942.613c-.4.309-.539.6-.478.674"
                  transform="translate(-341.317 -170.402)"
                  fill="#263238"
                />
                <path
                  id="Path_2774"
                  data-name="Path 2774"
                  d="M496.439,222.155a1.239,1.239,0,0,0,1.191,1.26,1.186,1.186,0,0,0,1.3-1.061c0-.017,0-.034,0-.051a1.247,1.247,0,0,0-1.186-1.26,1.191,1.191,0,0,0-1.3,1.065c0,.016,0,.031,0,.047"
                  transform="translate(-407.937 -181.632)"
                  fill="#263238"
                />
                <path
                  id="Path_2775"
                  data-name="Path 2775"
                  d="M489.091,210.559c.156.165,1.1-.526,2.438-.513s2.312.687,2.455.526c.074-.074-.083-.365-.508-.678a3.376,3.376,0,0,0-1.964-.621,3.267,3.267,0,0,0-1.938.613c-.413.309-.556.6-.482.674"
                  transform="translate(-401.884 -171.965)"
                  fill="#263238"
                />
                <path
                  id="Path_2776"
                  data-name="Path 2776"
                  d="M446.008,220.513a9.221,9.221,0,0,0-2.173-.4c-.339-.039-.665-.109-.717-.343a1.69,1.69,0,0,1,.235-1.008c.326-.826.665-1.686,1.025-2.607a44.858,44.858,0,0,0,2.312-6.783,44.928,44.928,0,0,0-2.851,6.579c-.343.913-.674,1.777-.986,2.607a1.989,1.989,0,0,0-.187,1.338.869.869,0,0,0,.56.5,2.3,2.3,0,0,0,.578.083,8.156,8.156,0,0,0,2.2.035"
                  transform="translate(-363.716 -172.042)"
                  fill="#263238"
                />
                <path
                  id="Path_2777"
                  data-name="Path 2777"
                  d="M455.832,345.026a24.611,24.611,0,0,0,12.988-3.372s-3.189,6.631-12.875,5.649Z"
                  transform="translate(-374.569 -280.746)"
                  fill="#aa6550"
                />
                <path
                  id="Path_2778"
                  data-name="Path 2778"
                  d="M464.13,285.885a2.412,2.412,0,0,1,2.173-.869,2.173,2.173,0,0,1,1.512.826,1.4,1.4,0,0,1,.1,1.591,1.625,1.625,0,0,1-1.738.434,5.094,5.094,0,0,1-1.7-1.034,1.483,1.483,0,0,1-.378-.4.435.435,0,0,1,0-.5"
                  transform="translate(-381.295 -234.189)"
                  fill="#aa6550"
                />
                <path
                  id="Path_2779"
                  data-name="Path 2779"
                  d="M463.938,277.23c-.217,0-.23,1.438-1.482,2.459s-2.79.869-2.8,1.052c-.022.091.343.287,1,.309a3.6,3.6,0,0,0,2.338-.8,3.125,3.125,0,0,0,1.147-2.06c.052-.608-.109-.969-.2-.96"
                  transform="translate(-377.709 -227.808)"
                  fill="#263238"
                />
                <path
                  id="Path_2780"
                  data-name="Path 2780"
                  d="M485.878,198.809c.13.365,1.464.2,3.042.4s2.833.639,3.042.313c.1-.156-.122-.5-.63-.869a5.146,5.146,0,0,0-2.251-.8,5.067,5.067,0,0,0-2.377.265c-.591.226-.882.508-.826.7"
                  transform="translate(-399.254 -162.544)"
                  fill="#263238"
                />
                <path
                  id="Path_2781"
                  data-name="Path 2781"
                  d="M418.276,186.655c.235.3,1.156,0,2.264,0s2.042.217,2.26-.1c.1-.152-.048-.456-.434-.747a3.076,3.076,0,0,0-1.834-.508,3.125,3.125,0,0,0-1.812.6c-.413.3-.539.613-.443.76"
                  transform="translate(-343.684 -152.263)"
                  fill="#263238"
                />
                <path
                  id="Path_2782"
                  data-name="Path 2782"
                  d="M481.612,187.02a11.829,11.829,0,0,0-.261-1.7,6.685,6.685,0,0,0-.782-1.842,6.951,6.951,0,0,0-8.313-2.938,6.551,6.551,0,0,0-1.738.939,6.373,6.373,0,0,0-1.456,1.469,6.853,6.853,0,0,0,0,8.148,6.366,6.366,0,0,0,1.456,1.469,6.546,6.546,0,0,0,1.738.939,6.952,6.952,0,0,0,3.624.309,6.862,6.862,0,0,0,2.916-1.3,6.954,6.954,0,0,0,1.773-1.96,6.636,6.636,0,0,0,.782-1.842,11.944,11.944,0,0,0,.261-1.7.431.431,0,0,1,0,.113v.335a3.279,3.279,0,0,1-.044.539,5.6,5.6,0,0,1-.13.734,6.73,6.73,0,0,1-.76,1.886,7.051,7.051,0,0,1-8.5,3.068,6.672,6.672,0,0,1-1.8-.96,8.047,8.047,0,0,1-.808-.687,7.418,7.418,0,0,1-.7-.821,7.066,7.066,0,0,1,0-8.417,7.417,7.417,0,0,1,.7-.821,8.066,8.066,0,0,1,.808-.687,6.674,6.674,0,0,1,1.8-.96,7.09,7.09,0,0,1,8.513,3.068,6.734,6.734,0,0,1,.76,1.886,5.746,5.746,0,0,1,.131.734,3.334,3.334,0,0,1,.043.539v.334a.48.48,0,0,1-.017.135"
                  transform="translate(-384.145 -147.831)"
                  fill="#263238"
                />
                <path
                  id="Path_2783"
                  data-name="Path 2783"
                  d="M409.734,108.234a.487.487,0,0,1-.039-.135c-.026-.1-.057-.23-.091-.4s-.083-.4-.152-.647-.152-.547-.239-.869a20.911,20.911,0,0,0-2.42-5.214,22.741,22.741,0,0,0-5.853-6.153A25.412,25.412,0,0,0,391.4,90.7a30.63,30.63,0,0,0-10.429-.348,28.134,28.134,0,0,0-8.2,2.281,20.3,20.3,0,0,0-2.855,1.612,18.162,18.162,0,0,0-1.942,1.538l-.661.621-.435.491-.278.3a.661.661,0,0,1-.1.1.63.63,0,0,1,.087-.113l.261-.313.435-.5.647-.643a17.693,17.693,0,0,1,1.929-1.577,20.15,20.15,0,0,1,2.864-1.651,27.7,27.7,0,0,1,8.256-2.347,30.5,30.5,0,0,1,10.485.335,25.484,25.484,0,0,1,9.608,4.167,22.677,22.677,0,0,1,5.875,6.231,20.085,20.085,0,0,1,2.368,5.275l.222.869c.065.256.1.478.13.656s.052.3.069.4a.481.481,0,0,1,0,.152"
                  transform="translate(-301.161 -73.829)"
                  fill="#263238"
                />
                <path
                  id="Path_2784"
                  data-name="Path 2784"
                  d="M482.607,38.786a.284.284,0,0,1-.022-.1.9.9,0,0,0-.039-.3c-.026-.261-.087-.643-.187-1.134a14.007,14.007,0,0,0-1.486-3.933,18.276,18.276,0,0,0-3.941-4.823,33.887,33.887,0,0,0-6.344-4.3,90.477,90.477,0,0,0-12.754-5.684c-1.686-.6-3.068-1.043-4.024-1.338l-1.112-.339-.283-.091c-.065,0-.1-.035-.1-.039a.617.617,0,0,1,.1,0l.291.07c.256.065.635.161,1.117.3.965.269,2.351.687,4.05,1.269a84.8,84.8,0,0,1,12.81,5.649,33.455,33.455,0,0,1,6.375,4.345,18.18,18.18,0,0,1,3.941,4.9,13.508,13.508,0,0,1,1.434,3.993,9.976,9.976,0,0,1,.148,1.147v.3a.318.318,0,0,1,.022.109"
                  transform="translate(-371.683 -13.723)"
                  fill="#263238"
                />
                <path
                  id="Path_2785"
                  data-name="Path 2785"
                  d="M453.709,127.2s9.086-.135,15.965,10.12c4.532,6.762,5.488,15.248,5.488,15.248l3.507-16.152-2.607-7.387-6.279-3.55-11.915-.669-4.5,2.225"
                  transform="translate(-372.546 -102.563)"
                  fill="#1b5c96"
                />
                <path
                  id="Path_2786"
                  data-name="Path 2786"
                  d="M407.416,115.164c-14.47-3.476-26.042,1.3-28.163,12.658l-3.885-6.34,8.469-10.194,8.256-2.147,16.6,2.525Z"
                  transform="translate(-308.451 -89.684)"
                  fill="#1b5c96"
                />
                <path
                  id="Path_2787"
                  data-name="Path 2787"
                  d="M403.513,76.282a.561.561,0,0,1-.035-.139,2.946,2.946,0,0,0-.069-.408,5.3,5.3,0,0,0-.13-.66,8.176,8.176,0,0,0-.243-.869,12.528,12.528,0,0,0-1-2.312,14.16,14.16,0,0,0-1.96-2.69,16.44,16.44,0,0,0-3.081-2.607,18.366,18.366,0,0,0-4.172-2,31.106,31.106,0,0,0-10.511-1.351,45.739,45.739,0,0,0-10.564,1.273,23.635,23.635,0,0,0-4.346,1.595,18.546,18.546,0,0,0-3.415,2.151,15.079,15.079,0,0,0-3.82,4.4c-.17.3-.317.574-.434.817s-.213.434-.278.613-.117.287-.161.387-.057.13-.061.126a10.026,10.026,0,0,1,.847-1.986,14.8,14.8,0,0,1,3.8-4.476,18.2,18.2,0,0,1,3.433-2.173,23.617,23.617,0,0,1,4.38-1.634,45.458,45.458,0,0,1,10.616-1.3,30.574,30.574,0,0,1,10.581,1.382,18.292,18.292,0,0,1,4.211,2.038,16.281,16.281,0,0,1,3.094,2.651,14.125,14.125,0,0,1,1.955,2.733,12.207,12.207,0,0,1,.969,2.347,8.4,8.4,0,0,1,.226.9,4.379,4.379,0,0,1,.109.669c.022.174.039.308.052.434a.647.647,0,0,1,.009.1"
                  transform="translate(-295.191 -51.785)"
                  fill="#263238"
                />
                <path
                  id="Path_2788"
                  data-name="Path 2788"
                  d="M379.175,131.769a2.554,2.554,0,0,1-.6-.117,5.181,5.181,0,0,1-1.486-.756,6.787,6.787,0,0,1-1.66-1.838,9.834,9.834,0,0,1-1.16-2.8,13.174,13.174,0,0,1-.061-5.467,8.694,8.694,0,0,1,.474-1.6c.07-.182.144-.317.187-.408a.392.392,0,0,1,.083-.135,15.263,15.263,0,0,0-.587,2.173,13.9,13.9,0,0,0,.126,5.384,10.225,10.225,0,0,0,1.112,2.746,6.848,6.848,0,0,0,1.582,1.816,6.549,6.549,0,0,0,1.99,1"
                  transform="translate(-307.286 -97.494)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2789"
                  data-name="Path 2789"
                  d="M428.922,132.111l-.1-.03-.269-.117-.435-.191-.608-.23a17.567,17.567,0,0,0-4.011-.965,24.836,24.836,0,0,0-6.118.026c-1.147.135-2.342.352-3.576.6s-2.5.561-3.772.956c-5.136,1.486-9.095,4.723-10.863,7.709a14.031,14.031,0,0,0-1.595,3.793c-.126.474-.2.843-.252,1.1-.026.113-.043.209-.061.287a.427.427,0,0,1-.03.1.254.254,0,0,1,0-.1,2.486,2.486,0,0,1,.044-.291,10.615,10.615,0,0,1,.213-1.112,13.352,13.352,0,0,1,1.538-3.854c1.773-3.042,5.771-6.344,10.955-7.822,1.3-.4,2.555-.7,3.793-.956s2.438-.465,3.594-.591a24.411,24.411,0,0,1,6.166,0,16.8,16.8,0,0,1,4.02,1.043l.6.252.435.213.261.135c.039.026.07.044.065.048"
                  transform="translate(-326.415 -107.01)"
                  fill="#263238"
                />
                <path
                  id="Path_2790"
                  data-name="Path 2790"
                  d="M468.775,140.886a1.448,1.448,0,0,1,.317,0,8.214,8.214,0,0,1,.9.117,12.568,12.568,0,0,1,3.15,1.052,22.879,22.879,0,0,1,8.23,7.113,35.231,35.231,0,0,1,3.063,5.214,42.814,42.814,0,0,1,1.96,4.519,22.439,22.439,0,0,1,.926,3.185c.074.387.126.687.148.891a1.692,1.692,0,0,1,.031.313,1.34,1.34,0,0,1-.074-.3c-.035-.2-.1-.5-.191-.869a26.481,26.481,0,0,0-.986-3.155,46.013,46.013,0,0,0-2-4.476,36.672,36.672,0,0,0-3.042-5.158,23.39,23.39,0,0,0-8.117-7.1,13.37,13.37,0,0,0-3.1-1.108c-.382-.078-.678-.135-.869-.161a1.722,1.722,0,0,1-.343-.074"
                  transform="translate(-385.205 -115.763)"
                  fill="#263238"
                />
                <path
                  id="Path_2791"
                  data-name="Path 2791"
                  d="M389.675,187.035a11.077,11.077,0,0,0-.261-1.7,6.678,6.678,0,0,0-.782-1.842,6.952,6.952,0,0,0-1.773-1.96,6.863,6.863,0,0,0-2.916-1.3,6.952,6.952,0,0,0-3.624.309,6.552,6.552,0,0,0-1.738.939,6.37,6.37,0,0,0-1.456,1.469,6.87,6.87,0,0,0,0,8.148,6.371,6.371,0,0,0,1.456,1.469,6.552,6.552,0,0,0,1.738.939,6.919,6.919,0,0,0,8.313-2.938,6.628,6.628,0,0,0,.782-1.842,11.157,11.157,0,0,0,.261-1.7.308.308,0,0,1,0,.113v.335a3.281,3.281,0,0,1-.048.556,5.632,5.632,0,0,1-.13.734,6.733,6.733,0,0,1-.739,1.873,7.051,7.051,0,0,1-8.5,3.068,6.674,6.674,0,0,1-1.8-.96,8.04,8.04,0,0,1-.808-.687,7.439,7.439,0,0,1-.7-.821,7.066,7.066,0,0,1,0-8.417,7.44,7.44,0,0,1,.7-.821,8.018,8.018,0,0,1,.808-.687,6.674,6.674,0,0,1,1.8-.96,7.053,7.053,0,0,1,8.5,3.081,6.734,6.734,0,0,1,.76,1.886,5.745,5.745,0,0,1,.13.734,3.347,3.347,0,0,1,.044.539v.335a.349.349,0,0,1-.017.117"
                  transform="translate(-308.613 -147.846)"
                  fill="#263238"
                />
                <path
                  id="Path_2792"
                  data-name="Path 2792"
                  d="M561.267,265.6a2.771,2.771,0,0,0-1.134-.713h.087a1.579,1.579,0,0,0-.243-.039,1.1,1.1,0,0,0-.869.13A2.082,2.082,0,0,0,558,265.984a2.442,2.442,0,0,0,.343,2.516,1.869,1.869,0,0,0,3.415-.717,2.321,2.321,0,0,0-.491-2.186"
                  transform="translate(-458.356 -217.6)"
                  fill="#455a64"
                />
                <path
                  id="Path_2793"
                  data-name="Path 2793"
                  d="M395.708,121.836a1.987,1.987,0,0,1-.435-.217,4.241,4.241,0,0,1-.469-.3,7.181,7.181,0,0,1-.591-.434,11.082,11.082,0,0,1-1.391-1.3,12.926,12.926,0,0,1-1.391-1.886,13.229,13.229,0,0,1-1-2.125,11.454,11.454,0,0,1-.487-1.851c-.057-.265-.074-.513-.1-.726a3.948,3.948,0,0,1-.039-.552,2.127,2.127,0,0,1,0-.474c.044,0,.07.669.287,1.738a13.227,13.227,0,0,0,.526,1.812,13.8,13.8,0,0,0,1,2.077,14.033,14.033,0,0,0,1.352,1.864,13.192,13.192,0,0,0,1.334,1.33c.847.669,1.425,1.008,1.4,1.047"
                  transform="translate(-320.307 -92.007)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2794"
                  data-name="Path 2794"
                  d="M419.414,98.757c.035,0-.243.634-.56,1.677a16.942,16.942,0,0,0-.73,4.172,17.278,17.278,0,0,0,.326,4.228c.222,1.065.434,1.708.4,1.738a1.733,1.733,0,0,1-.187-.435,10.266,10.266,0,0,1-.369-1.247,15.277,15.277,0,0,1,.4-8.487,10.039,10.039,0,0,1,.482-1.238,1.978,1.978,0,0,1,.23-.408"
                  transform="translate(-343.384 -81.151)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2795"
                  data-name="Path 2795"
                  d="M458.639,98.813a12.564,12.564,0,0,1-.569-1.238,21.921,21.921,0,0,0-3.537-5.471,12.741,12.741,0,0,1-.9-1.026,1.313,1.313,0,0,1,.291.226,8.672,8.672,0,0,1,.721.691,17.411,17.411,0,0,1,3.568,5.519c.156.391.269.713.339.939a1.322,1.322,0,0,1,.083.36"
                  transform="translate(-372.766 -74.841)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2796"
                  data-name="Path 2796"
                  d="M496.839,95.661a2.937,2.937,0,0,1,.265.917,10.66,10.66,0,0,1-.148,4.567,3.109,3.109,0,0,1-.322.9,25.343,25.343,0,0,0,.435-3.177,25.54,25.54,0,0,0-.23-3.2"
                  transform="translate(-408.098 -78.607)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2797"
                  data-name="Path 2797"
                  d="M519.321,104.948a5.445,5.445,0,0,1-1.26,4.576,14.069,14.069,0,0,0,.939-2.2,14.115,14.115,0,0,0,.322-2.373"
                  transform="translate(-425.705 -86.239)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2798"
                  data-name="Path 2798"
                  d="M534.357,145.323c-.044,0,.048-.752.052-1.96a20.241,20.241,0,0,0-.56-4.684,19.9,19.9,0,0,0-1.656-4.4c-.56-1.069-.982-1.69-.952-1.712a2.2,2.2,0,0,1,.326.434,12.3,12.3,0,0,1,.765,1.221,17.891,17.891,0,0,1,1.738,4.424,17.7,17.7,0,0,1,.5,4.723,13.02,13.02,0,0,1-.113,1.434,2.671,2.671,0,0,1-.1.521"
                  transform="translate(-436.534 -108.932)"
                  fill="#455a64"
                />
                <path
                  id="Path_2799"
                  data-name="Path 2799"
                  d="M573.5,163.229a3.048,3.048,0,0,1,.048.6v.713a6.657,6.657,0,0,1-.048.939,17.929,17.929,0,0,1-.361,2.42,18.374,18.374,0,0,1-2.229,5.536,18.963,18.963,0,0,1-1.412,2c-.213.274-.435.5-.617.708s-.352.378-.491.513a3.065,3.065,0,0,1-.435.4,3,3,0,0,1,.387-.46c.13-.144.291-.322.461-.534s.382-.435.586-.726a19.872,19.872,0,0,0,1.36-2.008,19.87,19.87,0,0,0,2.2-5.488,19.836,19.836,0,0,0,.409-2.39c.048-.343.057-.656.078-.93s.035-.508.035-.7a3.327,3.327,0,0,1,.031-.587"
                  transform="translate(-466.705 -134.13)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2800"
                  data-name="Path 2800"
                  d="M523.637,69.8a2.3,2.3,0,0,1,0,.913,5.567,5.567,0,0,1-2.277,3.672,2.32,2.32,0,0,1-.813.434c0-.03.287-.2.717-.543A6.355,6.355,0,0,0,523.48,70.7a9.006,9.006,0,0,1,.156-.9"
                  transform="translate(-427.748 -57.356)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2801"
                  data-name="Path 2801"
                  d="M569.5,95.686a1.99,1.99,0,0,1-.022.9,4.654,4.654,0,0,1-.869,1.977,4.578,4.578,0,0,1-1.686,1.365,1.966,1.966,0,0,1-.869.252c0-.035.317-.135.791-.4a5.143,5.143,0,0,0,2.486-3.233c.122-.521.13-.865.17-.865"
                  transform="translate(-465.144 -78.628)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2802"
                  data-name="Path 2802"
                  d="M579.553,116.7a2.133,2.133,0,0,1-.491.691,5.451,5.451,0,0,1-3.633,1.738,2.055,2.055,0,0,1-.843-.057,8.425,8.425,0,0,0,2.729-.665,8.528,8.528,0,0,0,2.238-1.708"
                  transform="translate(-472.153 -95.893)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2803"
                  data-name="Path 2803"
                  d="M486.462,70.782a3.719,3.719,0,0,1-.678-2.125,3.666,3.666,0,0,1,.434-2.173,10.174,10.174,0,0,0-.226,2.173,10.556,10.556,0,0,0,.469,2.125"
                  transform="translate(-399.164 -54.632)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2804"
                  data-name="Path 2804"
                  d="M472.577,69.323a2.508,2.508,0,0,1-.6-.569,6.665,6.665,0,0,1-1.478-3.641,2.205,2.205,0,0,1,.026-.821,13.13,13.13,0,0,0,.647,2.668,12.768,12.768,0,0,0,1.4,2.364"
                  transform="translate(-386.604 -52.83)"
                  fill="#455a64"
                />
                <path
                  id="Path_2805"
                  data-name="Path 2805"
                  d="M435.021,69.8a6.418,6.418,0,0,1-.939,4.315c-.065-.03.374-.93.63-2.125s.239-2.19.309-2.19"
                  transform="translate(-356.692 -57.356)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2806"
                  data-name="Path 2806"
                  d="M394.375,90.584c0,.026-.209-.039-.487-.226a3.126,3.126,0,0,1-.869-.956,3.083,3.083,0,0,1-.435-1.225c-.035-.33,0-.539.03-.534a6.621,6.621,0,0,0,.591,1.647,6.483,6.483,0,0,0,1.169,1.295"
                  transform="translate(-322.585 -72.018)"
                  fill="#455a64"
                />
                <path
                  id="Path_2807"
                  data-name="Path 2807"
                  d="M409.5,10.051c.03.026-.435.521-1.017,1.456a9.851,9.851,0,0,0-.956,8.2c.339,1.043.691,1.63.652,1.651a1.79,1.79,0,0,1-.265-.4,8.265,8.265,0,0,1-.539-1.2,9.4,9.4,0,0,1,.982-8.334,8.466,8.466,0,0,1,.8-1.039c.2-.226.33-.343.343-.33"
                  transform="translate(-334.294 -8.259)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2808"
                  data-name="Path 2808"
                  d="M371.453,37.5c.039,0-.1.556-.1,1.451a7.317,7.317,0,0,0,3.042,5.975c.726.526,1.247.734,1.23.774a1.2,1.2,0,0,1-.374-.126,6.08,6.08,0,0,1-.947-.517,7.057,7.057,0,0,1-2.368-2.642,7.136,7.136,0,0,1-.761-3.476,5.8,5.8,0,0,1,.139-1.069,1.168,1.168,0,0,1,.135-.369"
                  transform="translate(-305.004 -30.817)"
                  fill="#455a64"
                />
                <path
                  id="Path_2809"
                  data-name="Path 2809"
                  d="M460.375,22.609a.978.978,0,0,1,.152.3,4.784,4.784,0,0,1,.283.869,7.21,7.21,0,0,1-1.043,5.705,4.673,4.673,0,0,1-.569.7c-.148.152-.235.23-.248.217s.291-.369.682-1a7.845,7.845,0,0,0,1.021-5.588c-.139-.743-.335-1.177-.278-1.195"
                  transform="translate(-377.131 -18.578)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2810"
                  data-name="Path 2810"
                  d="M481.8,30.7a2.488,2.488,0,0,1,.156.952A6.418,6.418,0,0,1,480.436,36a2.674,2.674,0,0,1-.717.652,9.3,9.3,0,0,1,.591-.752,7.361,7.361,0,0,0,1.482-4.241c.013-.582-.03-.952,0-.956"
                  transform="translate(-394.198 -25.229)"
                  fill="#455a64"
                />
                <path
                  id="Path_2811"
                  data-name="Path 2811"
                  d="M529.033,44.62a1.171,1.171,0,0,1,.274.517,3.477,3.477,0,0,1,.156,1.408,3.52,3.52,0,0,1-.434,1.356c-.183.317-.343.474-.365.435a6.639,6.639,0,0,0,.552-1.834,6.686,6.686,0,0,0-.183-1.881"
                  transform="translate(-434.418 -36.665)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2812"
                  data-name="Path 2812"
                  d="M563.653,71.506c.039.048-.3.4-.869.634a1.63,1.63,0,0,1-1.043.2,5.42,5.42,0,0,1,.952-.4,5.8,5.8,0,0,1,.96-.43"
                  transform="translate(-461.598 -58.758)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2813"
                  data-name="Path 2813"
                  d="M596.352,91.006a2.4,2.4,0,0,1,.322,1.538,2.443,2.443,0,0,1-.482,1.495c-.061-.03.217-.669.256-1.508s-.161-1.5-.1-1.525"
                  transform="translate(-489.899 -74.782)"
                  fill="#455a64"
                />
                <path
                  id="Path_2814"
                  data-name="Path 2814"
                  d="M552.915,14.931a1.269,1.269,0,0,1,.191.261,4.521,4.521,0,0,1,.369.8,5.649,5.649,0,0,1-.925,5.5,4.514,4.514,0,0,1-.613.63c-.157.135-.252.2-.265.183s.322-.322.752-.908a5.983,5.983,0,0,0,.9-5.349c-.208-.7-.443-1.091-.408-1.108"
                  transform="translate(-453.324 -12.269)"
                  fill="#455a64"
                />
                <path
                  id="Path_2815"
                  data-name="Path 2815"
                  d="M568.939,40.524a2.281,2.281,0,0,1-.209.939,4.984,4.984,0,0,1-3.376,2.985,2.3,2.3,0,0,1-.956.1c0-.039.361-.074.913-.248a5.471,5.471,0,0,0,3.272-2.9c.243-.513.317-.882.356-.873"
                  transform="translate(-463.781 -33.3)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2816"
                  data-name="Path 2816"
                  d="M599.6,69.8a1.224,1.224,0,0,1-.113.626,3.476,3.476,0,0,1-2.142,2.077,1.3,1.3,0,0,1-.626.1,5.752,5.752,0,0,0,1.773-1.056A5.57,5.57,0,0,0,599.6,69.8"
                  transform="translate(-490.34 -57.356)"
                  fill="#455a64"
                />
                <path
                  id="Path_2817"
                  data-name="Path 2817"
                  d="M378.507,389.174l10.764-6.214,21.145-2.086.139,3.1-5.71,43.2,4.65,36.449H375.613Z"
                  transform="translate(-308.651 -312.975)"
                  fill="#577c47"
                />
                <path
                  id="Path_2818"
                  data-name="Path 2818"
                  d="M444.062,370.432l-.665-6.218a1.973,1.973,0,0,1,1.754-2.17l.028,0L464.9,360.26a.934.934,0,0,1,1.014.847c0,.017,0,.035,0,.052l.222,7.387-5.71,9.69-5.871,1.86h-7.174l-4.128-4.406Z"
                  transform="translate(-364.234 -296.032)"
                  fill="#577c47"
                />
                <path
                  id="Path_2819"
                  data-name="Path 2819"
                  d="M464.724,392.167a6.7,6.7,0,0,1-.786-.078,14.139,14.139,0,0,0-2.151-.039,21.236,21.236,0,0,0-3.159.456c-1.178.248-2.455.6-3.811.926a25.635,25.635,0,0,1-3.885.691,10.135,10.135,0,0,1-3.216-.217,6.891,6.891,0,0,1-2.012-.834,5.431,5.431,0,0,1-.478-.348c-.1-.087-.156-.139-.148-.143s.235.156.678.408a7.425,7.425,0,0,0,2,.747,10.292,10.292,0,0,0,3.159.161,26.8,26.8,0,0,0,3.841-.7c1.351-.33,2.638-.674,3.828-.908a19.471,19.471,0,0,1,3.194-.408,11.781,11.781,0,0,1,2.173.117c.256.043.435.078.582.113a1.094,1.094,0,0,1,.191.061"
                  transform="translate(-365.732 -322.008)"
                  fill="#455a64"
                />
                <g
                  id="Group_7809"
                  data-name="Group 7809"
                  transform="translate(79.945 69.95)"
                  opacity="0.3"
                >
                  <g id="Group_7808" data-name="Group 7808">
                    <g
                      id="Group_7807"
                      data-name="Group 7807"
                      clipPath="url(#clipPath-3)"
                    >
                      <path
                        id="Path_2820"
                        data-name="Path 2820"
                        d="M448.8,393.555a4.22,4.22,0,0,0,.526,4.345,3.785,3.785,0,0,0,3.511.869,11.1,11.1,0,0,0,3.428-1.538c2.716-1.616,5.471-3.289,8.556-3.972a1.106,1.106,0,0,0,.552-.235.36.36,0,0,0,.042-.508l-.02-.022a.486.486,0,0,0-.256-.074c-2.729-.274-5.354.8-8.026,1.4s-5.744,1.23-8.191-.213"
                        transform="translate(-448.443 -392.377)"
                      />
                    </g>
                  </g>
                </g>
                <path
                  id="Path_2821"
                  data-name="Path 2821"
                  d="M465.97,369.174a1.77,1.77,0,0,0-.678.647,4.867,4.867,0,0,0-.269,2.264c.052,1.842.356,3.311.283,3.329a3.362,3.362,0,0,1-.265-.952,14.3,14.3,0,0,1-.261-2.368,10.048,10.048,0,0,1,.026-1.3,2.3,2.3,0,0,1,.339-1.047,1.342,1.342,0,0,1,.556-.5.777.777,0,0,1,.269-.074"
                  transform="translate(-381.913 -303.36)"
                  fill="#455a64"
                />
                <path
                  id="Path_2822"
                  data-name="Path 2822"
                  d="M496.837,372.091a29.015,29.015,0,0,0-.143-3.007c-.243-1.642-.773-2.9-.7-2.933a2.715,2.715,0,0,1,.4.8,9.664,9.664,0,0,1,.539,2.1,9.364,9.364,0,0,1,.065,2.173,2.637,2.637,0,0,1-.165.869"
                  transform="translate(-407.566 -300.876)"
                  fill="#455a64"
                />
                <path
                  id="Path_2823"
                  data-name="Path 2823"
                  d="M534.748,363.226a1.937,1.937,0,0,1,.374.734,5.1,5.1,0,0,1-.261,3.889,1.785,1.785,0,0,1-.469.678,8.111,8.111,0,0,0,.743-2.607,8.416,8.416,0,0,0-.387-2.694"
                  transform="translate(-439.124 -298.473)"
                  fill="#455a64"
                />
                <path
                  id="Path_2824"
                  data-name="Path 2824"
                  d="M274.06,480.677s4.78-35.2,6.175-41.581a18.28,18.28,0,0,1,8.256-11.641l1.325,63.773-5.827,7.335-6.518,2.494a6.762,6.762,0,0,1-4.65.074l-6.244-2.173-.53-11.9,5.275-1.525Z"
                  transform="translate(-218.618 -351.251)"
                  fill="#577c47"
                />
                <path
                  id="Path_2825"
                  data-name="Path 2825"
                  d="M533.771,420.641a15.67,15.67,0,0,1,9.6,11.155c3.728,16.43,10.107,38.292,10.207,45.344.126,8.982-3.8,15.131-8.93,16.265-2.933.647-22.883.322-33.716.091a8.07,8.07,0,0,1-.991-16.052l21.949-3.229-4.076-22.774L531.85,419.9Z"
                  transform="translate(-413.37 -345.042)"
                  fill="#577c47"
                />
                <path
                  id="Path_2826"
                  data-name="Path 2826"
                  d="M544.16,384.53l8.791,3.594,8.36,2.712.2,25.016-4.975,50.707-17.447.065.035-22.631a131.677,131.677,0,0,1,2.12-32.591l2.655-21.514"
                  transform="translate(-442.822 -315.979)"
                  fill="#577c47"
                />
                <path
                  id="Path_2827"
                  data-name="Path 2827"
                  d="M640.54,583.918a1.094,1.094,0,0,1,.065.252c.039.2.087.434.143.73.122.669.291,1.582.491,2.69.4,2.307.947,5.436,1.551,8.891s1.1,6.592,1.482,8.9c.174,1.117.317,2.029.435,2.7.043.3.078.543.1.739a.911.911,0,0,1,0,.261,1.091,1.091,0,0,1-.065-.252c-.043-.2-.091-.434-.152-.73-.122-.669-.291-1.577-.5-2.69-.408-2.273-.96-5.414-1.551-8.891s-1.117-6.618-1.478-8.9c-.174-1.117-.313-2.029-.434-2.7-.039-.3-.07-.543-.1-.739a.906.906,0,0,1,0-.261"
                  transform="translate(-526.34 -479.821)"
                  fill="#263238"
                />
                <path
                  id="Path_2828"
                  data-name="Path 2828"
                  d="M643.075,444a4.554,4.554,0,0,1-.673.248,16.672,16.672,0,0,0-1.786.774,13.861,13.861,0,0,0-4.828,4.115,10.242,10.242,0,0,0-.951,1.547,9.983,9.983,0,0,0-.569,1.59,15.63,15.63,0,0,0-.478,2.842c-.074.817-.087,1.486-.091,1.947a5.488,5.488,0,0,1,0,.717,3.15,3.15,0,0,1-.078-.717,17.611,17.611,0,0,1,0-1.96,14.709,14.709,0,0,1,.435-2.885,9.847,9.847,0,0,1,.574-1.638A10.091,10.091,0,0,1,635.6,449a13.38,13.38,0,0,1,4.949-4.128,12.684,12.684,0,0,1,1.834-.708,3.371,3.371,0,0,1,.7-.156"
                  transform="translate(-520.64 -364.851)"
                  fill="#263238"
                />
                <path
                  id="Path_2829"
                  data-name="Path 2829"
                  d="M538.9,726.965a1.456,1.456,0,0,0,.3-.022l.869-.109,3.207-.435,10.568-1.569,7.683-1.182h-.03a5.385,5.385,0,0,1,2.664,1.178,5.762,5.762,0,0,1,1.46,1.738,6.743,6.743,0,0,1,.652,1.882.442.442,0,0,0,0-.135,1.225,1.225,0,0,0-.052-.391,5.072,5.072,0,0,0-.487-1.412,5.817,5.817,0,0,0-1.46-1.817,5.53,5.53,0,0,0-2.746-1.251h-.03l-7.674,1.156-10.555,1.638-3.194.521-.869.156a1.453,1.453,0,0,0-.309.052"
                  transform="translate(-442.83 -594.471)"
                  fill="#263238"
                />
                <path
                  id="Path_2830"
                  data-name="Path 2830"
                  d="M535.678,759.686a.706.706,0,0,0,.209-.07,4.037,4.037,0,0,0,.578-.261,6.306,6.306,0,0,0,1.812-1.447,8.254,8.254,0,0,0,1.647-3.02,9.756,9.756,0,0,0,.317-4.2,8.057,8.057,0,0,0-1.738-3.837,8.516,8.516,0,0,0-2.655-2.173,7.678,7.678,0,0,0-2.173-.769c-.265-.057-.482-.057-.626-.078a.82.82,0,0,0-.217,0,9.627,9.627,0,0,1,2.951,1,8.6,8.6,0,0,1,2.559,2.173,7.958,7.958,0,0,1,1.664,3.728,9.72,9.72,0,0,1-.291,4.08,8.252,8.252,0,0,1-1.564,2.972,6.6,6.6,0,0,1-1.738,1.478c-.46.274-.743.391-.734.417"
                  transform="translate(-437.842 -611.222)"
                  fill="#263238"
                />
                <g
                  id="Group_7812"
                  data-name="Group 7812"
                  transform="translate(111.25 105.722)"
                  opacity="0.2"
                >
                  <g id="Group_7811" data-name="Group 7811">
                    <g
                      id="Group_7810"
                      data-name="Group 7810"
                      clipPath="url(#clipPath-4)"
                    >
                      <path
                        id="Path_2831"
                        data-name="Path 2831"
                        d="M627.32,593.034,624.043,617.5l4.15-.93,1.286-10.677Z"
                        transform="translate(-624.043 -593.034)"
                      />
                    </g>
                  </g>
                </g>
                <g
                  id="Group_7815"
                  data-name="Group 7815"
                  transform="translate(47.525 135.546)"
                  opacity="0.3"
                >
                  <g id="Group_7814" data-name="Group 7814">
                    <g
                      id="Group_7813"
                      data-name="Group 7813"
                      clipPath="url(#clipPath-5)"
                    >
                      <path
                        id="Path_2832"
                        data-name="Path 2832"
                        d="M266.584,760.6a16.754,16.754,0,0,1,5.962,0,5.97,5.97,0,0,1,3.976,3.042,8.284,8.284,0,0,1-.908,8.478,5.114,5.114,0,0,1-4.871,1.569l-3.694-1.2Z"
                        transform="translate(-266.584 -760.332)"
                      />
                    </g>
                  </g>
                </g>
                <path
                  id="Path_2833"
                  data-name="Path 2833"
                  d="M271.627,773.507a1.166,1.166,0,0,0,.248.1,4.5,4.5,0,0,0,.743.2,5.563,5.563,0,0,0,2.811-.148,4.859,4.859,0,0,0,1.7-.973,6.085,6.085,0,0,0,1.36-1.79,8.56,8.56,0,0,0,.778-2.36,7.029,7.029,0,0,0,.061-2.668,8.278,8.278,0,0,0-.986-2.477,5.792,5.792,0,0,0-3.728-2.763,9.973,9.973,0,0,0-1.942-.217,19.2,19.2,0,0,0-2.807.183l-.756.109a.873.873,0,0,0-.261.057,1.113,1.113,0,0,0,.265,0l.761-.061a22.3,22.3,0,0,1,2.794-.1,9.927,9.927,0,0,1,1.9.243,5.679,5.679,0,0,1,1.973.9,6.924,6.924,0,0,1,2.551,4.185,6.836,6.836,0,0,1-.057,2.577,8.336,8.336,0,0,1-.743,2.3,5.889,5.889,0,0,1-1.3,1.738,4.757,4.757,0,0,1-1.625.96,5.72,5.72,0,0,1-2.738.226c-.643-.113-.991-.243-1-.222"
                  transform="translate(-220.921 -624.852)"
                  fill="#263238"
                />
                <g
                  id="Group_7818"
                  data-name="Group 7818"
                  transform="translate(89.852 132.637)"
                  opacity="0.3"
                >
                  <g id="Group_7817" data-name="Group 7817">
                    <g
                      id="Group_7816"
                      data-name="Group 7816"
                      clipPath="url(#clipPath-6)"
                    >
                      <path
                        id="Path_2834"
                        data-name="Path 2834"
                        d="M509.7,744.013c1.738.478,3.585,1.017,4.78,2.373a9.125,9.125,0,0,1,.561,10.724,7.205,7.205,0,0,1-2.147,2.229,4.533,4.533,0,0,1-2.89.4,7.351,7.351,0,0,1-5.606-5.084,9.994,9.994,0,0,1,1.126-8,9.853,9.853,0,0,1,4.172-2.646"
                        transform="translate(-504.015 -744.013)"
                      />
                    </g>
                  </g>
                </g>
                <path
                  id="Path_2835"
                  data-name="Path 2835"
                  d="M673.868,733.349a3.453,3.453,0,0,1-.187-.526c-.109-.339-.265-.83-.478-1.43a15.576,15.576,0,0,0-2.251-4.376,8.857,8.857,0,0,0-3.911-2.907,10.069,10.069,0,0,0-2-.435,2.2,2.2,0,0,1,.556,0,6.052,6.052,0,0,1,1.495.291,8.588,8.588,0,0,1,4.024,2.92,14.426,14.426,0,0,1,2.247,4.463c.2.613.33,1.112.434,1.46a2.173,2.173,0,0,1,.07.539"
                  transform="translate(-546.483 -594.65)"
                  fill="#455a64"
                />
                <path
                  id="Path_2836"
                  data-name="Path 2836"
                  d="M629.942,525.832a1.747,1.747,0,0,1,.1.348c.056.252.139.591.239,1.017.2.869.482,2.173.817,3.741.669,3.164,1.534,7.552,2.386,12.415s1.512,9.282,1.951,12.489c.222,1.6.391,2.9.5,3.8.048.435.087.778.117,1.034a1.928,1.928,0,0,1,.026.365,1.89,1.89,0,0,1-.074-.361c-.039-.252-.091-.6-.161-1.025-.135-.917-.326-2.208-.565-3.789-.482-3.2-1.173-7.613-2.021-12.471s-1.686-9.251-2.316-12.423c-.313-1.569-.565-2.846-.747-3.754-.083-.434-.148-.769-.2-1.021a2.221,2.221,0,0,1-.057-.361"
                  transform="translate(-517.64 -432.09)"
                  fill="#455a64"
                />
                <path
                  id="Path_2837"
                  data-name="Path 2837"
                  d="M642.011,427.137c0,.035-.482.309-1.225.843a14.887,14.887,0,0,0-2.607,2.407,14.611,14.611,0,0,0-1.951,2.972c-.4.822-.6,1.356-.63,1.343a1.193,1.193,0,0,1,.1-.391,8.548,8.548,0,0,1,.391-1.021A12.736,12.736,0,0,1,638,430.248a13.038,13.038,0,0,1,2.7-2.394,8.511,8.511,0,0,1,.947-.547,1.353,1.353,0,0,1,.361-.17"
                  transform="translate(-522.287 -350.99)"
                  fill="#455a64"
                />
                <path
                  id="Path_2838"
                  data-name="Path 2838"
                  d="M364.519,559.946a.39.39,0,0,1,0-.113v-1.564c-.022-1.078-.026-2.637-.148-4.558-.065-.961-.165-2.012-.335-3.129s-.434-2.3-.743-3.533a44.55,44.55,0,0,1-1.66-8.135,26.109,26.109,0,0,1,1.056-8.23c.656-2.468,1.386-4.65,1.912-6.518a41.412,41.412,0,0,0,1.052-4.437c.1-.53.143-.947.191-1.225a1.814,1.814,0,0,1,.048-.321c.013-.087,0-.109.026-.1a.529.529,0,0,1,0,.109,1.524,1.524,0,0,1-.026.322c-.03.282-.056.7-.148,1.234a36.833,36.833,0,0,1-1.047,4.485c-.5,1.86-1.217,4.045-1.864,6.518a26.337,26.337,0,0,0-1.017,8.152,45.267,45.267,0,0,0,1.638,8.1,34.588,34.588,0,0,1,.721,3.555c.165,1.125.257,2.173.309,3.146.1,1.93.087,3.476.078,4.571,0,.526,0,.943-.022,1.243v.322a.394.394,0,0,1-.022.117"
                  transform="translate(-297.153 -425.719)"
                  fill="#455a64"
                />
                <path
                  id="Path_2839"
                  data-name="Path 2839"
                  d="M402.179,467.655l-.139-.035-.391-.122a9.02,9.02,0,0,0-1.543-.3,12.726,12.726,0,0,0-5.684.778,22.36,22.36,0,0,0-3.611,1.708c-1.251.713-2.542,1.556-3.911,2.429a36.914,36.914,0,0,1-4.445,2.5,15.46,15.46,0,0,1-5.284,1.443,11.221,11.221,0,0,1-5.371-1.025,29.424,29.424,0,0,1-4.419-2.555c-1.347-.9-2.607-1.777-3.881-2.472a14.866,14.866,0,0,0-3.685-1.5,9.651,9.651,0,0,0-3.263-.217,7.685,7.685,0,0,0-2.381.656,6.951,6.951,0,0,0-1.738,1.208.712.712,0,0,1,.091-.1c.065-.065.161-.17.291-.291a5.972,5.972,0,0,1,1.3-.869,7.579,7.579,0,0,1,2.407-.7,9.783,9.783,0,0,1,3.315.187,15,15,0,0,1,3.737,1.5c1.273.691,2.555,1.56,3.911,2.459a29.415,29.415,0,0,0,4.4,2.525,11.032,11.032,0,0,0,5.249.982,15.261,15.261,0,0,0,5.215-1.417,36.7,36.7,0,0,0,4.423-2.481c1.373-.869,2.668-1.7,3.933-2.416a22.2,22.2,0,0,1,3.65-1.695,12.541,12.541,0,0,1,5.745-.713,8.493,8.493,0,0,1,1.547.348l.387.143a.5.5,0,0,1,.143.057"
                  transform="translate(-289.604 -383.774)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2840"
                  data-name="Path 2840"
                  d="M653.834,496.43a3.66,3.66,0,0,1-.435.5,5.731,5.731,0,0,1-.56.534,8.951,8.951,0,0,1-.787.652,11.006,11.006,0,0,1-1.025.7,13.266,13.266,0,0,1-1.247.691,15.488,15.488,0,0,1-3.076,1.086,15.66,15.66,0,0,1-3.246.365,13.223,13.223,0,0,1-1.425-.083,10.478,10.478,0,0,1-1.225-.182c-.378-.061-.7-.169-1-.244a6.058,6.058,0,0,1-.734-.239,3.252,3.252,0,0,1-.6-.256c0-.048.869.317,2.364.578a10.431,10.431,0,0,0,1.212.156c.435.056.913.048,1.408.061a16.471,16.471,0,0,0,3.194-.378,16.163,16.163,0,0,0,3.042-1.052c.434-.226.869-.435,1.243-.665a11.658,11.658,0,0,0,1.025-.669c1.212-.882,1.834-1.586,1.873-1.551"
                  transform="translate(-524.651 -407.929)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2841"
                  data-name="Path 2841"
                  d="M346.806,532.913a3.317,3.317,0,0,1-1.026.03,9.578,9.578,0,0,1-2.433-.482,9.769,9.769,0,0,1-2.229-1.086,3.239,3.239,0,0,1-.8-.643,18.09,18.09,0,0,0,3.1,1.512,17.907,17.907,0,0,0,3.385.669"
                  transform="translate(-279.649 -436.117)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2842"
                  data-name="Path 2842"
                  d="M426.161,549.115a11.432,11.432,0,0,0-1.556-.956,10.514,10.514,0,0,0-4.85-.869,31.761,31.761,0,0,0-7.274,1.3c-2.7.717-5.649,1.6-8.83,2.2a30.484,30.484,0,0,1-4.689.539,27.818,27.818,0,0,1-4.393-.209,45.172,45.172,0,0,1-7.266-1.573c-2.029-.6-3.646-1.164-4.78-1.556l-1.282-.456-.33-.126c-.074-.03-.113-.048-.109-.052a.67.67,0,0,1,.117.031l.339.1,1.3.435c1.125.365,2.746.9,4.78,1.486a47.384,47.384,0,0,0,7.248,1.521,28.525,28.525,0,0,0,4.345.191,31.343,31.343,0,0,0,4.658-.539c3.163-.6,6.11-1.486,8.817-2.173a32.421,32.421,0,0,1,7.322-1.26,10.356,10.356,0,0,1,4.893.969l.369.2.313.2a3.529,3.529,0,0,1,.469.33l.274.226Z"
                  transform="translate(-312.916 -449.602)"
                  fill="#fafafa"
                />
                <path
                  id="Path_2843"
                  data-name="Path 2843"
                  d="M172.5,774.176a41.571,41.571,0,0,1,6.279,1.373,6.621,6.621,0,0,0,5.8-.708,16.245,16.245,0,0,0,2.512-1.96l5.805.031a6.975,6.975,0,0,0,1.3-7.17,7.824,7.824,0,0,0-1.3-2.294,6.034,6.034,0,0,0-3.624-1.738l-6.927,1.347s-8.782-.148-12.323-.313a4,4,0,0,0-2.4.665c-.83.552-1.768,1.3-1.695,1.768.052.352-.521,2.607,1.186,4.063,0,0-1.538,1.169-.743,2.268l.382.487s-1.043,1.3-.534,1.877,5.028,3.363,6.675,2.755.33-2.147-.387-2.438"
                  transform="translate(-136.296 -625.917)"
                  fill="#b78876"
                />
                <path
                  id="Path_2844"
                  data-name="Path 2844"
                  d="M173.824,790.862a3.588,3.588,0,0,1,.565.213c.356.144.869.348,1.525.574.326.109.687.226,1.073.335s.813.209,1.251.361a2.681,2.681,0,0,1,1.238.782,1.556,1.556,0,0,1,.3,1.534,1.221,1.221,0,0,1-.578.6,2.235,2.235,0,0,1-.765.2,6.545,6.545,0,0,1-1.451-.109,12.4,12.4,0,0,1-2.342-.665c-.648-.243-1.16-.474-1.512-.63a3.432,3.432,0,0,1-.539-.269,3.667,3.667,0,0,1,.569.2c.361.139.869.348,1.525.574a13.578,13.578,0,0,0,2.329.617,6.235,6.235,0,0,0,1.4.1,2,2,0,0,0,.7-.178.982.982,0,0,0,.487-.5,1.369,1.369,0,0,0-.269-1.339,2.547,2.547,0,0,0-1.152-.734c-.434-.152-.847-.252-1.238-.374s-.747-.243-1.073-.361c-.652-.239-1.165-.465-1.512-.634a3.592,3.592,0,0,1-.534-.278"
                  transform="translate(-141.825 -649.873)"
                  fill="#aa6550"
                />
                <path
                  id="Path_2845"
                  data-name="Path 2845"
                  d="M177.026,772.41a4.657,4.657,0,0,1,.561.217c.356.148.869.356,1.517.587s1.425.482,2.3.713a3.2,3.2,0,0,1,1.338.573,1.143,1.143,0,0,1,.382.708,1.448,1.448,0,0,1-.135.808,1.947,1.947,0,0,1-1.212.986,3.134,3.134,0,0,1-1.451.087,13.766,13.766,0,0,1-2.321-.726l-1.543-.569a3.432,3.432,0,0,1-.552-.235,3.706,3.706,0,0,1,.578.165l1.543.513a15.151,15.151,0,0,0,2.3.678,3.007,3.007,0,0,0,1.369-.087,1.781,1.781,0,0,0,1.095-.9,1.064,1.064,0,0,0-.2-1.3,3.041,3.041,0,0,0-1.256-.543c-.869-.243-1.66-.508-2.3-.756s-1.152-.478-1.5-.643a3.126,3.126,0,0,1-.513-.278"
                  transform="translate(-144.549 -634.71)"
                  fill="#aa6550"
                />
                <path
                  id="Path_2846"
                  data-name="Path 2846"
                  d="M210.3,748.926l-4.02-1.647s-11.155-5.493-11.35-5.215-.8.239-.7,1.616,6.609,4.28,6.809,4.78-.2,2.877,2.173,4.649a8.326,8.326,0,0,0,4.137,1.851"
                  transform="translate(-159.596 -609.766)"
                  fill="#b78876"
                />
                <path
                  id="Path_2847"
                  data-name="Path 2847"
                  d="M206.906,754.51h-.1a2.588,2.588,0,0,1-.278-.035,6.071,6.071,0,0,1-1.047-.274,9.628,9.628,0,0,1-1.56-.717,9.376,9.376,0,0,1-1.838-1.334,4.857,4.857,0,0,1-1.334-2.4,10.786,10.786,0,0,1-.209-1.517,1.687,1.687,0,0,0-.035-.178.339.339,0,0,0-.1-.109,3.625,3.625,0,0,0-.326-.222c-.226-.139-.465-.274-.708-.413-.965-.539-1.995-1.086-3.042-1.712-.517-.313-1.039-.643-1.543-1.017a5.819,5.819,0,0,1-.721-.63,2.026,2.026,0,0,1-.3-.4.908.908,0,0,1-.087-.252,1.649,1.649,0,0,1,0-.252,1.738,1.738,0,0,1,.2-.973,1.125,1.125,0,0,1,.365-.352.7.7,0,0,0,.156-.13l.039-.039a.157.157,0,0,1,.083-.026h.078l.122.031c.608.2,1.16.461,1.738.7,1.1.491,2.173.986,3.159,1.464,2,.952,3.8,1.829,5.3,2.577.374.182.73.369,1.073.521l.982.413,1.577.661.991.435.257.117.083.044-.091-.026-.261-.1-1.008-.4-1.59-.634-.991-.4c-.348-.152-.7-.335-1.082-.517l-5.314-2.538c-1-.469-2.06-.965-3.163-1.451-.547-.239-1.112-.5-1.69-.691l-.1-.026h-.048v-.043c.126.087.031.026.057.048a.905.905,0,0,1-.2.174.961.961,0,0,0-.309.3,1.642,1.642,0,0,0-.169.869.871.871,0,0,0,.087.435,1.773,1.773,0,0,0,.274.356,5.786,5.786,0,0,0,.7.608c.5.369,1.012.7,1.525,1.008,1.03.626,2.055,1.178,3.042,1.738.243.139.482.274.713.434.113.074.23.144.339.23a.435.435,0,0,1,.156.187,1,1,0,0,1,.043.209,11.294,11.294,0,0,0,.2,1.508,4.781,4.781,0,0,0,1.277,2.342,9.5,9.5,0,0,0,1.795,1.338,10.193,10.193,0,0,0,1.534.739,7.086,7.086,0,0,0,1.03.309c.117.026.213.039.274.048Z"
                  transform="translate(-159.138 -609.332)"
                  fill="#aa6550"
                />
                <path
                  id="Path_2848"
                  data-name="Path 2848"
                  d="M176.795,815.846a1.607,1.607,0,0,1,.435.135,4.369,4.369,0,0,1,1.047.639,4.007,4.007,0,0,1,.6.608,1.3,1.3,0,0,1,.3.956,1.047,1.047,0,0,1-.765.8,2.032,2.032,0,0,1-1.182-.061,21.285,21.285,0,0,1-3.7-1.582,10.179,10.179,0,0,1-1.03-.643,2.2,2.2,0,0,1-.352-.274,2.208,2.208,0,0,1,.391.213c.248.148.608.352,1.06.587a26.192,26.192,0,0,0,3.68,1.516,1.889,1.889,0,0,0,1.069.065.869.869,0,0,0,.652-.647,1.143,1.143,0,0,0-.265-.834,3.939,3.939,0,0,0-.56-.6,6.528,6.528,0,0,0-1.386-.873"
                  transform="translate(-141.464 -670.403)"
                  fill="#aa6550"
                />
                <path
                  id="Path_2849"
                  data-name="Path 2849"
                  d="M412.644,738.017l-7.161.148s-10.733-3.88-12.941-3.507c-1.99.33-9.269,5.167-10.694,6.127a.868.868,0,0,0-.348.435,1.556,1.556,0,0,0,.869,1.981l.152.065-.065.217a2.6,2.6,0,0,0-.113.869,1.052,1.052,0,0,0,1.951.456l-.274.487a1.186,1.186,0,0,0-.152.652l.026.435a1.193,1.193,0,0,0,2.073.739c.921-1.013,2.916-3.042,4.263-3.272a31.876,31.876,0,0,1,4.411-.221,1.49,1.49,0,0,1,1.382.956c-.8.056-6.379,2.963-8.352,4.006a2.111,2.111,0,0,0-1.147,1.591c-.074.617.239,1.264,1.46.978a54.984,54.984,0,0,0,7.344-2.781s2.438-.7,4.015.435c2.216,1.564,3.233,2.138,5.141,1.508a16.4,16.4,0,0,0,3.311-1.538l6.492.63s3.972-6.492-1.643-11.394"
                  transform="translate(-313.409 -603.668)"
                  fill="#ef9456"
                />
                <path
                  id="Path_2850"
                  data-name="Path 2850"
                  d="M408.815,762.479c0,.044-.765-.2-2.016-.278a10.429,10.429,0,0,0-8.691,3.7c-.808.96-1.156,1.673-1.191,1.651a2.114,2.114,0,0,1,.239-.5,9.245,9.245,0,0,1,.843-1.243,10.1,10.1,0,0,1,8.8-3.754,8.887,8.887,0,0,1,1.482.252,2.954,2.954,0,0,1,.534.169"
                  transform="translate(-326.157 -626.17)"
                  fill="#aa6550"
                />
                <path
                  id="Path_2851"
                  data-name="Path 2851"
                  d="M400.085,747.624a8.931,8.931,0,0,0-2.1-.365,9.168,9.168,0,0,0-4.884,1.382,19.756,19.756,0,0,0-3.937,3.329l-1.078,1.147a3.474,3.474,0,0,1-.409.408,2.547,2.547,0,0,1,.352-.461c.235-.287.586-.7,1.03-1.191A18.2,18.2,0,0,1,393,748.467a8.931,8.931,0,0,1,4.993-1.352,5.787,5.787,0,0,1,1.56.287,3.177,3.177,0,0,1,.4.152.435.435,0,0,1,.135.07"
                  transform="translate(-318.566 -613.922)"
                  fill="#aa6550"
                />
                <path
                  id="Path_2852"
                  data-name="Path 2852"
                  d="M16.455,83.643a3.062,3.062,0,0,0-.435.178l-1.234.587c-.274.135-.582.265-.908.435l-1.043.63-1.212.743-1.3.982a25.483,25.483,0,0,0-5.684,6.084,27.247,27.247,0,0,0-4.2,10.164,27.58,27.58,0,0,0,.869,13.284A28.512,28.512,0,0,0,2.7,120.14c.287.556.526,1.143.869,1.682l1.012,1.63A26.982,26.982,0,0,0,9.9,129.321a28.9,28.9,0,0,0,7.152,4.233,28.248,28.248,0,0,0,8.487,1.86,25.639,25.639,0,0,0,8.943-.978,17.224,17.224,0,0,0,2.173-.73,20.366,20.366,0,0,0,2.142-.869,27.6,27.6,0,0,0,4.037-2.377,27.119,27.119,0,0,0,6.953-7.231l-.243.135,10.142-.256h.6l-.374-.469-6.518-8.152.057.248a27.068,27.068,0,0,0,.061-12.554,24.471,24.471,0,0,0-1.995-5.558,19.144,19.144,0,0,0-1.369-2.494l-.739-1.182c-.256-.382-.56-.73-.83-1.1a26.942,26.942,0,0,0-15.713-9.908,25.764,25.764,0,0,0-7.035-.578l-1.495.1-1.36.217-1.247.2c-.387.087-.752.2-1.1.291-.691.2-1.3.33-1.825.526l-1.3.5-.8.335a1.161,1.161,0,0,0-.256.122,1.133,1.133,0,0,0,.278-.078l.8-.269,1.3-.461c.517-.174,1.138-.3,1.829-.478a26.8,26.8,0,0,1,27.528,9.768c.265.361.561.7.808,1.078l.721,1.164a18.225,18.225,0,0,1,1.33,2.455,23.986,23.986,0,0,1,1.938,5.458,26.579,26.579,0,0,1-.1,12.3l-.035.135.091.113q3.155,3.95,6.518,8.165l.226-.487-10.147.243H49.38l-.091.135a26.556,26.556,0,0,1-10.738,9.391,19.94,19.94,0,0,1-2.1.869,16.389,16.389,0,0,1-2.134.713,25.132,25.132,0,0,1-8.747.943,27.875,27.875,0,0,1-8.3-1.808,28.338,28.338,0,0,1-7.022-4.145,26.552,26.552,0,0,1-5.214-5.736c-.339-.535-.669-1.065-1-1.591s-.565-1.1-.869-1.647a27.533,27.533,0,0,1-1.378-3.346,26.607,26.607,0,0,1,3.146-23.1,25.564,25.564,0,0,1,5.549-6.084l1.26-1,1.186-.761,1.021-.656c.321-.183.626-.322.869-.461l1.208-.63a2.65,2.65,0,0,0,.43-.213"
                  transform="translate(0 -66.828)"
                  fill="#1b5c96"
                />
                <path
                  id="Path_2853"
                  data-name="Path 2853"
                  d="M186.892,185.354a5.328,5.328,0,0,1-.582-.109,2.2,2.2,0,0,1-1.3-1,2.234,2.234,0,0,1-.322-1.147V161.659a5.3,5.3,0,0,1,.091-1.425,2.221,2.221,0,0,1,4.233.117,7.728,7.728,0,0,1,.07,1.434v6.987c0,3.524.035,6.7.048,9.373,0,1.33,0,2.538.03,3.6v1.473a2.173,2.173,0,0,1-.378,1.13,2.264,2.264,0,0,1-1.356.93c-.382.083-.591.061-.591.078a1.824,1.824,0,0,0,.6-.022,2.269,2.269,0,0,0,1.434-.921,2.3,2.3,0,0,0,.434-1.186V161.064a2.923,2.923,0,0,0-.074-.769,2.42,2.42,0,0,0-2.347-1.829,2.472,2.472,0,0,0-1.512.435,2.434,2.434,0,0,0-.921,1.265,2.612,2.612,0,0,0-.109.773v2.186c0,1.921,0,3.772.022,5.536,0,3.52.035,6.7.048,9.373,0,1.33.022,2.533.026,3.6v1.477a2.3,2.3,0,0,0,.361,1.208,2.23,2.23,0,0,0,1.382,1,1.741,1.741,0,0,0,.713.039"
                  transform="translate(-151.478 -130.213)"
                  fill="#1b5c96"
                />
                <path
                  id="Path_2854"
                  data-name="Path 2854"
                  d="M143.923,185.445a5.741,5.741,0,0,1-.587-.1,2.242,2.242,0,0,1-1.621-2.173V161.75a5.083,5.083,0,0,1,.091-1.421,2.216,2.216,0,0,1,4.233.113,7.392,7.392,0,0,1,.065,1.434v6.987c0,3.524.035,6.7.048,9.373,0,1.334,0,2.538.026,3.6v1.473a2.172,2.172,0,0,1-.378,1.13,2.3,2.3,0,0,1-1.36.93c-.378.078-.591.056-.587.074a1.85,1.85,0,0,0,.6,0,2.308,2.308,0,0,0,1.438-.926,2.255,2.255,0,0,0,.434-1.182c.022-.465,0-.952,0-1.482v-20.7a2.765,2.765,0,0,0-.074-.769,2.5,2.5,0,0,0-4.78-.13,2.64,2.64,0,0,0-.1.774v7.722c0,3.524.035,6.7.052,9.373,0,1.33,0,2.538.026,3.6V183.2a2.3,2.3,0,0,0,.356,1.208,2.246,2.246,0,0,0,1.386,1,1.618,1.618,0,0,0,.734.035"
                  transform="translate(-116.166 -130.291)"
                  fill="#1b5c96"
                />
                <path
                  id="Path_2855"
                  data-name="Path 2855"
                  d="M95.26,185.642a5.27,5.27,0,0,1-.587-.109,2.242,2.242,0,0,1-1.621-2.151V161.955a5.083,5.083,0,0,1,.091-1.421,2.216,2.216,0,0,1,4.233.113,7.39,7.39,0,0,1,.065,1.434v6.987c0,3.524.035,6.7.048,9.373,0,1.33,0,2.538.026,3.6v1.469a2.173,2.173,0,0,1-.378,1.13,2.3,2.3,0,0,1-1.36.934c-.378.078-.591.056-.587.074a1.85,1.85,0,0,0,.6,0,2.316,2.316,0,0,0,1.864-2.112V161.377a3.134,3.134,0,0,0-.074-.769,2.5,2.5,0,0,0-4.784-.13,2.794,2.794,0,0,0-.1.774v7.691c0,3.524.035,6.7.052,9.373,0,1.33,0,2.538.026,3.6v1.478a2.3,2.3,0,0,0,.356,1.208,2.246,2.246,0,0,0,1.386,1,1.617,1.617,0,0,0,.748.044"
                  transform="translate(-76.167 -130.47)"
                  fill="#1b5c96"
                />
                <path
                  id="Path_2856"
                  data-name="Path 2856"
                  d="M143.99,173.7a3.285,3.285,0,1,1-3.294-3.277h0a3.285,3.285,0,0,1,3.29,3.277"
                  transform="translate(-112.922 -140.039)"
                  fill="#ef9456"
                />
                <path
                  id="Path_2857"
                  data-name="Path 2857"
                  d="M95.46,219.282A3.285,3.285,0,1,1,92.171,216,3.285,3.285,0,0,1,95.46,219.282Z"
                  transform="translate(-73.043 -177.494)"
                  fill="#ef9456"
                />
                <path
                  id="Path_2858"
                  data-name="Path 2858"
                  d="M179.926,245.732a3.285,3.285,0,1,1,1.848,4.262,3.285,3.285,0,0,1-1.848-4.262"
                  transform="translate(-147.661 -200.216)"
                  fill="#ef9456"
                />
                <path
                  id="Path_2859"
                  data-name="Path 2859"
                  d="M23.9,620.254H89.441l-8.456-46a3.437,3.437,0,0,0-3.381-2.816H18.711a3.437,3.437,0,0,0-3.376,4.085Z"
                  transform="translate(-12.55 -469.566)"
                  fill="#1b5c96"
                />
                <path
                  id="Path_2860"
                  data-name="Path 2860"
                  d="M428.165,830.2h24.86l-.1-.074a13.427,13.427,0,0,0-7.665-2.672l-17.777-.521Z"
                  transform="translate(-351.274 -679.517)"
                  fill="#455a64"
                />
                <path
                  id="Path_2861"
                  data-name="Path 2861"
                  d="M343,571.389a3.88,3.88,0,0,1,.53-.035,3.3,3.3,0,0,1,1.456.361,4.489,4.489,0,0,1,1.738,1.59,8.431,8.431,0,0,1,1.073,2.972c.213,1.164.4,2.455.634,3.833s.487,2.851.778,4.4c.573,3.09,1.247,6.479,1.986,10.033,1.478,7.1,2.846,13.527,3.746,18.194.434,2.334.8,4.224,1.034,5.532.113.643.2,1.147.274,1.508.03.161.056.287.074.391a.825.825,0,0,0,.035.13.4.4,0,0,0,0-.135,3.711,3.711,0,0,0-.052-.4c-.056-.365-.13-.869-.23-1.512-.2-1.3-.526-3.211-.956-5.549-.869-4.68-2.2-11.111-3.676-18.216-.734-3.55-1.421-6.953-2-10.025-.3-1.543-.556-3.011-.8-4.389s-.435-2.659-.665-3.833a8.531,8.531,0,0,0-1.125-3.011,4.57,4.57,0,0,0-1.821-1.6,3.139,3.139,0,0,0-1.5-.322,1.979,1.979,0,0,0-.53.083"
                  transform="translate(-281.852 -469.457)"
                  fill="#263238"
                />
                <path
                  id="Path_2862"
                  data-name="Path 2862"
                  d="M188.687,685.709a3.168,3.168,0,1,1,0,4.48,3.168,3.168,0,0,1,0-4.48"
                  transform="translate(-154.287 -562.704)"
                  fill="#f5f5f5"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WomanWithLaptop;
