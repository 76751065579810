import { css } from 'glamor';
import React from 'react';
import { spacing } from '../library/theme';
import { Card, Title, Typography } from '../library';

const titleWrapper = css({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: spacing.xxs,
  marginTop: spacing.s,
});
const contentWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.xxs,
});
const cardSx = {
  display: 'flex',
  alignItems: 'flex-start',
  minWidth: '312px',
  padding: '0  !important',
  paddingLeft: `${spacing.m} !important`,
};

export default function StatementsCardAmount({ amount, title }) {
  return (
    <>
      <Card minHeight={spacing.xxl} sx={cardSx}>
        <div className={contentWrapper}>
          <div className={titleWrapper}>
            <Typography weight="bold">{title}</Typography>
          </div>
          <Title pb={0} sx={{ fontSize: '24px' }}>
            {amount}
          </Title>
        </div>
      </Card>
    </>
  );
}
