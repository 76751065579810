import React from 'react';
import Grid from './Grid';
import { spacing, uiColors } from './theme';
import Typography from './Typography';
import { toCurrency } from '../../utils';

const typographyColor = {
  color: '#ffffff',
};
const smallSeparator = {
  marginTop: spacing.s,
  marginBottom: spacing.s,
  border: `1px solid ${uiColors.border}`,
};

export default function ResultListItem({
  separated,
  toBRL,
  toPercentage,
  label,
  value,
  currency,
}) {
  return (
    <>
      <Grid item xs={12}>
        <Typography weight="medium" sx={typographyColor}>
          {label}
        </Typography>
        {!toBRL && !toPercentage && (
          <Typography weight="bolder" sx={typographyColor}>
            {value}
          </Typography>
        )}
        {!!toBRL && !toPercentage && (
          <Typography weight="bolder" sx={typographyColor}>
            {toCurrency(value, currency?.code)}
          </Typography>
        )}
        {!toBRL && !!toPercentage && (
          <Typography weight="bolder" sx={typographyColor}>
            {value}%
          </Typography>
        )}
      </Grid>
      {separated && (
        <Grid item xs={12}>
          <hr style={smallSeparator} />
        </Grid>
      )}
    </>
  );
}
