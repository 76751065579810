const initialState = {};

export default function recipesSupplyReducer(state = initialState, action) {
  switch (action.type) {
    case 'RECIPE_FETCHED':
    case 'RECIPE_CREATED':
    case 'RECIPE_UPDATED':
    case 'RECIPES_FETCHED':
    case 'RECIPE_SUPPLY_FETCHED':
      return { ...state, ...action.entities.recipesSupplies };
    case 'RECIPE_SUPPLY_DELETED':
      if (state[action.id]) {
        let newState = { ...state };
        delete newState[action.id];
        return newState;
      }
    default:
      return state;
  }
}
