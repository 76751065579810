import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { getCurrencyById } from '../../selectors/configSelectors';
import { makeIsMobile } from '../../utils/useScreenWidth';
import { getCurrentUserCompany } from '../../selectors/userSelectors';
import { fetchCustomer } from '../../processes/customerProcessess';
import { getCustomer } from '../../selectors/customerSelectors';
import {
  getOrderPageInfo,
  getOrdersByFetchedIds,
} from '../../selectors/orderSelectors';
import CustomerOrdersPage from './CustomerOrdersPage';
import { fetchOrders } from '../../processes/orderProcesses';

function CustomerOrdersPageContainer(props) {
  const { dispatch, currentPage, filters } = props;
  const { customerId } = props?.match?.params;
  const isMobile = makeIsMobile();

  useEffect(() => {
    fetchOrders(dispatch, {
      currentPage,
      filters: { customerIdEq: customerId },
      paged: true,
    });
  }, [dispatch, currentPage, customerId, filters]);
  useEffect(() => {
    fetchCustomer(dispatch, customerId);
  }, [dispatch, customerId]);

  const pageChangeHandler = useCallback(
    (_, currentPage = 1) => {
      dispatch({
        type: 'ORDERS_PAGE_CHANGED',
        currentPage,
      });
    },
    [dispatch],
  );

  return (
    <CustomerOrdersPage
      onPageChange={pageChangeHandler}
      isMobile={isMobile}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { customerId } = ownProps.match.params;
  const customer = getCustomer(state, customerId);
  const { currentPage, totalPages, filters, loading } = getOrderPageInfo(state);
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);

  return {
    orders: getOrdersByFetchedIds(state),
    customer,
    currentPage,
    totalPages,
    filters,
    loading,
    currency,
  };
}

export default connect(mapStateToProps)(CustomerOrdersPageContainer);
