import React, { useCallback, useEffect, useState } from 'react';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import {
  getFetchedStatementsByType,
  getStatementPageInfo,
  getExpensesCategoriesRanking,
} from '../../selectors/statementSelectors';
import ExpensesPage from './ExpensesPage';
import {
  fetchStatements,
  fetchExpenseCategoriesRanking,
} from '../../processes/statementProcesses';

function ExpensesPageContainer(props) {
  const { dispatch, filters, expenses } = props;
  const [loadingExpenseCategoriesRanking, setLoadingExpenseCategoriesRanking] =
    useState(false);

  useEffect(() => {
    filters?.typeEq == 'expense' && fetchStatements(dispatch, { filters });
  }, [dispatch, filters]);

  if (!expenses && !expenses?.length > 0) {
    return null;
  } else {
    useEffect(() => {
      const betweenDates = filters?.betweenDates;

      const fetchExpenseCategoriesRankingAsync = async () => {
        setLoadingExpenseCategoriesRanking(true);
        await fetchExpenseCategoriesRanking(dispatch, { betweenDates });
        setLoadingExpenseCategoriesRanking(false);
      };

      fetchExpenseCategoriesRankingAsync();
    }, [dispatch, expenses, filters]);
  }

  const onAdd = useCallback(() => {
    dispatch(replace('/statements/expenses/new'));
  }, [dispatch]);

  return (
    <ExpensesPage
      onAdd={onAdd}
      loadingRanking={loadingExpenseCategoriesRanking}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const { filters, loading } = getStatementPageInfo(state);

  return {
    expenses: getFetchedStatementsByType(state, 'expense'),
    expensesCategoriesRanking: getExpensesCategoriesRanking(state),
    loading,
    filters,
  };
}

export default connect(mapStateToProps)(ExpensesPageContainer);
