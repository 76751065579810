import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import { css } from 'glamor';
import { spacing } from './theme';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

const defaultSx = {
  display: 'flex',
  borderRadius: '10px',
  fontWeight: 'bolder',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
};

const iconPositions = {
  top: {
    flexDirection: 'column',
  },
  right: {
    flexDirection: 'row-reverse',
  },
  bottom: {
    flexDirection: 'column-reverse',
  },
};

const iconWrappStyle = {
  display: 'flex',
  paddingLeft: spacing.xxs,
  paddingRight: spacing.xxs,
};

export default ({
  children,
  fluid,
  variant = 'contained',
  color = 'primary',
  size = 'large',
  className,
  sx,
  icon,
  iconPosition,
  onClick,
  confirmationText,
  ...rest
}) => {
  const customSxProps = {
    width: fluid ? '100%' : 'auto',
    size,
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleConfirmOpen = useCallback(() => {
    setOpenConfirmation(!openConfirmation);
  }, [openConfirmation]);

  const handleConfirm = useCallback(() => {
    onClick();
    setOpenConfirmation(false);
  }, [onClick]);

  const handleConfirmClose = useCallback(() => {
    setOpenConfirmation(false);
  }, []);

  return (
    <>
      <Button
        variant={variant}
        color={color}
        className={css(className).toString()}
        sx={{
          ...defaultSx,
          ...customSxProps,
          ...(iconPosition && iconPositions[iconPosition]),
          ...sx,
        }}
        size={size}
        onClick={confirmationText ? handleConfirmOpen : onClick}
        {...rest}
      >
        {icon && <div style={iconWrappStyle}>{icon}</div>}
        <div>{children}</div>
      </Button>
      <Dialog open={openConfirmation} onClose={handleConfirmClose}>
        <DialogContent>
          <DialogContentText>
            {confirmationText ||
              'Tem certeza que deseja continuar com essa ação?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={defaultSx} onClick={handleConfirmClose}>
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="danger"
            sx={defaultSx}
            onClick={handleConfirm}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
