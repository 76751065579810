const initialState = {};

export default function productsRecipesReducer(state = initialState, action) {
  switch (action.type) {
    case 'PRODUCT_FETCHED':
    case 'PRODUCTS_FETCHED':
    case 'PRODUCT_UPDATED':
    case 'PRODUCT_CREATED':
    case 'RECIPE_DELETED':
      if (action.id) {
        const productsRecipes = Object.values(state);
        let newState = { ...state };

        productsRecipes.forEach((productsRecipe) => {
          if (productsRecipe.recipeId == action.id)
            delete newState[productsRecipe.id];
        });
        return newState;
      }
    case 'PRODUCTS_RECIPES_FETCHED':
      return { ...state, ...action.entities.productsRecipes };
    case 'PRODUCTS_RECIPE_DELETED':
      if (state[action.id]) {
        let newState = { ...state };
        delete newState[action.id];
        return newState;
      }
    default:
      return state;
  }
}
