import React from 'react';
import Page from '../page/Page';
import { Box, Button } from '../library';
import { spacing } from '../library/theme';
import ReplayIcon from '@mui/icons-material/Replay';
import EmptyPricing from './EmptyPricing';
import PricingContent from './PricingContent';
import Spinner from '../library/Spinner';

const buttonWrapper = {
  marginTop: spacing.xs,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};
const iconStyle = { width: 32, height: 32 };

const loadingSx = {
  textAlign: 'center',
};

export default function Pricing(props) {
  const {
    scenarioData,
    scenario,
    changeScenarioHandler,
    remakeOnboarding,
    recalculatePricing,
    loading,
    hasPricing,
    currency,
  } = props;

  return loading ? (
    <Box pt={6} sx={loadingSx}>
      <Spinner inline />
    </Box>
  ) : (
    <>
      {!!hasPricing && (
        <Page title="Seu preço" back="/" headerType="secondary">
          <PricingContent
            scenarioData={scenarioData}
            scenario={scenario}
            currency={currency}
            changeScenarioHandler={changeScenarioHandler}
            remakeOnboarding={remakeOnboarding}
          />
        </Page>
      )}
      {!hasPricing && <EmptyPricing />}
      <div style={buttonWrapper}>
        <Button
          icon={<ReplayIcon style={iconStyle} />}
          onClick={recalculatePricing}
        >
          Recalcular
        </Button>
      </div>
    </>
  );
}
