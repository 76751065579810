import React from 'react';
import { Button, Card, Link } from '../library';
import { FaFileDownload } from 'react-icons/fa';
import Pagination from '../library/Pagination';
import EmptyState from '../library/EmptyState';

const wrapperSx = {
  marginTop: '56px',
};

const paginationWrapper = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

export default function OrdersReports({
  reports,
  currentPage,
  totalCount,
  totalPages,
  onPageChange,
  isMobile,
  loading,
}) {
  return (
    <>
      {!loading && reports.length ? (
        <>
          {reports.map((report) => (
            <Card
              key={report.id}
              title={`Relatório - ${report.createdAt}`}
              cardActions={false}
            >
              <div style={wrapperSx}>
                <Link
                  href={report.fileUrl}
                  component={Button}
                  fluid
                  startIcon={<FaFileDownload />}
                >
                  Baixar relatório
                </Link>
              </div>
            </Card>
          ))}
          <div style={paginationWrapper}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalCount={totalCount}
              onPageChange={onPageChange}
              short={isMobile}
              itemsPerPage={5}
              loading={loading}
            />
          </div>
        </>
      ) : (
        <EmptyState loading={loading} title="Nenhum relatório encontrado" />
      )}
    </>
  );
}
