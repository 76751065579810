import React from 'react';
import Box from '../library/Box';
import Grid from '../library/Grid';
import Link from '../library/Link';
import Title from '../library/Title';
import Typography from '../library/Typography';
import StatementsTableData from './StatementsTableData';
import Separator from '../library/Separator';

const containerSx = {
  mt: 3,
};
const titleSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
const lastWrapperSx = {
  marginTop: 1,
  display: 'flex',
  justifyContent: 'space-between',
  gap: 2,
};
const showAllSx = {
  pt: 3,
  width: '100%',
  textAlign: 'right',
};

export default function ExpensesData(props) {
  const { expenses, currency } = props;
  return (
    <>
      <Separator />
      <Box sx={containerSx}>
        <Box sx={titleSx}>
          <Title p={0}>Pagamentos</Title>
          <Link
            to="/statements/expenses/new"
            component={Typography}
            variant="link"
          >
            Adicionar pagamento
          </Link>
        </Box>
        <Grid container sx={lastWrapperSx} spacing={2}>
          <StatementsTableData
            statements={expenses.slice(0, 10)}
            currency={currency}
            type="expense"
          />
          <Box sx={showAllSx}>
            <Link
              to="/statements/expenses"
              component={Typography}
              variant="link"
              align="right"
            >
              Ver todos
            </Link>
          </Box>
        </Grid>
      </Box>
    </>
  );
}
