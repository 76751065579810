import React, {useCallback} from 'react';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import EmailSent from './EmailSent';
import RecoveryPasswordForm from './RecoveryPasswordForm';
import {recoveryPassword} from '../../processes/sessionProcesses';

function RecoveryPasswordFormContainer(props) {
  const {submitSucceeded} = props;

  const resetForm = useCallback(() => {
    props.reset();
  }, [props.reset]);

  return submitSucceeded ? (
    <EmailSent resetForm={resetForm} />
  ) : (
    <RecoveryPasswordForm {...props} />
  );
}

function mapStateToProps() {
  return {
    initialValues: {},
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'recoveryPasswordForm',
    onSubmit: recoveryPassword,
  })(RecoveryPasswordFormContainer),
);
