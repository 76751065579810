import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getConfigById, getUnitById } from '../../selectors/configSelectors';
import SupplyListItem from './SupplyListItem';
import { deleteSupply } from '../../processes/supplyProcessess';

function SuppyListItemContainer(props) {
  const { supply, dispatch } = props;
  const [openInventoryModal, setOpenInventoryModal] = useState(false);

  const onEdit = useCallback(
    () => dispatch(push(`/catalogs/supplies/${supply.id}/edit`)),
    [supply.id, dispatch],
  );
  const onDelete = useCallback(
    () => deleteSupply(dispatch, supply.id),
    [supply.id],
  );
  const onClickManageInventory = useCallback(
    () => setOpenInventoryModal(true),
    [],
  );
  const closeInventoryModal = useCallback(
    () => setOpenInventoryModal(false),
    [],
  );

  return (
    <SupplyListItem
      onEdit={onEdit}
      onDelete={onDelete}
      onClickManageInventory={onClickManageInventory}
      closeInventoryModal={closeInventoryModal}
      openInventoryModal={openInventoryModal}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    supplyCategory: getConfigById(
      state,
      'supplyCategories',
      ownProps.supply.supplyCategoryId,
    ),
    unit: getUnitById(state, ownProps.supply.unitId),
  };
}

export default connect(mapStateToProps)(SuppyListItemContainer);
