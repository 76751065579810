import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const GreenCheck = (props) => {
  const {color = '#fff', ...rest} = props;

  return (
    <SvgIcon
      id="Group_7943"
      data-name="Group 7943"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        id="Path_3075"
        data-name="Path 3075"
        d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
        fill="#70985f"
      />
      <g id="ic_confirmation" transform="translate(8 6.55)">
        <path
          id="shape-check"
          d="M4.5,13.275,0,9.3,1.087,8.063l3.413,3L10.875,5.1,12,6.3Z"
          transform="translate(-2 -3.651)"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  );
};

export default GreenCheck;
