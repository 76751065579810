import { Grid, IconButton } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Form, DateField } from '../library/forms';
import AddButton from '../library/AddButton';
import theme, { decorationColors, spacing } from '../library/theme';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Modal, Separator, Title } from '../library';
import ToggleButtons from '../library/ToggleButtons';
import CustomSpeedDial from '../library/SpeedDial';
import SummarizeIcon from '@mui/icons-material/Summarize';

const wrapperSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const marginSx = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: spacing.xs,
};
const containerSx = {
  padding: spacing.s,
  display: 'flex',
};
const removeShadowSx = {
  '&:hover': {
    boxShadow: 'none',
  },
  width: '50%',
};
const iconSx = {
  height: 24,
  width: 24,
  color: decorationColors.decorationBrand,
};
const titleSx = { paddingBottom: spacing.xs };
const paperSx = { '.MuiDrawer-paper': { borderRadius: spacing.s } };
const filterButtonSx = {
  backgroundColor: theme.palette.add.main,
  ':hover': {
    backgroundColor: theme.palette.add.active,
  },
  ...removeShadowSx,
};

export default function OrderSearchForm(props) {
  const {
    orderTypeEq,
    deliveryTypeEq,
    statusEq,
    paymentStatusEq,
    orderTypeEqOptions,
    deliveryTypeEqOptions,
    statusEqOptions,
    paymentStatusEqOptions,
    onAddButton,
    handleSubmit,
    error,
    onButtonsChange,
    onClearFilters,
    reportActions,
    isMobile,
  } = props;
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);
  const hideFilters = useCallback(() => {
    setShowFilters(false);
  }, []);

  return (
    <Form onSubmit={handleSubmit} error={error}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4.5}>
          <DateField
            name="scheduledAtFrom"
            label="Data Inicial"
            width="100%"
            fluid
          />
        </Grid>
        <Grid item xs={4.5}>
          <DateField
            name="scheduledAtTo"
            label="Data Final"
            width="100%"
            fluid
          />
        </Grid>
        <Grid item xs={3} style={wrapperSx}>
          <IconButton
            style={{ padding: 4, marginTop: spacing.m }}
            onClick={toggleFilters}
          >
            <FilterListIcon style={iconSx} />
          </IconButton>
          {!isMobile && (
            <CustomSpeedDial
              sx={{ paddingTop: spacing.xs }}
              icon={SummarizeIcon}
              actions={reportActions}
            />
          )}
          <AddButton onClick={onAddButton} sx={{ paddingTop: spacing.xs }} />
        </Grid>
      </Grid>

      <Modal
        anchor="right"
        open={showFilters}
        onClose={hideFilters}
        sx={paperSx}
        maxWidth="260px"
        title="Filtros"
      >
        <Grid container sx={containerSx}>
          <Grid item xs={12} sx={marginSx}>
            <Title sx={titleSx} variant="h7">
              Tipo de pedido
            </Title>
            <ToggleButtons
              name="orderTypeEq"
              onChange={onButtonsChange}
              buttons={orderTypeEqOptions}
              fieldValue={orderTypeEq}
            />
          </Grid>
          <Grid item xs={12}>
            <Separator />
          </Grid>
          <Grid item xs={12} sx={marginSx}>
            <Title sx={titleSx} variant="h7">
              Tipo de entrega
            </Title>
            <ToggleButtons
              name="deliveryTypeEq"
              onChange={onButtonsChange}
              buttons={deliveryTypeEqOptions}
              fieldValue={deliveryTypeEq}
            />
          </Grid>
          <Grid item xs={12}>
            <Separator />
          </Grid>
          <Grid item xs={12} sx={marginSx}>
            <Title sx={titleSx} variant="h7">
              Status do pedido
            </Title>
            <ToggleButtons
              name="statusEq"
              onChange={onButtonsChange}
              buttons={statusEqOptions}
              fieldValue={statusEq}
            />
          </Grid>
          <Grid item xs={12}>
            <Separator />
          </Grid>
          <Grid item xs={12} sx={marginSx}>
            <Title sx={titleSx} variant="h7">
              Status de pagamento
            </Title>
            <ToggleButtons
              name="paymentStatusEq"
              onChange={onButtonsChange}
              buttons={paymentStatusEqOptions}
              fieldValue={paymentStatusEq}
            />
          </Grid>
          <Grid item xs={12}>
            <Separator />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', gap: spacing.xs }}>
            <Button sx={removeShadowSx} onClick={onClearFilters}>
              Limpar filtros
            </Button>
            <Button sx={filterButtonSx} onClick={hideFilters}>
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Form>
  );
}
