import React, { useCallback, useRef } from 'react';
import Button from '../Button';
import { FaFileUpload } from 'react-icons/fa';

const doFocus = (e) => e.target.focus();

function freeMemory(url) {
  url && window.URL.revokeObjectURL(url);
}
export default function FileInput(props) {
  const {
    placeholder = 'Arquivo',
    icon = { FaFileUpload },
    value,
    onChange,
    ...rest
  } = props;

  let previewUrl = '';
  let objectUrl = '';
  if (typeof value === 'string') {
    previewUrl = value;
  } else if (value) {
    objectUrl = previewUrl = URL.createObjectURL(value);
  }

  const onChangeHandler = useCallback(
    (e) => {
      e.preventDefault();
      onChange(e.target.files);
      e.target.value = '';
    },
    [onChange],
  );

  const inputRef = useRef();

  const Icon = icon;

  React.useEffect(() => () => freeMemory(objectUrl), [objectUrl]);

  const buttonClickHandler = useCallback(() => {
    inputRef.current.click();
  }, [inputRef]);

  return (
    <div>
      <Button
        type="button"
        component="label"
        color="success"
        onClick={buttonClickHandler}
        startIcon={<Icon />}
      >
        {`Anexar ${placeholder}`}
      </Button>
      <input
        ref={inputRef}
        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        {...rest}
        title={value ? value.name : undefined}
        hidden
        onClick={doFocus}
        onChange={onChangeHandler}
      />
    </div>
  );
}
