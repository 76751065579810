import React from 'react';
import Page from '../page/Page';
import SupplyFormContainer from './SupplyFormContainer';

export default function SupplyPage(props) {
  const supplyId = props?.match?.params?.supplyId;
  return (
    <Page
      back="/catalogs/supplies"
      headerType="secondary"
      title="Insumos"
      offsetHeader={0}
    >
      <SupplyFormContainer supplyId={supplyId} />
    </Page>
  );
}
