import React from 'react';
import {Card as MuiCard, Typography} from '@mui/material';
import theme from './theme';
import Box from './Box';
import Button from './Button';

const defaultSx = {
  padding: theme.spacing(4),
  minWidth: '352px',
  borderRadius: '20px',
};
const textSx = {
  textAlign: 'center',
  fontSize: '16px',
  pb: '24px',
};
const buttonsWrapperSx = {
  display: 'flex',
  gap: '16px',
  justifyContent: 'center',
};
const buttonSx = {
  width: '136px',
  height: '48px',
};

export default function ConfirmationCard(props) {
  const {
    children,
    sx,
    text,
    okText = 'Sim',
    cancelText = 'Não',
    okHandler,
    cancelHandler,
    redColor,
    ...rest
  } = props;

  return (
    <MuiCard sx={{...defaultSx, ...sx}} {...rest}>
      <Typography sx={textSx}>{text}</Typography>
      <Box sx={buttonsWrapperSx}>
        <Button sx={buttonSx} fluid variant="outlined" onClick={cancelHandler}>
          {cancelText}
        </Button>
        <Button
          sx={buttonSx}
          fluid
          variant="add"
          color={redColor ? 'danger' : 'add'}
          onPress={okHandler}
        >
          {okText}
        </Button>
      </Box>
    </MuiCard>
  );
}
