import React from 'react';
import Segment from '../library/Segment';
import Box from '../library/Box';
import theme from '../library/theme';
import Typography from '../library/Typography';
import { toCurrency } from '../../utils';
import IncomeDoughnutChart from './IncomeDoughnutChart';

const containerSx = {
  p: 3,
  mb: 4,
};
const titleSx = {
  display: 'flex',
  justifyContent: 'space-between',
};
const positiveSx = {
  color: theme.palette.add.main,
};
const negativeSx = {
  color: theme.palette.danger.main,
};
const totalWrapperSx = {
  pt: 3,
  textAlign: 'center',
};

export default function StatementPeriodChartData({
  totalRevenues = 0,
  totalExpenses = 0,
  currency,
}) {
  const total = totalRevenues + totalExpenses;
  const incomeAmount = totalRevenues - totalExpenses;
  const positiveIncome = incomeAmount > 0;
  const currencySymbol = currency?.symbol ? `(${currency?.symbol})` : '(R$)';

  if (total === 0) {
    return null;
  }

  return (
    <Segment sx={containerSx}>
      <Box sx={titleSx}>
        <IncomeDoughnutChart
          revenue={totalRevenues}
          expense={totalExpenses}
          currency={currency}
        />
      </Box>
      <Box sx={totalWrapperSx}>
        <Typography>Total {currencySymbol}</Typography>
        <Typography
          sx={positiveIncome ? positiveSx : negativeSx}
          variant="h6"
          weight="bold"
        >
          {positiveIncome && '+'} {toCurrency(incomeAmount, currency?.code)}
        </Typography>
      </Box>
    </Segment>
  );
}
