import { toArray } from '../utils/toArray';

const initialState = {
  orderIds: [],
  loading: false,
  totalPages: 0,
  currentPage: 1,
  totalCount: 0,
};

export default function ordersListReducer(state = initialState, action) {
  switch (action.type) {
    case 'ORDERS_FETCH_REQUESTED':
      return { ...state, loading: true };
    case 'ORDERS_FETCHED':
      return {
        ...state,
        loading: false,
        totalPages: action.totalPages,
        totalCount: action.totalCount,
        currentPage: action.currentPage,
        orderIds: action.result
          ? toArray(action.result)
          : initialState.orderIds,
      };
    case 'ORDERS_PAGE_CHANGED':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case 'ORDERS_FILTERS_CHANGED':
      return {
        ...state,
        filters: action.filters,
        currentPage: 1,
      };
    default:
      return state;
  }
}
