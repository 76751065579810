import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import {
  createOrUpdateSupply,
  fetchSupply,
} from '../../processes/supplyProcessess';
import { getConfig, getUnitById } from '../../selectors/configSelectors';
import { getSupply } from '../../selectors/supplySelectors';
import SupplyForm from './SupplyForm';

const FORM_NAME = 'supply';

function SupplyFormContainer(props) {
  const { dispatch, supplyId, supply } = props;

  useEffect(() => {
    if (supply || !supplyId) return;
    fetchSupply(dispatch, supplyId);
  }, [supply, supplyId]);

  return <SupplyForm {...props} />;
}

function mapStateToProps(state, { supplyId }) {
  const supply = getSupply(state, supplyId);
  const selector = formValueSelector(FORM_NAME);
  const unitId = selector(state, 'unitId');

  return {
    initialValues: supply,
    supplyCategories: getConfig(state, 'supplyCategories'),
    units: getConfig(state, 'units'),
    unit: getUnitById(state, unitId),
    supplyId,
    supply,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: createOrUpdateSupply,
  })(SupplyFormContainer),
);
