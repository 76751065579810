import React from 'react';
import { connect } from 'react-redux';
import { FieldArray } from 'redux-form';
import { getUnits } from '../../../selectors/configSelectors';
import MultipleItemsInput from './MultipleItemsInput';

function MultipleItemsField(props) {
  return (
    <FieldArray
      component={MultipleItemsInput}
      rerenderOnEveryChange={props.editEnabled}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  return {
    units: getUnits(state),
  };
}

export default connect(mapStateToProps)(MultipleItemsField);
