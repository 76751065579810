import React from 'react';
import { Box, Paragraph, Title } from '../library';
import {
  spacing,
  stateColors,
  styles,
  typographyColors,
} from '../library/theme';
import HorizontalItem from '../library/HorizontalItem';
import { toCurrency } from '../../utils';

const containerSx = {
  ...styles.shadow,
  borderRadius: spacing.m,
  padding: spacing.m,
  marginBottom: spacing.m,
};
const titleSx = {
  margin: '0 !important',
  padding: '0 !important',
};
const subtitleSx = {
  fontSize: 12,
  color: stateColors.hover,
  marginBottom: spacing.s,
};
const cardSx = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: spacing.s,
};
const rankingWrapperSx = {
  minWidth: 82,
  display: 'flex',
  flexDirection: 'column',
};
const rankingSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing.xxs,
  color: stateColors.hover,
  fontSize: 12,
  margin: '0 !important',
  padding: '0 !important',
};
const selecedRankingSx = {
  ...rankingSx,
  order: 1,
  color: typographyColors.defaultTitle,
  fontSize: 16,
  fontWeight: 'bold',
};
const unselectedRankingSx = { ...rankingSx, order: 2 };

const makeRankingItemSx = (condition) => {
  return condition ? selecedRankingSx : unselectedRankingSx;
};
const makePriceSuggestion = (priceSuggestion) => {
  switch (priceSuggestion) {
    case 'increase':
      return '⬆️';
    case 'decrease':
      return '⬇️';
    case 'keep':
      return '🟰';
  }
};
const makeSoldQuantitySuggestion = (soldQuantitySuggestion) => {
  switch (soldQuantitySuggestion) {
    case 'keep':
      return '🟰';
    case 'increase':
      return '⬆️';
  }
};
const makeType = (type) => {
  switch (type) {
    case 'suggested_price':
    case 'suggested_quantity':
      return '(Sugerido)';
    case 'price':
    case 'sold_quantity':
      return '(Atual)';
    default:
      return '';
  }
};
const makeOldValue = (data, oldValue, newValue, parseMethod = (el) => el) => {
  return data[oldValue] != data[newValue]
    ? `${parseMethod(data[oldValue])} ⇒`
    : '';
};

export default function PricingProduct(props) {
  const { product, productData, productCategory, scenario, sortBy, currency } =
    props;
  const isCurrent = scenario == 'current';
  const isCurrentPrice = scenario == 'suggested_current';

  return (
    <Box sx={containerSx}>
      <Box sx={cardSx}>
        <Box>
          <Title sx={titleSx}>{productData?.name}</Title>
          <Paragraph sx={subtitleSx}>{productCategory?.name}</Paragraph>
        </Box>
        <Box sx={rankingWrapperSx}>
          <Box sx={makeRankingItemSx(sortBy == 'soldQuantityRanking')}>
            {product?.soldQuantityRanking}º VENDAS
          </Box>
          <Box sx={makeRankingItemSx(sortBy == 'incomeRanking')}>
            {product?.incomeRanking}º LUCRO
          </Box>
        </Box>
      </Box>
      <HorizontalItem
        title={`Preço Unitário ${makeType(product?.priceType)}`}
        description={`${makeOldValue(
          product,
          'currentPrice',
          'price',
          toCurrency,
        )} ${toCurrency(product?.price, currency?.code)}`}
      />
      <HorizontalItem
        title={`Quantidade Vendida ${makeType(product?.soldQuantityType)}`}
        description={`${makeOldValue(
          product,
          'currentSoldQuantity',
          'soldQuantity',
        )} ${product?.soldQuantity > 0 ? product.soldQuantity : 'Sem vendas'}`}
      />
      {!isCurrent && (
        <>
          <HorizontalItem
            title="Sugestão Quantidade de Vendas"
            description={makeSoldQuantitySuggestion(
              product?.soldQuantitySuggestion,
            )}
          />
          {!isCurrentPrice && (
            <HorizontalItem
              title="Sugestão Preço"
              description={makePriceSuggestion(product?.priceSuggestion)}
            />
          )}
        </>
      )}
    </Box>
  );
}
