import React from 'react';
import { Box, Grid, Segment } from '../library';
import Form from '../library/forms/Form';
import OnboardingNavigation from './OnboardingNavigation';
import RelaxedCook from '../library/images/RelaxedCook';
import { DropDownField, TextField, Label } from '../library/forms';
import { currencyMask, makeDropDownOptions } from '../../utils';

const segmentSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  height: 436,
  width: 352,
};
const containerSx = {
  position: 'relative',
};
const imageSx = {
  position: 'absolute',
  top: -130,
  left: 88,
  zIndex: -1,
  ['@media (max-height:750px)']: {
    display: 'none',
  },
};

export default function CompanyForm(props) {
  return (
    <Box sx={containerSx}>
      <Box sx={imageSx}>
        <RelaxedCook />
      </Box>
      <Form onSubmit={props.handleSubmit} error={props.error}>
        <Segment fluid sx={segmentSx}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="companiesAttributes[0][monthlyBillingGoal]"
                label="Qual a sua meta de faturamento mensal?"
                placeholder="Sua meta"
                fluid
                {...currencyMask(`${props?.selectedCurrency?.symbol} `)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Label required>Você possui funcionários</Label>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <DropDownField
                    name="companiesAttributes[0][hasEmployees]"
                    data={makeDropDownOptions(
                      [
                        { name: 'Sim', id: 'yes' },
                        { name: 'Não', id: 'no' },
                      ],
                      'Selecione',
                    )}
                    fluid
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="companiesAttributes[0][employeeQuantity]"
                    placeholder="Quantos?"
                    type="number"
                    min="0"
                    required={props.hasEmployees == 'yes'}
                    disabled={props.hasEmployees != 'yes'}
                    fluid
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DropDownField
                name="companiesAttributes[0][companyLocationTypeId]"
                label="Você trabalha de"
                data={makeDropDownOptions(
                  props.companyLocationTypes,
                  'Selecione uma opção',
                )}
                fluid
                required
              />
            </Grid>
            <Grid item xs={12}>
              <DropDownField
                name="companiesAttributes[0][companyFlagshipProductId]"
                label={`Você quer ser reconhecido(a) pelo que?\nQual o tipo de produto que você mais quer\nvender?`}
                data={makeDropDownOptions(
                  props.companyFlagshipProducts,
                  'Selecione uma opção',
                )}
                fluid
                required
              />
            </Grid>
          </Grid>
        </Segment>

        <OnboardingNavigation {...props} />
      </Form>
    </Box>
  );
}
