import React from 'react';
import { Paragraph, Title, Modal } from '../library';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AddButton from '../library/AddButton';
import { spacing } from '../library/theme';

const iconStyle = { width: 32, height: 32 };
const suggestionSx = {
  marginBottom: spacing.m,
};

const makeList = (ids, data, separator = ', ') => {
  return ids
    .map((id) => data.products.find(({ id: productId }) => productId == id))
    .map((item) => item.name)
    .join(separator);
};

export default function PricingSuggestionsModal(props) {
  const { open, toggleModal, title, data } = props;

  return (
    <>
      <Modal title="Sugestões" open={open} onClose={toggleModal}>
        <Title variant="h6">{title}</Title>
        {!!data?.suggestions?.justKeepProductIds.length && (
          <Paragraph sx={suggestionSx}>
            ● <strong>Manter</strong>{' '}
            {makeList(data?.suggestions?.justKeepProductIds, data)}{' '}
            <strong>em seu porfólio, mas sem focar em sua produção.</strong>
          </Paragraph>
        )}
        {!!data?.suggestions?.focusProductIds.length && (
          <Paragraph sx={suggestionSx}>
            ● <strong>Focar na produção e venda de</strong>{' '}
            {makeList(data?.suggestions?.focusProductIds, data, ' e ')}.
          </Paragraph>
        )}
        {!!data?.suggestions?.useProductIds.length && (
          <Paragraph sx={suggestionSx}>
            ● <strong>Usar</strong>{' '}
            {makeList(data?.suggestions?.useProductIds, data)}.
          </Paragraph>
        )}
        {!!data?.suggestions?.increaseProductIds.length && (
          <Paragraph sx={suggestionSx}>
            ● <strong>Aumentar as vendas de</strong>{' '}
            {makeList(data?.suggestions?.increaseProductIds, data, ' e ')}.
          </Paragraph>
        )}
      </Modal>
      <AddButton
        icon={<ContactSupportIcon style={iconStyle} />}
        onClick={toggleModal}
      />
    </>
  );
}
