import { normalize, schema } from 'normalizr';
import { apiDel, apiGet, apiPost, apiPut } from './helpers/api';
import { replace } from 'connected-react-router';
import toQueryString from '../utils/toQueryString';
import { notifySubmitSucceeded } from './notifierProcesses';

const taskSchema = new schema.Entity('tasks');

export const fetchTasks = (dispatch, props) => {
  const currentPage = props?.currentPage || 1;
  const pageParams = props?.paged == true ? `page=${currentPage}` : '';
  const params = { ...props?.filters };
  const filterParams = toQueryString(params);

  dispatch({ type: 'TASKS_FETCH_REQUESTED' });

  return apiGet(`/api/v1/tasks?${filterParams}${pageParams}`).then(
    (response) => {
      dispatch({
        type: 'TASKS_FETCHED',
        ...normalize(response.body.data, new schema.Array(taskSchema)),
        totalPages: response.body.totalPages,
        currentPage: response.body.currentPage,
      });
    },
  );
};

export const fetchTask = (dispatch, id) => {
  return apiGet(`/api/v1/tasks/${id}`).then((response) => {
    dispatch({
      type: 'TASK_FETCHED',
      ...normalize(response.body.data, taskSchema),
    });
  });
};

export const createOrUpdateTask = (values, dispatch, props) => {
  const handler = !values.id ? createTask : updateTask;
  return handler(values, dispatch, props);
};

const createTask = (values, dispatch) => {
  return apiPost('/api/v1/tasks')
    .send({ task: values })
    .then((response) => {
      dispatch({
        type: 'TASK_CREATED',
        ...normalize(response.body.data, [taskSchema]),
      });
      notifySubmitSucceeded(dispatch, 'success', 'Tarefa adicionada!');
      dispatch(replace('/tasks'));
    });
};

const updateTask = (values, dispatch, props) => {
  return apiPut(`/api/v1/tasks/${props.taskId}`)
    .send({ task: values })
    .then((response) => {
      dispatch({
        type: 'TASK_UPDATED',
        ...normalize(response.body.data, [taskSchema]),
      });
      notifySubmitSucceeded(dispatch, 'success', 'Tarefa atualizada!');
      dispatch(replace('/tasks'));
    });
};

export const deleteTask = (dispatch, id) =>
  apiDel(`/api/v1/tasks/${id}`).then(() => {
    dispatch({
      type: 'TASK_DELETED',
      id,
    });
  });

export const submitSearchFilter = (filters, dispatch) => {
  const { statusEq, titleCont } = filters;
  dispatch({
    type: 'TASKS_FILTERS_CHANGED',
    filters: { titleCont, statusEq },
  });
};
