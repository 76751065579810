import React from 'react';
import Button from '../library/Button';
import Form from '../library/forms/Form';
import TextField from '../library/forms/TextField';
import { spacing } from '../library/theme';
import Box from '../library/Box';
import {
  currencyMask,
  makeDropDownOptions,
  percentageMask,
  toCurrency,
} from '../../utils';
import Page from '../page/Page';
import { DropDownField, SearchDropDownField } from '../library/forms';
import Hint from '../library/Hint';
import { Card, Separator } from '../library';
import ResultInfo from '../library/ResultInfo';
import { CardContent, CardHeader } from '@mui/material';

const buttonMargin = {
  mt: spacing.s,
  mb: spacing.m,
};
const formWrapper = {
  mt: spacing.s,
};
const cardStyle = {
  marginBottom: spacing.s,
};

export default function PricingCalculatorForm({
  products,
  productCategories,
  strategicRoles,
  workHourCost,
  fixedHourCost,
  averageProfitMargin,
  resultData,
  currency,
  ...props
}) {
  const resultCondition = !!resultData?.result;
  return (
    <Page title="Precificação" back="/" headerType="secondary">
      <Box sx={formWrapper}>
        <Form>
          <Card fluid style={cardStyle}>
            <CardHeader title="Escolha o produto (opcional)" />
            <CardContent>
              <SearchDropDownField
                name="productId"
                label="Qual é o produto?"
                options={makeDropDownOptions(products, 'Selecione uma opção')}
                fluid
              />
            </CardContent>
          </Card>
          <Card fluid style={cardStyle}>
            <CardHeader title="Revise seus dados" />
            <CardContent>
              <TextField
                name="desiredSalary"
                type="number"
                label="Qual o salário desejado?"
                placeholder="Salário desejado"
                minValue="1"
                required
                fluid
                {...currencyMask(currency?.symbol)}
              />
              <TextField
                name="hoursPerDay"
                type="number"
                label="Quantas horas deseja trabalhar por dia?"
                placeholder="Horas por dia"
                minValue="1"
                maxValue="24"
                required
                fluid
              />
              <TextField
                name="daysPerWeek"
                type="number"
                label="Quantos dias de trabalho por semana?"
                placeholder="Dias por semana"
                minValue="1"
                maxValue="7"
                required
                fluid
              />
              {workHourCost > 0 && (
                <>
                  <Separator />
                  <Hint>
                    Custo da hora trabalhada:
                    {toCurrency(workHourCost, currency?.code)}
                  </Hint>
                </>
              )}
            </CardContent>
          </Card>
          <Card fluid style={cardStyle}>
            <CardHeader title="Despesas fixas" />
            <CardContent>
              <TextField
                name="fixedMonthlyExpenses"
                type="number"
                label="Qual sua despesa fixa mensal?"
                placeholder="Despesa fixa"
                minValue="1"
                required
                fluid
                {...currencyMask(currency?.symbol)}
              />
              {fixedHourCost > 0 && (
                <>
                  <Separator />
                  <Hint>
                    Custo fixo por hora trabalhada:
                    {toCurrency(fixedHourCost, currency?.code)}
                  </Hint>
                </>
              )}
            </CardContent>
          </Card>
          <Card fluid style={cardStyle}>
            <CardHeader title="Informações do produto" />
            <CardContent>
              <TextField
                name="productCost"
                type="number"
                label="Qual o custo do produto?"
                placeholder="Custo do produto"
                minValue="1"
                required
                fluid
                {...currencyMask(currency?.symbol)}
              />
              <TextField
                name="productPreparationTime"
                type="number"
                label="Quantos minutos são necessários para produzir o produto?"
                placeholder="Tempo em minutos"
                minValue="1"
                required
                fluid
              />
              <DropDownField
                name="productCategoryId"
                label="Qual a categoria do produto?"
                data={makeDropDownOptions(
                  productCategories,
                  'Selecione uma opção',
                )}
                required
                fluid
              />
              <DropDownField
                name="strategicRoleId"
                label="Qual a função estratégica do produto?"
                data={makeDropDownOptions(
                  strategicRoles,
                  'Selecione uma opção',
                )}
                required
                fluid
              />
              <TextField
                name="profitMargin"
                type="number"
                label="Qual a margem de lucro desejada?"
                placeholder="Margem de lucro"
                minValue="1"
                required
                fluid
                {...percentageMask}
              />
            </CardContent>
          </Card>
          <Box sx={buttonMargin}>
            <Button
              type="submit"
              onClick={props?.handleSubmit}
              fluid
              disabled={props?.invalid || props?.submitting}
            >
              Calcular
            </Button>
            {resultCondition && (
              <ResultInfo resultData={resultData} currency={currency} />
            )}
          </Box>
        </Form>
      </Box>
    </Page>
  );
}
