import { Checkbox } from '@mui/material';
import { css } from 'glamor';
import React from 'react';
import { Field } from 'redux-form';
import Hint from '../Hint';
import { spacing } from '../theme';
import FieldBlock from './FieldBlock';

const container = { display: 'flex', gap: spacing.xxs };
const labelClass = {
  display: 'flex',
  alignItems: 'center',
};
const hintClass = css({ marginTop: 0 });
const inputComponentClass = css({ padding: '0 !important' });

const buildCheckBoxField = (InputComponent) => (props) => {
  const { input, style, label, hint, ...rest } = props;
  const id = input.id || input.name + input.value;

  return (
    <>
      <div style={container}>
        <FieldBlock style={style}>
          <InputComponent
            id={id}
            className={inputComponentClass}
            label=" "
            {...input}
            {...rest}
          />
        </FieldBlock>
        <div style={labelClass}>{label}</div>
      </div>
      {hint && <Hint className={hintClass}>{hint}</Hint>}
    </>
  );
};

const FormField = buildCheckBoxField(Checkbox, (input, meta, rest) => ({
  ...input,
  invalid: meta.touched && meta.invalid,
  ...rest,
}));

export default function CheckBoxField(attrs) {
  const { checked = false, ...props } = attrs;
  
  return <Field {...props} checked={checked} component={FormField} />;
}
