import React from 'react';
import { Field } from 'redux-form';
import buildFormField from './buildFormField';
import SearchDropDownInput from './SearchDropDownInput';

const FormField = buildFormField(SearchDropDownInput, (input, meta, rest) => {
  return {
    ...input,
    ...meta,
    ...rest,
    onChange: (event, { value }) => input.onChange(value),
    value: input.value,
    invalid: `${meta.touched && (meta.invalid || rest.invalid)}`,
    onBlur: (e) => e?.preventDefault(),
    getOptionLabel: (option) => {
      return typeof option === 'object'
        ? option.text
        : rest?.options?.find((option) => option.value == input.value)?.text;
    },
  };
});

export default function SearchDropDownField(props) {
  return <Field {...props} component={FormField} />;
}
