import React from 'react';
import { connect } from 'react-redux';
import OrderSearchForm from './OrderSearchForm';
import { submitSearchFilter } from '../../processes/orderProcesses';
import { change, formValueSelector, reduxForm } from 'redux-form';
import debounce from '../../utils/debounce';
import moment from 'moment-timezone';
import { useCallback } from 'react';
import { makeIsMobile } from '../../utils/useScreenWidth';

const FORM_NAME = 'orderSearch';

function OrderSearchFormContainer(props) {
  const { dispatch } = props;
  const isMobile = makeIsMobile();

  const onButtonsChange = (event, value, fieldName) =>
    onChangeHandler(event, value, fieldName);

  const onChangeHandler = useCallback(
    (_, value, fieldName) => {
      dispatch(change(FORM_NAME, fieldName, value));
    },
    [dispatch],
  );
  const onClearFilters = useCallback(() => {
    dispatch(change(FORM_NAME, 'orderTypeEq', ''));
    dispatch(change(FORM_NAME, 'deliveryTypeEq', ''));
    dispatch(change(FORM_NAME, 'statusEq', ''));
    dispatch(change(FORM_NAME, 'paymentStatusEq', ''));
  }, [dispatch]);

  return (
    <OrderSearchForm
      onButtonsChange={onButtonsChange}
      onClearFilters={onClearFilters}
      isMobile={isMobile}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const orderTypeEqOptions = [
    { text: 'Por catálogo', value: 'standard' },
    { text: 'Rápida', value: 'quick_sell' },
  ];
  const deliveryTypeEqOptions = [
    { text: 'Entregue', value: 'delivery' },
    { text: 'Retirada', value: 'takeaway' },
  ];
  const statusEqOptions = [
    { text: 'Agendado', value: 'scheduled' },
    { text: 'Em produção', value: 'in_production' },
    { text: 'Finalizado', value: 'completed' },
    { text: 'Entregue', value: 'delivered' },
    { text: 'Cancelado', value: 'canceled' },
  ];
  const paymentStatusEqOptions = [
    { text: 'Pago', value: 'paid' },
    { text: 'Não pago', value: 'unpaid' },
  ];

  const today = moment();
  const firstDay = moment(today)?.startOf('month');
  const lastDay = moment(today)?.endOf('month');

  const valueSelector = formValueSelector(FORM_NAME);
  const {
    scheduledAtFrom,
    scheduledAtTo,
    orderTypeEq,
    deliveryTypeEq,
    statusEq,
    paymentStatusEq,
  } = valueSelector(
    state,
    'scheduledAtFrom',
    'scheduledAtTo',
    'orderTypeEq',
    'deliveryTypeEq',
    'statusEq',
    'paymentStatusEq',
  );

  return {
    initialValues: {
      scheduledAtFrom: firstDay,
      scheduledAtTo: lastDay,
    },
    scheduledAtFrom,
    scheduledAtTo,
    orderTypeEq,
    deliveryTypeEq,
    statusEq,
    paymentStatusEq,
    orderTypeEqOptions,
    deliveryTypeEqOptions,
    statusEqOptions,
    paymentStatusEqOptions,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onChange: debounce(submitSearchFilter, 500),
  })(OrderSearchFormContainer),
);
