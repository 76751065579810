import React from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { IconButton, MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import { Box, Link, Typography } from '../library';
import NotificationListItemContainer from './NotificationListItemContainer';
import EmptyState from '../library/EmptyState';
import RecipeIcon from '../library/images/recipe.png';
import theme, { spacing } from '../library/theme';
import { css } from 'glamor';

const containerSx = {
  display: 'flex',
  alignItems: 'center',
};
const iconSx = {
  color: 'white !important',
  fontSize: 27,
};
const all = {
  textAlign: 'center',
  width: '100%',
};
const menuSx = {
  borderRadius: 3,
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  p: 0,
  mt: 1,
  '& .MuiIconButton-root': {
    width: 32,
    height: 32,
    ml: 1,
    mr: 1,
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 20,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
};
const emptySx = {
  maxWidth: '300px',
  mb: 3,
};
const allWrapper = {
  mt: 2,
  borderRadius: spacing.m,
};
const badge = css({
  position: 'absolute',
  right: 5,
  top: 5,
  backgroundColor: theme.palette.danger.main,
  borderRadius: '50%',
  width: 21,
  height: 21,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
const badgeSx = {
  fontSize: 9,
  color: 'white',
  padding: 0,
  margin: 0,
  lineHeight: 0,
};
const notificationsWrapperStyle = {
  position: 'relative',
  maxHeight: 370,
  overflowY: 'scroll',
};

export default function Notifications(props) {
  const {
    notifications,
    open,
    onOpen,
    onClose,
    anchorEl,
    unreadCount,
    inverted,
  } = props;

  return (
    <>
      <Box sx={containerSx}>
        <IconButton size="large" onClick={onOpen}>
          {!!unreadCount && (
            <div className={badge}>
              <Typography sx={badgeSx} p={0} weight="bold">
                {unreadCount}
              </Typography>
            </div>
          )}
          <NotificationsNoneIcon
            sx={{ ...iconSx, ...(inverted && { color: '#0B70EC' }) }}
          />
        </IconButton>
      </Box>
      <Menu
        title="Notificações"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={{ elevation: 0, sx: menuSx }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div style={notificationsWrapperStyle}>
          {notifications.map((notification) => (
            <NotificationListItemContainer
              key={notification.id}
              notification={notification}
            />
          ))}
          {!notifications.length && (
            <Box sx={emptySx}>
              <EmptyState
                iconUrl={RecipeIcon}
                title="Nenhuma notificação nova"
              />
            </Box>
          )}
          <MenuItem sx={allWrapper}>
            <Link to="/notifications" style={all}>
              Ver todas
            </Link>
          </MenuItem>
        </div>
      </Menu>
    </>
  );
}
