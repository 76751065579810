import { apiGet, apiPost } from './helpers/api';

export const fetchPricing = (dispatch) => {
  dispatch({ type: 'PRICING_FETCH_REQUESTED' });
  return apiGet('/api/v1/companies_pricings').then((response) => {
    pricingFetched(dispatch, response.body);
  });
};

export const pricingFetched = (dispatch, pricing) =>
  dispatch({
    type: 'PRICING_FETCHED',
    data: pricing,
  });

export const pricingGenerated = (dispatch, pricing) =>
  dispatch({
    type: 'PRICING_GENERATED',
    data: pricing,
  });

export const changeScenario = (dispatch, selectedScenario) => {
  dispatch({
    type: 'SCENARIO_CHANGED',
    selectedScenario,
  });
};

export const calculateProductPricing = (values, dispatch, props) => {
  return apiPost('/api/v1/pricing_calculator')
    .send({
      calculationInfo: {
        price: props?.finalProductPrice,
        margin: values?.profitMargin,
        averageCost: values?.averageCost,
        averagePrice: values?.averagePrice,
        averageProfitMargin: values?.averageProfitMargin,
        strategicRoleId: values?.strategicRoleId,
        productCategoryId: values?.productCategoryId,
      },
    })
    .then((response) => {
      dispatch({
        type: 'PRICING_CALCULATED',
        data: response.body.data,
      });
    });
};
