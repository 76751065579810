import React from 'react';
import { Field } from 'redux-form';
import buildFormField from './buildFormField';
import isLength from 'validator/lib/isLength';
import memoize from 'fast-memoize';
import DropDownInput from './DropDownInput';

const FormField = buildFormField(DropDownInput, (input, meta, rest) => {
  return {
    ...input,
    autoComplete: 'off',
    ...rest,
    invalid: `${meta.touched && (meta.invalid || rest.invalid)}`,
  };
});

// this needs to be memoizable!
const buildValidator = memoize((required, minSize, other) => {
  return [
    (value) => {
      if (required && !value) {
        return 'required';
      }
      if (minSize && !isLength(value, minSize)) {
        return 'minSize';
      }
      return undefined;
    },
  ].concat(other || []);
});

export default function DropDownField(props) {
  const { required, type, validate, minSize } = props;
  return (
    <Field
      {...props}
      component={FormField}
      validate={buildValidator(required, Number(minSize), validate)}
    />
  );
}
