import React from 'react';
import { Typography } from '../library';
import { Card } from '../library/cards';
import formatDate from '../../utils/formatDate';
import { spacing } from '../library/theme';
import Label from '../library/Label';

const descriptionStyle = {
  marginTop: spacing.m,
};
const labelSx = {
  size: 'small',
  maxWidth: 'fit-content',
  marginTop: spacing.xxs,
};

const translatedStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'Pendente';
    case 'in_progress':
      return 'Em progresso';
    case 'finished':
      return 'Finalizado';
  }
};
const setStatusLabelColor = (status) => {
  switch (status) {
    case 'pending':
      return 'primary';
    case 'in_progress':
      return 'warning';
    case 'finished':
      return 'success';
  }
};

export default function TaskListItem(props) {
  const { task, onEdit, onDelete } = props;

  return (
    <Card key={task.id} title={task.title} onEdit={onEdit} onDelete={onDelete}>
      {formatDate(task.scheduleAt, 'DD/MM/yyyy - HH:mm')}
      {task?.status && (
        <Label
          color={setStatusLabelColor(task?.status)}
          label={translatedStatus(task?.status)}
          sx={labelSx}
        />
      )}
      {task.description && (
        <Typography variant="p" style={descriptionStyle}>
          {task.description}
        </Typography>
      )}
    </Card>
  );
}
