import moment from 'moment-timezone';

export default function getDates(date, num) {
  const startDate = moment(date);
  const result = [];

  for (let i = 0; i < num; i++) {
    const currentDate = moment(startDate).add(i, 'days');
    result.push(currentDate.format());
  }

  return result;
}
