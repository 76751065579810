import { currencyMask, phoneMask, percentageMask } from './inputMasks';
import makeDropDownOptions from './makeDropDownOptions';
import { toCurrency } from './formatCurrency';
import capitalize from './capitalize';

export {
  currencyMask,
  phoneMask,
  percentageMask,
  makeDropDownOptions,
  toCurrency,
  capitalize,
};
