import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import InventoryListItem from './InventoryListItem';
import { deleteInventory } from '../../processes/inventoryProcessess';
import { getUnitById } from '../../selectors/configSelectors';

function InventoryListItemContainer(props) {
  const { inventory, dispatch } = props;
  const [openInventoryModal, setOpenInventoryModal] = useState(false);

  const onEdit = useCallback(() => setOpenInventoryModal(true), []);
  const closeInventoryModal = useCallback(
    () => setOpenInventoryModal(false),
    [],
  );
  const onDelete = useCallback(
    () => deleteInventory(dispatch, inventory.id),
    [inventory.id],
  );

  return (
    <InventoryListItem
      onEdit={onEdit}
      onDelete={onDelete}
      closeInventoryModal={closeInventoryModal}
      openInventoryModal={openInventoryModal}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { supply } = ownProps;

  return {
    unit: getUnitById(state, supply?.unitId),
  };
}

export default connect(mapStateToProps)(InventoryListItemContainer);
