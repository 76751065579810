import React from 'react';
import Page from '../page/Page';
import NotificationListItemContainer from './NotificationListItemContainer';
import EmptyState from '../library/EmptyState';
import RecipeIcon from '../library/images/recipe.png';
import { Box, Button } from '../library';
import Pagination from '../library/Pagination';
import { css } from 'glamor';
import { spacing } from '../library/theme';

const emptySx = {
  pb: 5,
};
const paginationWrapper = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};
const buttonWrapper = css({
  display: 'flex',
  marginBottom: spacing.xs,
  justifyContent: 'flex-end',
});

export default function NotificationsPage(props) {
  const {
    notifications,
    loading,
    currentPage,
    totalPages,
    onPageChange,
    isMobile,
    readAll,
  } = props;

  return (
    <Page back="/" title="Notificações" headerType="secondary">
      {!loading && notifications?.length ? (
        <>
          <div className={buttonWrapper}>
            <Button variant="link" onClick={readAll}>
              Marcar todos como lido
            </Button>
          </div>
          {notifications.map((notification) => (
            <NotificationListItemContainer
              key={notification.id}
              notification={notification}
              expand
              wrapped
            />
          ))}
          <div style={paginationWrapper}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
              short={isMobile}
            />
          </div>
        </>
      ) : (
        <Box sx={emptySx}>
          <EmptyState
            iconUrl={RecipeIcon}
            title="Nenhuma notificação recebida"
            loading={loading}
          />
        </Box>
      )}
    </Page>
  );
}
