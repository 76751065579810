import React from 'react';
import Form from '../library/forms/Form';
import Segment from '../library/Segment';
import Typography from '../library/Typography';
import OnboardingNavigation from './OnboardingNavigation';
import { Box, Grid } from '../library';
import BlueHairCookWoman from '../library/images/BlueHairCookWoman';
import { RadioField } from '../library/forms';

const segmentSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  height: 436,
  width: 352,
};
const typographySx = {
  whiteSpace: 'pre-line',
};
const containerSx = {
  position: 'relative',
};
const imageSx = {
  position: 'absolute',
  top: -130,
  left: 88,
  zIndex: -1,
  ['@media (max-height:750px)']: {
    display: 'none',
  },
};

const renderLevelRadios = (levels) => {
  return levels.map((level) => (
    <Grid item xs={12}>
      <RadioField
        name="levelId"
        value={level.id}
        label={level.name}
        hint={level.description}
        required
      />
    </Grid>
  ));
};

export default function LevelForm(props) {
  return (
    <Box sx={containerSx}>
      <Box sx={imageSx}>
        <BlueHairCookWoman />
      </Box>
      <Form onSubmit={props.handleSubmit} error={props.error}>
        <Segment fluid sx={segmentSx}>
          <Typography color="typography.black" sx={typographySx}>
            {`Como você classifica seu nível de\nconhecimento na confeitaria?`}
          </Typography>
          <Grid>{renderLevelRadios(props.levels)}</Grid>
        </Segment>
        <OnboardingNavigation {...props} />
      </Form>
    </Box>
  );
}
