import React from 'react';
import { Box, Grid, Segment } from '../library';
import Form from '../library/forms/Form';
import OnboardingNavigation from './OnboardingNavigation';
import RelaxedCook from '../library/images/RelaxedCook';
import { DropDownField, TextField } from '../library/forms';
import { currencyMask, makeDropDownOptions } from '../../utils';

const segmentSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  height: 530,
  width: 352,
};
const containerSx = {
  position: 'relative',
};
const imageSx = {
  position: 'absolute',
  top: -130,
  left: 88,
  zIndex: -1,
  ['@media (max-height:750px)']: {
    display: 'none',
  },
};

export default function CompanyForm(props) {
  return (
    <Box sx={containerSx}>
      <Box sx={imageSx}>
        <RelaxedCook />
      </Box>
      <Form onSubmit={props.handleSubmit} error={props.error}>
        <Segment fluid sx={segmentSx}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="companiesAttributes[0][desiredSalary]"
                label="Qual é o seu salário desejado?"
                placeholder="Sua meta"
                fluid
                {...currencyMask(`${props?.selectedCurrency?.symbol} `)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <DropDownField
                name="companiesAttributes[0][productCompetitionId]"
                label="Seu produto tem concorrência"
                data={makeDropDownOptions(
                  props.productCompetitions,
                  'Selecione uma opção',
                )}
                fluid
                required
              />
            </Grid>
            <Grid item xs={12}>
              <DropDownField
                name="companiesAttributes[0][investmentCapitalId]"
                label="Capital para investir"
                data={makeDropDownOptions(
                  props.investmentCapitals,
                  'Selecione uma opção',
                )}
                hint='Exemplo: Se você possui US$ 1.000, coloque "Até 5.000" aqui.'
                fluid
                required
              />
            </Grid>
            <Grid item xs={12}>
              <DropDownField
                name="companiesAttributes[0][currentRevenueId]"
                label="Faturamento Atual"
                data={makeDropDownOptions(
                  props.currentRevenues,
                  'Selecione uma opção',
                )}
                hint="Exemplo: Se você fatura US$ 1.000, coloque 5.000 aqui."
                fluid
                required
              />
            </Grid>
            <Grid item xs={12}>
              <DropDownField
                name="companiesAttributes[0][customerTypeId]"
                label="Tipos de Clientes"
                data={makeDropDownOptions(
                  props.customerTypes,
                  'Selecione uma opção',
                )}
                fluid
                required
              />
            </Grid>
          </Grid>
        </Segment>
        <OnboardingNavigation {...props} />
      </Form>
    </Box>
  );
}
