const LOCALE = 'pt-BR';

export const toCurrency = (
  amount = 0.0,
  currencyCode = 'BRL',
  amountLength = 2,
  currencyDisplay = 'narrowSymbol',
) => {
  if (currencyDisplay == 'none') {
    if (!amount) {
      return '0';
    }
    return amount.toLocaleString(LOCALE, {
      minimumFractionDigits: 2,
      maximumFractionDigits: amountLength,
    });
  }

  if (!amount) {
    return new Intl.NumberFormat(LOCALE, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: currencyDisplay,
    }).format(0);
  }

  const multiplier = Math.pow(10, amountLength);
  const value = Math.round(amount * multiplier);

  return new Intl.NumberFormat(LOCALE, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: currencyDisplay,
    maximumFractionDigits: amountLength,
    trailingZeroDisplay: 'stripIfInteger',
    roundingMode: 'ceil',
  }).format(value / multiplier);
};
