import { replace } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { fetchSupplies } from '../../processes/supplyProcessess';
import {
  getSupplyPageInfo,
  getSuppliesByFetchedIds,
} from '../../selectors/supplySelectors';
import SuppliesPage from './SuppliesPage';
import { makeIsMobile } from '../../utils/useScreenWidth';

const FORM_NAME = 'supplySearch';

function SuppliesPageContainer(props) {
  const { dispatch, currentPage, filters } = props;
  const isMobile = makeIsMobile();

  useEffect(() => {
    fetchSupplies(dispatch, { currentPage, filters, paged: true });
  }, [dispatch, currentPage, filters]);

  const onAdd = useCallback(() => {
    dispatch(replace('/catalogs/supplies/new'));
  }, [dispatch]);

  const onImport = useCallback(() => {
    dispatch(replace('/catalogs/supplies/import'));
  }, [dispatch]);

  const pageChangeHandler = useCallback(
    (_, currentPage = 1) => {
      dispatch({
        type: 'SUPPLIES_PAGE_CHANGED',
        currentPage,
      });
    },
    [dispatch],
  );
  const [showCreateMethods, setShowCreateMethods] = useState(false);
  const toggleCreateMethods = useCallback(() => {
    setShowCreateMethods(!showCreateMethods);
  }, [showCreateMethods]);
  const hideCreateMethods = useCallback(() => setShowCreateMethods(false));

  return (
    <SuppliesPage
      onPageChange={pageChangeHandler}
      isMobile={isMobile}
      onAdd={onAdd}
      onImport={onImport}
      showCreateMethods={showCreateMethods}
      toggleCreateMethods={toggleCreateMethods}
      hideCreateMethods={hideCreateMethods}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const formSelector = formValueSelector(FORM_NAME);
  const nameCont = formSelector(state, 'nameCont');
  const { currentPage, totalPages, filters, loading } =
    getSupplyPageInfo(state);

  return {
    supplies: getSuppliesByFetchedIds(state),
    loading,
    currentPage,
    totalPages,
    filters,
    nameCont,
  };
}

export default connect(mapStateToProps)(SuppliesPageContainer);
