import React from 'react';
import { CardForm, FileField } from '../library/forms';
import { Box, Button, Grid, Link, Info, Separator } from '../library';
import CardActions from '../library/cards/CardActions';
import { FaFileDownload, FaFileUpload } from 'react-icons/fa';

export default function SuppliesImportForm(props) {
  const { importTemplateUrl, hints } = props;
  return (
    <CardForm {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <Info title={'Dicas:'} content={hints} amount={10} />
          </Box>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '10px' }}>
          <Link
            href={importTemplateUrl}
            component={Button}
            fluid
            startIcon={<FaFileDownload />}
            variant="outlined"
          >
            Baixar planilha modelo
          </Link>
          <Separator />
        </Grid>
        <Grid item xs={12}>
          <FileField name="file" icon={FaFileUpload} placeholder="planilha" />
        </Grid>
      </Grid>
      <CardActions>
        <Button
          type="button"
          onClick={props.handleSubmit}
          color="primary"
          size="large"
          disabled={props.pristine || props.submitting}
          fluid
        >
          Importar
        </Button>
      </CardActions>
    </CardForm>
  );
}
