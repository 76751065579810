import React from 'react';
import Page from '../page/Page';
import RecipeFormContainer from './RecipeFormContainer';

export default function RecipePage(props) {
  const recipeId = props?.match?.params?.recipeId;
  return (
    <Page
      back="/catalogs/recipes"
      headerType="secondary"
      title="Receitas"
      offsetHeader={0}
    >
      <RecipeFormContainer recipeId={recipeId} />
    </Page>
  );
}
