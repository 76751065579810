import { SubmissionError } from 'redux-form';
import { normalize, schema } from 'normalizr';
import { replace } from 'connected-react-router';
import { apiGet, apiPost, apiDel, apiPut } from './helpers/api';
import { getQueryParam } from './helpers/getQueryParam';
import moment from 'moment-timezone';

export const user = new schema.Entity('users');

export const getSession = () => JSON.parse(localStorage.getItem('session'));
export const setSession = (response) => {
  localStorage.setItem(
    'session',
    JSON.stringify({
      headers: {
        'access-token': response.headers['access-token'],
        client: response.headers.client,
        uid: response.headers.uid,
      },
      currentUserId: response.body.data.id,
    }),
  );
};

const setUserTimezone = (user) => {
  const userTimezone = user?.companiesAttributes[0]?.timezone;
  if (userTimezone) moment.tz.setDefault(userTimezone);
};

export const signIn = (values, dispatch) => {
  return apiPost('/api/v1/auth/sign_in')
    .send(values)
    .then((response) => {
      setSession(response);
      dispatch({
        type: 'USER_FETCHED',
        ...normalize(response.body.data, user),
      });
      dispatch({ type: 'CURRENT_USER_FETCHED', id: response.body.data.id });
      dispatch(replace('/'));
      setUserTimezone(response.body.data);
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response.body.errors });
    });
};

export const recoveryPassword = (values, dispatch) => {
  return apiPost('/api/v1/auth/password')
    .send({
      ...values,
      redirectUrl: `https://${window.location.host}/reset-password`,
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response.body.errors });
    });
};

export const newPassword = (values, dispatch) => {
  return apiPut('/api/v1/auth/password')
    .send(values)
    .set('Uid', getQueryParam('uid'))
    .set('Access-Token', getQueryParam('access-token'))
    .set('Client', getQueryParam('client'))
    .then(() => {
      dispatch(replace('/signin'));
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response.body.errors });
    });
};

export const signOut = (dispatch) => {
  apiDel('/api/v1/auth/sign_out').then(() => {
    dispatch({ type: 'CURRENT_USER_REMOVED', id: null });
    localStorage.removeItem('session');
    dispatch(replace('/signin'));
  });
};

export const sessionValidate = (dispatch) => {
  apiGet('/api/v1/auth/validate_token').then((response) => {
    dispatch({
      type: 'USER_FETCHED',
      ...normalize(response.body.data, user),
    });
    setUserTimezone(response.body.data);
  });
};
