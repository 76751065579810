import React from 'react';
import { Separator } from '../library';
import EmptyState from '../library/EmptyState';
import SupplyIcon from '../library/images/supply.png';
import Page from '../page/Page';
import Pagination from '../library/Pagination';
import OrderListItemContainer from '../orders/OrderListItemContainer';
import CustomerListItemContainer from './CustomerListItemContainer';

export default function CustomerOrdersPage(props) {
  const {
    orders,
    customer,
    loading,
    currentPage,
    totalPages,
    onPageChange,
    isMobile,
    currency,
  } = props;
  return (
    <Page
      back="/customers"
      title={`Pedidos - ${customer?.name}`}
      headerType="secondary"
    >
      <CustomerListItemContainer
        key={customer?.id}
        customer={customer}
        currency={currency}
        showOrderHistory={false}
      />
      {!loading && orders?.length > 0 ? (
        <>
          <Separator />
          {orders.map((order) => (
            <OrderListItemContainer
              key={order?.id}
              order={order}
              currency={currency}
            />
          ))}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            short={isMobile}
          />
        </>
      ) : (
        <EmptyState
          iconUrl={SupplyIcon}
          loading={loading}
          title="Nenhum pedido cadastrado para este cliente"
        />
      )}
    </Page>
  );
}
