import React from 'react';
import Paper from '@mui/material/Paper';
import theme from './theme';

const defaultSx = {
  p: theme.spacing(3),
  borderRadius: 6,
  boxShadow: '0px 0px 20px #19518E29',
};

export default function Segment(props) {
  const { sx, fluid, ...rest } = props;
  const customSx = {
    width: fluid ? '100%' : defaultSx.width,
  };

  return (
    <Paper sx={{ ...defaultSx, ...customSx, ...sx }} {...rest}>
      {props?.children}
    </Paper>
  );
}
