import React from 'react';
import Box from '../library/Box';
import Grid from '../library/Grid';
import Link from '../library/Link';
import Separator from '../library/Separator';
import Title from '../library/Title';
import Typography from '../library/Typography';
import StatementsTableData from './StatementsTableData';

const containerSx = {
  mt: 3,
};
const titleSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
const lastWrapperSx = {
  marginTop: 1,
};
const showAllSx = {
  pt: 3,
  width: '100%',
  textAlign: 'right',
};

export default function RevenuesData(props) {
  const { revenues, currency } = props;
  return (
    <>
      <Separator />
      <Box sx={containerSx}>
        <Box sx={titleSx}>
          <Title p={0}>Recebimentos</Title>
          <Link
            to="/statements/revenues/new"
            component={Typography}
            variant="link"
          >
            Adicionar recebimento
          </Link>
        </Box>
        <Grid container sx={lastWrapperSx} spacing={2}>
          <StatementsTableData
            statements={revenues.slice(0, 10)}
            currency={currency}
            type="revenue"
          />
        </Grid>
        <Box sx={showAllSx}>
          <Link
            to="/statements/revenues"
            component={Typography}
            variant="link"
            align="right"
          >
            Ver todos
          </Link>
        </Box>
      </Box>
    </>
  );
}
