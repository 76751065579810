import React from 'react';
import { Box, MoneyInfo, Typography } from '../library';
import { Card } from '../library/cards';
import HorizontalItems from '../library/HorizontalItems';
import { css } from 'glamor';
import formatDate from '../../utils/formatDate';
import Label from '../library/Label';
import { spacing } from '../library/theme';

const marginTop = {
  marginTop: spacing.s,
};
const notesSx = {
  whiteSpace: 'pre-line',
  fontWeight: 'normal',
};
const labelSx = {
  size: 'small',
  maxWidth: 'fit-content',
  marginTop: spacing.xxs,
};
const labelWrapper = css({
  gap: spacing.xxs,
  display: 'flex',
});
const containerSx = {
  maxWidth: '540px',
  margin: '0px auto',
  minHeight: '460px',
  paddingLeft: spacing.s,
  paddingRight: spacing.s,
  marginTop: spacing.m,
};

const translatedStatus = (status) => {
  switch (status) {
    case 'scheduled':
      return 'Agendado';
    case 'in_production':
      return 'Em produção';
    case 'completed':
      return 'Finalizado';
    case 'delivered':
      return 'Entregue';
    case 'canceled':
      return 'Cancelado';
  }
};
const translatedPaymentStatus = (status) => {
  switch (status) {
    case 'unpaid':
      return 'Não pago';
    case 'paid':
      return 'Pago';
  }
};
const setStatusLabelColor = (status) => {
  switch (status) {
    case 'scheduled':
      return 'primary';
    case 'in_production':
      return 'warning';
    case 'completed':
      return 'info';
    case 'delivered':
      return 'success';
    case 'canceled':
      return 'danger';
  }
};
const setPaymentLabelColor = (status) => {
  switch (status) {
    case 'paid':
      return 'success';
    case 'unpaid':
      return 'danger';
  }
};
const getCustomerName = (customer) => {
  return customer?.isCompany ? customer?.fantasyName : customer?.name;
};

export default function Budget({
  order,
  customer,
  products,
  items,
  company,
  currency,
}) {
  const amount = order?.amount || 0;
  const customerName = customer ? getCustomerName(customer) : order?.buyerName;
  return (
    <Box sx={containerSx}>
      <Card title={`Pedido de ${customerName}`}>
        <Typography variant="h7">
          Criado em: {formatDate(order?.createdAt, 'DD/MM/yyyy')}
        </Typography>
        {order?.discount > 0 && (
          <Typography variant="h7">
            Desconto de {+order?.discount.toFixed(2)}%
          </Typography>
        )}
        <Typography variant="h7">
          Entrega estimada para:{' '}
          {formatDate(order?.scheduleAt, 'DD/MM/yyyy - HH:mm')}
        </Typography>
        <Typography variant="h7">Loja: {company?.name}</Typography>
        <Typography variant="h7">{company?.address}</Typography>
        <div className={labelWrapper}>
          <Label
            color={setStatusLabelColor(order?.status)}
            label={translatedStatus(order?.status)}
            sx={labelSx}
          />
          <Label
            color={setPaymentLabelColor(order?.paymentStatus)}
            label={translatedPaymentStatus(order?.paymentStatus)}
            sx={labelSx}
          />
        </div>
        <Typography variant="h6" sx={marginTop}>
          Itens do Pedido
        </Typography>
        {products && <HorizontalItems items={products} />}
        {items && <HorizontalItems items={items} />}
        <Box sx={marginTop}>
          <MoneyInfo
            label="Valor do Pedido"
            amount={amount}
            currency={currency}
          />
          {order?.paymentStatus == 'unpaid' && (
            <MoneyInfo
              label="Valor pago"
              twinLabel="Resta pagar"
              info
              amount={order?.amountPaid}
              twinAmount={order?.amountDifference}
              sx={{ marginTop: spacing.xs }}
              currency={currency}
            />
          )}
        </Box>
        {order?.notes && (
          <>
            <Typography variant="h6" sx={marginTop}>
              Observação
            </Typography>
            <p style={notesSx}>{order?.notes}</p>
          </>
        )}
      </Card>
    </Box>
  );
}
