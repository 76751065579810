import React from 'react';
import { Box as MuiBox } from '@mui/material';

export default React.forwardRef((props, ref) => {
  return (
    <MuiBox {...props} ref={ref}>
      {props.children}
    </MuiBox>
  );
});
