import React, { useCallback } from 'react';
import Form from '../library/forms/Form';
import Segment from '../library/Segment';
import Typography from '../library/Typography';
import Button from '../library/Button';
import ImageField from '../library/forms/ImageField';
import { onboardingUser } from '../../processes/profileProcesses';
import { Box } from '../library';
import BlueHairCookMan from '../library/images/BlueHairCookMan';

const segmentSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 480,
  width: 352,
};
const typographySx = {
  whiteSpace: 'pre-line',
  paddingBottom: '16px',
};
const containerSx = {
  position: 'relative',
};
const imageSx = {
  position: 'absolute',
  top: -130,
  left: 33,
  zIndex: -1,
  ['@media (max-height:750px)']: {
    display: 'none',
  },
};

export default function AvatarForm(props) {
  const { handleSubmit, dispatch, currentUser } = props;

  const finishOnboarding = useCallback(() => {
    handleSubmit();
    onboardingUser(dispatch, currentUser?.id);
  }, [dispatch, currentUser]);

  return (
    <Box sx={containerSx}>
      <Box sx={imageSx}>
        <BlueHairCookMan />
      </Box>
      <Form error={props.error}>
        <Segment fluid sx={segmentSx}>
          <Typography variant="h4" color="typography.black" sx={typographySx}>
            {`Prontinho,
          ${currentUser?.firstName}!`}
          </Typography>
          <Typography color="typography.main" sx={typographySx}>
            {`Muito bom te conhecer. E agora para
           finalizar, faça o upload de uma imagem do
           seu logo ou de uma foto sua. (ou se preferir,
           pode atualizar a imagem depois)`}
          </Typography>
          <ImageField name="avatarUrl" />
          <Button
            type="button"
            disabled={props.submitting}
            onClick={finishOnboarding}
            variant="primary"
            fluid
          >
            Concluir
          </Button>
        </Segment>
      </Form>
    </Box>
  );
}
