const initialState = {};

export default function recipesConnectionsReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case 'RECIPE_FETCHED':
    case 'RECIPE_CREATED':
    case 'RECIPE_UPDATED':
    case 'RECIPES_FETCHED':
    case 'RECIPE_CONNECTION_FETCHED':
      return { ...state, ...action.entities.recipesConnections };
    case 'RECIPE_CONNECTION_DELETED':
      if (state[action.id]) {
        let newState = { ...state };
        delete newState[action.id];
        return newState;
      }
    default:
      return state;
  }
}
