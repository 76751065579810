import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const User = (props) => {
  const {color = '#fff', ...rest} = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...rest}
    >
      <g
        id="Group_7926"
        data-name="Group 7926"
        transform="translate(19859 19422)"
      >
        <rect
          id="Rectangle_8033"
          data-name="Rectangle 8033"
          width="24"
          height="24"
          transform="translate(-19859 -19422)"
          fill="none"
        />
        <path
          id="user-regular"
          d="M14.7,14.25c-1.345,0-1.992.75-4.2.75s-2.85-.75-4.2-.75A6.3,6.3,0,0,0,0,20.55v1.2A2.251,2.251,0,0,0,2.25,24h16.5A2.251,2.251,0,0,0,21,21.75v-1.2A6.3,6.3,0,0,0,14.7,14.25Zm4.05,7.5H2.25v-1.2A4.057,4.057,0,0,1,6.3,16.5c.684,0,1.8.75,4.2.75,2.423,0,3.511-.75,4.2-.75a4.057,4.057,0,0,1,4.05,4.05ZM10.5,13.5A6.75,6.75,0,1,0,3.75,6.75,6.752,6.752,0,0,0,10.5,13.5Zm0-11.25A4.5,4.5,0,1,1,6,6.75,4.507,4.507,0,0,1,10.5,2.25Z"
          transform="translate(-19857.5 -19422)"
          fill="#888"
        />
      </g>
    </SvgIcon>
  );
};

export default User;
