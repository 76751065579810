import { createSelector } from 'reselect';
import { orderByAlphabetic } from '../utils/orderByAlphabetic';

const getSupplies = (state) => state.entities.supplies;
const getId = (_, id) => id;
const getSuppliesList = (state) => state.suppliesList;
const getFetchedIds = (state) => state.suppliesList.supplyIds;

export const getSupplyPageInfo = createSelector(
  getSuppliesList,
  (suppliesList) => ({
    currentPage: suppliesList.currentPage,
    totalPages: suppliesList.totalPages,
    filters: suppliesList.filters,
    loading: suppliesList.loading,
  }),
);

export const getAllSupplies = createSelector(getSupplies, (supplies) =>
  Object.values(supplies).sort(orderByAlphabetic),
);

export const getSupply = createSelector(
  getSupplies,
  getId,
  (supplies, id) => supplies[id],
);

export const getIngredients = createSelector(getAllSupplies, (supplies) =>
  supplies.filter((supply) => supply.type == 'ingredient'),
);

export const getDecorations = createSelector(getAllSupplies, (supplies) =>
  supplies.filter((supply) => supply.type == 'decoration'),
);

export const getPackagings = createSelector(getAllSupplies, (supplies) =>
  supplies.filter((supply) => supply.type == 'packaging'),
);

export const getSuppliesByFetchedIds = createSelector(
  getAllSupplies,
  getFetchedIds,
  (supplies, ids) =>
    supplies
      .filter((supply) => ids.includes(supply.id))
      .sort(orderByAlphabetic),
);
