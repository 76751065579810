import React from 'react';
import { css } from 'glamor';
import FieldMessage from './FieldMessage';
import Label from './Label';
import FieldBlock from './FieldBlock';
import Hint from '../Hint';
import { spacing } from '../theme';

const layout = {
  message: {
    position: 'absolute',
    right: 0,
    top: '-18px',
    marginTop: `-${spacing.xxs}`,
  },
  label: {
    marginBottom: spacing.xxs,
    display: 'block',
  },
};

const errorWrapper = css({
  textAlign: 'left',
  position: 'relative',
  display: 'inline-block',
  width: '100%',
});

const defaultMessages = {
  required: '*',
  date: 'Data inválida',
  email: 'E-mail inválido',
  minSize: 'Muito curto',
  minValue: 'Inválido',
  maxValue: 'Inválido',
};

const defaultShortMessages = {
  required: '*',
  date: 'Inválido',
  email: 'Inválido',
  minSize: 'Muito curto',
  minValue: 'Inválido',
  maxValue: 'Inválido',
};

function errorMessage(errorType, customMessages, short = false) {
  return (
    (customMessages && customMessages[errorType]) ||
    (short ? defaultShortMessages : defaultMessages)[errorType] ||
    errorType
  );
}

export default (InputComponent, selectProps) => (props) => {
  const {
    input = {},
    meta = {},
    style,
    label,
    hint,
    required,
    validationMessages,
    shortMessages,
    message,
    minSize, // eslint-disable-line no-unused-vars
    ...rest
  } = props;
  const id = input.id || input.name;
  const error = meta.touched ? meta.error : undefined;
  const errorMsg = error
    ? errorMessage(error, validationMessages, shortMessages)
    : undefined;
  const errorStyle = errorMsg == '*' ? { fontSize: '1.5rem' } : {};
  return (
    <FieldBlock style={{ ...style, width: rest.width }}>
      {label && (
        <Label
          style={layout.label}
          required={required}
          htmlFor={id}
          disabled={props.disabled}
        >
          {label}
        </Label>
      )}
      <span className={css(errorWrapper, { width: rest.width })}>
        <InputComponent
          {...selectProps({ ...input, id: id }, meta, rest)}
          error={!!error}
        />
        {errorMsg && (
          <FieldMessage
            style={{
              ...layout.message,
              ...errorStyle,
            }}
            message={errorMsg}
          />
        )}
        {message && (
          <FieldMessage
            type="success"
            style={layout.message}
            message={message}
          />
        )}
        {hint && <Hint>{hint}</Hint>}
      </span>
    </FieldBlock>
  );
};
