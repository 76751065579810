import { createSelector } from 'reselect';

export const getPricing = (state) => state.pricing;
export const getPricingData = (state) => state.pricing.data;
export const getSelectedScenario = (state) => state?.pricing.selectedScenario;
export const getScenario = (_, scenario) => scenario;

export const getPricingScenario = createSelector(
  getPricing,
  getScenario,
  (pricing, scenario) => pricing?.data?.scenarios?.[scenario],
);

export const getPricingCalculationResult = createSelector(
  getPricing,
  (pricing) => pricing?.result,
);
