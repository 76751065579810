import React from 'react';
import { Box } from '../library';
import { Card } from '../library/cards';
import formatDate from '../../utils/formatDate';
import Modal from '../library/Modal';
import InventoryFormContainer from '../inventory/InventoryFormContainer';
import HorizontalItems from '../library/HorizontalItems';
import { toCurrency } from '../../utils';

export default function InventoryListItem(props) {
  const {
    inventory,
    unit,
    onEdit,
    onDelete,
    openInventoryModal,
    closeInventoryModal,
    currency,
  } = props;

  const totalQuantity = inventory.quantityPerPackage
    ? inventory?.quantity * inventory?.quantityPerPackage
    : inventory?.quantity;

  const inventoryType = inventory?.type == 'add' ? 'Reposição' : 'Baixa';
  const orderSequentialId = inventory?.orderSequentialId
    ? `- (Pedido #${inventory?.orderSequentialId})`
    : '';

  const title = `${inventoryType} - ${formatDate(
    inventory?.createdAt,
  )} ${orderSequentialId}`;

  return (
    <Card key={inventory?.id} title={title} onEdit={onEdit} onDelete={onDelete}>
      <Box pt={2}>
        <HorizontalItems
          items={
            inventory?.type == 'add'
              ? [
                  {
                    title: 'Quantidade',
                    description: `${inventory?.quantity}`,
                  },
                  {
                    title: 'Quantidade por embalagem',
                    description: `${inventory?.quantityPerPackage} ${
                      unit?.abbreviation.toLowerCase() ?? ''
                    }`,
                  },
                  {
                    title: 'Quantidade total',
                    description: `${+totalQuantity.toFixed(2)} ${
                      unit?.abbreviation ? unit.abbreviation.toLowerCase() : ''
                    }`,
                  },
                  {
                    title: 'Preço',
                    description: toCurrency(inventory?.price, currency?.code),
                  },
                  {
                    title: 'Validade',
                    description:
                      formatDate(inventory?.expiresAt) ||
                      'Sem prazo de validade',
                  },
                  {
                    title: 'Marca',
                    description: inventory?.brand || 'Marca desconhecida',
                  },
                  {
                    title: 'Fornecedor',
                    description:
                      inventory?.supplier || 'Fornecedor desconhecido',
                  },
                ]
              : [
                  {
                    title: 'Quantidade',
                    description: `${+totalQuantity.toFixed(2)} ${
                      unit?.abbreviation ? unit.abbreviation.toLowerCase() : ''
                    }`,
                  },
                ]
          }
        />
        <Modal
          title="Gerenciamento de Estoque"
          open={openInventoryModal}
          onClose={closeInventoryModal}
        >
          <InventoryFormContainer
            inventory={inventory}
            closeInventoryModal={closeInventoryModal}
          />
        </Modal>
      </Box>
    </Card>
  );
}
