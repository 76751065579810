import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  getConfigById,
  getStrategicRoleById,
} from '../../selectors/configSelectors';
import ProductListItem from './ProductListItem';
import {
  deleteProduct,
  duplicateProduct,
} from '../../processes/productProcessess';

function SuppyListItemContainer(props) {
  const { product, dispatch } = props;

  const onEdit = useCallback(
    () => dispatch(push(`/catalogs/products/${product.id}/edit`)),
    [product.id, dispatch],
  );
  const onDelete = useCallback(
    () => deleteProduct(dispatch, product.id),
    [product.id],
  );
  const onDuplicate = useCallback(() => {
    duplicateProduct(dispatch, product.id);
  }, [dispatch, product.id]);

  return (
    <ProductListItem
      onDuplicate={onDuplicate}
      onEdit={onEdit}
      onDelete={onDelete}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    strategicRole: getStrategicRoleById(
      state,
      ownProps.product.strategicRoleId,
    ),
    productCategory: getConfigById(
      state,
      'productCategories',
      ownProps.product.productCategoryId,
    ),
  };
}

export default connect(mapStateToProps)(SuppyListItemContainer);
