import React from 'react';
import Box from '../library/Box';
import { Card } from '../library/cards';
import { useTranslation } from 'react-i18next';
import HorizontalItems from '../library/HorizontalItems';
import { Modal } from '../library';
import InventoryFormContainer from '../inventory/InventoryFormContainer';

export default function SupplyListItem(props) {
  const {
    supply,
    supplyCategory,
    onEdit,
    onDelete,
    unit,
    onClickManageInventory,
    openInventoryModal,
    closeInventoryModal,
  } = props;
  const { id, name } = supply;
  const { t } = useTranslation('app');

  return (
    <Card
      key={id}
      title={name}
      onEdit={onEdit}
      onDelete={onDelete}
      onManageInventory={onClickManageInventory}
    >
      <Box pt={2}>
        <HorizontalItems
          items={[
            { title: 'Categoria', description: supplyCategory?.name },
            {
              title: 'Tipo',
              description: t(`supplies.supplyTypes.${supply?.type}`),
            },
            {
              title: 'Quantidade em Estoque',
              description: `${+supply?.inStock.toFixed(2)} ${
                unit?.abbreviation
              }`,
            },
          ]}
        />
      </Box>
      <Modal
        title="Gerenciamento de Estoque"
        open={openInventoryModal}
        onClose={closeInventoryModal}
      >
        <InventoryFormContainer
          supplyId={supply?.id}
          closeInventoryModal={closeInventoryModal}
        />
      </Modal>
    </Card>
  );
}
