import React, { useCallback, useState } from 'react';
import Box from '../library/Box';
import Grid from '../library/Grid';
import Title from '../library/Title';
import StatementsTableData from './StatementsTableData';
import Link from '../library/Link';
import { Modal, Typography } from '../library';
import RevenueFormContainer from './RevenueFormContainer';

const containerSx = {
  mt: 3,
};
const titleSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'right',
};
const lastWrapperSx = {
  marginTop: 1,
};

export default function OrderStatements(props) {
  const { statements, orderId, currency } = props;
  const [openStatementModal, setOpenStatementModal] = useState(false);

  const onCreate = useCallback(() => setOpenStatementModal(true), []);
  const closeStatementModal = useCallback(
    () => setOpenStatementModal(false),
    [],
  );
  return (
    <>
      <Box sx={containerSx}>
        <Box sx={titleSx}>
          <Title p={0} style={{ padding: '4px 4px 4px 0px' }}>
            Recebimentos
          </Title>
          <Link onClick={onCreate} component={Typography} variant="link">
            Adicionar recebimentos
          </Link>
        </Box>
        {statements?.length > 0 && (
          <Grid container sx={lastWrapperSx} spacing={2}>
            <StatementsTableData
              statements={statements}
              currency={currency}
              actions
            />
          </Grid>
        )}
      </Box>
      <Modal
        title={'Criação de Recebimentos'}
        open={openStatementModal}
        onClose={closeStatementModal}
      >
        <RevenueFormContainer
          closeStatementModal={closeStatementModal}
          orderId={orderId}
        />
      </Modal>
    </>
  );
}
