const initialState = {
  result: '',
  selectedScenario: '',
  loading: false,
};

export default function pricingReducer(state = initialState, action) {
  switch (action.type) {
    case 'PRICING_GENERATION_STARTED':
    case 'PRICING_FETCH_REQUESTED':
      return { ...state, loading: true };
    case 'PRICING_FETCHED':
    case 'PRICING_GENERATED':
      return {
        ...state,
        ...action.data,
        selectedScenario: 'current',
        loading: false,
      };
    case 'PRICING_CALCULATED':
      return {
        ...state,
        result: action.data,
        loading: false,
      };
    case 'SCENARIO_CHANGED':
      return {
        ...state,
        selectedScenario: action.selectedScenario,
      };
    default:
      return state;
  }
}
