import React from 'react';
import { Autocomplete, TextField, styled } from '@mui/material';
import theme, { spacing } from '../theme';

const CustomTextInput = styled(TextField)({
  '& .MuiInputBase-input': {
    height: 48,
    boxSizing: 'border-box',
    borderRadius: '4px !important',
    border: 'none !important',
  },
  '& .MuiOutlinedInput-root': {
    borderWidth: '1px',
    borderRadius: spacing.xxs,
    borderColor: theme.palette.border.main,
    '&:hover fieldset': {
      borderColor: theme.palette.border.hover,
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.primary.active,
    },
  },
});

const CustomSearchDropDownInput = styled(Autocomplete)({
  '& .MuiFormControl-root': {
    width: '100% !important',
  },
  '& .MuiAutocomplete-inputRoot': {
    height: '48px !important',
  },
  '& .MuiInputBase-input': {
    height: '32px !important',
  },
});

export default function SearchDropDownInput({
  fluid,
  autoSelect = true,
  ...rest
}) {
  return (
    <CustomSearchDropDownInput
      fullWidth={fluid}
      autoSelect={autoSelect}
      noOptionsText="Nenhum resultado encontrado"
      renderInput={(params) => <CustomTextInput {...params} />}
      {...rest}
    />
  );
}
