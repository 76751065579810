import React from 'react';
import { Box, Button } from '../library';
import DropDownInput from '../library/forms/DropDownInput';
import { spacing } from '../library/theme';

const titleWrapperSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  marginBottom: spacing.xxs,
};

export default function PricingContentHeader(props) {
  const { scenario, scenarios, changeScenarioHandler, remakeOnboarding } =
    props;

  return (
    <>
      <Box sx={titleWrapperSx}>
        <Button variant="link" onClick={remakeOnboarding}>
          ATUALIZAR METAS
        </Button>
      </Box>

      <DropDownInput
        fluid
        value={scenario}
        onChange={changeScenarioHandler}
        data={scenarios}
      />
    </>
  );
}
