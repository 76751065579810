import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import buildForm from '../library/forms/buildForm';
import ProfileEditForm from './ProfileEditForm';
import { updateProfile } from '../../processes/profileProcesses';
import {
  getCurrentUser,
  getCurrentUserAvailableTimezones,
} from '../../selectors/userSelectors';

const FORM_NAME = 'profileEdit';
const form = buildForm(FORM_NAME);

function ProfileEditFormContainer(props) {
  return <ProfileEditForm {...props} />;
}

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);
  const timezones = getCurrentUserAvailableTimezones(state);
  const valueSelector = formValueSelector(FORM_NAME);
  const password = valueSelector(state, 'password');
  const passwordConfirmRequired = Boolean(password);
  const printerSizesOptions = [
    { name: 'Mini', value: 'mini' },
    { name: 'Padrão', value: 'standard' },
    { name: 'Grande', value: 'big' },
  ];

  return {
    initialValues: currentUser,
    imageUrl: form.getValue('imageUrl', state),
    currentUser,
    timezones,
    passwordConfirmRequired,
    emailNotificationsEnabled: valueSelector(
      state,
      'emailNotificationsEnabled',
    ),
    printerSizes: printerSizesOptions,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: updateProfile,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ProfileEditFormContainer),
);
