import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Facebook = (props) => {
  const {color = '#1b5c96', ...rest} = props;

  return (
    <SvgIcon
      id="Group_7744"
      data-name="Group 7744"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        id="Path_2562"
        data-name="Path 2562"
        d="M0,0H16V16H0Z"
        fill="none"
      />
      <path
        id="facebook-f-brands"
        d="M30.9,9l.444-2.9H28.564V4.225A1.448,1.448,0,0,1,30.2,2.661h1.263V.2A15.4,15.4,0,0,0,29.217,0a3.535,3.535,0,0,0-3.784,3.9V6.1H22.89V9h2.543v7h3.13V9Z"
        transform="translate(-19.176)"
        fill={color}
      />
    </SvgIcon>
  );
};

export default Facebook;
