import React from 'react';
import { Box, Link, Paragraph, Separator, Title } from '../library';
import { spacing, styles, stateColors } from '../library/theme';
import PricingSuggestionsModalContainer from './PricingSuggestionsModalContainer';
import PricingProductsContainer from './PricingProductsContainer';
import { toCurrency } from '../../utils';
import HorizontalItem from '../library/HorizontalItem';
import PricingContentHeader from './PricingContentHeader';

const subtitleSx = {
  marginTop: spacing.s,
  color: stateColors.hover,
};
const errorsWrapperSx = {
  margin: `${spacing.m} 0`,
  textAlign: 'center',
};
const generalSx = {
  ...styles.shadow,
  padding: `${spacing.m} ${spacing.s} ${spacing.xxs}`,
  borderRadius: spacing.m,
  marginBottom: spacing.l,
};
const warningSx = {
  ...generalSx,
  padding: `${spacing.m} ${spacing.s}`,
};
const warningItemSx = {
  margin: `${spacing.s} 0`,
  padding: 0,
};

const scenarios = [
  { text: 'Cenário Atual', value: 'current' },
  { text: 'Quantidade Sugerida & Valor Atual', value: 'suggested_current' },
  { text: 'Quantidade Sugerida & Valor Sugerido', value: 'suggested' },
];

const getTitleByScenario = (scenario) =>
  scenarios.find((item) => item.value == scenario)?.text;

export default function PricingContent(props) {
  const {
    scenarioData,
    scenario,
    currency,
    remakeOnboarding,
    changeScenarioHandler,
  } = props;

  const title = getTitleByScenario(scenario);

  const scenarioDataRevenues =
    scenarioData?.revenues > 0 ? scenarioData.revenues : 1;
  const showCostWarning = scenarioData?.expenses / scenarioDataRevenues < 0.3;

  return (
    <>
      <PricingContentHeader
        scenario={scenario}
        scenarios={scenarios}
        remakeOnboarding={remakeOnboarding}
        changeScenarioHandler={changeScenarioHandler}
      />
      {!scenarioData?.errors && (
        <div>
          {/* <PricingSuggestionsModalContainer title={title} /> */}
          <Paragraph sx={subtitleSx}>{title}</Paragraph>
          <Box sx={generalSx}>
            <HorizontalItem
              title="FATURAMENTO TOTAL"
              description={toCurrency(scenarioData?.revenues, currency?.code)}
            />
            <HorizontalItem
              title="CUSTOS"
              description={toCurrency(scenarioData?.expenses, currency?.code)}
            />
            <HorizontalItem
              title="LUCRO"
              description={toCurrency(
                +scenarioData?.revenues - +scenarioData?.expenses,
                currency?.code,
              )}
            />
          </Box>
          <Separator />
          {showCostWarning && (
            <>
              <Box sx={warningSx}>
                <Title variant="h5" align="center">
                  Importante!
                </Title>
                <Paragraph align="center">
                  Para que possamos ter um cálculo preciso é necessário que seu{' '}
                  <strong>custo</strong> reflita a realidade. Para tal,
                  indicamos:
                </Paragraph>
                <Paragraph align="center" sx={warningItemSx}>
                  1 . Cadastrar estoque em{' '}
                  <Link to="/catalogs/supplies">INSUMOS</Link>
                </Paragraph>
                <Paragraph align="center" sx={warningItemSx}>
                  2 . Cadastrar pagamentos em{' '}
                  <Link to="/statements">FINANCEIRO</Link>
                </Paragraph>
              </Box>
              <Separator />
            </>
          )}
          <PricingProductsContainer
            scenario={scenario}
            products={scenarioData?.products}
            currency={currency}
          />
        </div>
      )}
      {!!scenarioData?.errors && (
        <Box sx={errorsWrapperSx}>
          <Title>Não conseguimos calcular o cenário, pendêcias abaixos:</Title>
          {scenarioData?.errors?.map((error) => (
            <Paragraph>{error}</Paragraph>
          ))}
        </Box>
      )}
    </>
  );
}
