import React from 'react';
import EmptyState from '../library/EmptyState';
import AddButton from '../library/AddButton';
import Page from '../page/Page';
import SupplyIcon from '../library/images/supply.png';
import BackgroundTaskListItemContainer from '../backgroundTasks/BackgroundTaskListItemContainer';
import { Modal } from '../library';
import SuppliesImportFormContainer from './SuppliesImportFormContainer';

const wrapperSx = {
  display: 'flex',
  marginBottom: '16px',
};

export default function SuppliesImportPage({
  backgroundTasks,
  onAdd,
  openImportModal,
  closeImportModal,
}) {
  return (
    <Page back="/catalogs/supplies" title="Importações" headerType="secondary">
      {backgroundTasks?.length ? (
        <>
          <div style={wrapperSx}>
            <AddButton onClick={onAdd} wide text="Adicionar importação" />
          </div>
          {backgroundTasks.map((task) => (
            <BackgroundTaskListItemContainer key={task.id} task={task} />
          ))}
        </>
      ) : (
        <EmptyState
          iconUrl={SupplyIcon}
          title="Nenhuma importação feita"
          onClick={onAdd}
          ctaLabel="Criar importação"
        />
      )}
      <Modal
        title="Importação de Insumos"
        open={openImportModal}
        onClose={closeImportModal}
      >
        <SuppliesImportFormContainer closeImportModal={closeImportModal} />
      </Modal>
    </Page>
  );
}
