const initialState = {};

export default function ordersProductsReducer(state = initialState, action) {
  switch (action.type) {
    case 'ORDERS_FETCHED':
    case 'ORDER_FETCHED':
    case 'BUDGET_FETCHED':
    case 'ORDER_UPDATED':
    case 'ORDER_CREATED':
    case 'ORDERS_PRODUCTS_FETCHED':
      return { ...state, ...action.entities.ordersProducts };
    case 'ORDERS_PRODUCT_DELETED':
      if (state[action.id]) {
        let newState = { ...state };
        delete newState[action.id];
        return newState;
      }
    default:
      return state;
  }
}
