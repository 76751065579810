import React from 'react';

const SmallLogo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="21.19"
      viewBox="0 0 24 21.19"
      {...props}
    >
      <path
        id="Path_3024"
        data-name="Path 3024"
        d="M211.441,227.758c1.113-2.38,1-3.3,1-5.989v-7.575c0-1.459-.345-2.073-.845-2.073-1.343,0-3.954,4.031-4.952,7.985v1.663a13.775,13.775,0,0,0,.416,4.411,1.23,1.23,0,0,1-1.184,1.577H197.2c1.113-2.38,1-3.3,1-5.989v-8.254c0-.153.006-.345.006-.568a4.313,4.313,0,0,1,3.4-4.217,18.75,18.75,0,0,0,5.042-1.818v9.559c2.38-6.833,6.68-9.9,9.943-9.9a4.2,4.2,0,0,1,4.3,4.53V221.77a16.031,16.031,0,0,0,.267,3.859,1.73,1.73,0,0,1-1.682,2.13Z"
        transform="translate(-197.199 -206.568)"
        fill="#1b5c96"
      />
    </svg>
  );
};

export default SmallLogo;
