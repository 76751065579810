import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const GreenAdd = (props) => {
  const {color = '#577c47', crossColor = '#fff', ...rest} = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="108"
      height="108"
      viewBox="0 0 108 108"
      {...rest}
    >
      <defs>
        <filter
          id="Path_3082"
          x="0"
          y="0"
          width="108"
          height="108"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="10" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="10" result="blur" />
          <feFlood flood-color={color} flood-opacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Component_39_12"
        data-name="Component 39 – 12"
        transform="translate(30 20)"
      >
        <g transform="matrix(1, 0, 0, 1, -30, -20)" filter="url(#Path_3082)">
          <path
            id="Path_3082-2"
            data-name="Path 3082"
            d="M10,0H38A10,10,0,0,1,48,10V38A10,10,0,0,1,38,48H10A10,10,0,0,1,0,38V10A10,10,0,0,1,10,0Z"
            transform="translate(30 20)"
            fill={color}
          />
        </g>
        <g
          id="Group_7961"
          data-name="Group 7961"
          transform="translate(18987.5 19810)"
        >
          <g
            id="Group_7962"
            data-name="Group 7962"
            transform="translate(-39 20)"
          >
            <line
              id="Line_11"
              data-name="Line 11"
              x2="16"
              transform="translate(-18932.5 -19806)"
              fill="none"
              stroke={crossColor}
              stroke-width="2"
            />
            <line
              id="Line_12"
              data-name="Line 12"
              y2="16"
              transform="translate(-18924.5 -19814)"
              fill="none"
              stroke={crossColor}
              stroke-width="2"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default GreenAdd;
