import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import FileInput from './FileInput';
import memoize from 'fast-memoize';
import buildFormField from './buildFormField';
const FILE_MIME_TYPE = new RegExp(
  '^((application/(vnd.ms-excel|vnd.openxmlformats-officedocument.spreadsheetml.sheet))|(text/csv))$',
);

const toValue = (delegate, files) => {
  if (files && files.length > 0 && FILE_MIME_TYPE.test(files[0].type)) {
    delegate(files[0]);
  }
};

const buildValidator = memoize((required, other) => {
  return [(value) => (required && !value ? 'required' : undefined)].concat(
    other || [],
  );
});

const FileFieldComponent = buildFormField(FileInput, (input, meta, rest) => ({
  ...input,
  onChange: useCallback(
    (files) => toValue(input.onChange, files),
    [input.onChange],
  ),
  onBlur: useCallback((files) => toValue(input.onBlur, files), [input.onBlur]),
  clear: useCallback(() => input.onChange(''), [input]),
  invalid: meta.touched && meta.invalid,
  ...rest,
}));

export default function FileField(props) {
  return (
    <Field
      {...props}
      type="file"
      component={FileFieldComponent}
      validate={buildValidator(props.required, props.validate)}
    />
  );
}
