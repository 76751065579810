import React from 'react';
import EmptyState from '../library/EmptyState';
import RevenueListItemContainer from './RevenueListItemContainer';
import DefaultIcon from '../library/icons/DefaultIcon';

export default function RevenuesList({ revenues, onAdd }) {
  return revenues.length ? (
    <>
      {revenues.map((revenue) => (
        <RevenueListItemContainer key={revenue.id} revenue={revenue} />
      ))}
    </>
  ) : (
    <EmptyState
      iconUrl={DefaultIcon}
      title="Lista Vazia"
      description="Nenhum recebimento cadastrado"
      cta="/statements/revenues/new"
      ctaLabel="Cadastrar recebimento"
    />
  );
}
