import React from 'react';
import { connect } from 'react-redux';
import { getLastStatementsByType } from '../../selectors/statementSelectors';
import RevenuesData from './RevenuesData';
import { getCurrencyById } from '../../selectors/configSelectors';
import { getCurrentUserCompany } from '../../selectors/userSelectors';

function RevenuesDataContainer(props) {
  const { revenues } = props;

  return revenues?.length ? <RevenuesData {...props} /> : null;
}

function mapStateToProps(state) {
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);

  return {
    revenues: getLastStatementsByType(state, 'revenue'),
    currency,
  };
}

export default connect(mapStateToProps)(RevenuesDataContainer);
