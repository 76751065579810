import React from 'react';
import HorizontalItem from './HorizontalItem';
import { spacing } from './theme';

export default function HorizontalItems({
  items,
  compact,
  titleStyle,
  descriptionStyle,
  containerStyle,
}) {
  const data = compact ? items.filter((item) => !!item.description) : items;

  return (
    <div style={{ marginTop: spacing.xs }}>
      {data.map(
        ({ title, description, descriptionHtml, descriptionLarge }, index) =>
          (title || description) && (
            <HorizontalItem
              key={index}
              title={title}
              containerStyle={containerStyle}
              titleStyle={titleStyle}
              descriptionStyle={descriptionStyle}
              description={description}
              descriptionHtml={descriptionHtml}
              descriptionLarge={descriptionLarge}
            />
          ),
      )}
    </div>
  );
}
