import React, { useCallback, useState } from 'react';
import { Drawer } from '@mui/material';
import HeaderContainer from '../header/HeaderContainer';
import MainMenuContainer from '../mainMenu/MainMenuContainer';
import Box from '../library/Box';
import { css } from 'glamor';
import { PageContext } from './PageContext';
import Footer from '../footer/Footer';

const wrapper = css({
  position: 'relative',
  minHeight: '100vh',
  margin: 0,
  overflowX: 'hidden',
  overflowY: 'auto',
});
const containerSx = {
  maxWidth: '540px',
  margin: '0px auto',
  minHeight: '460px',
};

export default function PageWithContext({ children }) {
  const [pageContext, setPageContext] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = useCallback(() => {
    setShowSidebar(!showSidebar);
  }, [showSidebar]);
  const hideSidebar = useCallback(() => setShowSidebar(false));
  const onUpdatePageContext = useCallback(
    (newValues) => setPageContext({ ...pageContext, ...newValues }),
    [pageContext],
  );
  const FooterComponent = pageContext.footer || Footer;

  return (
    <PageContext.Provider value={{ pageContext, onUpdatePageContext }}>
      <div className={wrapper} anchor="left">
        <Drawer anchor="left" open={showSidebar} onClose={hideSidebar}>
          <MainMenuContainer toggleSidebar={toggleSidebar} />
        </Drawer>
        <HeaderContainer toggleSidebar={toggleSidebar} />
        <Box
          sx={{
            ...containerSx,
            paddingBottom: pageContext.showFooter ? '120px' : 0,
          }}
        >
          {children}
        </Box>
        {pageContext.showFooter && (
          <FooterComponent {...pageContext.footerProps} />
        )}
      </div>
    </PageContext.Provider>
  );
}
