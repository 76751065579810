import React from 'react';
import { BarChart as BarChartComponent } from '@mui/x-charts/BarChart';
import { spacing } from './theme';

const colors = ['#F4745E', '#40A01F'];

export default function BarChart({ groups, data, currency }) {
  return (
    <div style={{ display: 'flex', marginTop: `-${spacing?.m}` }}>
      <BarChartComponent
        xAxis={[{ scaleType: 'band', data: groups }]}
        yAxis={[
          {
            tickNumber: 5,
            label: currency?.symbol,
            labelStyle: {
              angle: 0,
              transform: 'translateY(-90px)',
            },
          },
        ]}
        grid={{ horizontal: true, vertical: true }}
        colors={colors}
        series={data}
        width={335}
        height={250}
        slotProps={{
          legend: {
            position: { vertical: 'bottom', horizontal: 'middle' },
            itemGap: 20,
            markGap: 10,
            itemMarkHeight: 2,
            itemMarkWidth: 15,
          },
        }}
      />
    </div>
  );
}
