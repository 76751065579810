import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import { getAllRecipes } from './recipeSelectors';
import { getAllRecipesSupplies } from './recipesSupplySelectors';
import { getAllSupplies } from './supplySelectors';

const getProductsRecipes = (state) => state.entities.productsRecipes;
const getProps = (_, props) => props;

function formState(state) {
  return getFormValues('product')(state);
}

const getAllProductsRecipes = createSelector(
  getProductsRecipes,
  (productsRecipes) => Object.values(productsRecipes),
);

export const getProductsRecipesByProduct = createSelector(
  getAllProductsRecipes,
  getProps,
  (productsRecipes, { productId }) => {
    return productsRecipes.filter(
      (productsRecipe) => productsRecipe.productId == productId,
    );
  },
);

export const getProductRecipes = createSelector(formState, (form) =>
  form?.productsRecipesAttributes?.filter((pR) => !!!pR?._destroy),
);

export const getProductRecipesCostErrors = createSelector(
  getProductRecipes,
  getAllRecipesSupplies,
  getAllSupplies,
  getAllRecipes,
  (pR, rS, s, r) => {
    const recipeIds = pR?.map(({ recipeId }) => recipeId);
    const recipeSupplies = rS?.filter(({ recipeId }) =>
      recipeIds?.includes(recipeId),
    );
    const supplyIds = recipeSupplies?.map(({ supplyId }) => supplyId);
    const supplies = s?.filter(
      (supply) => supplyIds.includes(supply.id) && supply?.cost <= 0,
    );

    return !!supplies?.length
      ? r.filter((recipe) => {
          return rS.some(({ supplyId, recipeId }) => {
            return (
              recipe.id == recipeId &&
              recipeIds.includes(recipe.id) &&
              supplies.map(({ id }) => id).includes(supplyId)
            );
          });
        })
      : false;
  },
);

export const getProductsRecipesTotalCost = createSelector(
  getProductRecipes,
  getAllRecipes,
  (productRecipes, recipes) => {
    const getRecipe = (id) => recipes?.find((r) => r.id == id);

    return productRecipes
      ?.map((item) => {
        const recipe = getRecipe(item?.recipeId);

        return (
          recipe?.cost * (item?.quantity / (recipe?.estimatedProduce || 1))
        );
      })
      .reduce((acc, num) => (acc += num), 0);
  },
);

export const getProductRecipesTotalCost = createSelector(
  getProductsRecipesByProduct,
  getAllRecipes,
  (productRecipes, recipes) => {
    const getRecipe = (id) => recipes?.find((r) => r.id == id);

    return productRecipes
      ?.map((item) => {
        const recipe = getRecipe(item?.recipeId);

        return (
          recipe?.cost * (item?.quantity / (recipe?.estimatedProduce || 1))
        );
      })
      .reduce((acc, num) => (acc += num), 0);
  },
);
