import React from 'react';
import Typography from './Typography';

export default function Title({ weight = 'normal', variant = 'h6', ...props }) {
  return (
    <Typography weight="bold" pb={3} variant={variant} {...props}>
      {props.children}
    </Typography>
  );
}
