import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem as MenuItemComponent,
} from '@mui/material';
import Button from './Button';

const defaultSx = {
  display: 'flex',
  borderRadius: '10px',
  fontWeight: 'bolder',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
};

export default function MenuItem({
  children,
  onClick,
  onMenuClose,
  confirmationText,
}) {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
    onMenuClose();
  }, [onClick]);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleConfirmOpen = useCallback(() => {
    setOpenConfirmation(!openConfirmation);
  }, [openConfirmation]);

  const handleConfirm = useCallback(() => {
    handleClick();
    setOpenConfirmation(false);
  }, [onClick]);

  const handleConfirmClose = useCallback(() => {
    setOpenConfirmation(false);
  }, []);
  return (
    <>
      <MenuItemComponent
        onClick={confirmationText ? handleConfirmOpen : handleClick}
      >
        {children}
      </MenuItemComponent>
      <Dialog open={openConfirmation} onClose={handleConfirmClose}>
        <DialogContent>
          <DialogContentText>
            {confirmationText ||
              'Tem certeza que deseja continuar com essa ação?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={defaultSx} onClick={handleConfirmClose}>
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="danger"
            sx={defaultSx}
            onClick={handleConfirm}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
