import React from 'react';
import Segment from './Segment';
import Typography from './Typography';
import Link from './Link';
import { decorationColors, spacing } from './theme';
import Box from './Box';

const iconWrapper = {
  display: 'flex',
  width: '48px',
  height: '48px',
  backgroundColor: `${decorationColors.decorationBrand}10`,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: spacing.xs,
};

export default function HomeCard({
  sx,
  title,
  icon,
  iconBackgroundSx,
  width,
  height = '120px',
  to = false,
}) {
  const Container = to ? Link : Segment;

  return (
    <Container
      to={to}
      component={Segment}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        cursor: to ? 'pointer' : 'normal',
        width,
        height,
        ...sx,
      }}
    >
      {icon && <Box sx={{ ...iconWrapper, ...iconBackgroundSx }}>{icon}</Box>}
      <Typography weight="600" variant="h7" pt={2} sx={{ color: '#454545' }}>
        {title}
      </Typography>
    </Container>
  );
}
