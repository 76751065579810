const getNotifications = (action) => action.entities.notifications;

export default function notificationsReducer(state = {}, action) {
  switch (action.type) {
    case 'NOTIFICATIONS_FETCHED':
    case 'NOTIFICATION_FETCHED':
    case 'NOTIFICATION_UPDATED':
      return { ...state, ...getNotifications(action) };
    default:
      return state;
  }
}
