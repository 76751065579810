import React from 'react';
import { Tooltip } from '@mui/material';
import { decorationColors } from './theme';

const defaultStyle = {
  fontSize: '0.8rem',
};

export default function ({
  arrow = true,
  disableFocusListener = true,
  disableTouchListener = true,
  color = decorationColors.decorationBrand,
  placement = 'top',
  title,
  children,
  ...rest
}) {
  return (
    <Tooltip
      arrow={arrow}
      placement={placement}
      disableFocusListener={disableFocusListener}
      disableTouchListener={disableTouchListener}
      title={title}
      componentsProps={{
        tooltip: {
          sx: {
            ...defaultStyle,
            backgroundColor: color,
          },
        },
        arrow: {
          sx: {
            color: color,
          },
        },
      }}
      {...rest}
    >
      <span>{children}</span>
    </Tooltip>
  );
}
