import { normalize, schema } from 'normalizr';
import { apiDel, apiGet, apiPost, apiPut } from './helpers/api';
import { replace } from 'connected-react-router';
import toQueryString from '../utils/toQueryString';
import { notifySubmitSucceeded } from './notifierProcesses';

const supply = new schema.Entity('supplies');

export const fetchSupplies = (dispatch, props) => {
  const currentPage = props?.currentPage || 1;
  const pageParams = props?.paged == true ? `page=${currentPage}` : '';
  const filterParams = props?.filters
    ? toQueryString({ ...props.filters })
    : '';

  dispatch({ type: 'SUPPLIES_FETCH_REQUESTED' });

  return apiGet(`/api/v1/supplies?${filterParams}${pageParams}`).then(
    (response) => {
      dispatch({
        type: 'SUPPLIES_FETCHED',
        ...normalize(response.body.data, new schema.Array(supply)),
        totalPages: response.body.totalPages,
        currentPage: response.body.currentPage,
      });
    },
  );
};

export const fetchSupply = (dispatch, id) => {
  return apiGet(`/api/v1/supplies/${id}`).then((response) => {
    dispatch({
      type: 'SUPPLY_FETCHED',
      ...normalize(response.body.data, supply),
    });
  });
};

export const createOrUpdateSupply = (values, dispatch, props) => {
  const handler = !values.id ? createSupply : updateSupply;
  return handler(values, dispatch, props);
};

const createSupply = (values, dispatch, props) => {
  const onModal = !!props?.closeCreateModal;

  return apiPost('/api/v1/supplies')
    .send({ supply: values })
    .then((response) => {
      dispatch({
        type: 'SUPPLY_CREATED',
        ...normalize(response.body.data, supply),
      });
      onModal
        ? props.closeCreateModal()
        : dispatch(replace('/catalogs/supplies'));
      notifySubmitSucceeded(dispatch, 'success', 'Insumo adicionado!');
    });
};

const updateSupply = (values, dispatch, props) => {
  return apiPut(`/api/v1/supplies/${props.supplyId}`)
    .send({ supply: values })
    .then((response) => {
      dispatch({
        type: 'SUPPLY_UPDATED',
        ...normalize(response.body.data, new schema.Array(supply)),
      });
      notifySubmitSucceeded(dispatch, 'success', 'Insumo atualizado!');
    });
};

export const deleteSupply = (dispatch, id) =>
  apiDel(`/api/v1/supplies/${id}`).then(() => {
    dispatch({
      type: 'SUPPLY_DELETED',
      id,
    });
  });

export const submitSearchFilter = (filters, dispatch) => {
  dispatch({
    type: 'SUPPLIES_FILTERS_CHANGED',
    filters,
  });
};
