import React from 'react';
import { connect } from 'react-redux';
import BackgroundTaskListItem from './BackgroundTaskListItem';
import { useEffect } from 'react';
import { fetchBackgroundTask } from '../../processes/backgroundTaskProcesses';

function BackgroundTaskListItemContainer(props) {
  const { task, dispatch } = props;
  const FETCH_INTERVAL = 5000;

  useEffect(() => {
    const fetchingBackgroundTask = setInterval(() => {
      if (task.status === 'processing') {
        fetchBackgroundTask(dispatch, task);
      }
    }, FETCH_INTERVAL);

    return () => clearInterval(fetchingBackgroundTask);
  }, [dispatch, task]);
  return <BackgroundTaskListItem {...props} />;
}

export default connect()(BackgroundTaskListItemContainer);
