import { apiGet, apiPut } from './helpers/api';
import { normalize, schema } from 'normalizr';
import moment from 'moment-timezone';

const notificationSchema = new schema.Entity('notifications');

export const notificationFetched = (dispatch, notification) =>
  dispatch({
    type: 'NOTIFICATION_FETCHED',
    ...normalize(notification, notificationSchema),
  });

export const fetchNotifications = (dispatch, props) => {
  const currentPage = props?.currentPage || 1;
  const pageParams = props?.paged == true ? `page=${currentPage}` : '';

  dispatch({ type: 'NOTIFICATIONS_FETCH_REQUESTED' });

  return apiGet(`/api/v1/notifications?${pageParams}`).then((response) =>
    dispatch({
      type: 'NOTIFICATIONS_FETCHED',
      ...normalize(response.body.data, new schema.Array(notificationSchema)),
      totalPages: response.body.totalPages,
      totalCount: response.body.totalCount,
      unreadCount: response.body.unreadCount,
      currentPage: response.body.currentPage,
    }),
  );
};

export const updateNotification = (dispatch, notificationId) => {
  const currentTime = moment();

  return apiPut(`/api/v1/notifications/${notificationId}`)
    .send({ readAt: currentTime })
    .then((response) => {
      dispatch({
        type: 'NOTIFICATION_UPDATED',
        ...normalize(response.body.data, notificationSchema),
      });
    });
};

export const readAllNotifications = (dispatch) => {
  return apiPut('/api/v1/notifications/read_all').then(
    fetchNotifications(dispatch, { paged: true }),
  );
};
