import { utilityColors } from '../../components/library/theme';

export const getCalendarEventColor = (status) =>
  ({
    in_production: utilityColors.warning,
    completed: utilityColors.progress,
    delivered: utilityColors.success,
    scheduled: utilityColors.information,
    canceled: utilityColors.danger,
    task: utilityColors.overlay,
    revenue: utilityColors.success,
    expense: utilityColors.danger,
  }[status]);
