import * as React from 'react';
import Box from '@mui/material/Box';
import Bell from './Bell';
import Calendar from './Calendar';
import HamburguerMenu from './HamburguerMenu';

export default {
  title: 'Library/Icons',
  component: Box,
};

const defaultArgs = {
  sx: {
    border: '1px solid red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px',
  },
};

const Template = (args) => <Box {...defaultArgs} {...args} />;

export const BellIcon = Template.bind({});
BellIcon.args = {
  format: "<Bell color='black' />",
  children: <Bell color="black" />,
};

export const CalendarIcon = Template.bind({});
CalendarIcon.args = {
  children: <Calendar color="black" />,
};

export const HamburguerMenuIcon = Template.bind({});
HamburguerMenuIcon.args = {
  children: <HamburguerMenu color="black" />,
};
