import React, { useEffect } from 'react';
import Box from '../library/Box';
import { spacing } from '../library/theme';
import { PageContext } from './PageContext';

const containerSx = {
  flex: 1,
  paddingLeft: spacing.m,
  paddingRight: spacing.m,
};

function Component(props) {
  const {
    title,
    back,
    greeting,
    offsetHeader,
    onUpdatePageContext,
    showFooter = true,
    showMenu = true,
    children,
    headerType,
    customBackOnClick,
    sx,
    footer,
    footerProps,
    showWeeklySales,
    ...rest
  } = props;

  useEffect(
    () =>
      onUpdatePageContext({
        title,
        back,
        offsetHeader,
        showFooter,
        showMenu,
        greeting,
        headerType,
        customBackOnClick,
        footer,
        footerProps,
        showWeeklySales,
      }),
    [
      title,
      back,
      offsetHeader,
      showFooter,
      showMenu,
      greeting,
      headerType,
      customBackOnClick,
      footer,
      footerProps,
      showWeeklySales,
    ],
  );

  return (
    <Box sx={{ ...containerSx, ...sx }} {...rest}>
      {children}
    </Box>
  );
}

export default function Page(props) {
  return (
    <PageContext.Consumer>
      {({ onUpdatePageContext }) => (
        <Component onUpdatePageContext={onUpdatePageContext} {...props} />
      )}
    </PageContext.Consumer>
  );
}
