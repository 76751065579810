import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { createOrUpdateInventory } from '../../processes/inventoryProcessess';
import { getCurrencyById, getUnitById } from '../../selectors/configSelectors';
import { getSupply } from '../../selectors/supplySelectors';
import InventoryForm from './InventoryForm';
import React, { useCallback } from 'react';
import { replace } from 'connected-react-router';
import { getCurrentUserCompany } from '../../selectors/userSelectors';

const FORM_NAME = 'inventory';

function InventoryFormContainer(props) {
  const { supply, dispatch } = props;

  const openInventoryManager = useCallback(
    () => dispatch(replace(`/catalogs/supplies/${supply.id}/inventories`)),
    [supply.id, dispatch],
  );

  return (
    <InventoryForm openInventoryManager={openInventoryManager} {...props} />
  );
}

function mapStateToProps(state, ownProps) {
  const { inventory } = ownProps;
  const supplyId = ownProps.supplyId || inventory.supplyId;
  const supply = getSupply(state, supplyId);
  const formSelector = formValueSelector(FORM_NAME);
  const quantity = formSelector(state, 'quantity');
  const quantityPerPackage = formSelector(state, 'quantityPerPackage');
  const type = formSelector(state, 'type');
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);

  return {
    initialValues: inventory
      ? inventory
      : {
          type: 'add',
          quantity: 1,
          quantityPerPackage: 1,
          supplyId: supplyId,
        },
    unit: getUnitById(state, supply?.unitId),
    quantityPerPackage,
    quantity,
    type,
    inventory,
    supply,
    currency,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: createOrUpdateInventory,
  })(InventoryFormContainer),
);
