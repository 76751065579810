import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Trash = (props) => {
  const {color = '#19518e', ...rest} = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      {...rest}
    >
      <path
        id="trash-alt-regular"
        className="trash-alt-regular"
        d="M8.375,13h.75a.375.375,0,0,0,.375-.375V5.875A.375.375,0,0,0,9.125,5.5h-.75A.375.375,0,0,0,8,5.875v6.75A.375.375,0,0,0,8.375,13ZM13.5,2.5H10.925L9.862.728A1.5,1.5,0,0,0,8.575,0H5.425A1.5,1.5,0,0,0,4.138.728L3.075,2.5H.5A.5.5,0,0,0,0,3v.5A.5.5,0,0,0,.5,4H1V14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4h.5a.5.5,0,0,0,.5-.5V3A.5.5,0,0,0,13.5,2.5ZM5.37,1.591A.188.188,0,0,1,5.531,1.5H8.469a.188.188,0,0,1,.161.091l.546.909H4.825ZM11.5,14.5h-9V4h9ZM4.875,13h.75A.375.375,0,0,0,6,12.625V5.875A.375.375,0,0,0,5.625,5.5h-.75a.375.375,0,0,0-.375.375v6.75A.375.375,0,0,0,4.875,13Z"
        transform="translate(0 0)"
        fill={color}
      />
    </SvgIcon>
  );
};

export default Trash;
