import { normalize, schema } from 'normalizr';
import { apiDel, apiGet, apiPost, apiPut } from './helpers/api';
import { replace } from 'connected-react-router';
import { updateFile } from './helpers/updateFile';
import { customerSchema } from './customerProcessess';
import toQueryString from '../utils/toQueryString';
import { notifySubmitSucceeded } from './notifierProcesses';
import normalizeObject from '../lib/normalizeObject';
import { underscore } from 'inflection';

const orderSchema = new schema.Entity('orders', {
  meta: {
    customer: customerSchema,
    ordersProducts: [new schema.Entity('ordersProducts')],
    ordersItems: [new schema.Entity('ordersItems')],
  },
});

const updatedOrder = (dispatch, response) => {
  dispatch({
    type: 'ORDER_UPDATED',
    ...normalize(response.body.data, orderSchema),
  });
};

const isValid = (value) => {
  return value && !isNaN(value);
};

export const fetchOrders = (dispatch, props) => {
  const currentPage = props?.currentPage || 1;
  const pageParams = props?.paged == true ? `page=${currentPage}` : '';
  const params = {
    isDeletedEq: props?.paged ? false : [true, false],
    ...props?.filters,
  };
  const filterParams = toQueryString(params);

  dispatch({ type: 'ORDERS_FETCH_REQUESTED' });

  return apiGet(`/api/v1/orders?${filterParams}${pageParams}`).then(
    (response) => {
      dispatch({
        type: 'ORDERS_FETCHED',
        ...normalize(response.body.data, new schema.Array(orderSchema)),
        totalPages: response.body.totalPages,
        currentPage: response.body.currentPage,
        totalCount: response.body.totalCount,
      });
    },
  );
};

export const fetchOrder = (dispatch, id) => {
  return apiGet(`/api/v1/orders/${id}`).then((response) => {
    dispatch({
      type: 'ORDER_FETCHED',
      ...normalize(response.body.data, orderSchema),
    });
  });
};

export const fetchBudget = (dispatch, id) => {
  return apiGet(`/api/v1/budgets/${id}`).then((response) => {
    dispatch({
      type: 'BUDGET_FETCHED',
      ...normalize(response.body.data, orderSchema),
    });
  });
};

export const createOrUpdateOrder = (values, dispatch, props) => {
  const handler = !values.id ? createOrder : updateOrder;

  const deliveryFee =
    values.deliveryType === 'takeaway' ? 0 : values.deliveryFee;

  return handler({ ...values, deliveryFee }, dispatch, props);
};

const createOrder = (values, dispatch) => {
  const { imageUrl, paymentStatus, orderType, ...attrs } = values;
  if (paymentStatus == 'paid') attrs.amountPaidInitially = attrs.amount;
  return apiPost('/api/v1/orders')
    .send({
      order: {
        ...normalizeObject(attrs, 'snake'),
        orderType: underscore(orderType),
      },
    })
    .then((response) => {
      dispatch({
        type: 'ORDER_CREATED',
        ...normalize(response.body.data, orderSchema),
      });
      updateFile({
        file: imageUrl,
        dispatch,
        fieldName: 'order[image]',
        path: `/api/v1/orders/${response.body.data.id}`,
        actionFunction: updatedOrder,
      });
      notifySubmitSucceeded(dispatch, 'success', 'Pedido adicionado!');
      dispatch(replace('/orders'));
    });
};

export const onlyDeleted = (array) =>
  array.filter((item) => item._destroy == 1);

const metaCleanUp = (
  dispatch,
  { ordersProductsAttributes, ordersItemsAttributes },
) => {
  onlyDeleted(ordersProductsAttributes).map(({ id }) =>
    dispatch({ type: 'ORDERS_PRODUCT_DELETED', id }),
  );
  onlyDeleted(ordersItemsAttributes).map(({ id }) =>
    dispatch({ type: 'ORDERS_ITEM_DELETED', id }),
  );
};

const updateOrder = (values, dispatch, props) => {
  const { ordersProductsAttributes, ordersItemsAttributes, orderType } = values;
  const { imageUrl, ...attrs } = values;
  return apiPut(`/api/v1/orders/${props.orderId}`)
    .send({
      order: {
        ...normalizeObject(attrs, 'snake'),
        orderType: underscore(orderType),
      },
    })
    .then((response) => {
      updatedOrder(dispatch, response);
      updateFile({
        file: imageUrl,
        dispatch: dispatch,
        fieldName: 'order[image]',
        path: `/api/v1/orders/${props.orderId}`,
        actionFunction: updatedOrder,
      });
      metaCleanUp(dispatch, {
        ordersProductsAttributes,
        ordersItemsAttributes,
      });
      notifySubmitSucceeded(dispatch, 'success', 'Pedido atualizado!');
      dispatch(replace('/orders'));
    });
};

export const deleteOrder = (dispatch, id) =>
  apiDel(`/api/v1/orders/${id}`).then(() => {
    dispatch({
      type: 'ORDER_DELETED',
      id,
    });
  });

export const sendOrder = (order, customer, budgetUrl) => {
  const messageText = `Olá, aqui está o seu pedido: ${budgetUrl}${order?.uuid}`;
  const url = `https://wa.me/${customer?.phoneCountryCode || '55'}${
    customer?.phone
  }?text=${encodeURIComponent(messageText)}`;
  return window.open(url, '_blank').focus;
};

export const submitSearchFilter = (filters, dispatch) => {
  const {
    scheduledAtFrom,
    scheduledAtTo,
    orderTypeEq,
    deliveryTypeEq,
    statusEq,
    paymentStatusEq,
  } = filters;
  const betweenDates = [scheduledAtFrom, scheduledAtTo];

  if (!isValid(scheduledAtFrom) || !isValid(scheduledAtTo)) return;

  dispatch({
    type: 'ORDERS_FILTERS_CHANGED',
    filters: {
      betweenDates,
      orderTypeEq,
      deliveryTypeEq,
      statusEq,
      paymentStatusEq,
    },
  });
};
