import * as React from 'react';
import Button from './Button';
import Arrow from './icons/Arrow';
import Google from './icons/Google';
import {stateColors} from './theme';

const decoratorStyle = {
  backgroundColor: stateColors.empty,
  height: '200px',
  width: '350px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
};

export default {
  title: 'Library/Button',
  component: Button,
  argTypes: {
    color: {
      name: 'color',
      type: {name: 'string', required: false},
      defaultValue: 'primary',
      description: 'Button colors came from theme`s palette',
      control: {
        type: 'text',
      },
    },
    variant: {
      name: 'variant',
      type: {name: 'string', required: false},
      defaultValue: 'contained',
      description: 'Button variants came from theme',
    },
    size: {
      name: 'size',
      type: {name: 'string', required: false},
      defaultValue: 'large',
      description: 'Button`s size',
    },
    children: {
      name: 'children',
      type: {name: 'string', required: false},
      defaultValue: 'Lorem Ipsum',
      description: 'Button content, can be also a component',
    },
    fluid: {
      name: 'fluid',
      type: {name: 'boolean', required: false},
      defaultValue: false,
      description: 'Fluid button?',
    },
    disabled: {
      name: 'disabled',
      type: {name: 'boolean', required: false},
      defaultValue: false,
      description: 'disabled button?',
    },
  },
};

const defaultArgs = {
  color: 'primary',
  children: 'Lorem Ipsum',
  variant: 'content',
  fluid: false,
  size: 'large',
  disabled: 'false',
};

const Template = (args) => (
  <div style={decoratorStyle}>
    <Button {...defaultArgs} {...args} />
  </div>
);

export const FluidWithPrimaryVariant = Template.bind({});
FluidWithPrimaryVariant.args = {
  variant: 'primary',
  fluid: true,
  children: 'Entrar',
};

export const SocialWithIcon = Template.bind({});
SocialWithIcon.args = {
  variant: 'social',
  children: 'Google',
  startIcon: <Google />,
};

export const FluidWithPrimaryInverse = Template.bind({});
FluidWithPrimaryInverse.args = {
  variant: 'primary-inverse',
  children: 'Começar',
  fluid: true,
};

export const IconSquaredVariant = Template.bind({});
IconSquaredVariant.args = {
  variant: 'icon-squared',
  children: <Arrow />,
};

export const IconSquaredVariantDisabled = Template.bind({});
IconSquaredVariantDisabled.args = {
  variant: 'icon-squared',
  children: <Arrow position="left" />,
  disabled: true,
};

export const TextVariant = Template.bind({});
TextVariant.args = {
  variant: 'text',
};

export const OutlinedVariant = Template.bind({});
OutlinedVariant.args = {
  variant: 'outlined',
};

export const ColorError = Template.bind({});
ColorError.args = {
  color: 'error',
};

export const SecondaryWithMediumSize = Template.bind({});
SecondaryWithMediumSize.args = {
  color: 'secondary',
  size: 'medium',
};

export const DisabledWithSmallSize = Template.bind({});
DisabledWithSmallSize.args = {
  color: 'success',
  size: 'small',
  disabled: true,
};
