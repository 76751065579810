import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { css } from 'glamor';
import React, { useCallback, useEffect, useState } from 'react';
import { currencyMask, toCurrency } from '../../../utils';
import Button from '../Button';
import Separator from '../Separator';
import { buttonColors, spacing, uiColors, utilityColors } from '../theme';
import Typography from '../Typography';
import FieldBlock from './FieldBlock';
import Label from './Label';
import TextField from './TextField';

const labelStyle = {
  marginBottom: spacing.xs,
  display: 'block',
};
const actions = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: spacing.m,
};
const list = css({
  marginTop: spacing.s,
  paddingTop: spacing.s,
  borderTop: `1px solid ${uiColors.border}`,
});
const listItem = css({
  display: 'flex',
  paddingLeft: spacing.m,
  paddingRight: spacing.m,
  marginBottom: spacing.s,
  paddingBottom: spacing.s,
  borderBottom: `1px solid ${uiColors.border}`,
});

const listItemDescription = {
  color: utilityColors.success,
  flex: 3,
};
const listItemAmount = {
  display: 'flex',
  justifyContent: 'flex-end',
  flex: 1,
  paddingLeft: spacing.s,
};
const listItemAction = {
  display: 'flex',
  justifyContent: 'center',
  flex: 0.5,
};
const iconStyle = {
  marginLeft: spacing.m,
  color: buttonColors.primary,
  cursor: 'pointer',
};
const currencyStyle = {
  fontSize: 12,
  alignItems: 'center',
  display: 'flex',
};
const totalAmountStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export default function SimpleMultipleItemsInput(props) {
  const {
    required,
    label,
    disabled,
    fields,
    showAmount = false,
    style = {},
    hint,
    currency,
  } = props;
  const [removedIndex, setRemovedIndex] = useState(null);
  const [values, setValues] = useState({ name: '', price: '' });
  const [deletedValue, setDeletedValue] = useState({});

  const hideDestroyed = (item) => !!!item?._destroy;

  const serialize = (item, index) => {
    return { ...item, index };
  };

  const items = fields.getAll()?.map(serialize)?.filter(hideDestroyed) || [];

  const onNameChange = useCallback(
    (e) => {
      setValues({ ...values, name: e.target.value });
    },
    [values],
  );

  const onCurrencyChange = useCallback(
    (_, value) => {
      setValues({ ...values, price: value });
    },
    [values],
  );

  const onAdd = useCallback(() => {
    !!removedIndex ? fields.insert(removedIndex, values) : fields.push(values);
  }, [fields, values, removedIndex]);

  const onDelete = useCallback(
    (index) => () => {
      setDeletedValue({ ...fields.get(index), _destroy: 1 });
      fields.remove(index);
    },
    [fields],
  );
  useEffect(() => {
    if (deletedValue?.id) {
      fields.push(deletedValue);
      if (!removedIndex) {
        setRemovedIndex(fields?.length);
      }
      setDeletedValue({});
    }
  }, [fields, deletedValue, removedIndex]);

  return (
    <>
      <FieldBlock style={style}>
        <Label style={labelStyle} required={required} disabled={disabled}>
          {label}
        </Label>

        <div>
          <FieldBlock>
            <TextField
              name="itemName"
              onChange={onNameChange}
              value={values.itemName}
              label="Nome"
              fluid
            />
            <TextField
              {...currencyMask(currency?.symbol)}
              name="itemPrice"
              onChange={onCurrencyChange}
              value={values.itemPrice}
              label="Valor Unitário"
              fluid
            />
          </FieldBlock>

          <div style={actions}>
            <Button onClick={onAdd}>Adicionar</Button>
          </div>
        </div>
      </FieldBlock>

      <div className={list}>
        {items.length ? (
          items.map((item, index) => {
            return (
              <div className={listItem}>
                <Typography
                  sx={{ color: 'palette.success.dark' }}
                  style={listItemDescription}
                  weight="bold"
                >
                  {item?.name}
                </Typography>

                {item.price && (
                  <div style={listItemAmount}>
                    <Typography weight="bold" style={currencyStyle}>
                      {toCurrency(item.price, currency?.code)}
                    </Typography>
                  </div>
                )}
                <div style={listItemAction}>
                  <DeleteForeverIcon
                    style={iconStyle}
                    onClick={onDelete(index)}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className={listItem}>
            <Typography sx={{ fontStyle: 'italic' }}>Lista vazia...</Typography>
          </div>
        )}
        {showAmount && (
          <>
            <div style={totalAmountStyle}>
              <Typography weight="bold" variant="h6">
                Total:
                {toCurrency(
                  items.reduce((a, b) => a + b.amount * b.quantity, 0),
                  currency?.code,
                )}
              </Typography>
            </div>
            <Separator />
          </>
        )}
        {hint}
      </div>
    </>
  );
}
