import { createSelector } from 'reselect';
import orderByDate from '../utils/orderByDate';
import { getAllTasks } from './taskSelectors';
import { getAllOrders } from './orderSelectors';
import getDates from '../utils/getDates';
import filterByDate from '../utils/filterByDate';
import { getCalendarEventColor } from './helpers/getCalendarEventColor';
import { getCurrentUserTimezone } from './userSelectors';
import moment from 'moment-timezone';
import {
  getAllExpenses,
  getAllRevenues,
  getAllStatements,
} from './statementSelectors';

const getDays = (_, numDays = 7) => getDates(moment(), numDays);
const getCurrentWeek = (_, numDays = 7) =>
  getDates(moment().startOf('week'), numDays);

const parseToEvent = (
  collection,
  date,
  type,
  timezone = 'America/Sao_Paulo',
) => {
  return collection
    .filter((item) =>
      filterByDate(item.scheduleAt || item.date, date, timezone),
    )
    .map((item) => ({ ...item, type }));
};

export const getEvents = createSelector(
  getAllTasks,
  getAllOrders,
  // getAllRevenues,
  // getAllExpenses,
  getDays,
  getCurrentUserTimezone,
  (
    tasks,
    orders,
    //  revenues,
    // expenses,
    days,
    timezone,
  ) => {
    const events = {};
    days.map((day) => {
      const tasksOfTheDay = parseToEvent(tasks, day, 'task', timezone);
      const ordersOfTheDay = parseToEvent(orders, day, 'order', timezone);
      // const revenuesOfTheDay = parseToEvent(revenues, day, 'revenue', timezone);
      // const expensesOfTheDay = parseToEvent(expenses, day, 'expense', timezone);
      events[day] = [
        ...tasksOfTheDay,
        ...ordersOfTheDay,
        // ...revenuesOfTheDay,
        // ...expensesOfTheDay,
      ].sort(orderByDate('scheduleAt', 'asc'));
    });

    return events;
  },
);

export const getSales = createSelector(
  getAllOrders,
  getCurrentWeek,
  getCurrentUserTimezone,
  (orders, days, timezone) => {
    const events = {};
    days.map((day) => {
      const ordersOfTheDay = parseToEvent(orders, day, 'order', timezone);
      events[day] = [...ordersOfTheDay].sort(orderByDate('scheduleAt', 'asc'));
    });

    return events;
  },
);

export const getSalesTotalAmount = createSelector(getSales, (sales) =>
  Object.keys(sales).reduce((total, date) => {
    return (
      total +
      sales[date].reduce((subtotal, order) => subtotal + order?.amountPaid, 0)
    );
  }, 0),
);

export const getStatementsOfWeek = createSelector(
  getAllRevenues,
  getAllExpenses,
  getCurrentWeek,
  getCurrentUserTimezone,
  (revenues, expenses, days, timezone) => {
    const events = {};
    days.map((day) => {
      const revenuesOfTheDay = parseToEvent(revenues, day, 'revenue', timezone);
      const expensesOfTheDay = parseToEvent(expenses, day, 'expense', timezone);
      events[day] = [...revenuesOfTheDay, ...expensesOfTheDay].sort(
        orderByDate('scheduleAt', 'asc'),
      );
    });

    return events;
  },
);

export const getWeeklySummaryInfo = createSelector(
  getStatementsOfWeek,
  (statements) => {
    const totals = { revenuesTotalAmount: 0, expensesTotalAmount: 0 };

    Object.keys(statements).forEach((day) => {
      statements[day].forEach((event) => {
        if (event.type === 'revenue') {
          totals.revenuesTotalAmount += event?.amount || 0;
        } else if (event.type === 'expense') {
          totals.expensesTotalAmount += event?.amount || 0;
        }
      });
    });

    return [[totals.expensesTotalAmount], [totals.revenuesTotalAmount]];
  },
);

const getOrdersCalendarEvents = createSelector(getAllOrders, (orders) =>
  orders.map((order) => ({
    title: order.name,
    start: order.scheduleAt,
    color: getCalendarEventColor(order.status),
    backgroundColor: getCalendarEventColor(order.status),
    extendedProps: {
      objectEndpoint: '/orders',
      objectId: order.id,
    },
  })),
);

const getTasksCalendarEvents = createSelector(getAllTasks, (tasks) =>
  tasks.map((task) => ({
    title: task.title,
    start: task.scheduleAt,
    color: getCalendarEventColor('task'),
    backgroundColor: getCalendarEventColor('task'),
    extendedProps: {
      objectEndpoint: '/tasks',
      objectId: task.id,
    },
  })),
);

const getStatementsCalendarEvents = createSelector(
  getAllStatements,
  (statements) =>
    statements.map((statement) => ({
      title: `Financeiro - #${statement.id}`,
      start: statement.date,
      color: getCalendarEventColor(`${statement?.type}`),
      backgroundColor: getCalendarEventColor(`${statement?.type}`),
      extendedProps: {
        objectEndpoint: `/statements/${statement?.type}s`,
        objectId: statement.id,
      },
    })),
);

export const getCalendarEvents = createSelector(
  getOrdersCalendarEvents,
  getTasksCalendarEvents,
  getStatementsCalendarEvents,
  (ordersEvents, tasksEvents, statementsEvents) => [
    ...ordersEvents,
    ...tasksEvents,
    ...statementsEvents,
  ],
);
