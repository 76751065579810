import React, { useState, useEffect } from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { css } from 'glamor';
import { spacing, decorationColors } from './theme';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Typography from './Typography';

const BUTTON_SIZE = 48;

const buttonClass = css({
  width: BUTTON_SIZE,
  height: BUTTON_SIZE,
  backgroundColor: '#fff',
  color: decorationColors.decorationOrange,
  outline: 'none',
  border: `3px solid ${decorationColors.decorationOrange}`,
  borderRadius: '50%',
  cursor: 'pointer',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'ease-in-out',
  transitionProperty: 'background-color, color',
  ':hover': {
    backgroundColor: decorationColors.decorationOrange,
    color: '#fff',
  },
});
const buttonWrapper = css({
  position: 'fixed',
  bottom: 125,
  right: spacing.m,
  zIndex: 2,
});
const iconStyle = { width: 32, height: 32 };
const popperStyle = { pointerEvents: 'none' };
const popperTextStyle = { p: 1 };

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export default function BackToTop() {
  const [visible, setVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrolled = document.documentElement.scrollTop;

      if (scrolled > 200) {
        setVisible(true);
      } else if (scrolled <= 200) {
        setVisible(false);
      }
    });

    return () => window.removeEventListener('scroll', () => {});
  }, []);

  return (
    visible && (
      <>
        <div className={buttonWrapper}>
          <button
            onClick={scrollToTop}
            className={buttonClass}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <KeyboardDoubleArrowUpIcon style={iconStyle} />
          </button>
        </div>
        <Popper
          id="mouse-over-popover"
          sx={popperStyle}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          placement="top-start"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Typography sx={popperTextStyle}>Voltar ao topo</Typography>
              </Paper>
            </Fade>
          )}
        </Popper>
      </>
    )
  );
}
