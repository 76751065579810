import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import StatementsTableRowContainer from './StatementsTableRowContainer';

const containerSx = {
  p: 0,
  marginLeft: '10px',
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: 'none',
    color: theme.palette.border.hover,
  },
}));

export default function StatementsTableData(props) {
  const { statements, actions, currency } = props;
  const currencySymbol = currency?.symbol ? `(${currency?.symbol})` : '(R$)';

  return (
    <TableContainer sx={containerSx}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">#</StyledTableCell>
            <StyledTableCell align="left">Descrição</StyledTableCell>
            <StyledTableCell align="right">
              Valor {currencySymbol}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody
          elevation={3}
          square={false}
          sx={{
            borderRadius: '10px !important',
            overflow: 'hidden !important',
          }}
        >
          {statements?.map((statement) => (
            <StatementsTableRowContainer
              statement={statement}
              actions={actions}
              currency={currency}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
