import React from 'react';
import Box from './Box';
import Grid from './Grid';
import { spacing, utilityColors } from './theme';
import Typography from './Typography';
import { toCurrency } from '../../utils';

const containerSx = {
  borderRadius: '10px',
  padding: '13px 16px',
};
const containerPositiveSx = {
  ...containerSx,
  backgroundColor: `${utilityColors.success}20`,
  color: utilityColors.success,
};
const containerNegativeSx = {
  ...containerSx,
  backgroundColor: `${utilityColors.danger}50`,
};
const containerInfoSx = {
  ...containerSx,
  backgroundColor: `${utilityColors.information}50`,
};
const labelPositiveSx = {
  fontWeight: 100,
  color: utilityColors.success,
};
const labelNegativeSx = {
  fontWeight: 100,
  color: utilityColors.danger,
};
const labelInfoSx = {
  fontWeight: 100,
  color: utilityColors.information,
};
const positiveAmountSx = {
  fontWeight: 500,
  color: utilityColors.success,
};
const negativeAmountSx = {
  fontWeight: 'bolder',
  color: utilityColors.danger,
};
const infoAmountSx = {
  fontWeight: 'bolder',
  color: utilityColors.information,
};
const paymentStatusLabelSx = {
  size: 'small',
  maxWidth: 'fit-content',
  marginBottom: spacing.xs,
};

const translatedPaymentStatus = (status) => {
  switch (status) {
    case 'unpaid':
      return 'Não pago';
    case 'paid':
      return 'Pago';
  }
};
const setPaymentLabelColor = (status) => {
  switch (status) {
    case 'paid':
      return 'success';
    case 'unpaid':
      return 'danger';
  }
};

const setContainerSx = (info, negative) => {
  if (info) {
    return containerInfoSx;
  } else if (negative) {
    return containerNegativeSx;
  } else return containerPositiveSx;
};

const setLabelSx = (info, negative) => {
  if (info) {
    return labelInfoSx;
  } else if (negative) {
    return labelNegativeSx;
  } else return labelPositiveSx;
};

const setAmountSx = (info, negative) => {
  if (info) {
    return infoAmountSx;
  } else if (negative) {
    return negativeAmountSx;
  } else return positiveAmountSx;
};

export default function MoneyInfo(props) {
  const {
    info,
    negative,
    label,
    amount,
    twinLabel,
    twinAmount,
    tag,
    tagStatus,
    sx,
    currency,
  } = props;
  const Tag = tag;

  return (
    <Box sx={{ ...sx, ...setContainerSx(info, negative) }}>
      <Box sx={{ flexGrow: 1 }}>
        {tag && (
          <Tag
            color={setPaymentLabelColor(tagStatus)}
            label={translatedPaymentStatus(tagStatus)}
            sx={paymentStatusLabelSx}
          />
        )}
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item>
            <Typography sx={setLabelSx(info, negative)}>{label}</Typography>
          </Grid>
          <Grid item>
            <Typography weight="bolder" sx={setAmountSx(info, negative)}>
              {toCurrency(amount, currency?.code)}
            </Typography>
          </Grid>
        </Grid>
        {(twinLabel || twinAmount) && (
          <Grid container justifyContent="space-between" spacing={2}>
            {twinLabel && (
              <Grid item>
                <Typography sx={setLabelSx(info, negative)}>
                  {twinLabel}
                </Typography>
              </Grid>
            )}
            {twinAmount && (
              <Grid item>
                <Typography weight="bolder" sx={setAmountSx(info, negative)}>
                  {toCurrency(twinAmount, currency?.code)}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
}
