import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getSales, getSalesTotalAmount } from '../../selectors/eventSelectors';
import WeeklySales from './WeeklySales';
import moment from 'moment-timezone';
import { fetchOrders } from '../../processes/orderProcesses';
import { toCurrency } from '../../utils';
import { getCurrentUserCompany } from '../../selectors/userSelectors';
import { getCurrencyById } from '../../selectors/configSelectors';

function WeeklySalesContainer(props) {
  const { dispatch } = props;

  const startOfWeek = moment().startOf('week');
  const endOfWeek = startOfWeek.clone().add(6, 'days');
  const betweenDates = [startOfWeek.format(), endOfWeek.format()];

  useEffect(() => {
    fetchOrders(dispatch, { filters: { betweenDates, isDeletedEq: false } });
  }, [dispatch]);

  return <WeeklySales {...props} />;
}

function mapStateToProps(state) {
  const today = moment().startOf('day').format();
  const sales = getSales(state);
  const salesTotalAmount = getSalesTotalAmount(state);
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);
  const salesQuantity = sales[today]?.length || 0;

  return {
    salesAmount: toCurrency(salesTotalAmount, currency?.code),
    salesQuantity,
  };
}

export default connect(mapStateToProps)(WeeklySalesContainer);
