import React from 'react';
import Box from '../library/Box';
import Button from '../library/Button';
import Arrow from '../library/icons/Arrow';
import theme, { spacing } from '../library/theme';

const indicatorSx = {
  height: spacing.xs,
  width: spacing.xs,
  borderRadius: spacing.xxs,
  backgroundColor: '#F7E4DD',
};
const activeIndicatorSx = {
  height: spacing.xs,
  width: '40px',
  borderRadius: spacing.xxs,
  backgroundColor: '#577C47',
};
const navigationSx = {
  paddingTop: theme.spacing(4),
  display: 'flex',
  justifyContent: 'space-between',
};
const indicatorWrapperSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: spacing.xs,
};

export default function OnboardingNavigation(props) {
  const { stepIndex } = props;

  return (
    <Box sx={navigationSx}>
      <Button
        variant="icon-squared"
        type="button"
        onClick={props.previousStep}
        disabled={stepIndex === 1}
      >
        <Arrow position="left" />
      </Button>
      <Box sx={indicatorWrapperSx}>
        {[1, 2, 3, 4].map((step) => (
          <Box sx={stepIndex === step ? activeIndicatorSx : indicatorSx} />
        ))}
      </Box>
      <Button variant="icon-squared" type="button" onClick={props.nextStep}>
        <Arrow />
      </Button>
    </Box>
  );
}
