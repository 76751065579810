import React from 'react';
import { connect } from 'react-redux';
import {
  getNotificationPageInfo,
  getNotificationsByFetchedIds,
} from '../../selectors/notificationSelectors';
import NotificationsPage from './NotificationsPage';
import { makeIsMobile } from '../../utils/useScreenWidth';
import {
  fetchNotifications,
  readAllNotifications,
} from '../../processes/notificationProcesses';
import { useCallback, useEffect } from 'react';

function NotificationsPageContainer(props) {
  const { dispatch, currentPage } = props;
  const isMobile = makeIsMobile();

  useEffect(() => {
    fetchNotifications(dispatch, { currentPage, paged: true });
  }, [dispatch, currentPage]);

  const pageChangeHandler = useCallback(
    (_, currentPage = 1) => {
      dispatch({
        type: 'NOTIFICATIONS_PAGE_CHANGED',
        currentPage,
      });
    },
    [dispatch],
  );

  const readAll = useCallback(() => {
    readAllNotifications(dispatch);
  }, [dispatch]);

  return (
    <NotificationsPage
      onPageChange={pageChangeHandler}
      isMobile={isMobile}
      readAll={readAll}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const { loading, currentPage, totalPages, unreadCount } =
    getNotificationPageInfo(state);

  return {
    notifications: getNotificationsByFetchedIds(state),
    currentPage,
    totalPages,
    unreadCount,
    loading,
  };
}

export default connect(mapStateToProps)(NotificationsPageContainer);
