import React, { useCallback } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { decorationColors, spacing, typographyColors } from './theme';

const defaultSx = {
  gap: spacing.xs,
  flexWrap: 'wrap',
};
const defaultButtonsSx = {
  display: 'flex',
  fontWeight: 'bolder',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  color: decorationColors.decorationBrand,
  border: `0.5px solid ${decorationColors.decorationBrand} !important`,
  borderRadius: `${spacing.xs} !important`,
  textTransform: 'none',
  height: spacing.l,
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
};
const activeButtonsSx = {
  ...defaultButtonsSx,
  color: typographyColors.inverseTitle,
  backgroundColor: decorationColors.decorationBrand,
  border: 'none',
  '&:hover': {
    backgroundColor: '#074ea5',
  },
};

export default function ToggleButtons(props) {
  const { name, buttons, sx, onChange, fieldValue } = props;

  const handleChange = useCallback(
    (event, value) => {
      if (onChange) {
        onChange(event, value, name);
      }
    },
    [name, onChange],
  );
  return (
    <ToggleButtonGroup
      value={name}
      exclusive
      onChange={handleChange}
      aria-label={`${name} value`}
      sx={{ ...defaultSx, ...sx }}
    >
      {buttons?.map((button) => {
        const active = button?.value == fieldValue;

        return (
          <ToggleButton
            value={button?.value}
            aria-label={`${button?.value}`}
            sx={active ? activeButtonsSx : defaultButtonsSx}
          >
            {button?.text}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
