import React, { useCallback } from 'react';
import IntlCurrencyInput from 'react-intl-currency-input';
import TextInput from './TextInput';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export default function CurrencyInput(props) {
  return (
    <IntlCurrencyInput
      component={TextInput}
      currency="BRL"
      config={currencyConfig}
      {...props}
    />
  );
}
