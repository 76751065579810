import React from 'react';
import { Box } from '../library';
import { Card } from '../library/cards';
import HorizontalItems from '../library/HorizontalItems';
import formatDate from '../../utils/formatDate';

export default function BackgroundTaskListItem(props) {
  const { task } = props;

  const taskType =
    task?.taskType == 'import_supplies' ? 'Importação de Insumos' : 'Outros';

  const title = `${taskType} - ${formatDate(task?.createdAt)}`;

  function translatedStatus(statusType) {
    if (statusType == 'processing') return 'Em processamento';
    else return 'Finalizado';
  }
  return (
    <Card key={task?.id} title={title}>
      <Box pt={2}>
        <HorizontalItems
          items={[
            {
              title: 'Status',
              description: `${translatedStatus(task?.status)}`,
            },
            {
              title: 'Quantidade de sucessos',
              description: `${task.successCount}`,
            },
            {
              title: 'Quantidade de falhas',
              description: `${task.errorCount}`,
            },
            task.errorCount > 0 && {
              title: 'Observações',
              description: `${task.parsedErrors}`,
              descriptionHtml: true,
              descriptionLarge: true,
            },
          ]}
        />
      </Box>
    </Card>
  );
}
