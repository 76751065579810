import React from "react";
import { Rating as Stars } from "semantic-ui-react";
import { css } from "glamor";
import { spacing, uiColors } from "../library/theme";

const container = css({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  margin: `${spacing.m} 0`,
  background: uiColors.contentCards,
  padding: `${spacing.m} 0`,
});
const titleStyle = css({
  marginTop: spacing.s,
});

export default function Rating(props) {
  const { onRate, title, className, value, defaultValue } = props;

  return (
    <div className={css(container, className)}>
      <h3 className={titleStyle}>{title}</h3>
      <Stars
        icon="star"
        maxRating={5}
        rating={value || defaultValue}
        size="large"
        onRate={onRate}
      />
    </div>
  );
}
