import React from 'react';
import { Card } from '../library/cards';
import { toCurrency } from '../../utils/formatCurrency';

import HorizontalItems from '../library/HorizontalItems';
import Box from '../library/Box';

export default function ProductListItem(props) {
  const {
    product,
    productCategory,
    onEdit,
    onDelete,
    strategicRole,
    onDuplicate,
    currency,
  } = props;
  const { id, name } = product;

  return (
    <Card
      key={id}
      title={name}
      onDuplicate={onDuplicate}
      onEdit={onEdit}
      onDelete={onDelete}
    >
      <Box pt={2}>
        <HorizontalItems
          items={[
            { title: 'Categoria', description: productCategory?.name },
            { title: 'Função estratégica', description: strategicRole?.name },
            {
              title: 'Quantidade em estoque',
              description: `${+product?.inStock.toFixed(2)}`,
            },
            {
              title: 'Preço',
              description: toCurrency(product?.amount, currency?.code),
            },
          ]}
        />
      </Box>
    </Card>
  );
}
