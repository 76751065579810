import React from 'react';
import EmptyState from '../library/EmptyState';
import Page from '../page/Page';
import RecipeListItemContainer from './RecipeListItemContainer';
import RecipeIcon from '../library/images/recipe.png';
import RecipeSearchFormContainer from './RecipeSearchFormContainer';
import Pagination from '../library/Pagination';

const paginationWrapper = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

export default function RecipesPage({
  recipes,
  onAdd,
  currency,
  loading,
  currentPage,
  totalPages,
  onPageChange,
  isMobile,
}) {
  return (
    <Page back="/" title="Receitas" headerType="secondary">
      <RecipeSearchFormContainer onAddButton={onAdd} />
      {!loading && recipes?.length ? (
        <>
          {recipes.map((recipe) => (
            <RecipeListItemContainer
              key={recipe.id}
              recipe={recipe}
              currency={currency}
            />
          ))}
          <div style={paginationWrapper}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
              short={isMobile}
            />
          </div>
        </>
      ) : (
        <EmptyState
          iconUrl={RecipeIcon}
          loading={loading}
          title="Nenhuma receita cadastrada"
          cta="/catalogs/recipes/new"
          ctaLabel="Cadastrar receita"
        />
      )}
    </Page>
  );
}
