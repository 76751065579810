import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from '../../processes/sessionProcesses';
import { PageContext } from '../page/PageContext';
import Header from './Header';

export default function HeaderContainer(props) {
  const dispatch = useDispatch();
  const signOutHandler = useCallback(() => signOut(dispatch), [dispatch]);

  return (
    <PageContext.Consumer>
      {({ pageContext }) => {
        const {
          title,
          back,
          offsetHeader,
          showMenu,
          greeting,
          headerType,
          customBackOnClick,
          showWeeklySales,
        } = pageContext;

        return (
          <Header
            title={title}
            back={back}
            offsetHeader={offsetHeader}
            showMenu={showMenu}
            greeting={greeting}
            headerType={headerType}
            customBackOnClick={customBackOnClick}
            showWeeklySales={showWeeklySales}
            {...props}
            signOutHandler={signOutHandler}
          />
        );
      }}
    </PageContext.Consumer>
  );
}
