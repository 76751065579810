import React from 'react';
import { useRouteMatch } from 'react-router';
import Button from './Button';
import Link from './Link';
import Segment from './Segment';
import { spacing } from './theme';

const containerSx = {
  display: 'flex',
  justifyContent: 'space-around',
  gap: spacing.m,
};

export default function Tabs({ data }) {
  return (
    <Segment sx={containerSx}>
      {data.map((tab, index) => {
        const isActive = !!useRouteMatch({ path: tab.to, exact: true });
        return (
          <Link
            component={Button}
            key={index}
            to={tab.to}
            variant={isActive ? 'contained' : 'text'}
            disabled={isActive}
            fluid
          >
            {tab.label}
          </Link>
        );
      })}
    </Segment>
  );
}
