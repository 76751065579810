import React from 'react';
import Button from '../library/Button';
import { Box, Grid, Typography } from '../library';
import { spacing, typographyColors, uiColors } from '../library/theme';
import { toCurrency } from '../../utils';

const footerContainerSx = {
  position: 'absolute',
  bottom: '5px',
  height: '72px',
};
const footerSx = {
  display: 'flex',
  width: '100%',
  maxWidth: '540px',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: `${spacing.m} ${spacing.xs} ${spacing.m} ${spacing.m}`,
  backgroundColor: uiColors.background,
  borderRadius: '24px 24px 0px 0px',
  marginLeft: 'auto',
  marginRight: 'auto',
};
const buttonSx = {
  backgroundColor: typographyColors.inverseTitle,
  color: typographyColors.defaultTitle,
  marginRight: spacing.xxs,
  width: '150px',
  ':hover': {
    backgroundColor: uiColors.border,
  },
};
const amountSx = {
  display: 'flex',
  flexDirection: 'column',
};

export default function OrderFormFooter(props) {
  const {
    isLastStep,
    handleNext,
    handleSubmit,
    amount,
    order,
    orderId,
    currency,
  } = props;
  const submitText = order || orderId ? 'Atualizar pedido' : 'Criar pedido';
  const buttonText = isLastStep ? submitText : 'Continuar';
  return (
    <Grid
      container
      sx={footerContainerSx}
      alignContent="center"
      alignItems="center"
    >
      <Box sx={footerSx}>
        <Box sx={amountSx}>
          <Typography variant="h8" color="white">
            Subtotal:
          </Typography>
          <Typography variant="h7" color="white" weight="bold">
            {toCurrency(amount, currency?.code)}
          </Typography>
        </Box>
        <Button onClick={isLastStep ? handleSubmit : handleNext} sx={buttonSx}>
          {buttonText}
        </Button>
      </Box>
    </Grid>
  );
}
