import { css } from 'glamor';
import React from 'react';
import { Title } from '../library';
import { spacing } from '../library/theme';
import DailyEventsContainer from './DailyEventsContainer';
import CalendarContainer from '../calendar/CalendarContainer';

const container = css({ marginBottom: spacing.xs });
const list = css({
  display: 'flex',
  justifyContent: 'space-between',
  overflowY: 'hidden',
  overflowX: 'scroll',
  '-ms-overflow-style': 'none' /* IE and Edge */,
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
});
const titleSx = { paddingLeft: spacing.m };
const titleWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: spacing.xl,
  marginTop: spacing.s,
});

export default function WeeklyEventsCalendar({ events }) {
  return (
    <div className={container}>
      <div className={titleWrapper}>
        <Title pt={3} sx={titleSx}>
          Calendário
        </Title>
        <CalendarContainer />
      </div>
      <div className={list}>
        {Object.keys(events).map((date) => (
          <DailyEventsContainer key={date} date={date} events={events[date]} />
        ))}
      </div>
    </div>
  );
}
