import { createSelector } from 'reselect';
import { orderByCreatedAtDesc } from '../utils/orderByCreatedAt';

const getNotifications = (state) => state.entities.notifications;
const getNotificationsList = (state) => state.notificationsList;
const getFetchedIds = (state) => state.notificationsList.notificationIds;

export const getNotificationPageInfo = createSelector(
  getNotificationsList,
  (notificationsList) => ({
    loading: notificationsList.loading,
    currentPage: notificationsList.currentPage,
    totalPages: notificationsList.totalPages,
    unreadCount: notificationsList.unreadCount,
    totalCount: notificationsList.totalCount,
    filters: notificationsList.filters,
  }),
);

export const getAllNotifications = createSelector(
  getNotifications,
  (notifications) => Object.values(notifications).sort(orderByCreatedAtDesc),
);

export const getNotificationsByFetchedIds = createSelector(
  getAllNotifications,
  getFetchedIds,
  (notifications, ids) =>
    notifications
      .filter((notification) => ids.includes(notification.id))
      .sort(orderByCreatedAtDesc),
);

export const getUnreadNotifications = createSelector(
  getNotificationsByFetchedIds,
  (notifications) =>
    notifications.filter((notification) => !notification.readAt),
);
