import React from 'react';
import { Field } from 'redux-form';
import { spacing } from '../theme';
import Hint from '../Hint';
import Label from './Label';
import Box from '../Box';

const containerSx = {
  marginBottom: 1,
};
const hintWrapper = {
  marginLeft: spacing.m,
  whiteSpace: 'pre-line',
  fontSize: 12,
};

export default function RadioField(props) {
  const { name, value, hint, label, disabled } = props;
  const id = `${name}${value?.toString()}`;

  return (
    <Box sx={containerSx}>
      <Field
        id={id}
        name={name}
        type="radio"
        component="input"
        value={value.toString()}
        disabled={disabled}
      />{' '}
      <Label htmlFor={id} disabled={disabled}>
        {label || name}
      </Label>
      {hint && <Hint style={hintWrapper}>{hint}</Hint>}
    </Box>
  );
}
