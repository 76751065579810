import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DeliveryBox = (props) => {
  const {color = '#fff', ...rest} = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...rest}
    >
      <g id="Group_8812" data-name="Group 8812" transform="translate(-56 -35)">
        <rect
          id="Rectangle_8772"
          data-name="Rectangle 8772"
          width="16"
          height="16"
          transform="translate(56 35)"
          fill="none"
        />
        <g id="Group_8771" data-name="Group 8771" transform="translate(-7 -12)">
          <path
            id="box-solid"
            d="M13.932,5.048,12.548.9A1.309,1.309,0,0,0,11.3,0H7.438V5.25h6.527A1.249,1.249,0,0,0,13.932,5.048ZM6.563,0H2.7A1.309,1.309,0,0,0,1.452.9L.068,5.048a1.249,1.249,0,0,0-.033.2H6.563ZM0,6.125v6.563A1.313,1.313,0,0,0,1.313,14H12.688A1.313,1.313,0,0,0,14,12.688V6.125Z"
            transform="translate(64 48)"
            fill="#1b5c96"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default DeliveryBox;
