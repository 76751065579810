import React from 'react';
import { CardForm } from '../library/forms';
import Page from '../page/Page';
import OrderFormFooter from './OrderFormFooter';

export default function OrderForm(props) {
  const { currentStep, isFirstStep, handlePrevious, order, orderId } = props;
  const showFloatingFooter = order || orderId || !isFirstStep;
  return (
    <Page
      title={isFirstStep ? 'Fazer uma venda' : 'Pedido'}
      headerType={currentStep == 0 ? 'primary' : 'secondary'}
      back={isFirstStep && '/'}
      customBackOnClick={!isFirstStep && handlePrevious}
      footer={showFloatingFooter && OrderFormFooter}
      footerProps={showFloatingFooter && props}
    >
      <CardForm padded={false}>{currentStep}</CardForm>
    </Page>
  );
}
