import { css } from 'glamor';
import React from 'react';
import { spacing } from '../library/theme';
import { Card, Title, Typography } from '../library';

const container = css({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  zIndex: 2,
  marginTop: '-42px',
  height: spacing.xxl,
  marginBottom: spacing.s,
});
const titleWrapper = css({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: spacing.xxs,
  marginTop: spacing.s,
});
const contentWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.xxs,
});
const cardSx = {
  display: 'flex',
  alignItems: 'flex-start',
  minWidth: '312px',
  padding: '0  !important',
  paddingLeft: `${spacing.m} !important`,
};

export default function WeeklySales({ salesAmount, salesQuantity }) {
  return (
    <div className={container}>
      <Card minHeight={spacing.xxl} sx={cardSx}>
        <div className={contentWrapper}>
          <div className={titleWrapper}>
            <Typography weight="bold">Vendas na semana</Typography>
            {!!salesQuantity && (
              <Typography weight="bold" color="#135A94">
                | Sendo {salesQuantity} hoje
              </Typography>
            )}
          </div>
          <Title pb={0} sx={{ fontSize: '24px' }}>
            {salesAmount}
          </Title>
        </div>
      </Card>
    </div>
  );
}
