import React from 'react';
import TextField from '@mui/material/TextField';
import theme, { spacing } from '../theme';
import { styled } from '@mui/material';

export const CustomTextInput = styled(TextField)({
  '& .MuiInputBase-input': {
    height: 48,
    boxSizing: 'border-box',
    borderRadius: '4px !important',
    border: 'none !important',
  },
  '& .MuiOutlinedInput-root': {
    borderWidth: '1px',
    borderRadius: spacing.xxs,
    borderColor: theme.palette.border.main,
    '&:hover fieldset': {
      borderColor: theme.palette.border.hover,
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.primary.active,
    },
  },
});

export default function TextInput(props) {
  const { sx, fluid, hint, ...rest } = props;

  const customSx = {
    width: fluid ? '100%' : 'auto',
  };

  return (
    <CustomTextInput helperText={hint} sx={{ ...customSx, ...sx }} {...rest} />
  );
}
