import * as React from 'react';
import TextInput from './TextInput';

const decoratorStyle = {
  border: '1px solid red',
  height: '200px',
  width: '350px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
};

export default {
  title: 'Library/TextInput',
  component: TextInput,
  argTypes: {},
};

const defaultArgs = {
  placeholder: 'Email',
};

const Template = (args) => (
  <div style={decoratorStyle}>
    <TextInput {...defaultArgs} {...args} />
  </div>
);

export const Fluid = Template.bind({});
Fluid.args = {
  fluid: true,
};

export const HelperText = Template.bind({});
HelperText.args = {
  helperText: 'Use seu melhor email',
};
