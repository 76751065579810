import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const File = (props) => {
  const {color = '#888', ...rest} = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...rest}
    >
      <g
        id="Group_7917"
        data-name="Group 7917"
        transform="translate(19859 19422)"
      >
        <rect
          id="Rectangle_8033"
          data-name="Rectangle 8033"
          width="24"
          height="24"
          transform="translate(-19859 -19422)"
          fill="none"
        />
        <path
          id="file-alt-regular"
          d="M13.5,11.625v1.313a.564.564,0,0,1-.562.563H5.063a.564.564,0,0,1-.562-.562V11.625a.564.564,0,0,1,.563-.562h7.875A.564.564,0,0,1,13.5,11.625ZM12.938,15H5.063a.564.564,0,0,0-.562.563v1.313a.564.564,0,0,0,.563.563h7.875a.564.564,0,0,0,.563-.562V15.563A.564.564,0,0,0,12.938,15ZM18,6.183V21.75A2.251,2.251,0,0,1,15.75,24H2.25A2.251,2.251,0,0,1,0,21.75V2.25A2.251,2.251,0,0,1,2.25,0h9.567a2.25,2.25,0,0,1,1.589.661l3.933,3.933A2.242,2.242,0,0,1,18,6.183Zm-6-3.75V6h3.567L12,2.433ZM15.75,21.75V8.25H10.875A1.122,1.122,0,0,1,9.75,7.125V2.25H2.25v19.5Z"
          transform="translate(-19856 -19422)"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
};

export default File;
