import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { makeIsMobile } from '../../utils/useScreenWidth';
import { fetchBackgroundTasks } from '../../processes/backgroundTaskProcesses';
import OrdersReports from './OrdersReports';
import { useEffect } from 'react';
import {
  getBackgroundTasksInfo,
  getReports,
} from '../../selectors/backgroundTaskSelectors';

function OrdersReportsContainer(props) {
  const { dispatch, currentPage } = props;
  const isMobile = makeIsMobile();

  useEffect(() => {
    fetchBackgroundTasks(dispatch, {
      currentPage,
      filters: { taskTypeEq: 'orders_report' },
      paged: true,
    });
  }, [dispatch, currentPage]);

  const pageChangeHandler = useCallback(
    (_, currentPage = 1) => {
      dispatch({
        type: 'BACKGROUND_TASKS_PAGE_CHANGED',
        currentPage,
      });
    },
    [dispatch],
  );

  return (
    <OrdersReports
      onPageChange={pageChangeHandler}
      isMobile={isMobile}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const { currentPage, totalPages, totalCount, loading } =
    getBackgroundTasksInfo(state);

  return {
    reports: getReports(state),
    loading,
    currentPage,
    totalPages,
    totalCount,
  };
}

export default connect(mapStateToProps)(OrdersReportsContainer);
