import React, {useCallback, useState} from 'react';
import TextInput from './TextInput';
import InputAdornment from '@mui/material/InputAdornment';
import Eye from '../icons/Eye';
import theme from '../theme';

const inputAddornmentSx = {
  ':hover': {
    cursor: 'pointer',
  },
};

export default function PasswordInput(props) {
  const [showPassword, setShowPassword] = useState(false);

  const toogleVisibility = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleMouseDownPassword = useCallback((event) => {
    event.preventDefault();
  });

  return (
    <TextInput
      InputProps={{
        endAdornment: (
          <InputAdornment sx={inputAddornmentSx} position="end">
            <Eye
              color={
                showPassword
                  ? theme.palette.primary.active
                  : theme.palette.primary.main
              }
              onClick={toogleVisibility}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            />
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
      {...props}
    />
  );
}
