import React, { useCallback, useEffect, useState } from 'react';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { connect } from 'react-redux';
import {
  fetchNotifications,
  notificationFetched,
} from '../../processes/notificationProcesses';
import {
  getNotificationPageInfo,
  getUnreadNotifications,
} from '../../selectors/notificationSelectors';
import Notifications from './Notifications';

function NotificationsContainer(props) {
  const { dispatch } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    fetchNotifications(dispatch, { paged: true });
  }, [dispatch]);

  const onOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [dispatch]);

  const onReceived = useCallback(
    (payload) => {
      notificationFetched(dispatch, payload?.data);
    },
    [dispatch],
  );

  return (
    <>
      <ActionCableConsumer
        channel={{ channel: 'NotificationsChannel' }}
        onReceived={onReceived}
      />
      <Notifications
        {...props}
        onOpen={onOpen}
        onClose={onClose}
        open={open}
        anchorEl={anchorEl}
      />
    </>
  );
}

function mapStateToProps(state) {
  const { loading, currentPage, totalPages, unreadCount } =
    getNotificationPageInfo(state);

  return {
    notifications: getUnreadNotifications(state),
    unreadCount,
    loading,
  };
}

export default connect(mapStateToProps)(NotificationsContainer);
