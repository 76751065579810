import React from 'react';
import Alert from '../Alert';

const formStyle = {
  width: '100%',
};
const errorSx = {
  mb: 2,
};

export default function Form({
  children,
  style,
  touched,
  error = [],
  handleSubmit,
}) {
  const errors = Array.isArray(error) ? error : [error];

  return (
    <>
      {errors.map((e) => (
        <Alert sx={errorSx} severity="error">
          {e}
        </Alert>
      ))}
      <form
        onSubmit={handleSubmit}
        style={{ ...formStyle, ...style }}
        error={touched && error}
      >
        {children}
      </form>
    </>
  );
}
