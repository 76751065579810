import React, { useCallback, useEffect } from 'react';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { fetchCustomers } from '../../processes/customerProcessess';
import {
  getActiveCustomersByFetchedIds,
  getCustomerPageInfo,
} from '../../selectors/customerSelectors';
import CustomersPage from './CustomersPage';
import { formValueSelector } from 'redux-form';
import { getCurrencyById } from '../../selectors/configSelectors';
import { getCurrentUserCompany } from '../../selectors/userSelectors';
import { makeIsMobile } from '../../utils/useScreenWidth';

const FORM_NAME = 'customerSearch';

function CustomersPageContainer(props) {
  const { dispatch, currentPage, filters } = props;
  const isMobile = makeIsMobile();

  useEffect(() => {
    fetchCustomers(dispatch, {
      currentPage,
      filters,
      paged: true,
      withAmountSpent: true,
    });
  }, [dispatch, currentPage, filters]);

  const onAdd = useCallback(() => {
    dispatch(replace('/customers/new'));
  }, [dispatch]);

  const pageChangeHandler = useCallback(
    (_, currentPage = 1) => {
      dispatch({
        type: 'CUSTOMERS_PAGE_CHANGED',
        currentPage,
      });
    },
    [dispatch],
  );

  return (
    <CustomersPage
      onAdd={onAdd}
      onPageChange={pageChangeHandler}
      isMobile={isMobile}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const formSelector = formValueSelector(FORM_NAME);
  const nameCont = formSelector(state, 'nameCont');
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);
  const { currentPage, totalPages, totalCount, filters, loading } =
    getCustomerPageInfo(state);

  return {
    customers: getActiveCustomersByFetchedIds(state),
    currency,
    nameCont,
    loading,
    currentPage,
    totalPages,
    totalCount,
    filters,
  };
}

export default connect(mapStateToProps)(CustomersPageContainer);
