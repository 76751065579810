import { normalize, schema } from 'normalizr';
import { apiDel, apiGet, apiPost, apiPut } from './helpers/api';
import { replace } from 'connected-react-router';
import { updateFile } from './helpers/updateFile';
import toQueryString from '../utils/toQueryString';
import { notifySubmitSucceeded } from './notifierProcesses';

const product = new schema.Entity('products', {
  meta: {
    productsSupplies: [new schema.Entity('productsSupplies')],
    productsRecipes: [new schema.Entity('productsRecipes')],
    productsBundles: [new schema.Entity('productsBundles')],
  },
});

const updatedProduct = (dispatch, response) => {
  dispatch({
    type: 'PRODUCT_UPDATED',
    ...normalize(response.body.data, product),
  });
};

export const fetchProducts = (dispatch, props = {}) => {
  const { currentPage = 1, betweenDates } = props;
  const pageParams = props?.paged == true ? `page=${currentPage}` : '';
  const params = {
    isDeletedEq: props?.paged ? false : [true, false],
    betweenDates: betweenDates,
    ...props?.filters,
  };
  const filterParams = toQueryString(params);

  dispatch({ type: 'PRODUCTS_FETCH_REQUESTED' });

  return apiGet(`/api/v1/products?${filterParams}${pageParams}`).then(
    (response) => {
      dispatch({
        type: 'PRODUCTS_FETCHED',
        ...normalize(response.body.data, new schema.Array(product)),
        totalPages: response.body.totalPages,
        currentPage: response.body.currentPage,
      });
    },
  );
};

export const fetchProduct = (dispatch, id) => {
  return apiGet(`/api/v1/products/${id}`).then((response) => {
    dispatch({
      type: 'PRODUCT_FETCHED',
      ...normalize(response.body.data, product),
    });
  });
};

export const createOrUpdateProduct = (values, dispatch, props) => {
  const handler = !values.id ? createProduct : updateProduct;
  return handler(values, dispatch, props);
};

const createProduct = (values, dispatch, props) => {
  const onModal = !!props?.closeCreateModal;
  const { imageUrl, ...attrs } = values;
  return apiPost('/api/v1/products')
    .send({ product: attrs })
    .then((response) => {
      dispatch({
        type: 'PRODUCT_CREATED',
        ...normalize(response.body.data, product),
      });
      updateFile({
        file: imageUrl,
        dispatch,
        fieldName: 'product[image]',
        path: `/api/v1/products/${response.body.data.id}`,
        actionFunction: updatedProduct,
      });
      onModal
        ? props.closeCreateModal()
        : dispatch(replace('/catalogs/products'));
      notifySubmitSucceeded(dispatch, 'success', 'Produto adicionado!');
    });
};

export const onlyDeleted = (array) =>
  array.filter((item) => item._destroy == 1);

const metaCleanUp = (
  dispatch,
  { productsSupplies, productsRecipes, productsBundles },
) => {
  onlyDeleted(productsSupplies).map(({ id }) =>
    dispatch({ type: 'PRODUCTS_SUPPLY_DELETED', id }),
  );

  onlyDeleted(productsRecipes).map(({ id }) =>
    dispatch({ type: 'PRODUCTS_RECIPE_DELETED', id }),
  );

  onlyDeleted(productsBundles).map(({ id }) =>
    dispatch({ type: 'PRODUCT_BUNDLE_DELETED', id }),
  );
};

const updateProduct = (values, dispatch, props) => {
  const { imageUrl, ...attrs } = values;
  const productsSupplies = values.decorationsAttributes.concat(
    values.packagingsAttributes,
    values.ingredientsAttributes,
  );
  const productsRecipes = values.productsRecipesAttributes;
  const productsBundles = values.productsBundlesAttributes;

  return apiPut(`/api/v1/products/${props.productId}`)
    .send({ product: attrs })
    .then((response) => {
      updatedProduct(dispatch, response);
      updateFile({
        file: imageUrl,
        dispatch: dispatch,
        fieldName: 'product[image]',
        path: `/api/v1/products/${props.productId}`,
        actionFunction: updatedProduct,
      });
      metaCleanUp(dispatch, {
        productsSupplies,
        productsRecipes,
        productsBundles,
      });
      notifySubmitSucceeded(dispatch, 'success', 'Produto atualizado!');
    });
};

export const deleteProduct = (dispatch, id) =>
  apiDel(`/api/v1/products/${id}`).then(() => {
    dispatch({
      type: 'PRODUCT_DELETED',
      id,
    });
  });

export const duplicateProduct = (dispatch, id) =>
  apiPost(`/api/v1/products/${id}/duplicate`).then(() => {
    fetchProducts(dispatch);
    notifySubmitSucceeded(dispatch, 'success', 'Produto duplicado!');
  });

export const submitSearchFilter = (filters, dispatch) => {
  dispatch({
    type: 'PRODUCTS_FILTERS_CHANGED',
    filters,
  });
};
