import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import { getAllSupplies } from './supplySelectors';

const getProductsSupplies = (state) => state.entities.productsSupplies;
const getProps = (_, props) => props;

function formState(state) {
  return getFormValues('product')(state);
}

const getAllProductsSupplies = createSelector(
  getProductsSupplies,
  (productsSupplies) => Object.values(productsSupplies),
);

export const getProductsSuppliesByProduct = createSelector(
  getAllProductsSupplies,
  getProps,
  (productsSupplies, { productId }) =>
    productsSupplies.filter(
      (productsSupply) => productsSupply.productId == productId,
    ),
);

export const getProductsSuppliesByType = createSelector(
  getAllProductsSupplies,
  getProps,
  getAllSupplies,
  (productsSupplies, props, supplies) => {
    const getSupply = (id) => supplies.find((s) => s.id == id);

    const serialize = (pS) => {
      const supply = getSupply(pS.supplyId);
      return {
        name: supply.name,
        cost: supply.cost,
        ...pS,
      };
    };

    const byProductAndType = (pS) => {
      const fromProduct = pS.productId == props.productId;
      const fromSupply = getSupply(pS.supplyId)?.type == props.type;

      return fromProduct && fromSupply;
    };

    return productsSupplies.filter(byProductAndType).map(serialize);
  },
);

export const getProductSupplies = createSelector(formState, (form) => {
  const ingredients = form?.ingredientsAttributes || [];
  const decorations = form?.decorationsAttributes || [];
  const packagings = form?.packagingsAttributes || [];
  const productSupplies = [].concat(ingredients, decorations, packagings);

  return productSupplies.filter((pS) => !!!pS?._destroy);
});

export const getProductSuppliesCostErrors = createSelector(
  getProductSupplies,
  getAllSupplies,
  (productSupplies, supplies) => {
    const suppliesIds = productSupplies?.map(
      (productSupply) => productSupply?.supplyId,
    );
    const result = supplies?.filter(
      (supply) => supply?.cost <= 0 && suppliesIds.includes(supply?.id),
    );

    return !!result?.length ? result : false;
  },
);

export const getProductsSuppliesTotalCost = createSelector(
  getProductSupplies,
  getAllSupplies,
  (productSupplies, supplies) => {
    const getSupply = (id) => supplies.find((s) => s.id == id);

    return productSupplies
      .map((item) => {
        const supply = getSupply(item?.supplyId);
        return supply?.cost * item?.quantity;
      })
      .reduce((acc, num) => (acc += num), 0);
  },
);

export const getProductSuppliesTotalCost = createSelector(
  getProductsSuppliesByProduct,
  getAllSupplies,
  (productSupplies, supplies) => {
    const getSupply = (id) => supplies.find((s) => s.id == id);

    return productSupplies
      .map((item) => {
        const supply = getSupply(item?.supplyId);
        return supply?.cost * item?.quantity;
      })
      .reduce((acc, num) => (acc += num), 0);
  },
);
