import {SubmissionError} from 'redux-form';
import {schema} from 'normalizr';
import {apiPut} from './helpers/api';
import normalizeObject from '../lib/normalizeObject';

export const user = new schema.Entity('users');

const isFile = (obj) => obj && !!obj.lastModified;

export const updateCompany = (values, dispatch, props) => {
  const {avatarUrl, ...attrs} = values;
  if (isFile(avatarUrl)) updateAvatar(avatarUrl, dispatch);
  return apiPut(`/api/v1/company/${props.companyId ?? null}`)
    .send(normalizeObject(attrs, 'snake'))
    .catch((err) => {
      throw new SubmissionError({
        _error: err.response.body.errors.full_messages[0],
      });
    });
};
