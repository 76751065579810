const initialState = {};

export default function backgroundTasksReducer(state = initialState, action) {
  switch (action.type) {
    case 'BACKGROUND_TASKS_FETCHED':
    case 'BACKGROUND_TASK_FETCHED':
    case 'STARTED_IMPORT_SUPPLIES':
      return { ...state, ...action.entities.backgroundTasks };
    case 'PRICING_GENERATION_STARTED':
      return { ...state, ...action.entities.backgroundTasks, loading: true };
    default:
      return state;
  }
}
