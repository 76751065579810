import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Smartphone = (props) => {
  const {color = '#fff', ...rest} = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...rest}
    >
      <g id="Group_8810" data-name="Group 8810" transform="translate(-56 -97)">
        <rect
          id="Rectangle_8774"
          data-name="Rectangle 8774"
          width="16"
          height="16"
          transform="translate(56 97)"
          fill="none"
        />
        <path
          id="Subtraction_2"
          data-name="Subtraction 2"
          d="M6,14H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H6A2,2,0,0,1,8,2V12A2,2,0,0,1,6,14ZM4,11a1,1,0,1,0,1,1A1,1,0,0,0,4,11ZM2,1A1,1,0,0,0,1,2V9a1,1,0,0,0,1,1H6A1,1,0,0,0,7,9V2A1,1,0,0,0,6,1Z"
          transform="translate(60 98)"
          fill="#1b5c96"
        />
      </g>
    </SvgIcon>
  );
};

export default Smartphone;
