import { createSelector } from 'reselect';
import orderByDate from '../utils/orderByDate';

const getInventories = (state) => state.entities.inventories;
const getId = (_, id) => id;
const getInventoriesList = (state) => state.inventoriesList;
const getFetchedIds = (state) => state.inventoriesList.inventoryIds;

export const getInventoryPageInfo = createSelector(
  getInventoriesList,
  (inventoriesList) => ({
    currentPage: inventoriesList.currentPage,
    totalPages: inventoriesList.totalPages,
    filters: inventoriesList.filters,
  }),
);

export const getAllInventories = createSelector(getInventories, (inventories) =>
  Object.values(inventories),
);

export const getInventory = createSelector(
  getInventories,
  getId,
  (inventories, id) => inventories[id],
);

export const getInventoriesBySupplyId = createSelector(
  getAllInventories,
  getId,
  (inventories, supplyId) =>
    inventories.filter((inventory) => inventory.supplyId == supplyId),
);

export const getInventoriesByFetchedIds = createSelector(
  getInventoriesBySupplyId,
  getFetchedIds,
  (inventories, ids) =>
    inventories
      .filter((inventory) => ids.includes(inventory.id))
      .sort(orderByDate('createdAt')),
);
