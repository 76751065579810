import React from 'react';
import { matchPath } from 'react-router-dom';
import Menu from '../library/Menu';
import SidebarItem from '../library/SidebarItem';
import Logo from '../library/images/logo-blue.png';
import Link from '../library/Link';
import Box from '../library/Box';
import { decorationColors, spacing } from '../library/theme';
import { css } from 'glamor';

const containerSX = {
  padding: spacing.s,
};
const menuStyle = {
  marginTop: spacing.m,
};
const logoSx = {
  marginLeft: '20px',
};
const tutorialsClass = css({
  fontWeight: 'bold',
  color: decorationColors.decorationBrand,
});

export default function MainMenu(props) {
  const { location, toggleSidebar, onSignOut, currentUser } = props;
  const currentPath = location.pathname;
  return (
    <Box sx={containerSX}>
      <Link to="/">
        <img src={Logo} width={125} style={logoSx} />
      </Link>
      <Menu style={menuStyle} vertical>
        <SidebarItem
          onClick={toggleSidebar}
          to="/"
          active={!!matchPath(currentPath, { path: '/', exact: true })}
          label="Home"
        />
        <SidebarItem
          onClick={toggleSidebar}
          to="/orders"
          active={!!matchPath(currentPath, { path: '/orders', exact: true })}
          label="Vendas & Pedidos"
        />
        <SidebarItem
          onClick={toggleSidebar}
          to="/customers"
          active={!!matchPath(currentPath, { path: '/customers', exact: true })}
          label="Clientes"
        />
        <SidebarItem
          onClick={toggleSidebar}
          to="/statements"
          active={
            !!matchPath(currentPath, { path: '/statements', exact: true })
          }
          label="Financeiro"
        />
        <SidebarItem
          onClick={toggleSidebar}
          to="/tasks"
          active={!!matchPath(currentPath, { path: '/tasks', exact: true })}
          label="Tarefas"
        />
        {currentUser && currentUser?.isAdmin && (
          <SidebarItem
            onClick={toggleSidebar}
            href="/manager/users"
            active={!!matchPath(currentPath, { path: 'manager', exact: true })}
            label="Ir para o manager"
          />
        )}
        <SidebarItem
          onClick={toggleSidebar}
          to="/pricing"
          active={!!matchPath(currentPath, { path: '/pricing', exact: true })}
          label="Seu Preço"
        />
        <SidebarItem
          onClick={toggleSidebar}
          to="/pricing/calculator"
          active={
            !!matchPath(currentPath, {
              path: '/pricing/calculator',
              exact: true,
            })
          }
          label="Precificação de Produto"
        />
        <SidebarItem
          onClick={toggleSidebar}
          to="/profile/edit"
          active={
            !!matchPath(currentPath, { path: '/profile/edit', exact: true })
          }
          label="Editar meu perfil"
        />
        <SidebarItem
          onClick={toggleSidebar}
          href="https://www.youtube.com/playlist?list=PL2IgIFSCPapXy128NGUPRgqcbAI0c5nO6"
          label="Tutoriais"
          target="_blank"
          className={tutorialsClass}
        />
        <SidebarItem onClick={onSignOut} to="/sign_in" label="Sair" />
      </Menu>
    </Box>
  );
}
