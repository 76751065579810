import React from 'react';
import { Grid, Box, Segment } from '../library';
import { CardForm, DropDownField, Label, TextField } from '../library/forms';
import { makeDropDownOptions } from '../../utils';
import OnboardingNavigation from './OnboardingNavigation';
import WomanWithLaptop from '../library/images/WomanWithLaptop';

const segmentSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  height: 585,
  width: 352,
};
const containerSx = {
  position: 'relative',
};
const imageSx = {
  position: 'absolute',
  top: -130,
  left: 88,
  zIndex: -1,
  ['@media (max-height:750px)']: {
    display: 'none',
  },
};

export default function PersonalForm(props) {
  return (
    <Box sx={containerSx}>
      <Box sx={imageSx}>
        <WomanWithLaptop />,
      </Box>
      <CardForm onSubmit={props.handleSubmit} error={props.error}>
        <Segment fluid sx={segmentSx}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                placeholder="Primeiro Nome"
                label="Como você se chama?"
                fluid
                required
              />
            </Grid>
            <Grid item xs={16}>
              <TextField
                name="companiesAttributes[0][name]"
                label="Como chama sua empresa?"
                placeholder="Empresa"
                fluid
                required
              />
            </Grid>
            <Grid item xs={12}>
              <DropDownField
                name="companiesAttributes[0][companyTimeId]"
                label="Há quanto tempo você trabalha com confeitaria? (Há quanto tempo você já vende seus produtos?)"
                data={makeDropDownOptions(
                  props.companyTimes,
                  'Selecione uma opção',
                )}
                fluid
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Label required>Onde você tem seu negócio?</Label>
              <DropDownField
                name="companiesAttributes[0][countryId]"
                data={makeDropDownOptions(props.countries, 'País')}
                fluid
                required
                hint="Sua escolha de país influenciará no seu fuso horário"
              />
            </Grid>
            <Grid item xs={12}>
              <Label required>Qual moeda você usa?</Label>
              <DropDownField
                name="companiesAttributes[0][currencyId]"
                data={makeDropDownOptions(props.currencies, 'Moeda')}
                fluid
                required
              />
            </Grid>
            {!props?.isForeignUser && (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <DropDownField
                      name="companiesAttributes[0][stateId]"
                      data={makeDropDownOptions(props.states, 'UF')}
                      fluid
                      required={!props?.isForeignUser}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name="companiesAttributes[0][city]"
                      placeholder="Cidade"
                      fluid
                      required={!props?.isForeignUser}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Segment>
        <OnboardingNavigation {...props} />
      </CardForm>
    </Box>
  );
}
