import { toArray } from '../utils/toArray';

const initialState = {
  productIds: [],
  loading: false,
  totalPages: 0,
  currentPage: 1,
  totalCount: 0,
};

export default function productsListReducer(state = initialState, action) {
  switch (action.type) {
    case 'PRODUCTS_FETCH_REQUESTED':
      return { ...state, loading: true };
    case 'PRODUCTS_FETCHED':
      return {
        ...state,
        loading: false,
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        productIds: action.result
          ? toArray(action.result)
          : initialState.productIds,
      };
    case 'PRODUCTS_PAGE_CHANGED':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case 'PRODUCTS_FILTERS_CHANGED':
      return {
        ...state,
        filters: action.filters,
        currentPage: 1,
      };
    default:
      return state;
  }
}
