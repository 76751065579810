import React from 'react';
import Page from '../page/Page';
import ProductFormContainer from './ProductFormContainer';

export default function ProductPage(props) {
  const productId = props?.match?.params?.productId;
  return (
    <Page
      back="/catalogs/products"
      headerType="secondary"
      title="Produtos"
      offsetHeader={0}
    >
      <ProductFormContainer productId={productId} />
    </Page>
  );
}
