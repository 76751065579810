import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  getCurrentUser,
  getCurrentUserCompany,
} from '../../selectors/userSelectors';
import Dashboard from './Dashboard';
import { getWeeklySummaryInfo } from '../../selectors/eventSelectors';
import { fetchStatements } from '../../processes/statementProcesses';
import moment from 'moment-timezone';
import { toCurrency } from '../../utils';
import { getCurrencyById } from '../../selectors/configSelectors';

function DashboardContainer(props) {
  const { dispatch, weeklySummaryInfo, currency } = props;

  const startOfWeek = moment().startOf('week');

  const sixDaysLater = startOfWeek.clone().add(6, 'days');
  const betweenDates = [startOfWeek.format(), sixDaysLater.format()];

  const valueFormatter = useCallback(
    (value) => toCurrency(value, currency?.code),
    [currency?.code],
  );
  const hasStatementInWeek = useMemo(() => {
    return weeklySummaryInfo.some((info) => info.some((value) => value > 0));
  }, [weeklySummaryInfo]);

  const weeklySummaryData = hasStatementInWeek
    ? [
        { data: weeklySummaryInfo?.[0], label: 'Despesas', valueFormatter },
        { data: weeklySummaryInfo?.[1], label: 'Receitas', valueFormatter },
      ]
    : false;

  useEffect(() => {
    fetchStatements(dispatch, {
      filters: { betweenDates, isDeletedEq: false },
    });
  }, [dispatch]);

  return <Dashboard weeklySummaryData={weeklySummaryData} {...props} />;
}

function mapStateToProps(state) {
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);

  return {
    currentUser: getCurrentUser(state),
    currency,
    weeklySummaryInfo: getWeeklySummaryInfo(state),
  };
}

export default connect(mapStateToProps)(DashboardContainer);
