import React from 'react';
import { css } from 'glamor';
import LobbyLayout from '../lobby/LobbyLayout';
import Link from '../library/Link';
import Button from '../library/Button';
import { Form, TextField } from '../library/forms';
import theme from '../library/theme';
import Segment from '../library/Segment';
import Box from '../library/Box';
import PasswordField from '../library/forms/PasswordField';
import Typography from '../library/Typography';

const linkClass = css({
  display: 'flex',
  justifyContent: 'right',
  color: theme.palette.primary.main,
  fontSize: 14,
  paddingTop: theme.spacing(0),
  fontWeight: '500',
  '&:hover': { color: theme.palette.primary.active },
});
const segmentSx = {
  textAlign: 'center',
  minWidth: 350,
};
const signInButtonSx = {
  margin: `${theme.spacing(3)} 0 ${theme.spacing(4)} 0`,
};
const titleSx = {
  marginBottom: 3,
};

export default function SignInForm(props) {
  return (
    <LobbyLayout>
      <Segment sx={segmentSx}>
        <Form onSubmit={props.handleSubmit} error={props.error}>
          <Typography sx={titleSx}>
            Faça o login para acessar sua conta.
          </Typography>
          <TextField
            name="email"
            type="email"
            placeholder="E-mail"
            required
            fluid
          />
          <PasswordField name="password" placeholder="Senha" required fluid />
          <Link to="/forgotten-password" className={linkClass}>
            ESQUECI A SENHA
          </Link>
          <Box sx={signInButtonSx}>
            <Button
              onClick={props.handleSubmit}
              color="primary"
              variant="primary"
              disabled={props.pristine || props.submitting}
              fluid
              type="submit"
            >
              Entrar
            </Button>
          </Box>
        </Form>
      </Segment>
    </LobbyLayout>
  );
}
