import React from 'react';
import Button from '../library/Button';
import Form from '../library/forms/Form';
import TextField from '../library/forms/TextField';
import PasswordField from '../library/forms/PasswordField';
import { spacing } from '../library/theme';
import { Card, CardContent } from '@mui/material';
import ImageField from '../library/forms/ImageField';
import Box from '../library/Box';
import Typography from '../library/Typography';
import { DropDownField, CheckBoxField } from '../library/forms';
import { makeDropDownOptions } from '../../utils';
import Page from '../page/Page';

const buttonMargin = {
  mt: spacing.s,
  mb: spacing.m,
};
const formWrapper = {
  mt: spacing.s,
};
const checkboxWrapper = {
  marginTop: spacing.xs,
};

export default function ProfileEditForm({
  currentUser,
  handleSubmit,
  passwordConfirmRequired,
  error,
  emailNotificationsEnabled,
  timezones,
  printerSizes,
}) {
  return (
    <Page title="Editar Perfil" back="/" headerType="secondary">
      <Box style={{ marginTop: spacing.m }}>
        <ImageField name="avatarUrl" />
        <Typography variant="h3" align="center">
          {currentUser?.name}
        </Typography>
      </Box>
      <Box sx={formWrapper}>
        <Form handleSubmit={handleSubmit} error={error}>
          <Card fluid>
            <CardContent>
              <TextField
                name="name"
                label="Nome"
                placeholder="Novo nome de usuário..."
                required
                fluid
              />
              <TextField
                name="email"
                type="email"
                label="E-mail"
                placeholder="Novo e-mail do usuário..."
                disabled
                required
                fluid
              />
              {timezones && (
                <DropDownField
                  name="companiesAttributes[0][timezone]"
                  label="Fuso horário atual"
                  data={makeDropDownOptions(timezones)}
                  fluid
                />
              )}
              <DropDownField
                name="companiesAttributes[0][printerSize]"
                label="Tipo de Impressora Térmica"
                data={makeDropDownOptions(printerSizes)}
                fluid
              />
              <PasswordField
                name="password"
                label="Nova senha"
                placeholder="*******"
                autoComplete="new-password"
                fluid
              />
              <PasswordField
                name="passwordConfirmation"
                label="Confirme a nova senha"
                placeholder="*******"
                required={passwordConfirmRequired}
                fluid
              />
              <PasswordField
                name="currentPassword"
                label="Senha atual"
                hint="É necessário digitar a senha atual para confirmar. Ao atualizar a senha, você será deslogado e redirecionado para a tela de login."
                placeholder="*******"
                required={passwordConfirmRequired}
                fluid
              />
              <div style={checkboxWrapper}>
                <CheckBoxField
                  name="emailNotificationsEnabled"
                  label="Habilitar notificações por e-mail?"
                  checked={emailNotificationsEnabled}
                />
              </div>
            </CardContent>
          </Card>
          <Box sx={buttonMargin}>
            <Button type="submit" fluid color="success">
              Atualizar meu perfil
            </Button>
          </Box>
        </Form>
      </Box>
    </Page>
  );
}
