import React from 'react';
import Box from '../Box';
import { spacing } from '../theme';
import Form from './Form';

const containerSx = {
  flex: 1,
};
const paddedStyle = {
  padding: spacing.m,
};

export default function CardForm(props) {
  const { padded = true, children, ...rest } = props;
  return (
    <Box sx={{ ...containerSx, ...(padded && paddedStyle) }}>
      <Form {...rest}>{children}</Form>
    </Box>
  );
}
