import React from 'react';
import Page from '../page/Page';
import RevenueFormContainer from './RevenueFormContainer';

export default function RevenuePage(props) {
  const revenueId = props?.match?.params?.statementId;
  return (
    <Page
      back="/statements/revenues"
      headerType="secondary"
      title="Recebimentos"
      offsetHeader={0}
    >
      <RevenueFormContainer statementId={revenueId} />
    </Page>
  );
}
