import React from 'react';
import FieldBlock from './FieldBlock';
import Hint from '../Hint';
import FieldMessage from './FieldMessage';
import { spacing } from '../theme';

const container = { display: 'flex', marginTop: spacing.m };
const requiredStyle = {
  marginTop: spacing.xxs,
};
const inputStyle = {
  paddingTop: spacing.xxs,
};
const labelClass = {
  display: 'flex',
  alignItems: 'center',
};

export default (InputComponent) => (props) => {
  const { input, style, label, hint, meta, customLabel, ...rest } = props;
  const id = input.id || input.name + input.value;
  const error = meta.touched ? meta.error : undefined;
  const errorMsg = error
    ? (error == 'required' && 'Campo obrigatório') || error
    : '';

  return (
    <div style={container}>
      <FieldBlock style={style}>
        <InputComponent
          id={id}
          label=" "
          style={inputStyle}
          {...input}
          {...rest}
        />
        {hint && <Hint>{hint}</Hint>}
      </FieldBlock>
      <div style={labelClass}>
        {label}
        {errorMsg && <FieldMessage style={requiredStyle} message={errorMsg} />}
      </div>
    </div>
  );
};
