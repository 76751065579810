import React from 'react';
import EmptyState from '../library/EmptyState';
import ExpenseListItemContainer from './ExpenseListItemContainer';
import DefaultIcon from '../library/icons/DefaultIcon';

export default function ExpensesList({ expenses }) {
  return expenses.length ? (
    <>
      {expenses.map((expense) => (
        <ExpenseListItemContainer key={expense.id} expense={expense} />
      ))}
    </>
  ) : (
    <EmptyState
      iconUrl={DefaultIcon}
      title="Nenhum pagamento cadastrado"
      cta="/statements/expenses/new"
      ctaLabel="Cadastrar pagamento"
    />
  );
}
