import React from 'react';
import Image from '../library/Image';
import Logo from '../library/images/logo-with-slogan-inverse.png';
import { spacing, uiColors } from '../library/theme';
import LobbyBackground from './LobbyBackground.png';
import Box from '../library/Box';

const containerSx = {
  flex: 1,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: uiColors.fieldBackground,
  backgroundImage: `url(${LobbyBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  backgroundSize: 'contain 100%',
  margin: '0 !important',
};
const logoStyle = { paddingBottom: spacing.xl, width: 180 };

export default function LobbyLayout({ children }) {
  return (
    <Box sx={containerSx}>
      <Box>
        <Image src={Logo} style={logoStyle} centered />
        {children}
      </Box>
    </Box>
  );
}
