import React from 'react';
import Box from './Box';
import Grid from './Grid';
import { spacing, utilityColors } from './theme';
import Typography from './Typography';
import ResultListItem from './ResultListItem';

const containerSx = {
  marginTop: spacing.s,
  borderRadius: '10px',
  padding: '13px 16px',
  backgroundColor: `${utilityColors.information}`,
  color: utilityColors.information,
};
const typographyColor = {
  color: '#ffffff',
};

export default function ResultInfo({ resultData }) {
  const {
    currency,
    result,
    cost,
    price,
    strategicRole,
    averageCost,
    averagePrice,
    averageProfitMargin,
  } = resultData;

  return (
    <Box sx={containerSx}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" weight="bolder" sx={typographyColor}>
              Resultado
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ResultListItem
              label="Custo final do produto:"
              value={cost}
              toBRL
              currency={currency}
            />
          </Grid>
          <Grid item xs={12}>
            <ResultListItem
              label="Preço final do produto:"
              value={price}
              toBRL
              currency={currency}
              separated
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" weight="bolder" sx={typographyColor}>
              Insights da Maya
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ResultListItem
              label="O custo médio de produtos similares no mercado é de:"
              value={averageCost}
              toBRL
            />
          </Grid>
          <Grid item xs={12}>
            <ResultListItem
              label="O preço médio de produtos similares no mercado é de:"
              value={averagePrice}
              toBRL
            />
          </Grid>
          <Grid item xs={12}>
            <ResultListItem
              label="A margem de lucro média de produtos similares no mercado é de:"
              value={averageProfitMargin}
              toPercentage
            />
          </Grid>
          <Grid item xs={12}>
            <ResultListItem
              label="Baseado no seu perfil em relação ao mercado, leve em consideração	
              a sugestão:"
              value={result}
            />
          </Grid>
          <Grid item xs={12}>
            <ResultListItem
              label="Baseado na função estratégica do seu produto:"
              value={strategicRole}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
