import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import TaskListItem from './TaskListItem';
import { deleteTask } from '../../processes/taskProcesses';

function TaskListItemContainer(props) {
  const { task, dispatch } = props;

  if (!task) return null;

  const onEdit = useCallback(
    () => dispatch(push(`/tasks/${task.id}/edit`)),
    [task.id, dispatch],
  );
  const onDelete = useCallback(() => deleteTask(dispatch, task?.id), [task.id]);

  return <TaskListItem onEdit={onEdit} onDelete={onDelete} {...props} />;
}

export default connect()(TaskListItemContainer);
