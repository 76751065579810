import { connect } from 'react-redux';
import PricingProduct from './PricingProduct';
import { getProduct } from '../../selectors/productSelectors';
import { getConfigById } from '../../selectors/configSelectors';

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.product;
  const productData = getProduct(state, id);

  return {
    productData,
    productCategory: getConfigById(
      state,
      'productCategories',
      productData?.productCategoryId,
    ),
  };
}

export default connect(mapStateToProps)(PricingProduct);
