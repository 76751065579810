import React from 'react';
import GeneralStatementsDataContainer from './GeneralStatementsDataContainer';
import RevenuesDataContainer from './RevenuesDataContainer';
import ExpensesDataContainer from './ExpensesDataContainer';
import Box from '../library/Box';
import EmptyState from '../library/EmptyState';
import DefaultIcon from '../library/icons/DefaultIcon';
import Spinner from '../library/Spinner';
import StatementSearchFormContainer from './StatementSearchFormContainer';

const containerSx = {
  pb: 4,
};
const loadingSx = {
  textAlign: 'center',
};

export default function Statements(props) {
  return (
    <Box sx={containerSx}>
      <StatementSearchFormContainer form="statementSearch" />
      {props.loading ? (
        <Box pt={6} sx={loadingSx}>
          <Spinner inline />
        </Box>
      ) : (
        <>
          {props.empty && (
            <EmptyState
              iconUrl={DefaultIcon}
              title="Nenhuma movimentação cadastrada"
            />
          )}
          {!props.empty && (
            <>
              <GeneralStatementsDataContainer />
              <RevenuesDataContainer />
              <ExpensesDataContainer />
            </>
          )}
        </>
      )}
    </Box>
  );
}
