import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import SignUpForm from './SignUpForm';
import {signUp} from '../../processes/profileProcesses';

function mapStateToProps() {
  return {
    initialValues: {},
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'signUpForm',
    onSubmit: signUp,
  })(SignUpForm),
);
