import React from 'react';
import Page from '../page/Page';
import CustomerFormContainer from './CustomerFormContainer';

export default function SupplyPage(props) {
  const customerId = props?.match?.params?.customerId;
  return (
    <Page
      back="/customers"
      headerType="secondary"
      title="Clientes"
      offsetHeader={0}
    >
      <CustomerFormContainer customerId={customerId} />
    </Page>
  );
}
