import React from 'react';
import { Box, Title, Typography } from '../library';
import { spacing } from '../library/theme';

const container = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: spacing.xl,
  textAlign: 'center',
};
const boxWrapper = {
  margin: `${spacing.m} 0`,
  textAlign: 'center',
};

const typographySx = {
  fontWeight: 'normal',
  marginBottom: spacing.m,
};

export default function EmptyPricing() {
  return (
    <div style={container}>
      <Box sx={boxWrapper}>
        <Title>Nenhum cálculo encontrado</Title>
        <Typography sx={typographySx} align="center">
          Nenhum cálculo foi realizado nas últimas 24 horas, por favor,
          recalcule o Seu Preço.
        </Typography>
      </Box>
    </div>
  );
}
