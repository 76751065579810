import { css } from 'glamor';
import React from 'react';
import { spacing } from '../library/theme';
import { Card, Title, Typography } from '../library';
import MoneyReceive from '../library/icons/MoneyReceive';
import { makeIsMobile } from '../../utils/useScreenWidth';

const contentWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.xxs,
});
const cardContentWrapper = css({
  display: 'flex',
  flexDirection: 'row',
  gap: spacing.m,
  alignItems: 'center',
});
const iconWrapperStyle = css({
  borderRadius: '8px',
  display: 'flex',
  width: '44px',
  height: '44px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#327FF31A',
});

export default function FutureRevenuesCard({ amount, title, description }) {
  const isMobile = makeIsMobile();

  const cardSx = {
    display: 'flex',
    alignItems: 'flex-start',
    minWidth: '287px',
    minHeight: isMobile ? '95px' : '87px',
    padding: '0  !important',
    paddingLeft: `${spacing.m} !important`,
  };

  const titleWrapper = css({
    display: 'flex',
    justifyContent: 'flex-start',
    gap: spacing.xxs,
    marginTop: spacing.s,
    flexDirection: isMobile ? 'column' : 'row',
  });

  return (
    <>
      <Card minHeight={spacing.xxl} sx={cardSx}>
        <div className={cardContentWrapper}>
          <div className={iconWrapperStyle}>
            <MoneyReceive fontSize="large" />
          </div>
          <div className={contentWrapper}>
            <div className={titleWrapper}>
              <Typography weight="bold">{title}</Typography>
              {!!description && (
                <Typography weight="bold" color="#135A94">
                  {isMobile ? description : `| ${description}`}
                </Typography>
              )}
            </div>
            <Title pb={0} sx={{ fontSize: '24px' }}>
              {amount}
            </Title>
          </div>
        </div>
      </Card>
    </>
  );
}
