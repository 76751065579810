import React from 'react';
import EmptyState from '../library/EmptyState';
import Page from '../page/Page';
import TaskListItemContainer from './TaskListItemContainer';
import SupplyIcon from '../library/images/supply.png';
import TaskSearchFormContainer from './TaskSearchFormContainer';
import { Separator } from '../library';
import Pagination from '../library/Pagination';

const paginationWrapper = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

export default function TasksPage({
  loading,
  tasks,
  onAdd,
  currentPage,
  totalPages,
  onPageChange,
  isMobile,
}) {
  return (
    <Page back="/" title="Tarefas" headerType="secondary">
      <TaskSearchFormContainer onAddButton={onAdd} />
      <Separator />
      {!loading && tasks.length ? (
        <>
          {tasks.map((task) => (
            <TaskListItemContainer key={`task${task.id}`} task={task} />
          ))}
          {totalPages > 1 && (
            <div style={paginationWrapper}>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
                short={isMobile}
              />
            </div>
          )}
        </>
      ) : (
        <EmptyState
          iconUrl={SupplyIcon}
          loading={loading}
          title="Nenhuma tarefa cadastrada"
          cta="/tasks/new"
          ctaLabel="Cadastrar tarefa"
        />
      )}
    </Page>
  );
}
