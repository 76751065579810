import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import OrderListItem from './OrderListItem';
import { deleteOrder, sendOrder } from '../../processes/orderProcesses';
import { getCustomer } from '../../selectors/customerSelectors';
import {
  getOrderItemsPreview,
  getOrderProductsPreview,
} from '../../selectors/orderSelectors';
import { getConfigByName } from '../../selectors/configSelectors';

function OrderListItemContainer(props) {
  const { order, customer, dispatch, budgetUrl } = props;

  if (!order) return null;

  const onEdit = useCallback(
    () => dispatch(push(`/orders/${order.id}/edit`)),
    [order.id, dispatch],
  );
  const onDelete = useCallback(
    () => deleteOrder(dispatch, order?.id),
    [order.id],
  );

  const onWhatsapp = useCallback(
    () => sendOrder(order, customer, budgetUrl),
    [order, customer, budgetUrl],
  );

  const onExport = useCallback(() => {
    return window.open(`/export/orders/${order?.uuid}/export_to_pdf`, '_blank')
      .focus;
  }, [order]);

  const onThermalPrint = useCallback(() => {
    return window.open(`/export/orders/${order?.uuid}/thermal_print`, '_blank')
      .focus;
  }, [order]);

  return (
    <OrderListItem
      onEdit={onEdit}
      onDelete={onDelete}
      onWhatsapp={onWhatsapp}
      onExport={onExport}
      onThermalPrint={onThermalPrint}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps?.order;
  const { customer } = ownProps?.order?.meta;

  return {
    customer: getCustomer(state, customer),
    products: getOrderProductsPreview(state, id),
    items: getOrderItemsPreview(state, id),
    budgetUrl: getConfigByName(state, 'budgetUrl'),
  };
}

export default connect(mapStateToProps)(OrderListItemContainer);
