import React from 'react';
import { css } from 'glamor';
import { spacing, styles, uiColors, utilityColors } from '../library/theme';
import { Typography } from '../library';
import Modal from '../library/Modal';
import OrderListItemContainer from '../orders/OrderListItemContainer';
import TaskListItemContainer from '../tasks/TaskListItemContainer';
import moment from 'moment-timezone';
import RevenueListItemContainer from '../statements/RevenueListItemContainer';
import ExpenseListItemContainer from '../statements/ExpenseListItemContainer';

const container = css({
  paddingLeft: spacing.m,
  paddingTop: spacing.m,
  paddingBottom: spacing.m,
  cursor: 'pointer',
  '&:last-child': {
    marginRight: spacing.m,
  },
});
const wrapper = css({
  backgroundColor: '#FAFAFA',
  position: 'relative',
  borderRadius: '10px',
  padding: spacing.xs,
  paddingLeft: spacing.m,
  paddingRight: spacing.m,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 80,
});
const activeWrapperClass = css({
  backgroundColor: uiColors.background,
});
const badge = css({
  position: 'absolute',
  right: -12,
  top: -12,
  backgroundColor: '#D65050',
  borderRadius: '50%',
  width: 28,
  height: 28,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
const badgeSx = {
  fontSize: 12,
  color: 'white',
  padding: 0,
  margin: 0,
  lineHeight: 0,
};
const days = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];

const eventsModalSx = {
  flex: 1,
  overflowY: 'auto',
  outline: 'none',
  border: 'none',
  '.css-xtcfs2': {
    ':focus-visible': {
      outline: 'none',
    },
  },
};

export default function DailyEvents(props) {
  const { date, events, open, onOpen, onClose } = props;
  const currentDate = moment(date);
  const hasEvents = !!events?.length;
  const wrapperClass = css(wrapper, open && activeWrapperClass);
  const activeColorSx = { ...(open && { color: 'white' }) };
  const daySx = {
    fontSize: 14,
    ...activeColorSx,
  };

  const eventListItem = (event) => {
    switch (event?.type) {
      case 'task':
        return <TaskListItemContainer key={`event-${event.id}`} task={event} />;
      case 'order':
        return (
          <OrderListItemContainer key={`task-${event.id}`} order={event} />
        );
      case 'revenue':
        return (
          <RevenueListItemContainer
            key={`revenue-${event.id}`}
            revenue={event}
          />
        );
      case 'expense':
        return (
          <ExpenseListItemContainer
            key={`expense-${event.id}`}
            expense={event}
          />
        );
    }
  };

  return (
    <>
      <div className={container} onClick={onOpen}>
        <div className={wrapperClass}>
          {hasEvents && (
            <div className={badge}>
              <Typography sx={badgeSx} p={0} weight="bold">
                {events?.length}
              </Typography>
            </div>
          )}
          <div>
            <Typography
              variant="h4"
              fontSize="28px"
              weight="bold"
              p={0}
              sx={activeColorSx}
            >
              {currentDate?.date()}
            </Typography>
          </div>
          <div>
            <Typography variant="p" sx={daySx} p={0}>
              {days[currentDate?.day()]}
            </Typography>
          </div>
        </div>
      </div>
      <Modal
        title="Eventos do Dia"
        open={open}
        onClose={onClose}
        sx={eventsModalSx}
      >
        {!hasEvents && (
          <Typography align="center">Não há eventos para este dia!</Typography>
        )}
        {hasEvents && events?.map((event) => eventListItem(event))}
      </Modal>
    </>
  );
}
