import React from 'react';
import Box from '../Box';
import { spacing } from '../theme';

const cardActions = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  paddingTop: spacing.m,
  flexWrap: 'wrap',
  gap: spacing.xxs,
};

export default function CardActions({ children, ...rest }) {
  return (
    <Box sx={cardActions} {...rest}>
      {children}
    </Box>
  );
}
