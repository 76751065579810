import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateNotification } from '../../processes/notificationProcesses';
import NotificationListItem from './NotificationListItem';

export default function NotificationListItemContainer(props) {
  const { notification } = props;

  const dispatch = useDispatch();

  const readNotification = useCallback(() => {
    updateNotification(dispatch, notification?.id);
  }, [dispatch, notification?.id]);

  return (
    <NotificationListItem readNotification={readNotification} {...props} />
  );
}
