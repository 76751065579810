import React from 'react';

const WomanWithCake = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="461.144"
      height="412.462"
      viewBox="0 0 461.144 412.462"
      {...props}
    >
      <g
        id="Group_7825"
        data-name="Group 7825"
        transform="translate(-470.692 -563.56)"
      >
        <path
          id="Path_2875"
          data-name="Path 2875"
          d="M310.08,160.85c0,83.028-70.955,191.018-150.336,150.336C85.855,273.317,9.409,243.878,9.409,160.85s81-196.017,150.336-150.335C233.5,59.109,310.08,77.822,310.08,160.85"
          transform="translate(621.756 655.979)"
          fill="#ef9456"
        />
        <g
          id="undraw_forgot_password_re_hxwm"
          transform="translate(470.691 563.561)"
        >
          <path
            id="Path_2564"
            data-name="Path 2564"
            d="M442.355,438.368c13.018-22.9,37.849-40.483,64.135-38.789a121.363,121.363,0,0,0-31.919,76.968c-.432,11.039.238,23.362-7.092,31.627-4.56,5.143-11.531,7.634-18.385,8.157s-13.707-.716-20.469-1.949l-1.64.5C426.36,488.552,429.337,461.268,442.355,438.368Z"
            transform="translate(-306.101 -293.087)"
            fill="#f0f0f0"
          />
          <path
            id="Path_2565"
            data-name="Path 2565"
            d="M506.51,399.389a103.735,103.735,0,0,0-46.188,44.045,44.672,44.672,0,0,0-4.89,13.114,25.621,25.621,0,0,0,1.333,13.418c1.444,4.015,3.386,7.955,3.829,12.251a15.687,15.687,0,0,1-4.2,12.123c-3.67,4.112-8.6,6.659-13.5,8.984-5.447,2.581-11.146,5.174-15,9.991-.467.584-1.346-.176-.879-.759,6.712-8.379,18.2-9.954,26.17-16.7,3.718-3.15,6.509-7.439,6.333-12.464-.154-4.395-2.153-8.459-3.65-12.512A27.339,27.339,0,0,1,454.07,457.8a41.3,41.3,0,0,1,4.367-13.254,100.638,100.638,0,0,1,18.507-25.434,105.573,105.573,0,0,1,29.19-20.827c.671-.323,1.043.777.376,1.1Z"
            transform="translate(-306.082 -292.345)"
            fill="#fff"
          />
          <path
            id="Path_2566"
            data-name="Path 2566"
            d="M508.5,465.526a15.563,15.563,0,0,1-4.7-19.6c.34-.665,1.389-.168,1.049.5a14.411,14.411,0,0,0,4.406,18.221C509.866,465.085,509.1,465.961,508.5,465.526Z"
            transform="translate(-351.281 -320.806)"
            fill="#fff"
          />
          <path
            id="Path_2567"
            data-name="Path 2567"
            d="M498.45,556.418a30,30,0,0,0,18.992-10.826c.469-.582,1.348.177.879.759a31.2,31.2,0,0,1-19.786,11.225c-.741.106-.822-1.052-.085-1.158Z"
            transform="translate(-348.766 -380.736)"
            fill="#fff"
          />
          <path
            id="Path_2568"
            data-name="Path 2568"
            d="M573.475,432.475a8.81,8.81,0,0,0,7.874,2.807c.739-.115.819,1.043.085,1.158a9.872,9.872,0,0,1-8.717-3.086.6.6,0,0,1-.06-.819.583.583,0,0,1,.819-.06Z"
            transform="translate(-393.568 -312.83)"
            fill="#fff"
          />
          <path
            id="Path_2569"
            data-name="Path 2569"
            d="M557.266,558.951c-.46.085-.919.17-1.383.264a116.019,116.019,0,0,0-18.139,5.014c-.459.16-.922.329-1.375.5A122.306,122.306,0,0,0,497.9,588.14,118.778,118.778,0,0,0,485.444,601.2c-5.27,6.438-10.471,13.838-17.357,18.036a20.378,20.378,0,0,1-2.217,1.2L426.2,603.98c-.071-.083-.147-.156-.219-.239l-1.614-.585c.18-.254.372-.514.552-.768.1-.148.216-.292.32-.44.072-.1.144-.195.2-.287.024-.033.048-.065.068-.088.059-.092.124-.171.18-.254q1.605-2.182,3.247-4.35c0-.009,0-.009.016-.014,8.366-10.988,17.723-21.374,28.359-30.01.32-.26.643-.529.982-.781a113.341,113.341,0,0,1,15.32-10.363,100.2,100.2,0,0,1,9.088-4.494,83.321,83.321,0,0,1,25.243-6.438c17.343-1.61,35.009,2.344,48.311,13.239C556.6,558.387,556.93,558.663,557.266,558.951Z"
            transform="translate(-304.576 -380.219)"
            fill="#f0f0f0"
          />
          <path
            id="Path_2570"
            data-name="Path 2570"
            d="M557.8,577.088a103.733,103.733,0,0,0-63.4,7.36,44.671,44.671,0,0,0-11.8,7.527,25.621,25.621,0,0,0-7.014,11.516c-1.265,4.075-2.086,8.39-4.319,12.087a15.688,15.688,0,0,1-10.651,7.152c-5.406,1.073-10.874.141-16.191-.957-5.9-1.219-12.015-2.579-17.995-1.057-.725.184-.968-.951-.245-1.135,10.4-2.649,20.527,3.011,30.953,2.419,4.865-.276,9.676-2.02,12.561-6.139,2.523-3.6,3.374-8.051,4.619-12.188a27.339,27.339,0,0,1,6.439-11.516,41.3,41.3,0,0,1,11.467-7.953,100.639,100.639,0,0,1,30.089-9.165,105.573,105.573,0,0,1,35.846.945c.731.146.365,1.249-.361,1.1Z"
            transform="translate(-305.406 -397.89)"
            fill="#fff"
          />
          <path
            id="Path_2571"
            data-name="Path 2571"
            d="M612.228,564.122a15.563,15.563,0,0,1,8.049-18.475c.672-.326,1.211.7.538,1.029a14.411,14.411,0,0,0-7.452,17.2C613.582,564.591,612.446,564.832,612.228,564.122Z"
            transform="translate(-417.017 -380.856)"
            fill="#fff"
          />
          <path
            id="Path_2572"
            data-name="Path 2572"
            d="M550.116,642.436a30,30,0,0,0,21.682,2.791c.725-.182.969.953.245,1.135a31.2,31.2,0,0,1-22.556-2.95C548.831,643.05,549.464,642.077,550.116,642.436Z"
            transform="translate(-379.565 -438.986)"
            fill="#fff"
          />
          <path
            id="Path_2573"
            data-name="Path 2573"
            d="M694.674,575.387a8.81,8.81,0,0,0,4.6,6.982c.66.353.026,1.326-.629.976a9.872,9.872,0,0,1-5.1-7.713.6.6,0,0,1,.445-.69.583.583,0,0,1,.69.445Z"
            transform="translate(-466.254 -398.483)"
            fill="#fff"
          />
          <path
            id="Path_2574"
            data-name="Path 2574"
            d="M301.507,544.819c-5.555-26.861-12.5-35.817-12.5-35.817l-2.653-2.057-3.19-2.478.015-.319-.757-.257-.178-.138-.291-.224-.047.05-.1.1-14.437-4.907-18.315-6.221-8.481-11.024a14.669,14.669,0,0,0-11.735-5.724l-27.086.2a14.669,14.669,0,0,0-9.867,3.914l-17.8,16.514-27.741,13.249-.047-.047-.291.208-4.286,2.049.177.921-2.653,1.914s-6.944,8.333-12.5,33.327c-1.413,6.36-2.109,20-2.36,36.813a6.417,6.417,0,0,0,6.414,6.512H297.46a6.418,6.418,0,0,0,6.414-6.505C303.629,566.582,302.934,551.716,301.507,544.819Z"
            transform="translate(-124.382 -338.942)"
            fill="#577c47"
          />
          <path
            id="Path_2575"
            data-name="Path 2575"
            d="M582.853,191.732a21.736,21.736,0,1,0,0,43.471H744.837a21.736,21.736,0,1,0,0-43.471Z"
            transform="translate(-386.717 -168.307)"
            fill="#e5e5e5"
          />
          <path
            id="Path_2576"
            data-name="Path 2576"
            d="M589.47,202.747a17.337,17.337,0,0,0,0,34.674H751.453a17.337,17.337,0,0,0,0-34.674Z"
            transform="translate(-393.334 -174.923)"
            fill="#fff"
          />
          <circle
            id="ab6171fa-7d69-4734-b81c-8dff60f9761b"
            cx="35.485"
            cy="35.485"
            r="35.485"
            transform="translate(59.684 55.718)"
            fill="#f0b57d"
          />
          <path
            id="Path_2577"
            data-name="Path 2577"
            d="M339.29,444.462q-.227-.5-.454-1c.058,0,.116.01.174.012Z"
            transform="translate(-253.199 -319.511)"
            fill="#2f2e41"
          />
          <path
            id="Path_2578"
            data-name="Path 2578"
            d="M250.454,251.339c1.795-1.432,3.892-2.749,6.187-2.675s4.621,2.146,4.146,4.392A36.466,36.466,0,0,1,304.671,236.6c5.7,2.012,11.3,6.037,12.606,11.942.336,1.516.409,3.177,1.355,4.408a5.032,5.032,0,0,0,5.356,1.357l.056-.017a1.677,1.677,0,0,1,2.026,2.367l-1.614,3.01a12.932,12.932,0,0,0,6.161-.131,1.675,1.675,0,0,1,1.779,2.607,29.2,29.2,0,0,1-23.282,11.967c-6.447-.039-12.961-2.261-19.236-.779a16.709,16.709,0,0,0-11.239,23.457c-1.928-2.108-5.654-1.609-7.626.458s-2.482,5.153-2.284,8c.3,4.359,2.012,8.466,3.812,12.464-15.1-.475-29.372-11.064-34.178-25.386C233.537,277.945,238.6,260.8,250.454,251.339Z"
            transform="translate(-191.757 -194.008)"
            fill="#1b5c94"
          />
          <path
            id="Path_2579"
            data-name="Path 2579"
            d="M87.464,495.253,97.417,549.4l5.972.52Z"
            transform="translate(-52.537 -297.486)"
            opacity="0.2"
          />
          <path
            id="Path_2580"
            data-name="Path 2580"
            d="M338.053,485.026l-9.953,58.2-5.972.559Z"
            transform="translate(-193.493 -291.342)"
            opacity="0.2"
          />
          <path
            id="Path_2581"
            data-name="Path 2581"
            d="M262.541,172.176a3.538,3.538,0,0,1-4.735-2.076,3.593,3.593,0,0,1,1.883-4.9,3.773,3.773,0,0,1,2.852,6.978ZM258.885,161.2l-1.517.634a1.637,1.637,0,0,1-2.079-.762l-.075-.147c-1.633-2.944-1.82-6.523-.553-10.63,1.174-3.685,1.661-6.276.681-8.624-1.133-2.714-3.554-3.565-6.64-2.333a5.641,5.641,0,0,0-2.52,1.426,2.163,2.163,0,0,1-1.566.636,2.091,2.091,0,0,1-1.5-.656,2.135,2.135,0,0,1-.015-2.914,16.586,16.586,0,0,1,5.609-3.851c6.631-2.769,9.97,1.319,11.5,4.978,1.494,3.578.654,6.9-.7,11.2-1.132,3.573-1.066,6.221.22,8.853a1.63,1.63,0,0,1-.849,2.193Z"
            transform="translate(-195.355 -133.069)"
            fill="#ef9456"
          />
          <path
            id="Path_2582"
            data-name="Path 2582"
            d="M655.5,269.787H623.274a.776.776,0,1,1,0-1.553H655.5a.776.776,0,0,1,0,1.553Z"
            transform="translate(-423.587 -213.926)"
            fill="#f0b57d"
          />
          <path
            id="Path_2583"
            data-name="Path 2583"
            d="M757.884,270.435H725.655a.776.776,0,1,1,0-1.553h32.228a.776.776,0,0,1,0,1.553Z"
            transform="translate(-485.084 -214.575)"
            fill="#f0b57d"
          />
          <path
            id="Path_2584"
            data-name="Path 2584"
            d="M860.266,271.083H828.037a.776.776,0,1,1,0-1.553h32.228a.776.776,0,1,1,0,1.553Z"
            transform="translate(-546.583 -215.222)"
            fill="#f0b57d"
          />
          <path
            id="Path_2585"
            data-name="Path 2585"
            d="M962.644,271.731H930.415a.776.776,0,1,1,0-1.553h32.228a.776.776,0,1,1,0,1.553Z"
            transform="translate(-608.078 -215.87)"
            fill="#f0b57d"
          />
          <circle
            id="Ellipse_227"
            data-name="Ellipse 227"
            cx="5.99"
            cy="5.99"
            r="5.99"
            transform="translate(209.741 36.403)"
            fill="#19518e"
          />
          <circle
            id="Ellipse_228"
            data-name="Ellipse 228"
            cx="5.99"
            cy="5.99"
            r="5.99"
            transform="translate(250.871 36.403)"
            fill="#19518e"
          />
          <circle
            id="Ellipse_229"
            data-name="Ellipse 229"
            cx="5.99"
            cy="5.99"
            r="5.99"
            transform="translate(292.002 36.403)"
            fill="#19518e"
          />
          <circle
            id="Ellipse_230"
            data-name="Ellipse 230"
            cx="5.99"
            cy="5.99"
            r="5.99"
            transform="translate(333.133 36.403)"
            fill="#19518e"
          />
        </g>
      </g>
    </svg>
  );
};

export default WomanWithCake;
