import React, { useCallback, useState } from 'react';
import { makeIsMobile } from '../../../utils/useScreenWidth';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { css } from 'glamor';
import Typography from '../Typography';
import { decorationColors } from '../theme';

const mobileWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
const typographySx = {
  fontSize: '0.85rem',
  color: decorationColors.decorationBrand,
};

export default function CardAction(props) {
  const {
    title,
    onClick,
    size = 'small',
    variant = 'text',
    icon,
    confirmationText,
    fluid = true,
    disabled = false,
  } = props;
  const mobile = makeIsMobile();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleButtonClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
    handleTooltipClose;
  }, [onClick]);

  const handleTooltipClose = useCallback(() => {
    setTooltipOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setTooltipOpen(true);
  }, []);

  return mobile ? (
    <span className={mobileWrapper}>
      <Button
        onClick={onClick}
        size={size}
        variant={variant}
        icon={icon}
        fluid={fluid}
        confirmationText={confirmationText}
        disabled={disabled}
      />
      <Typography weight="bold" sx={typographySx}>
        {title}
      </Typography>
    </span>
  ) : (
    <Tooltip
      title={title}
      open={confirmationText && tooltipOpen}
      onClose={confirmationText && handleTooltipClose}
    >
      <Button
        onClick={confirmationText ? handleButtonClick : onClick}
        size={size}
        variant={variant}
        icon={icon}
        fluid={fluid}
        confirmationText={confirmationText}
        onMouseEnter={confirmationText && handleTooltipOpen}
        onMouseLeave={confirmationText && handleTooltipClose}
        disabled={disabled}
      />
    </Tooltip>
  );
}
