const initialState = {};

export default function productsBundlesReducer(state = initialState, action) {
  switch (action.type) {
    case 'PRODUCT_FETCHED':
    case 'PRODUCT_CREATED':
    case 'PRODUCT_UPDATED':
    case 'PRODUCTS_FETCHED':
    case 'PRODUCT_BUNDLE_FETCHED':
      return { ...state, ...action.entities.productsBundles };
    case 'PRODUCT_BUNDLE_DELETED':
      if (state[action.id]) {
        let newState = { ...state };
        delete newState[action.id];
        return newState;
      }
    default:
      return state;
  }
}
