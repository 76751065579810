import React from 'react';
import { currencyMask, makeDropDownOptions } from '../../utils';
import { Grid, Button, Separator } from '../library';
import CardActions from '../library/cards/CardActions';
import {
  CardForm,
  DropDownField,
  TextField,
  DateField,
  SearchDropDownField,
} from '../library/forms';

export default function ExpenseForm(props) {
  const isModal = !!props?.closeStatementModal;
  const invalidPaymentMethod = props?.installmentPaymentMethods.every(
    (id) => props?.paymentMethodId !== id,
  );

  return (
    <CardForm {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField name="title" label="Nome" fluid />
        </Grid>
        <Grid item xs={12}>
          <SearchDropDownField
            name="statementCategoryId"
            label="Categoria"
            options={makeDropDownOptions(
              props.statementCategories,
              'Selecione uma opção',
            )}
            fluid
            required
          />
        </Grid>
        <Grid item xs={12}>
          <DateField name="date" label="Data" fluid required />
        </Grid>
        <Grid item xs={12}>
          <DropDownField
            name="paymentMethodId"
            label="Forma de pagamento"
            data={makeDropDownOptions(
              props.paymentMethods,
              'Selecione uma opção',
            )}
            fluid
            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField name="paidTo" label="Pago a (opcional)" fluid />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="installments"
            label="Número de parcelas"
            type="number"
            disabled={invalidPaymentMethod || props?.statement?.id}
            minValue="1"
            maxValue="18"
            fluid
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Descrição (opcional)"
            multiline={true}
            rows={3}
            fluid
          />
        </Grid>
        <Grid item xs={12}>
          <Separator />
          <TextField
            name="amount"
            label="Valor total a pagar"
            fluid
            required
            {...currencyMask(props?.currency?.symbol)}
          />
          <Separator />
        </Grid>
      </Grid>
      <CardActions>
        <Button
          type="button"
          onClick={props.handleSubmit}
          color="primary"
          size="large"
          disabled={props.pristine || props.submitting}
          fluid
        >
          {props.statement ? 'Atualizar' : 'Adicionar'}
        </Button>
      </CardActions>
    </CardForm>
  );
}
