import React from 'react';

const ManSittingAtBlueHat = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="157.177"
      height="150.999"
      viewBox="0 0 157.177 150.999"
    >
      <g id="Group_8927" data-name="Group 8927" transform="translate(-378.822)">
        <path
          id="Intersection_2"
          data-name="Intersection 2"
          d="M74.169,151C34.181,132.837,0,113.181,0,72.374,0,48.267,12.638,20.034,31.055,0h50.23a20,20,0,0,1,20,20V131a20,20,0,0,1-20,20Z"
          transform="translate(434.715)"
          fill="#f7e4dd"
        />
        <g id="undraw_cooking_lyxy" transform="translate(378.822 11.541)">
          <rect
            id="Rectangle_8050"
            data-name="Rectangle 8050"
            width="5.717"
            height="90.782"
            transform="matrix(0.836, -0.548, 0.548, 0.836, 21.845, 48.863)"
            fill="#f7e4dd"
          />
          <path
            id="Path_3083"
            data-name="Path 3083"
            d="M262.835,167.1,273.9,183.964a1.473,1.473,0,0,1-2.463,1.615l-12.716-19.388a10.5,10.5,0,0,0-5.05.829l13.854,21.124a1.473,1.473,0,1,1-2.463,1.615L251.2,168.636a10.5,10.5,0,0,0-2.773,4.3l12.716,19.388a1.473,1.473,0,0,1-2.463,1.615l-11.061-16.864a26.072,26.072,0,0,0,4.609,15.4c6.245,9.521,16.431,13.879,22.751,9.733s6.382-15.225.138-24.746A26.072,26.072,0,0,0,262.835,167.1Z"
            transform="translate(-247.59 -149.939)"
            fill="#f7e4dd"
          />
          <rect
            id="Rectangle_8051"
            data-name="Rectangle 8051"
            width="5.717"
            height="90.782"
            transform="translate(42.344 35.343)"
            fill="#f7e4dd"
          />
          <ellipse
            id="Ellipse_241"
            data-name="Ellipse 241"
            cx="13.687"
            cy="20.617"
            rx="13.687"
            ry="20.617"
            transform="translate(31.429)"
            fill="#f7e4dd"
          />
          <ellipse
            id="Ellipse_242"
            data-name="Ellipse 242"
            cx="11.261"
            cy="16.963"
            rx="11.261"
            ry="16.963"
            transform="translate(33.854 3.654)"
            fill="#f7e4dd"
          />
          <path
            id="Path_3084"
            data-name="Path 3084"
            d="M459.543,639.5a201.453,201.453,0,0,0-71.471,0A2.607,2.607,0,0,1,385,636.937v-32.38h77.615v32.38a2.607,2.607,0,0,1-3.072,2.563Z"
            transform="translate(-361.194 -512.389)"
            fill="#1b5c96"
          />
          <path
            id="Path_3085"
            data-name="Path 3085"
            d="M400.975,384.709a16.088,16.088,0,0,0-5.266.881,31.021,31.021,0,0,0-56.209-.284,16.113,16.113,0,1,0,2.108,30.275,31.016,31.016,0,0,0,52.1-.375,16.113,16.113,0,1,0,7.263-30.5Z"
            transform="translate(-306.657 -316.449)"
            fill="#1b5c96"
          />
          <path
            id="Path_3086"
            data-name="Path 3086"
            d="M419.346,482.91H419a30.908,30.908,0,0,1,2.988-13.338l.313.148A30.563,30.563,0,0,0,419.346,482.91Z"
            transform="translate(-389.303 -400.79)"
            fill="#3f3d56"
          />
          <path
            id="Path_3087"
            data-name="Path 3087"
            d="M763.875,484.27h-.346a30.583,30.583,0,0,0-2.823-12.907l.315-.145A30.927,30.927,0,0,1,763.875,484.27Z"
            transform="translate(-671.809 -402.151)"
            fill="#3f3d56"
          />
          <path
            id="Path_3088"
            data-name="Path 3088"
            d="M384.842,669.372l-.084-.336a171.767,171.767,0,0,1,24.376-3.643c13.32-1.107,33.383-1.375,53.323,3.643l-.085.336c-19.892-5.005-39.911-4.739-53.2-3.634A171.426,171.426,0,0,0,384.842,669.372Z"
            transform="translate(-360.994 -562.136)"
            fill="#3f3d56"
          />
          <path
            id="Path_3089"
            data-name="Path 3089"
            d="M384.842,696.372l-.084-.336a171.773,171.773,0,0,1,24.376-3.643c13.32-1.108,33.383-1.375,53.323,3.643l-.085.336c-19.892-5.005-39.911-4.739-53.2-3.634A171.416,171.416,0,0,0,384.842,696.372Z"
            transform="translate(-360.994 -584.459)"
            fill="#3f3d56"
          />
          <path
            id="Path_3090"
            data-name="Path 3090"
            d="M384.842,723.372l-.084-.336a171.767,171.767,0,0,1,24.376-3.643c13.32-1.107,33.383-1.375,53.323,3.643l-.085.336c-19.892-5.005-39.911-4.739-53.2-3.634A171.409,171.409,0,0,0,384.842,723.372Z"
            transform="translate(-360.994 -606.781)"
            fill="#3f3d56"
          />
          <path
            id="Path_3091"
            data-name="Path 3091"
            d="M643.088,455.281l-4.2-1.427,4.03-20.57-2.771-.252c-2.7,2.073-6.3,2.885-10.914,2.267l7.388,11.166-3.767,2.659-12.018-14.415a3.615,3.615,0,0,1-.838-2.315h0a3.615,3.615,0,0,1,2.614-3.474l12.834-3.7,15.028,1.679c2.272,3.464,2.361,5.873-1.1,6.568Z"
            transform="translate(-555.48 -364.125)"
            fill="#2c462e"
          />
          <path
            id="Path_3092"
            data-name="Path 3092"
            d="M716.106,594.6h0a2.765,2.765,0,0,1-2.4-3.761l2.087-5.366c1.06-2.008,2.515-1.757,4.233,0l.84,2.463a1.59,1.59,0,0,1-.093,1.243l-2.034,3.933A2.765,2.765,0,0,1,716.106,594.6Z"
            transform="translate(-632.795 -495.44)"
            fill="#2c462e"
          />
          <path
            id="Path_3093"
            data-name="Path 3093"
            d="M684.792,554.034h0a2.765,2.765,0,0,1-2.4-3.761l2.087-5.366c1.06-2.008,2.515-1.757,4.233,0l.84,2.463a1.59,1.59,0,0,1-.093,1.243l-2.034,3.933A2.765,2.765,0,0,1,684.792,554.034Z"
            transform="translate(-606.906 -461.904)"
            fill="#2c462e"
          />
          <circle
            id="Ellipse_244"
            data-name="Ellipse 244"
            cx="4.702"
            cy="4.702"
            r="4.702"
            transform="translate(75.854 32.05)"
            fill="#ef9456"
          />
          <path
            id="Path_3094"
            data-name="Path 3094"
            d="M481.46,224.372,475,227.731l-1.343-6.045,4.45-2.771Z"
            transform="translate(-393.6 -180.988)"
            fill="#ef9456"
          />
          <path
            id="Path_3095"
            data-name="Path 3095"
            d="M700.828,329.941c-6.451-1.948-12.3-2.958-16.54-1.427,1.46-4.107-1.235-10.38-4.2-16.791,1.279-1.671,3.317-2.319,5.625-2.6,2.525,1.834,4.286.657,5.625-2.183a14.577,14.577,0,0,1,8.48,2.687A28.97,28.97,0,0,0,700.828,329.941Z"
            transform="translate(-605.161 -266.323)"
            fill="#577c47"
          />
          <path
            id="Path_3096"
            data-name="Path 3096"
            d="M664.053,349.2a10.1,10.1,0,0,0-2.855,2.855,15.664,15.664,0,0,1-3.9-3.237,6.932,6.932,0,0,1-1.27-6.484L658.9,333a4.09,4.09,0,0,1,1.33-1.972l.379-.308,3.778,7.136-3.778,7.052C661.09,346.2,662.5,347.683,664.053,349.2Z"
            transform="translate(-585.009 -285.998)"
            fill="#577c47"
          />
          <path
            id="Path_3097"
            data-name="Path 3097"
            d="M692.1,443.5h0a2.426,2.426,0,0,1-3.407-1.857l-.624-4.275,2.183-1.175,2.77,3.627a2.426,2.426,0,0,1-.922,3.68Z"
            transform="translate(-611.759 -373.197)"
            fill="#ef9456"
          />
          <path
            id="Path_3098"
            data-name="Path 3098"
            d="M690.041,250.935l-.8.024-2.227-3.154a11.888,11.888,0,0,0-7.17,3.433l-.2-1.337a5.042,5.042,0,0,1,3.968-5.686h0a5.042,5.042,0,0,1,5.99,4.1Z"
            transform="translate(-604.742 -214.389)"
            fill="#1b5c96"
          />
          <path
            id="Path_3099"
            data-name="Path 3099"
            d="M686.785,434.587h0a2.426,2.426,0,0,1,1.857-3.407l4.275-.624,1.175,2.183-3.627,2.77a2.426,2.426,0,0,1-3.68-.922Z"
            transform="translate(-610.514 -368.533)"
            fill="#ef9456"
          />
          <path
            id="Path_3100"
            data-name="Path 3100"
            d="M734.9,340.151a47.74,47.74,0,0,0-13.156,3.383,6.431,6.431,0,0,0-1.427-3.778c4.222-1.112,8.053-2.417,10.411-4.45L729.3,324.56l3.106-3.526.536.28a3.578,3.578,0,0,1,1.814,2.277,43.662,43.662,0,0,1,1.494,14.591A1.814,1.814,0,0,1,734.9,340.151Z"
            transform="translate(-638.415 -277.985)"
            fill="#577c47"
          />
          <path
            id="Path_3101"
            data-name="Path 3101"
            d="M275.99,724.05a9.955,9.955,0,0,1-11.347,0,.77.77,0,0,1-.364-.71l1.18-15.343H275l1.348,15.338A.769.769,0,0,1,275.99,724.05Z"
            transform="translate(-261.385 -597.906)"
            fill="#2c462e"
          />
          <ellipse
            id="Ellipse_245"
            data-name="Ellipse 245"
            cx="4.817"
            cy="1.736"
            rx="4.817"
            ry="1.736"
            transform="translate(3.985 108.526)"
            fill="#2c462e"
          />
          <path
            id="Path_3102"
            data-name="Path 3102"
            d="M285.4,692.362q-3.765,2.649-7.29,0v-2.777h7.29Z"
            transform="translate(-272.824 -582.686)"
            fill="#577c47"
          />
          <ellipse
            id="Ellipse_246"
            data-name="Ellipse 246"
            cx="3.645"
            cy="1.389"
            rx="3.645"
            ry="1.389"
            transform="translate(5.288 105.511)"
            fill="#577c47"
          />
          <ellipse
            id="Ellipse_247"
            data-name="Ellipse 247"
            cx="0.347"
            cy="0.174"
            rx="0.347"
            ry="0.174"
            transform="translate(8.759 106.552)"
            fill="#e6e6e6"
          />
          <ellipse
            id="Ellipse_248"
            data-name="Ellipse 248"
            cx="0.347"
            cy="0.174"
            rx="0.347"
            ry="0.174"
            transform="translate(10.398 106.552)"
            fill="#e6e6e6"
          />
          <ellipse
            id="Ellipse_249"
            data-name="Ellipse 249"
            cx="0.347"
            cy="0.174"
            rx="0.347"
            ry="0.174"
            transform="translate(9.579 106.962)"
            fill="#e6e6e6"
          />
          <ellipse
            id="Ellipse_250"
            data-name="Ellipse 250"
            cx="0.347"
            cy="0.174"
            rx="0.347"
            ry="0.174"
            transform="translate(6.301 106.962)"
            fill="#e6e6e6"
          />
          <ellipse
            id="Ellipse_251"
            data-name="Ellipse 251"
            cx="0.347"
            cy="0.174"
            rx="0.347"
            ry="0.174"
            transform="translate(7.12 106.552)"
            fill="#e6e6e6"
          />
          <ellipse
            id="Ellipse_252"
            data-name="Ellipse 252"
            cx="0.347"
            cy="0.174"
            rx="0.347"
            ry="0.174"
            transform="translate(7.94 107.167)"
            fill="#e6e6e6"
          />
          <path
            id="Path_3103"
            data-name="Path 3103"
            d="M880.99,724.05a9.955,9.955,0,0,1-11.347,0,.769.769,0,0,1-.364-.71l1.18-15.343H880l1.348,15.338A.769.769,0,0,1,880.99,724.05Z"
            transform="translate(-761.57 -597.906)"
            fill="#2c462e"
          />
          <ellipse
            id="Ellipse_253"
            data-name="Ellipse 253"
            cx="4.817"
            cy="1.736"
            rx="4.817"
            ry="1.736"
            transform="translate(108.8 108.526)"
            fill="#2c462e"
          />
          <path
            id="Path_3104"
            data-name="Path 3104"
            d="M890.4,692.362q-3.765,2.649-7.29,0v-2.777h7.29Z"
            transform="translate(-773.009 -582.686)"
            fill="#577c47"
          />
          <ellipse
            id="Ellipse_254"
            data-name="Ellipse 254"
            cx="3.645"
            cy="1.389"
            rx="3.645"
            ry="1.389"
            transform="translate(110.103 105.511)"
            fill="#577c47"
          />
          <ellipse
            id="Ellipse_255"
            data-name="Ellipse 255"
            cx="0.347"
            cy="0.174"
            rx="0.347"
            ry="0.174"
            transform="translate(113.575 106.552)"
            fill="#e6e6e6"
          />
          <ellipse
            id="Ellipse_256"
            data-name="Ellipse 256"
            cx="0.347"
            cy="0.174"
            rx="0.347"
            ry="0.174"
            transform="translate(115.214 106.552)"
            fill="#e6e6e6"
          />
          <ellipse
            id="Ellipse_257"
            data-name="Ellipse 257"
            cx="0.347"
            cy="0.174"
            rx="0.347"
            ry="0.174"
            transform="translate(114.394 106.962)"
            fill="#e6e6e6"
          />
          <ellipse
            id="Ellipse_258"
            data-name="Ellipse 258"
            cx="0.347"
            cy="0.174"
            rx="0.347"
            ry="0.174"
            transform="translate(111.116 106.962)"
            fill="#e6e6e6"
          />
          <ellipse
            id="Ellipse_259"
            data-name="Ellipse 259"
            cx="0.347"
            cy="0.174"
            rx="0.347"
            ry="0.174"
            transform="translate(111.936 106.552)"
            fill="#e6e6e6"
          />
          <ellipse
            id="Ellipse_260"
            data-name="Ellipse 260"
            cx="0.347"
            cy="0.174"
            rx="0.347"
            ry="0.174"
            transform="translate(112.755 107.167)"
            fill="#e6e6e6"
          />
        </g>
      </g>
    </svg>
  );
};

export default ManSittingAtBlueHat;
