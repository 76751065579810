import React from 'react';
import Logo from '../library/images/logo-inverse.png';
import theme, { spacing, uiColors } from '../library/theme';
import Link from '../library/Link';
import { GiHamburgerMenu } from 'react-icons/gi';
import Box from '../library/Box';
import Title from '../library/Title';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import NotificationsContainer from '../notifications/NotificationsContainer';
import { Typography } from '../library';
import WeeklySalesContainer from './WeeklySalesContainer';
import { IoMdHome } from 'react-icons/io';

const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
};
const primaryHeaderSx = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: `${spacing.m} ${spacing.xs} ${spacing.m} ${spacing.m}`,
  backgroundColor: uiColors.background,
  borderRadius: '0px 0px 24px 24px',
};
const secondaryHeaderSx = {
  display: 'flex',
  width: '100%',
  fontSize: '1.125rem',
  justifyContent: 'space-between',
  padding: `${spacing.m} ${spacing.m} ${spacing.s} ${spacing.m}`,
  borderBottom: `1px solid ${uiColors.border}`,
  marginBottom: spacing.m,
  alignItems: 'center',
  gap: spacing.xxs,
};
const menuButtonStyle = {
  color: theme.palette.primary.contrastText,
  cursor: 'pointer',
};
const flex = { display: 'flex', alignItems: 'center' };
const titleSx = { ...flex, padding: 0 };
const titleWrapperSx = {
  ...flex,
  flexDirection: 'column',
  justifyContent: 'center',
};
const rightSideWrapperSx = {
  marginTop: '-12px',
};

export default function Header(props) {
  const {
    title,
    back,
    toggleSidebar,
    showMenu,
    greeting,
    headerType = 'primary',
    customBackOnClick,
    noLink,
    showWeeklySales = false,
  } = props;
  const titleColor = headerType == 'primary' ? 'white' : 'primary';

  return (
    <Box sx={containerSx}>
      <div>
        <Box sx={headerType == 'primary' ? primaryHeaderSx : secondaryHeaderSx}>
          {headerType == 'primary' && (
            <Box>
              <GiHamburgerMenu
                onClick={toggleSidebar}
                size="25"
                style={showMenu ? menuButtonStyle : { display: 'none' }}
              />
            </Box>
          )}
          {(back || customBackOnClick) && headerType == 'secondary' && (
            <Box sx={{ cursor: 'pointer' }}>
              <Link to={back} onClick={customBackOnClick}>
                <BackIcon
                  style={
                    showMenu
                      ? { width: '16px', color: '#0B70EC' }
                      : { display: 'none' }
                  }
                />
              </Link>
            </Box>
          )}
          <Box
            sx={{
              ...titleWrapperSx,
              ...(back && { marginTop: '-6px' }),
              ...(headerType == 'primary' && { marginLeft: spacing.m }),
            }}
          >
            {back || customBackOnClick ? (
              <Title
                variant="h5"
                weight="normal"
                color={titleColor}
                p={0}
                sx={titleSx}
              >
                {title}
              </Title>
            ) : (
              <>
                <Link to={!noLink && '/'}>
                  <img src={Logo} width="100px" />
                </Link>
                <Typography
                  variant="h5"
                  color="white"
                  weight="bold"
                  sx={{ marginBottom: '42px !important' }}
                >
                  {greeting}
                </Typography>
              </>
            )}
          </Box>

          <Box sx={rightSideWrapperSx}>
            {headerType == 'primary' && showMenu ? (
              <NotificationsContainer />
            ) : (
              <div
                style={
                  showMenu ? { marginTop: spacing.xs } : { display: 'none' }
                }
              >
                <Link to="/" sx={!showMenu && { display: 'none' }}>
                  <IoMdHome size={27} color="#0B70EC" />
                </Link>
              </div>
            )}
          </Box>
        </Box>
      </div>
      {showWeeklySales && <WeeklySalesContainer />}
    </Box>
  );
}
