import React, { useCallback, useState } from 'react';
import DailyEvents from './DailyEvents';

export default function DailyEventsContainer(props) {
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  return (
    <DailyEvents {...props} onOpen={onOpen} onClose={onClose} open={open} />
  );
}
