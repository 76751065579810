import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ActionCableProvider } from 'react-actioncable-provider';
import NotifierContainer from './notifier/NotifierContainer';
import { sessionValidate } from '../processes/sessionProcesses';
import { getCurrentUser } from '../selectors/userSelectors';
import OnboardingFormContainer from './onboarding/OnboardingFormContainer';
import Spinner from './library/Spinner';
import Box from './library/Box';
import { fetchConfigs } from '../processes/configProcessess';
import PageProvider from './page/PageProvider';
import BackToTop from './library/BackToTop';
import moment from 'moment-timezone';
import 'regenerator-runtime/runtime';

moment.locale('pt-br');

const loaderSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100%',
};

function APP(props) {
  const { dispatch, currentUser } = props;

  useEffect(() => {
    sessionValidate(dispatch);
    fetchConfigs(dispatch);
  }, [dispatch]);

  // useEffect(() => {
  //   if (currentUser && window.userGuiding.identify)
  //     window.userGuiding.identify(currentUser.id, {
  //       email: currentUser.email,
  //       name: currentUser.name,
  //       hasSupply: currentUser.hasSupply,
  //     });
  // }, [currentUser]);

  if (!currentUser) {
    return (
      <Box sx={loaderSx}>
        <Spinner />
      </Box>
    );
  }

  if (currentUser?.onboard === false) {
    return <OnboardingFormContainer />;
  }

  return (
    <ActionCableProvider url={process.env.CABLE_URL}>
      <NotifierContainer />
      {/* <BackToTop /> */}
      <PageProvider>{props.children}</PageProvider>
    </ActionCableProvider>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: getCurrentUser(state),
  };
}

export default connect(mapStateToProps)(APP);
