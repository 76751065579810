import React from 'react';
import { connect } from 'react-redux';
import { getConfigById } from '../../selectors/configSelectors';
import StatementsTableRow from './StatementsTableRow';
import { useCallback, useState } from 'react';
import { deleteStatement } from '../../processes/statementProcesses';

function StatementsTableRowContainer(props) {
  const { statement, dispatch } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openStatementModal, setOpenStatementModal] = useState(false);
  const open = Boolean(anchorEl);

  const onOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [dispatch]);

  const onDelete = useCallback(() => {
    deleteStatement(dispatch, statement);
    onClose();
  }, [statement, onClose]);

  const closeStatementModal = useCallback(() => {
    setOpenStatementModal(false);
    onClose();
  }, [onClose]);

  const onEdit = useCallback(() => setOpenStatementModal(true), []);

  return (
    <StatementsTableRow
      {...props}
      anchorEl={anchorEl}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      onDelete={onDelete}
      onEdit={onEdit}
      openStatementModal={openStatementModal}
      closeStatementModal={closeStatementModal}
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    statementCategory: getConfigById(
      state,
      'statementCategories',
      ownProps.statement.statementCategoryId,
    )?.name,
  };
}

export default connect(mapStateToProps)(StatementsTableRowContainer);
