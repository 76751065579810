import React from "react";
import { css } from "glamor";
import { spacing } from "../theme";

const className = css({
  position: "relative",
  "& + &": {
    marginTop: spacing.m,
  },
});

export default function FieldBlock(props) {
  const { children, ...rest } = props;
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
}
