import React from 'react';

const BlueHairCookMan = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="284.163"
      height="260.919"
      viewBox="0 0 284.163 260.919"
      {...props}
    >
      <g
        id="Group_8904"
        data-name="Group 8904"
        transform="translate(-38.8 -96)"
      >
        <path
          id="Path_2684"
          data-name="Path 2684"
          d="M293.572,112.841c0,58.247-67.059,134.005-142.081,105.465C81.658,191.74,9.409,171.088,9.409,112.841S85.965-24.67,151.49,7.376c69.7,34.091,142.081,47.218,142.081,105.465"
          transform="translate(29.391 132.398)"
          fill="#ef9456"
        />
        <g
          id="Group_7825"
          data-name="Group 7825"
          transform="translate(57.776 96)"
        >
          <g
            id="Group_7826"
            data-name="Group 7826"
            transform="translate(72.223 0)"
          >
            <path
              id="Path_2600"
              data-name="Path 2600"
              d="M446.631,377.022c-.2.834-4.344,38.612-4.344,38.612l-3.755,5.339-1.019,8.172,7.8,19.294-57.721-.2s-.912-10-.834-12.726,1.463-12.726,1.463-12.726l-.132-10.156-2.184-44.268,19.552-6.309c11.814-2.033,29.2,6.826,29.2,6.826Z"
              transform="translate(-353.114 -284.101)"
              fill="#577c47"
            />
            <path
              id="Path_2601"
              data-name="Path 2601"
              d="M628.134,206.124c2.569-.161,4.539-2.306,5.953-4.457A12.692,12.692,0,0,0,636.555,195a15.607,15.607,0,0,0-1.638-6,25.092,25.092,0,0,0-3.023-5.671,8.32,8.32,0,0,0-5.329-3.33"
              transform="translate(-542.116 -141.366)"
              fill="#1b5c96"
            />
            <path
              id="Path_2602"
              data-name="Path 2602"
              d="M493.946,194.237l.029-.639,1.507-36.886.834-2.092,18.45-2.54a10.619,10.619,0,0,1,11.413,9.763q.018.238.026.477c.234,6.714.263,14.92-.673,19.9-1.872,9.986-9.615,11.054-9.615,11.054s-.132,2.618-.278,6.407c-.107,2.857-2.633,11.1-5.5,11.185-1.229.034-4.583-1.034-6.182-1.268-10.088-1.473-10.015-15.359-10.015-15.359"
              transform="translate(-437.963 -119.41)"
              fill="#ffbe9d"
            />
            <path
              id="Path_2603"
              data-name="Path 2603"
              d="M618.939,222.632a1.287,1.287,0,0,1-1.278,1.268,1.234,1.234,0,0,1-1.3-1.168c0-.011,0-.021,0-.031a1.287,1.287,0,0,1,1.277-1.268,1.233,1.233,0,0,1,1.3,1.168c0,.01,0,.021,0,.031"
              transform="translate(-534.106 -173.901)"
              fill="#1b5c96"
            />
            <path
              id="Path_2604"
              data-name="Path 2604"
              d="M616.752,214.2c-.166.166-1.122-.585-2.511-.619s-2.438.629-2.569.454c-.073-.073.1-.376.551-.683a3.514,3.514,0,0,1,2.062-.575,3.413,3.413,0,0,1,1.994.7c.414.341.551.648.473.722"
              transform="translate(-530.407 -167.101)"
              fill="#1b5c96"
            />
            <path
              id="Path_2605"
              data-name="Path 2605"
              d="M555.674,222.019a1.287,1.287,0,0,1-1.278,1.268,1.234,1.234,0,0,1-1.3-1.168c0-.011,0-.021,0-.031a1.287,1.287,0,0,1,1.277-1.268,1.233,1.233,0,0,1,1.3,1.168c0,.01,0,.021,0,.031"
              transform="translate(-484.419 -173.419)"
              fill="#1b5c96"
            />
            <path
              id="Path_2606"
              data-name="Path 2606"
              d="M553.169,213.653c-.166.166-1.126-.585-2.511-.619s-2.438.629-2.57.454c-.073-.073.1-.376.551-.683a3.506,3.506,0,0,1,2.063-.575,3.413,3.413,0,0,1,1.994.7c.414.341.551.648.473.722"
              transform="translate(-480.471 -166.672)"
              fill="#1b5c96"
            />
            <path
              id="Path_2607"
              data-name="Path 2607"
              d="M592.828,225.09a9.22,9.22,0,0,1,2.267-.341c.356-.029.692-.093.761-.332a1.831,1.831,0,0,0-.21-1.058l-.975-2.726c-1.346-3.9-2.316-7.07-2.155-7.123s1.375,3.052,2.726,6.933c.322.975.634,1.868.926,2.74a2.043,2.043,0,0,1,.146,1.4.893.893,0,0,1-.595.487,2.331,2.331,0,0,1-.6.063,8.622,8.622,0,0,1-2.287-.044"
              transform="translate(-515.362 -167.679)"
              fill="#1b5c96"
            />
            <path
              id="Path_2608"
              data-name="Path 2608"
              d="M547.388,329.6a25.61,25.61,0,0,1-13.37-3.964s3.077,7,13.165,6.339Z"
              transform="translate(-469.434 -255.741)"
              fill="#1b5c96"
            />
            <path
              id="Path_2609"
              data-name="Path 2609"
              d="M575.577,279.372a2.5,2.5,0,0,0-2.214-.975,2.3,2.3,0,0,0-1.6.8,1.462,1.462,0,0,0-.151,1.653,1.673,1.673,0,0,0,1.8.488,5.215,5.215,0,0,0,1.8-1.009,1.5,1.5,0,0,0,.4-.4.458.458,0,0,0,0-.517"
              transform="translate(-498.808 -218.631)"
              fill="#fff"
            />
            <path
              id="Path_2610"
              data-name="Path 2610"
              d="M573.955,271.414c.229,0,.19,1.5,1.463,2.6s2.867.975,2.877,1.194c0,.093-.366.283-1.048.283a3.736,3.736,0,0,1-2.4-.912,3.257,3.257,0,0,1-1.117-2.18c-.034-.634.122-1,.224-.99"
              transform="translate(-500.619 -213.155)"
              fill="#1b5c96"
            />
            <path
              id="Path_2611"
              data-name="Path 2611"
              d="M550.951,189.505c-.146.37-1.526.156-3.159.307s-2.965.556-3.169.215c-.093-.166.146-.488.688-.863a5.525,5.525,0,0,1,4.822-.4c.59.258.882.561.819.741"
              transform="translate(-477.747 -147.951)"
              fill="#1b5c96"
            />
            <path
              id="Path_2612"
              data-name="Path 2612"
              d="M616.265,195.131c-.254.312-1.2-.044-2.35-.088s-2.131.151-2.345-.185c-.093-.161.068-.488.488-.761a3.413,3.413,0,0,1,3.784.219c.409.337.536.658.424.814"
              transform="translate(-530.32 -152.065)"
              fill="#1b5c96"
            />
            <path
              id="Path_2613"
              data-name="Path 2613"
              d="M466.452,189.388a18.529,18.529,0,0,0-5.066,4.583,9.4,9.4,0,0,0-2.238,7.134c.551,3.515,3.6,6.012,6.431,8.167,1.628.234,2.842-1.565,3.1-3.194s.029-3.389.8-4.847c.692-1.307,2.057-2.106,2.97-3.272,1.7-2.165,1.585-5.212,1.17-7.933a13.011,13.011,0,0,1,3.5-10.727c0,.034-8.5,3.355-10.659,10.088"
              transform="translate(-410.566 -140.813)"
              fill="#1b5c96"
            />
            <path
              id="Path_2614"
              data-name="Path 2614"
              d="M421.953,232.7a6.047,6.047,0,0,0-.6-7.163,8.345,8.345,0,0,0-7-2.584,7.279,7.279,0,0,0-4.978,2.336,4.6,4.6,0,0,0-.57,5.246c.556.878,1.521,1.707,1.3,2.725s-1.375,1.346-2.194,1.921a4.2,4.2,0,0,0-1.463,4.681,5.226,5.226,0,0,0,3.788,3.3,6.958,6.958,0,0,0,5.042-.907,2.952,2.952,0,0,0,3.238,2.628,5.173,5.173,0,0,0,3.711-2.589,11.337,11.337,0,0,0,1.531-6.339,33.788,33.788,0,0,0-1.009-6.534"
              transform="translate(-369.081 -175.068)"
              fill="#1b5c96"
            />
            <path
              id="Path_2615"
              data-name="Path 2615"
              d="M463.519,246.448a1,1,0,0,1-.229-.146l-.634-.458c-.541-.4-1.326-.975-2.238-1.794a16.263,16.263,0,0,1-2.852-3.169,9.615,9.615,0,0,1-1.672-4.929,6.784,6.784,0,0,1,.424-2.692,6.675,6.675,0,0,1,1.365-2.145,19.5,19.5,0,0,1,3.355-2.628,20.535,20.535,0,0,0,2.267-1.726c.249-.229.434-.415.556-.546a1.235,1.235,0,0,1,.2-.185,1.938,1.938,0,0,1-.161.219,5.46,5.46,0,0,1-.522.58,17.356,17.356,0,0,1-2.233,1.809,20.49,20.49,0,0,0-3.286,2.643,6.4,6.4,0,0,0-1.3,2.072,6.633,6.633,0,0,0-.4,2.594,9.5,9.5,0,0,0,1.6,4.807,16.761,16.761,0,0,0,2.779,3.164c.892.809,1.658,1.419,2.179,1.848l.6.487a1.357,1.357,0,0,1,.215.2"
              transform="translate(-408.072 -177.511)"
              fill="#ef9456"
            />
            <path
              id="Path_2616"
              data-name="Path 2616"
              d="M425.127,249.252c-.044.024-.3-.517-.941-1.2a6.158,6.158,0,0,0-6.67-1.633c-.883.312-1.36.668-1.385.629s.1-.112.317-.268a5.489,5.489,0,0,1,1-.531,6.026,6.026,0,0,1,6.87,1.682,5.143,5.143,0,0,1,.643.936,1.213,1.213,0,0,1,.161.385"
              transform="translate(-376.848 -193.041)"
              fill="#ef9456"
            />
            <path
              id="Path_2617"
              data-name="Path 2617"
              d="M421.583,269.039a1.666,1.666,0,0,1,.809-.9,3.509,3.509,0,0,1,1.248-.449,8.463,8.463,0,0,1,1.6-.092,11.205,11.205,0,0,1,2.867.453,3.788,3.788,0,0,1,1.112.449,10.819,10.819,0,0,1-1.161-.278,14.315,14.315,0,0,0-2.828-.371,5.528,5.528,0,0,0-2.755.444,3.765,3.765,0,0,0-.9.741"
              transform="translate(-381.13 -210.155)"
              fill="#ef9456"
            />
            <path
              id="Path_2618"
              data-name="Path 2618"
              d="M413.779,287.088c0-.044.7-.029,1.794-.244a10.481,10.481,0,0,0,3.964-1.668,10.607,10.607,0,0,0,2.926-3.15c.575-.951.8-1.614.848-1.594a1.818,1.818,0,0,1-.132.488,8.758,8.758,0,0,1-.561,1.214,9.776,9.776,0,0,1-7.016,4.905,8.532,8.532,0,0,1-1.331.1,1.424,1.424,0,0,1-.492-.054"
              transform="translate(-375.003 -220.237)"
              fill="#ef9456"
            />
            <path
              id="Path_2619"
              data-name="Path 2619"
              d="M460.325,198.511a8.367,8.367,0,0,0,.912.6,5.578,5.578,0,0,0,2.857.624,6.617,6.617,0,0,0,1.989-.38,7.366,7.366,0,0,0,2.028-1.156,11.906,11.906,0,0,0,3.208-4.266,43.951,43.951,0,0,0,1.853-5.1,29.274,29.274,0,0,1,1.619-4.154,12.621,12.621,0,0,1,1.624-2.531,5.73,5.73,0,0,1,.556-.6,1.522,1.522,0,0,1,.214-.185s-.263.293-.692.848a13.9,13.9,0,0,0-1.541,2.545,30.6,30.6,0,0,0-1.555,4.149,41.242,41.242,0,0,1-1.848,5.129,11.877,11.877,0,0,1-3.3,4.344,7.419,7.419,0,0,1-2.1,1.166,6.6,6.6,0,0,1-2.058.366,5.454,5.454,0,0,1-2.925-.722,2.442,2.442,0,0,1-.395-.263,1.945,1.945,0,0,1-.268-.22,1.46,1.46,0,0,1-.185-.2"
              transform="translate(-411.559 -142.436)"
              fill="#ef9456"
            />
            <path
              id="Path_2620"
              data-name="Path 2620"
              d="M472.353,219.031c.024,0-.176.293-.4.863a8.611,8.611,0,0,0-.487,2.531,21.134,21.134,0,0,1-.434,3.862,8.081,8.081,0,0,1-2.555,3.993,7.739,7.739,0,0,1-4.354,1.877,8.2,8.2,0,0,1-3.837-.59,7.735,7.735,0,0,1-2.223-1.385,5.258,5.258,0,0,1-.488-.487,1.11,1.11,0,0,1-.156-.2c.024,0,.244.244.722.624a8.089,8.089,0,0,0,2.228,1.3,8.174,8.174,0,0,0,3.75.522,7.562,7.562,0,0,0,4.208-1.838,7.924,7.924,0,0,0,2.5-3.862,22.324,22.324,0,0,0,.488-3.813,8.213,8.213,0,0,1,.575-2.555,4.708,4.708,0,0,1,.341-.629,1.08,1.08,0,0,1,.127-.215"
              transform="translate(-409.276 -172.016)"
              fill="#ef9456"
            />
            <path
              id="Path_2621"
              data-name="Path 2621"
              d="M648.466,227.311a5.936,5.936,0,0,0,.424-.449,4.5,4.5,0,0,0,.853-1.434,5.85,5.85,0,0,0,.346-2.438,7.954,7.954,0,0,0-.834-2.926,27.423,27.423,0,0,0-3.164-4.588,12.775,12.775,0,0,1-.946-1.424,2.525,2.525,0,0,1-.273-.566,3.2,3.2,0,0,1,.356.517c.22.332.556.8,1,1.36a23.7,23.7,0,0,1,3.252,4.588,7.886,7.886,0,0,1,.839,3,5.818,5.818,0,0,1-.41,2.516,4.316,4.316,0,0,1-.975,1.434,3.14,3.14,0,0,1-.356.3c-.063.088-.112.117-.117.107"
              transform="translate(-556.495 -167.662)"
              fill="#ef9456"
            />
            <path
              id="Path_2622"
              data-name="Path 2622"
              d="M647.041,242.639a7.34,7.34,0,0,0,1.2-1.746,5.525,5.525,0,0,0,.488-2.223,6.912,6.912,0,0,0-.566-2.735,23.87,23.87,0,0,0-1.316-2.526c-.424-.77-.79-1.463-1.087-2.072s-.522-1.082-.668-1.424a2.251,2.251,0,0,1-.2-.546,3.12,3.12,0,0,1,.288.488l.741,1.385c.317.585.7,1.278,1.131,2.043a21.192,21.192,0,0,1,1.346,2.54,6.936,6.936,0,0,1,.565,2.838,5.626,5.626,0,0,1-.551,2.3,5.027,5.027,0,0,1-.922,1.3,4.075,4.075,0,0,1-.322.288c-.083.073-.127.1-.132.1"
              transform="translate(-556.513 -180.133)"
              fill="#ef9456"
            />
            <path
              id="Path_2623"
              data-name="Path 2623"
              d="M491.265,49.865c3.34-5.7,8.011-11,14.437-12.536a23.658,23.658,0,0,1,18.528,3.106V29.2L516.21,3.3,509.964,0,495.2,8.444l-3.818,31.142Z"
              transform="translate(-435.858 0.001)"
              fill="#ff725e"
            />
            <path
              id="Path_2624"
              data-name="Path 2624"
              d="M491.265,49.865c3.34-5.7,8.011-11,14.437-12.536a23.658,23.658,0,0,1,18.528,3.106V29.2L516.21,3.3,509.964,0,495.2,8.444l-3.818,31.142Z"
              transform="translate(-435.858 0.001)"
              fill="#577c47"
            />
            <path
              id="Path_2625"
              data-name="Path 2625"
              d="M524.406,126.275a1.959,1.959,0,0,1-.356-.166c-.229-.112-.551-.307-.975-.488a21.465,21.465,0,0,0-3.832-1.434,25.018,25.018,0,0,0-6-.863,30.036,30.036,0,0,0-3.584.132,18.12,18.12,0,0,0-3.769.688,25.424,25.424,0,0,0-6.621,3.316,23.025,23.025,0,0,0-4.5,4.042,18.086,18.086,0,0,0-2.267,3.413c-.185.424-.337.761-.454,1.014a1.845,1.845,0,0,1-.171.351,2.044,2.044,0,0,1,.127-.366c.093-.239.22-.595.41-1.043a17.106,17.106,0,0,1,2.214-3.477,22.864,22.864,0,0,1,4.51-4.12,25.142,25.142,0,0,1,6.68-3.364,18.456,18.456,0,0,1,3.823-.692,29.385,29.385,0,0,1,3.608-.117,24.651,24.651,0,0,1,6.041.921,19.979,19.979,0,0,1,3.828,1.521c.439.209.756.419.975.546a2.3,2.3,0,0,1,.312.185"
              transform="translate(-436.34 -96.668)"
              fill="#ef9456"
            />
            <path
              id="Path_2626"
              data-name="Path 2626"
              d="M581.77,27.079c-.068,0-1.117-5.9-2.336-13.214S577.279.623,577.347.613s1.117,5.9,2.336,13.214,2.155,13.243,2.087,13.253"
              transform="translate(-503.46 -0.481)"
              fill="#ef9456"
            />
            <path
              id="Path_2627"
              data-name="Path 2627"
              d="M552.3,37.174c-.068,0,.039-5.612.249-12.536s.429-12.531.487-12.531-.044,5.617-.249,12.541-.414,12.531-.488,12.526"
              transform="translate(-483.774 -9.508)"
              fill="#ef9456"
            />
            <path
              id="Path_2628"
              data-name="Path 2628"
              d="M513.541,55.185a1.016,1.016,0,0,1,0-.268c0-.2,0-.458.029-.766.034-.663.1-1.628.19-2.813.185-2.37.488-5.646.975-9.264s.975-6.826,1.36-9.2c.2-1.175.38-2.121.517-2.774.064-.3.117-.546.156-.746a.936.936,0,0,1,.073-.258,1.115,1.115,0,0,1,0,.268c-.034.2-.068.448-.112.756-.107.692-.258,1.633-.434,2.784-.366,2.35-.834,5.6-1.287,9.2s-.8,6.865-1.034,9.23c-.112,1.16-.2,2.106-.268,2.8-.034.312-.058.561-.083.765a1,1,0,0,1-.083.278"
              transform="translate(-453.345 -22.853)"
              fill="#ef9456"
            />
            <path
              id="Path_2629"
              data-name="Path 2629"
              d="M602.391,132.754a5.349,5.349,0,0,1,0,1.131c-.059.79-.127,1.7-.2,2.711s-.146,1.921-.2,2.711a5.288,5.288,0,0,1-.141,1.121,4.652,4.652,0,0,1-.039-1.131c0-.7.054-1.658.132-2.721s.181-2.019.273-2.711a4.889,4.889,0,0,1,.181-1.112"
              transform="translate(-522.658 -104.259)"
              fill="#ef9456"
            />
            <path
              id="Path_2630"
              data-name="Path 2630"
              d="M320.271,642.478a5.493,5.493,0,0,0-3.35-1.814c-3.745-.41-7.158,1.2-10.113,3.545l-.58,1.058c-1.59,1.238-3.247,2.653-3.74,4.608a28.728,28.728,0,0,0-.678,6.2,4.55,4.55,0,0,0,1.263,2.96,4.067,4.067,0,0,0,2.74,1.331,9.977,9.977,0,0,0,5.71-1.278A15.763,15.763,0,0,0,316,655.239a21.942,21.942,0,0,0,4.564-9.752,4.01,4.01,0,0,0-.293-3.008"
              transform="translate(-287.068 -503.095)"
              fill="#ef9456"
            />
            <path
              id="Path_2631"
              data-name="Path 2631"
              d="M320.271,642.478a5.493,5.493,0,0,0-3.35-1.814c-3.745-.41-7.158,1.2-10.113,3.545l-.58,1.058c-1.59,1.238-3.247,2.653-3.74,4.608a28.728,28.728,0,0,0-.678,6.2,4.55,4.55,0,0,0,1.263,2.96,4.067,4.067,0,0,0,2.74,1.331,9.977,9.977,0,0,0,5.71-1.278A15.763,15.763,0,0,0,316,655.239a21.942,21.942,0,0,0,4.564-9.752,4.01,4.01,0,0,0-.293-3.008"
              transform="translate(-287.068 -503.095)"
              fill="#1b5c96"
            />
            <path
              id="Path_2632"
              data-name="Path 2632"
              d="M265.923,392.562c-.59.2-29.811,41.611-30.6,44.37s-5.061,9.318.975,15.476A120.7,120.7,0,0,0,248.248,463c-1.355-10.439,8.918-18.855,16.09-16.671l-5.485-3.72,12.536-16.651"
              transform="translate(-233.125 -308.296)"
              fill="#577c47"
            />
            <path
              id="Path_2633"
              data-name="Path 2633"
              d="M652.9,432.884s4.6,5.749,5.744,9.469c.975,3.179,6.192,17.89,6.192,17.89l9.493,23.526a12.187,12.187,0,0,0-1.789,3.837c-.136.7-1.341,11.512-4.218,16.091L653.1,496.87l-1.921-13.628L648.554,471.5l2.711-31.62Z"
              transform="translate(-559.386 -339.963)"
              fill="#577c47"
            />
            <path
              id="Path_2634"
              data-name="Path 2634"
              d="M469.707,349.69a46.01,46.01,0,0,1,4.983-4.105,11.5,11.5,0,0,0,4.876,6.227c3.637,2,5.983,4.154,5.983,4.442v5.242s-14.906-4.071-15.842-11.8"
              transform="translate(-418.927 -271.405)"
              fill="#577c47"
            />
            <path
              id="Path_2635"
              data-name="Path 2635"
              d="M481.315,398.925l28.963-1.75-1.355-16.544-1.243-2.789-1.95-3.52a5.569,5.569,0,0,1-1.37,3.213,11.039,11.039,0,0,1-5.885,3.111l-.571,3.413L483.226,378s-1.911,19.991-1.911,20.45"
              transform="translate(-428.043 -293.972)"
              fill="#577c47"
            />
            <path
              id="Path_2636"
              data-name="Path 2636"
              d="M494.954,403.716a1.125,1.125,0,0,1,0,.224c-.025.161-.059.38-.1.658q-.142.892-.4,2.56v.044h-.049l-11.912.366-1.95.054-1.019.029a3.143,3.143,0,0,0-.975.127,2.238,2.238,0,0,0-1.38,1.385,2.612,2.612,0,0,0-.112,1.039v1.112c.063,2.984.132,6.144.2,9.41.224,9.157.366,17.451.322,23.453,0,3-.049,5.432-.088,7.109-.024.824-.039,1.463-.054,1.95v.487a.791.791,0,0,1,0,.171.651.651,0,0,1,0-.171v-9.542c0-6-.171-14.291-.395-23.448-.064-3.267-.127-6.426-.19-9.41v-1.112a2.924,2.924,0,0,1,.132-1.117,2.467,2.467,0,0,1,1.526-1.531,3.338,3.338,0,0,1,1.058-.132l1.014-.024,1.95-.049,11.917-.263-.054.049q.293-1.663.449-2.55l.127-.653a1.606,1.606,0,0,1-.02-.224"
              transform="translate(-424.534 -317.057)"
              fill="#ef9456"
            />
            <path
              id="Path_2637"
              data-name="Path 2637"
              d="M488.983,443.7a1.238,1.238,0,1,1-1.238,1.238,1.238,1.238,0,0,1,1.238-1.238"
              transform="translate(-433.092 -348.456)"
              fill="#ef9456"
            />
            <path
              id="Path_2638"
              data-name="Path 2638"
              d="M503.37,443.263c0,.078-1.394.258-3.091.653s-3.038.844-3.062.775a11.221,11.221,0,0,1,3-1.024,11.6,11.6,0,0,1,3.15-.4"
              transform="translate(-440.532 -348.116)"
              fill="#ef9456"
            />
            <path
              id="Path_2639"
              data-name="Path 2639"
              d="M500.252,451.41a20.419,20.419,0,0,1-2.165-.454,19.893,19.893,0,0,1-2.165-.453,9.751,9.751,0,0,1,4.33.907"
              transform="translate(-439.515 -353.8)"
              fill="#ef9456"
            />
            <path
              id="Path_2640"
              data-name="Path 2640"
              d="M488.983,508.938a1.238,1.238,0,1,1-1.238,1.238,1.238,1.238,0,0,1,1.238-1.238"
              transform="translate(-433.092 -399.694)"
              fill="#ef9456"
            />
            <path
              id="Path_2641"
              data-name="Path 2641"
              d="M503.37,508.526c0,.073-1.394.253-3.091.648s-3.038.844-3.062.775a11.1,11.1,0,0,1,3-1.019,11.376,11.376,0,0,1,3.15-.4"
              transform="translate(-440.532 -399.37)"
              fill="#ef9456"
            />
            <path
              id="Path_2642"
              data-name="Path 2642"
              d="M500.252,516.651a20.435,20.435,0,0,1-2.165-.454,19.864,19.864,0,0,1-2.165-.453,9.75,9.75,0,0,1,4.33.907"
              transform="translate(-439.515 -405.037)"
              fill="#ef9456"
            />
            <path
              id="Path_2643"
              data-name="Path 2643"
              d="M488.983,574.2a1.239,1.239,0,1,1-1.238,1.239,1.238,1.238,0,0,1,1.238-1.239"
              transform="translate(-433.092 -450.949)"
              fill="#ef9456"
            />
            <path
              id="Path_2644"
              data-name="Path 2644"
              d="M503.37,573.768c0,.073-1.394.253-3.091.648s-3.038.844-3.062.775a11.105,11.105,0,0,1,3-1.019,11.373,11.373,0,0,1,3.15-.4"
              transform="translate(-440.532 -450.608)"
              fill="#ef9456"
            />
            <path
              id="Path_2645"
              data-name="Path 2645"
              d="M500.252,581.829a20.449,20.449,0,0,1-2.165-.453,19.951,19.951,0,0,1-2.165-.453,6.587,6.587,0,0,1,2.219.2,6.459,6.459,0,0,1,2.111.7"
              transform="translate(-439.515 -456.211)"
              fill="#ef9456"
            />
            <path
              id="Path_2646"
              data-name="Path 2646"
              d="M387.517,445.161a1.689,1.689,0,0,1,.068-.356c.059-.254.141-.585.244-1,.214-.873.536-2.126.9-3.681s.771-3.413,1.087-5.51a38.063,38.063,0,0,0,.449-6.826,25.194,25.194,0,0,0-1.16-6.733,19.639,19.639,0,0,0-2.409-5.027,16.233,16.233,0,0,0-2.438-2.867c-.312-.317-.6-.517-.77-.678a1.605,1.605,0,0,1-.263-.249,2.379,2.379,0,0,1,.293.2,9.608,9.608,0,0,1,.809.649,15.409,15.409,0,0,1,2.521,2.838,19.553,19.553,0,0,1,2.487,5.056,25.046,25.046,0,0,1,1.19,6.8,36.631,36.631,0,0,1-.488,6.885c-.327,2.1-.756,3.959-1.141,5.515s-.736,2.8-.975,3.667c-.117.41-.214.741-.288.975a2.626,2.626,0,0,1-.117.341"
              transform="translate(-351.008 -323.747)"
              fill="#ef9456"
            />
            <path
              id="Path_2647"
              data-name="Path 2647"
              d="M397.775,501.868a3.888,3.888,0,0,1-.166-.648c-.093-.419-.2-1.034-.312-1.8a39.189,39.189,0,0,1-.385-6.012,38.759,38.759,0,0,1,.556-6c.132-.756.258-1.37.366-1.784a3.3,3.3,0,0,1,.185-.648,3.625,3.625,0,0,1-.088.668c-.078.419-.176,1.034-.283,1.794a46.448,46.448,0,0,0-.487,5.978,47.07,47.07,0,0,0,.312,5.983c.088.765.166,1.38.229,1.8a2.964,2.964,0,0,1,.073.668"
              transform="translate(-361.754 -380.873)"
              fill="#ef9456"
            />
            <path
              id="Path_2648"
              data-name="Path 2648"
              d="M561.028,612.775a1.638,1.638,0,0,1-.18.215l-.561.575c-.239.249-.541.546-.907.878s-.77.727-1.248,1.122a43.206,43.206,0,0,1-12.921,7.611c-1.546.566-2.96,1.009-4.164,1.316-.6.166-1.151.278-1.633.385s-.9.19-1.243.244l-.785.127a1.009,1.009,0,0,1-.278.029,1.029,1.029,0,0,1,.268-.078l.78-.175c.336-.068.751-.161,1.229-.283s1.024-.244,1.614-.424c1.195-.327,2.6-.785,4.13-1.36a45.351,45.351,0,0,0,9.41-4.92c1.351-.926,2.526-1.819,3.477-2.613.488-.38.9-.761,1.268-1.082s.688-.61.936-.849l.59-.541a1.193,1.193,0,0,1,.219-.176"
              transform="translate(-471.86 -481.242)"
              fill="#ef9456"
            />
            <path
              id="Path_2649"
              data-name="Path 2649"
              d="M653.08,463.414a1.637,1.637,0,0,1-.034.327c-.034.239-.083.551-.141.936l-.536,3.413c-.449,2.891-1.039,6.9-1.643,11.327s-1.131,8.421-1.526,11.351l-.458,3.438c-.058.385-.1.7-.136.936a1.686,1.686,0,0,1-.063.322,1.435,1.435,0,0,1,0-.332q.029-.361.088-.936c.093-.848.219-2.019.38-3.452.336-2.926.839-6.929,1.463-11.361s1.224-8.435,1.716-11.322c.254-1.463.458-2.613.624-3.413.073-.38.137-.687.185-.926a1.267,1.267,0,0,1,.083-.307"
              transform="translate(-559.368 -363.941)"
              fill="#ef9456"
            />
            <path
              id="Path_2650"
              data-name="Path 2650"
              d="M592.853,648.343a2.1,2.1,0,0,1,.263-.488c.185-.288.458-.7.8-1.214.7-1.014,1.687-2.4,2.823-3.9s2.209-2.818,3.008-3.755c.395-.487.722-.843.975-1.1s.366-.39.38-.375a2.353,2.353,0,0,1-.3.439l-.917,1.2c-.756.975-1.8,2.3-2.926,3.793s-2.145,2.857-2.877,3.847l-.887,1.136a2.2,2.2,0,0,1-.346.419"
              transform="translate(-515.641 -500.666)"
              fill="#ef9456"
            />
            <path
              id="Path_2651"
              data-name="Path 2651"
              d="M677.351,630.991a6.849,6.849,0,0,1-.78-.322c-.487-.215-1.219-.522-2.131-.839a20.97,20.97,0,0,0-7.4-1.243,5.853,5.853,0,0,0-2.062.424,5.292,5.292,0,0,0-1.623,1.1,8.469,8.469,0,0,0-1.829,2.833,22.674,22.674,0,0,0-.77,2.16,5.543,5.543,0,0,1-.278.8,5.814,5.814,0,0,1,.181-.829,19.238,19.238,0,0,1,.7-2.2,8.4,8.4,0,0,1,1.833-2.9,5.468,5.468,0,0,1,1.687-1.161,6.073,6.073,0,0,1,2.145-.449,19.832,19.832,0,0,1,7.47,1.312,21.287,21.287,0,0,1,2.116.922c.244.117.429.224.556.293a.75.75,0,0,1,.181.1"
              transform="translate(-568.752 -493.485)"
              fill="#ef9456"
            />
            <path
              id="Path_2652"
              data-name="Path 2652"
              d="M681.874,604.979c0,.054-.765.029-1.951.239a12.761,12.761,0,0,0-2.067.546,12.916,12.916,0,0,0-4.437,2.716,12.578,12.578,0,0,0-1.429,1.595c-.732.975-1.058,1.668-1.107,1.643a2.281,2.281,0,0,1,.21-.488,5.135,5.135,0,0,1,.3-.556,8.155,8.155,0,0,1,.449-.7,11.793,11.793,0,0,1,1.414-1.658,12.069,12.069,0,0,1,4.52-2.765,11.517,11.517,0,0,1,2.116-.488c.307-.054.59-.063.834-.083a5.78,5.78,0,0,1,.629,0,2.471,2.471,0,0,1,.517,0"
              transform="translate(-576.923 -475.109)"
              fill="#ef9456"
            />
            <path
              id="Path_2653"
              data-name="Path 2653"
              d="M674.677,718.892a9.615,9.615,0,0,0-5.563-7.353c-2.326-1.039-5.534-1.278-7.743,0s-5.164,4.417-4.564,6.89l5.066,8.508a8.059,8.059,0,0,0,9.035.288,8.776,8.776,0,0,0,3.769-8.333"
              transform="translate(-565.806 -558.121)"
              fill="#ef9456"
            />
            <path
              id="Path_2654"
              data-name="Path 2654"
              d="M674.677,718.892a9.615,9.615,0,0,0-5.563-7.353c-2.326-1.039-5.534-1.278-7.743,0s-5.164,4.417-4.564,6.89l5.066,8.508a8.059,8.059,0,0,0,9.035.288,8.776,8.776,0,0,0,3.769-8.333"
              transform="translate(-565.806 -558.121)"
              fill="#1b5c96"
            />
            <path
              id="Path_2655"
              data-name="Path 2655"
              d="M680.343,725.085s.181-.341.488-.946a9.9,9.9,0,0,0,.873-2.76,8.885,8.885,0,0,0-.371-4.237,10.256,10.256,0,0,0-.487-1.146,9.614,9.614,0,0,0-.658-1.112,9.172,9.172,0,0,0-4.14-3.389,9.439,9.439,0,0,0-6.685,0,10.153,10.153,0,0,0-2.555,1.36c-.551.415-.834.673-.853.653a1.572,1.572,0,0,1,.2-.2,6.387,6.387,0,0,1,.595-.532,9.671,9.671,0,0,1,9.381-1.507,9.154,9.154,0,0,1,2.394,1.414,9.536,9.536,0,0,1,1.867,2.062,10.942,10.942,0,0,1,.668,1.146,9.912,9.912,0,0,1,.487,1.18,8.83,8.83,0,0,1,.327,4.34,9.2,9.2,0,0,1-.975,2.774c-.166.3-.327.527-.424.678a.925.925,0,0,1-.127.219"
              transform="translate(-573.051 -558.112)"
              fill="#ef9456"
            />
            <path
              id="Path_2656"
              data-name="Path 2656"
              d="M659.288,667.415c-.073,0-.663-3.325-1.326-7.455s-1.141-7.484-1.073-7.5.663,3.33,1.326,7.46,1.141,7.485,1.073,7.494"
              transform="translate(-565.927 -512.409)"
              fill="#fff"
            />
            <path
              id="Path_2657"
              data-name="Path 2657"
              d="M489.563,375.193a2.959,2.959,0,0,1-.639-.141c-.41-.1-.975-.283-1.707-.536a27.89,27.89,0,0,1-5.3-2.565,28.226,28.226,0,0,1-4.656-3.618,17.358,17.358,0,0,1-1.234-1.292,2.793,2.793,0,0,1-.41-.512,3.9,3.9,0,0,1,.488.444c.293.3.731.722,1.287,1.229a31.52,31.52,0,0,0,9.9,6.148c.7.273,1.273.488,1.672.61a3.7,3.7,0,0,1,.6.234"
              transform="translate(-423.565 -287.853)"
              fill="#ef9456"
            />
            <path
              id="Path_2658"
              data-name="Path 2658"
              d="M573.484,394.285a1.162,1.162,0,0,1-.185.361,7.3,7.3,0,0,1-.629.907,11.442,11.442,0,0,1-6.022,3.9,7.254,7.254,0,0,1-1.082.21,1.285,1.285,0,0,1-.4.024c0-.044.561-.137,1.438-.4a13.247,13.247,0,0,0,3.238-1.516,13.077,13.077,0,0,0,2.692-2.345c.6-.678.917-1.161.956-1.136"
              transform="translate(-493.892 -309.65)"
              fill="#ef9456"
            />
            <path
              id="Path_2659"
              data-name="Path 2659"
              d="M599.939,408.3a8.513,8.513,0,0,1-3.974,4.442,28.211,28.211,0,0,0,2.2-2.033,28.567,28.567,0,0,0,1.775-2.409"
              transform="translate(-518.085 -320.661)"
              fill="#ef9456"
            />
            <path
              id="Path_2660"
              data-name="Path 2660"
              d="M495.309,676.3l.3-.063c1.082-.21,4.671-.863,5.251-.283.678.683,5.539,5.456,5.539,5.456s-.936,1.536-4.008,0a42.371,42.371,0,0,1-7.085-5.11"
              transform="translate(-439.034 -530.676)"
              fill="#ffbe9d"
            />
            <path
              id="Path_2661"
              data-name="Path 2661"
              d="M459.138,631.732h0a2.467,2.467,0,0,1,3.355.595l14.818,19.655-3.8,2.784L458.548,635.3a2.467,2.467,0,0,1,.44-3.461q.073-.057.15-.108"
              transform="translate(-409.757 -495.806)"
              fill="#ef9456"
            />
            <path
              id="Path_2662"
              data-name="Path 2662"
              d="M305.331,652.957s17.6,2.857,20.035,3.047,9.113,7.27,11.049,7.948,7.177.58,7.177.58,9.5.487,9.791,0,.873-.39,0-2.233a18.616,18.616,0,0,0-1.95-3.2s2.438,3,3.106,3.1,2.131.273,2.033-1.024-2.618-6.631-2.618-6.631-1.063-2.492-3-3.043-10.376-4.617-10.376-4.617h-9.02l-10.522-5s-6.368-2.048-12.429,4.637a13.077,13.077,0,0,0-2.638,4.569Z"
              transform="translate(-289.833 -503.87)"
              fill="#ffbe9d"
            />
            <path
              id="Path_2663"
              data-name="Path 2663"
              d="M503.383,686.567a2.208,2.208,0,0,1-.263-.512c-.171-.39-.38-.868-.624-1.434s-.571-1.346-.9-2.136a4.6,4.6,0,0,0-1.536-2.243,7.1,7.1,0,0,0-1.263-.693l-1.243-.556c-.8-.351-1.521-.653-2.126-.907l-1.463-.6A.288.288,0,1,1,494,677.4c.346.117.853.293,1.463.522s1.351.522,2.155.863l1.253.546a6.768,6.768,0,0,1,1.312.712,4.753,4.753,0,0,1,1.6,2.37c.317.795.6,1.521.848,2.15s.4,1.063.551,1.463a2.8,2.8,0,0,1,.2.546"
              transform="translate(-437.57 -531.873)"
              fill="#eb996e"
            />
            <path
              id="Path_2664"
              data-name="Path 2664"
              d="M498.625,697.916a4.672,4.672,0,0,1,.931.722,19.789,19.789,0,0,1,1.95,2.043,19.331,19.331,0,0,1,1.668,2.3,4.464,4.464,0,0,1,.546,1.039,6.764,6.764,0,0,1-.7-.941c-.414-.59-1-1.394-1.711-2.238s-1.39-1.56-1.9-2.072a6.533,6.533,0,0,1-.785-.853"
              transform="translate(-441.638 -548.108)"
              fill="#eb996e"
            />
            <path
              id="Path_2665"
              data-name="Path 2665"
              d="M496.086,723.349a1.113,1.113,0,0,1-.371-.146,6.781,6.781,0,0,1-.926-.566,15.926,15.926,0,0,1-2.569-2.472c-.912-1.048-1.726-2-2.3-2.706a9.03,9.03,0,0,1-.9-1.166,8.235,8.235,0,0,1,1.034,1.048c.614.673,1.463,1.609,2.36,2.652a19.888,19.888,0,0,0,2.487,2.5c.707.566,1.209.824,1.19.858"
              transform="translate(-434.091 -562.54)"
              fill="#eb996e"
            />
            <path
              id="Path_2666"
              data-name="Path 2666"
              d="M477.5,733.982a3.647,3.647,0,0,1-.907-.454,21.166,21.166,0,0,1-2.028-1.365,23.629,23.629,0,0,1-1.863-1.575,4.087,4.087,0,0,1-.7-.741c.053-.059,1.209.975,2.716,2.116s2.818,1.955,2.779,2.019"
              transform="translate(-420.729 -573.183)"
              fill="#eb996e"
            />
            <path
              id="Path_2667"
              data-name="Path 2667"
              d="M615.248,755.435a20.2,20.2,0,0,1-1.76-3.242c-.214-.854,0-4.915-2.847-4.276v.317c.073.346,0,8.606,0,8.606Z"
              transform="translate(-529.61 -587.32)"
              fill="#ffbe9d"
            />
            <path
              id="Path_2668"
              data-name="Path 2668"
              d="M461.885,803.343s-2.628,24.252,25.769,23.546c23.151-.576,22.746-23.141,22.746-23.141Z"
              transform="translate(-412.734 -630.904)"
              fill="#577c47"
            />
            <path
              id="Path_2669"
              data-name="Path 2669"
              d="M476.337,774.607c14.614,0,26.461,2.864,26.461,6.4s-11.847,6.4-26.461,6.4-26.461-2.864-26.461-6.4,11.847-6.4,26.461-6.4"
              transform="translate(-403.352 -608.336)"
              fill="#577c47"
            />
            <path
              id="Path_2670"
              data-name="Path 2670"
              d="M486.135,784.511c13.413,0,24.287,1.81,24.287,4.042s-10.874,4.042-24.287,4.042-24.287-1.81-24.287-4.042,10.874-4.042,24.287-4.042"
              transform="translate(-412.755 -616.114)"
              fill="#ef9456"
            />
            <path
              id="Path_2671"
              data-name="Path 2671"
              d="M509.946,812.926l-.117.068-.327.2c-.287.18-.727.429-1.3.722a26.163,26.163,0,0,1-5.129,1.926,64.89,64.89,0,0,1-7.987,1.492,78.8,78.8,0,0,1-9.937.488c-3.525-.029-6.89-.146-9.937-.414a56.7,56.7,0,0,1-8.031-1.248,36.194,36.194,0,0,1-5.227-1.628c-.6-.229-1.058-.454-1.37-.59l-.346-.166c-.083-.034-.122-.059-.117-.064a.865.865,0,0,1,.127.044l.361.141c.317.122.77.327,1.38.541a39.232,39.232,0,0,0,5.237,1.546,58.583,58.583,0,0,0,8.006,1.19c3.043.253,6.4.366,9.922.395a80.265,80.265,0,0,0,9.9-.488,66.41,66.41,0,0,0,7.967-1.433,27.537,27.537,0,0,0,5.134-1.838c.576-.283,1.024-.512,1.317-.678l.341-.185c.088-.01.127-.029.132-.024"
              transform="translate(-411.397 -638.43)"
              fill="#ef9456"
            />
            <path
              id="Path_2672"
              data-name="Path 2672"
              d="M616.751,730.387s2.9-9.708,3.9-11c.824-1.068,6.661-5.485,8.664-6.982a4.78,4.78,0,0,1,1.653-.8,9.716,9.716,0,0,1,7.236.541,8.289,8.289,0,0,1,4.457,4.807l-10.9,6.773-.424,4.583-8.777-2.238a2.436,2.436,0,0,0-.258-1.336s-2.789,6.758-5.554,5.656"
              transform="translate(-534.409 -558.494)"
              fill="#ffbe9d"
            />
            <path
              id="Path_2673"
              data-name="Path 2673"
              d="M549.692,739.134c-1.107-1.463-2.028-2.872-2.76-4.066a32.064,32.064,0,0,1-1.6-2.926,7.16,7.16,0,0,0-.571-1.082,7.453,7.453,0,0,0,.434,1.146,30.324,30.324,0,0,0,1.5,2.979,48.19,48.19,0,0,0,2.711,4.135c1.092,1.507,2.375,3.106,3.735,4.759,1.9,2.282,3.657,4.413,5.085,6.28h.395c-1.428-1.95-3.228-4.159-5.183-6.509-1.36-1.643-2.643-3.228-3.75-4.715"
              transform="translate(-477.873 -574.136)"
              fill="#f5f5f5"
            />
            <path
              id="Path_2674"
              data-name="Path 2674"
              d="M535.29,736.284a7.632,7.632,0,0,0,.458,1.126c.317.722.775,1.76,1.317,3.052a102.654,102.654,0,0,1,3.672,10.308c.38,1.37.785,2.7,1.263,3.935h.39c-.488-1.253-.887-2.609-1.287-4.037a83.5,83.5,0,0,0-3.789-10.308c-.575-1.282-1.073-2.306-1.429-3.008a7.58,7.58,0,0,0-.595-1.068"
              transform="translate(-470.433 -578.238)"
              fill="#f5f5f5"
            />
            <path
              id="Path_2675"
              data-name="Path 2675"
              d="M539.742,730.855a7.711,7.711,0,0,0,.522,1.112c.361.707.892,1.721,1.56,2.969,1.341,2.492,3.218,5.929,5.5,9.6,1.326,2.136,2.657,4.091,3.9,5.8h.409c-1.238-1.76-2.6-3.784-3.969-5.993-2.282-3.662-4.193-7.07-5.593-9.528l-1.668-2.925a7.5,7.5,0,0,0-.668-1.039"
              transform="translate(-473.929 -573.975)"
              fill="#f5f5f5"
            />
            <path
              id="Path_2676"
              data-name="Path 2676"
              d="M556.586,731.874a16.147,16.147,0,0,0-4.306-5.919,38.954,38.954,0,0,0-5.363-3.9c-1.75-1.107-3.413-2.111-4.827-3.13a33.873,33.873,0,0,1-6.134-5.593c-.639-.746-1.112-1.35-1.424-1.76l-.361-.488a.675.675,0,0,0-.132-.151.8.8,0,0,0,.1.176l.327.487c.3.429.746,1.048,1.375,1.814a32.433,32.433,0,0,0,6.09,5.715c1.429,1.039,3.072,2.063,4.812,3.179a38.966,38.966,0,0,1,5.3,3.9,15.837,15.837,0,0,1,4.208,5.8,11.479,11.479,0,0,1,.649,2.058l.38-.039a12.312,12.312,0,0,0-.7-2.15"
              transform="translate(-469.451 -558.328)"
              fill="#f5f5f5"
            />
            <path
              id="Path_2677"
              data-name="Path 2677"
              d="M563.295,746.507a16.917,16.917,0,0,0-2.6-4.447,28.153,28.153,0,0,0-4.534-4.388c-1.75-1.39-3.5-2.589-5.061-3.715s-2.926-2.179-4.038-3.125a29.981,29.981,0,0,1-2.477-2.384,10.844,10.844,0,0,0-.863-.931,1.089,1.089,0,0,0,.175.283c.122.176.317.429.575.746a23.687,23.687,0,0,0,2.409,2.487,51.217,51.217,0,0,0,4.008,3.208c1.555,1.146,3.3,2.355,5.032,3.73a29.32,29.32,0,0,1,4.5,4.3,17.271,17.271,0,0,1,2.623,4.32c.088.21.161.414.234.614l.253-.024a4.626,4.626,0,0,0-.234-.673"
              transform="translate(-477.052 -571.352)"
              fill="#f5f5f5"
            />
            <path
              id="Path_2678"
              data-name="Path 2678"
              d="M552.022,759.36c-1.872-3.413-3.447-6.592-4.564-8.9l-1.317-2.731a6.53,6.53,0,0,0-.517-.975,6.284,6.284,0,0,0,.38,1.048c.273.663.678,1.614,1.2,2.784,1.058,2.336,2.6,5.529,4.471,8.967.639,1.165,1.268,2.282,1.877,3.335h.419c-.629-1.126-1.287-2.3-1.955-3.53"
              transform="translate(-478.55 -586.463)"
              fill="#f5f5f5"
            />
            <path
              id="Path_2679"
              data-name="Path 2679"
              d="M555.847,742.03a101.533,101.533,0,0,1-7.441-8.167c-.892-1.1-1.595-2-2.087-2.623a7.909,7.909,0,0,0-.785-.975,7.478,7.478,0,0,0,.668,1.039c.454.653,1.122,1.585,1.994,2.706a83.024,83.024,0,0,0,7.382,8.289c1.541,1.526,3.106,2.872,4.418,4.193a20.638,20.638,0,0,1,2.56,3.052h.322a19.019,19.019,0,0,0-2.628-3.281c-1.316-1.365-2.872-2.716-4.4-4.232"
              transform="translate(-478.479 -573.511)"
              fill="#f5f5f5"
            />
            <path
              id="Path_2680"
              data-name="Path 2680"
              d="M537.225,756.7c-.648-1.95-1.087-3.842-1.5-5.554a52.325,52.325,0,0,0-2.311-8.026,14.207,14.207,0,0,0-1.4-2.726.383.383,0,0,0,.078.185l.273.527c.229.463.561,1.151.912,2.067a55.523,55.523,0,0,1,2.18,8.035c.4,1.716.824,3.6,1.463,5.588.127.375.268.756.409,1.141h.351c-.156-.414-.317-.834-.458-1.238"
              transform="translate(-467.863 -581.467)"
              fill="#f5f5f5"
            />
            <path
              id="Path_2681"
              data-name="Path 2681"
              d="M554.361,788.9a.422.422,0,0,1,.117,0,3.385,3.385,0,0,1,.341,0c.3.024.736.073,1.3.151a29.563,29.563,0,0,1,4.686,1.039,10.316,10.316,0,0,1,1.463.648,1.948,1.948,0,0,1,.668.575,1.125,1.125,0,0,1,.136.926,1.248,1.248,0,0,1-.634.717,5.157,5.157,0,0,1-.843.449,14.357,14.357,0,0,1-1.858.648,57.409,57.409,0,0,1-8.562,1.643,100.991,100.991,0,0,1-15.812.439c-2.023-.078-3.657-.175-4.788-.244l-1.3-.092-.336-.029a.284.284,0,0,1-.117,0h.458l1.3.044,4.788.161a108.852,108.852,0,0,0,15.778-.487,59.6,59.6,0,0,0,8.528-1.619,14.994,14.994,0,0,0,1.833-.629,4.771,4.771,0,0,0,.814-.424,1.091,1.091,0,0,0,.551-.59.922.922,0,0,0-.107-.76,1.812,1.812,0,0,0-.595-.522,10.527,10.527,0,0,0-1.429-.644,32.194,32.194,0,0,0-4.647-1.112c-.556-.093-.975-.161-1.282-.2a1.736,1.736,0,0,1-.449-.088"
              transform="translate(-465.349 -619.559)"
              fill="#263238"
            />
            <path
              id="Path_2682"
              data-name="Path 2682"
              d="M645.036,761c.044.059-.863.765-1.9,1.726s-1.814,1.809-1.872,1.76A12.164,12.164,0,0,1,645.036,761"
              transform="translate(-553.659 -597.649)"
              fill="#eb996e"
            />
            <path
              id="Path_2683"
              data-name="Path 2683"
              d="M646.428,776.775a5.141,5.141,0,0,0-1.565-.327,5.271,5.271,0,0,0-1.555.39c0-.024.107-.171.38-.327a2.441,2.441,0,0,1,1.166-.317,2.493,2.493,0,0,1,1.185.268c.278.147.409.288.39.312"
              transform="translate(-555.265 -609.582)"
              fill="#eb996e"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BlueHairCookMan;
