import React from 'react';

const ListingIngredients = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="151.116"
      height="150.999"
      viewBox="0 0 151.116 150.999"
      {...props}
    >
      <g id="Group_8928" data-name="Group 8928" transform="translate(-384.883)">
        <path
          id="Intersection_2"
          data-name="Intersection 2"
          d="M74.169,151C34.181,132.837,0,113.181,0,72.374,0,48.267,12.638,20.034,31.055,0h50.23a20,20,0,0,1,20,20V131a20,20,0,0,1-20,20Z"
          transform="translate(434.715)"
          fill="#f7e4dd"
        />
        <g id="undraw_diet_ghvw" transform="translate(384.883 40.594)">
          <ellipse
            id="Ellipse_261"
            data-name="Ellipse 261"
            cx="21.635"
            cy="1.629"
            rx="21.635"
            ry="1.629"
            transform="translate(0 88.497)"
            fill="#f7e4dd"
          />
          <path
            id="Path_3105"
            data-name="Path 3105"
            d="M219.271,319.423a3.061,3.061,0,0,1,3.063-3.014h15.383a3.069,3.069,0,0,1,3.06,2.965,2.994,2.994,0,0,1-1.162,2.406.67.67,0,0,0-.265.613,2.975,2.975,0,0,1,.027.4v10.64a2.982,2.982,0,0,1-2.982,2.983H223.754a2.982,2.982,0,0,1-2.982-2.983v-10.64a3,3,0,0,1,.02-.35.647.647,0,0,0-.272-.6A3,3,0,0,1,219.271,319.423Z"
            transform="translate(-105.615 -294.152)"
            fill="#3f3d56"
          />
          <path
            id="Path_3106"
            data-name="Path 3106"
            d="M222.972,322.157a2.386,2.386,0,0,1,2.388-2.072h15.4a2.392,2.392,0,0,1,2.352,1.853,2.354,2.354,0,0,1-1.125,2.536.648.648,0,0,0-.3.733,2.309,2.309,0,0,1,.082.612v10.639a2.333,2.333,0,0,1-1.9,2.293,2.36,2.36,0,0,1-.432.04H226.788a2.307,2.307,0,0,1-.631-.087,2.332,2.332,0,0,1-1.7-2.245V325.819a2.322,2.322,0,0,1,.072-.575.667.667,0,0,0,.02-.159.649.649,0,0,0-.344-.572h0A2.35,2.35,0,0,1,222.972,322.157Z"
            transform="translate(-108.65 -297.178)"
            fill="#1b5c96"
          />
          <path
            id="Path_3107"
            data-name="Path 3107"
            d="M230.87,326.839v-1h1.7v-5.752h1v5.752h5.352v-5.752h1v5.752h5.352v-5.752h1v5.752h1.9v1h-1.9v3h1.9v1h-1.9v3h1.9v1h-1.9v3.911a2.365,2.365,0,0,1-.432.04h-.568v-3.951h-5.352v3.951h-1v-3.951h-5.352v3.951H233.2a2.306,2.306,0,0,1-.631-.087v-3.864h-1.7v-1h1.7v-3h-1.7v-1h1.7v-3Zm14.4,0h-5.352v3h5.352Zm0,7v-3h-5.352v3Zm-11.7,0h5.352v-3h-5.352Zm0-4h5.352v-3h-5.352Z"
            transform="translate(-115.063 -297.179)"
            opacity="0.2"
          />
          <path
            id="Path_3108"
            data-name="Path 3108"
            d="M219.668,190.527a10.683,10.683,0,1,0,21.366,0Z"
            transform="translate(-105.942 -190.527)"
            fill="#1b5c96"
          />
          <path
            id="Path_3109"
            data-name="Path 3109"
            d="M239.332,198.9c0,.279.1.506.221.506s.221-.226.221-.506a.3.3,0,1,0-.443,0Z"
            transform="translate(-108.159 -197.002)"
            fill="#3f3d56"
          />
          <path
            id="Path_3110"
            data-name="Path 3110"
            d="M302.615,208.909c0,.279.1.506.221.506s.221-.226.221-.506a.3.3,0,1,0-.442,0Z"
            transform="translate(-182.631 -205.243)"
            fill="#3f3d56"
          />
          <path
            id="Path_3111"
            data-name="Path 3111"
            d="M256.136,203.189c0,.279.1.506.221.506s.221-.226.221-.506a.3.3,0,1,0-.442,0Z"
            transform="translate(-127.934 -200.534)"
            fill="#3f3d56"
          />
          <path
            id="Path_3112"
            data-name="Path 3112"
            d="M281.163,225.713c0,.279.1.506.221.506s.221-.226.221-.506a.3.3,0,1,0-.443,0Z"
            transform="translate(-157.386 -219.076)"
            fill="#3f3d56"
          />
          <path
            id="Path_3113"
            data-name="Path 3113"
            d="M254.348,222.853c0,.279.1.506.221.506s.221-.226.221-.506a.3.3,0,1,0-.443,0Z"
            transform="translate(-125.83 -216.722)"
            fill="#3f3d56"
          />
          <path
            id="Path_3114"
            data-name="Path 3114"
            d="M316.916,196.038c0,.279.1.506.221.506s.221-.226.221-.506a.3.3,0,1,0-.442,0Z"
            transform="translate(-199.461 -194.648)"
            fill="#3f3d56"
          />
          <path
            id="Path_3115"
            data-name="Path 3115"
            d="M294.034,231.434c0,.279.1.506.221.506s.221-.226.221-.506a.3.3,0,1,0-.442,0Z"
            transform="translate(-172.533 -223.785)"
            fill="#3f3d56"
          />
          <path
            id="Path_3116"
            data-name="Path 3116"
            d="M227.556,201.811a11.3,11.3,0,0,0,11.284-11.284h-1.2a10.083,10.083,0,1,1-20.165,0h-1.2A11.3,11.3,0,0,0,227.556,201.811Z"
            transform="translate(-103.147 -190.527)"
            fill="#3f3d56"
          />
          <rect
            id="Rectangle_8052"
            data-name="Rectangle 8052"
            width="3.536"
            height="3.536"
            transform="translate(98.418 3.874)"
            fill="#577c47"
          />
          <rect
            id="Rectangle_8053"
            data-name="Rectangle 8053"
            width="28.466"
            height="0.354"
            transform="translate(63.764 5.465)"
            fill="#1b5c96"
          />
          <rect
            id="Rectangle_8054"
            data-name="Rectangle 8054"
            width="3.536"
            height="3.536"
            transform="translate(98.418 30.492)"
            fill="#577c47"
          />
          <rect
            id="Rectangle_8055"
            data-name="Rectangle 8055"
            width="28.466"
            height="0.354"
            transform="translate(63.764 32.084)"
            fill="#1b5c96"
          />
          <rect
            id="Rectangle_8056"
            data-name="Rectangle 8056"
            width="3.536"
            height="3.536"
            transform="translate(98.418 56.926)"
            fill="#577c47"
          />
          <rect
            id="Rectangle_8057"
            data-name="Rectangle 8057"
            width="28.466"
            height="0.354"
            transform="translate(63.764 58.517)"
            fill="#1b5c96"
          />
          <path
            id="Path_3117"
            data-name="Path 3117"
            d="M741.7,319.738l4.517,3.56,9.572-3.364s3.254-3.661,5.095-.934c.63.934-3.5,2.659-3.887,2.819-.974.4-10.3,5.518-11.215,4.846s-6.056-4.067-6.056-4.067Z"
            transform="translate(-717.811 -295.454)"
            fill="#ef9456"
          />
          <path
            id="Path_3118"
            data-name="Path 3118"
            d="M869.884,230.976c4.281-1.6,8.354-2.357,11.656,0V223.37a5.828,5.828,0,0,0-5.828-5.828h0a5.828,5.828,0,0,0-5.828,5.828Z"
            transform="translate(-861.411 -212.765)"
            fill="#1b5c96"
          />
          <path
            id="Path_3119"
            data-name="Path 3119"
            d="M811.514,383.542l.35,2.449s.875,2.624,0,7.521l14.081,38.394-10.407,2.974-5.16-20.99-7.259-16,.7,18.191,3.673,19.416-10.845-.437-3.16-33.292a29.491,29.491,0,0,1,2.642-15.351c.877-1.862,1.819-3.329,2.674-3.542C801.369,382.23,811.514,383.542,811.514,383.542Z"
            transform="translate(-792.284 -348.717)"
            fill="#577c47"
          />
          <path
            id="Path_3120"
            data-name="Path 3120"
            d="M766.184,659.546l3.586,3.848s6.909,3.586,2.012,4.023-6.559-1.487-6.559-1.487-6.122-.437-6.035-1.312.525-3.761.525-3.761Z"
            transform="translate(-733.835 -576.619)"
            fill="#2c462e"
          />
          <path
            id="Path_3121"
            data-name="Path 3121"
            d="M915.348,662.758v2.711a7.328,7.328,0,0,0,0,1.4c.087.262,1.4,3.5-2.974,2.8s-2.624-3.411-2.624-3.411l1.049-3.936Z"
            transform="translate(-902.458 -578.903)"
            fill="#2c462e"
          />
          <ellipse
            id="Ellipse_262"
            data-name="Ellipse 262"
            cx="4.395"
            cy="4.395"
            rx="4.395"
            ry="4.395"
            transform="translate(9.96 7.145)"
            fill="#ef9456"
          />
          <path
            id="Path_3122"
            data-name="Path 3122"
            d="M884.649,267.133s.612,5.772,0,6.822,6.209-1.049,6.209-1.049-1.487-3.936-.962-5.51S884.649,267.133,884.649,267.133Z"
            transform="translate(-873.333 -253.167)"
            fill="#ef9456"
          />
          <path
            id="Path_3123"
            data-name="Path 3123"
            d="M827.389,301.951l-1.4,3.848,13.031,1.487s1.4-.262,1.4-1.4a29.886,29.886,0,0,1,1.487-5.16,39.147,39.147,0,0,1,5.391-3.739c.634-.285-.314-.167-.848-1.378-.16-.364-.352-.748-.561-1.137-.171-.319-.354-.643-.542-.962-.228-.389-.464-.772-.692-1.137-.691-1.1-1.322-2.03-1.524-2.405-.612-1.137-6.368-1.238-6.368-1.238-1.646,1.035-6.4,1.238-6.4,1.238a29.334,29.334,0,0,0-4.633,2.405C825.63,292.448,827.389,301.951,827.389,301.951Z"
            transform="translate(-819.572 -271.367)"
            fill="#f7e4dd"
          />
          <path
            id="Path_3124"
            data-name="Path 3124"
            d="M875.409,219.949v-.1l.124.007a5.832,5.832,0,0,1,5.012-1,5.807,5.807,0,0,0-2.237,1.156l4.213.234,1.778,3.951h-1.2l-.97-1.778v1.778h-4.07l-.277-1.383-.768,1.383h-.8c0,.1-.013.2-.013.3l.79,7.606-3.753-1.58v-6.025A5.815,5.815,0,0,1,875.409,219.949Z"
            transform="translate(-864.763 -213.685)"
            fill="#1b5c96"
          />
          <path
            id="Path_3125"
            data-name="Path 3125"
            d="M935.558,339.758l.262,6.909.7,13.381s-.962,5.772,1.487,5.685.612-6.822.612-6.822l.7-10.582-.7-7.434Z"
            transform="translate(-930.802 -313.372)"
            fill="#ef9456"
          />
          <path
            id="Path_3126"
            data-name="Path 3126"
            d="M922.7,307.279c0,.787,4.417.568,4.417.568l2.318-12.113a29.336,29.336,0,0,0-4.633,2.405,4.109,4.109,0,0,0-.776,1.137c-.1.281-.2.606-.3.962s-.179.737-.261,1.137A46.51,46.51,0,0,0,922.7,307.279Z"
            transform="translate(-918.641 -277.133)"
            fill="#f7e4dd"
          />
          <g
            id="Group_7963"
            data-name="Group 7963"
            transform="translate(136.64 56.465) rotate(150)"
          >
            <g
              id="Group_7963-2"
              data-name="Group 7963"
              transform="translate(0 0.85)"
            >
              <path
                id="Path_3129"
                data-name="Path 3129"
                d="M.621.294C2.92-1.284,3.151,4,3.149,4h0S-1.679,1.876.621.294Z"
                transform="matrix(-0.966, 0.259, -0.259, -0.966, 5.569, 3.867)"
                fill="#f0b57d"
              />
              <path
                id="Path_3132"
                data-name="Path 3132"
                d="M.622,3.707C2.921,5.285,3.151.006,3.149,0h0S-1.68,2.127.622,3.707Z"
                transform="matrix(-0.966, -0.259, 0.259, -0.966, 5.278, 8.867)"
                fill="#f0b57d"
              />
              <path
                id="Path_3130"
                data-name="Path 3130"
                d="M.621.294C2.92-1.284,3.151,4,3.149,4h0S-1.679,1.876.621.294Z"
                transform="translate(5.057 3.643) rotate(135)"
                fill="#f0b57d"
              />
              <path
                id="Path_3131"
                data-name="Path 3131"
                d="M.621,3.709C2.92,5.287,3.152.006,3.15,0h0S-1.679,2.127.621,3.709Z"
                transform="translate(2.972 8.059) rotate(-135)"
                fill="#f0b57d"
              />
            </g>
            <path
              id="Path_3128"
              data-name="Path 3128"
              d="M5.426,10.8C2.794,11.624.143,9.235.006,5.77Q0,5.626,0,5.48t.006-.29C.143,1.726,2.794-.664,5.426.165l8.928,2.81A2.283,2.283,0,0,1,16.311,5.48a2.283,2.283,0,0,1-1.957,2.505Z"
              transform="translate(4.514 0)"
              fill="#1b5c96"
            />
            <circle
              id="Ellipse_263"
              data-name="Ellipse 263"
              cx="0.5"
              cy="0.5"
              r="0.5"
              transform="translate(7.327 2.192)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_264"
              data-name="Ellipse 264"
              cx="0.5"
              cy="0.5"
              r="0.5"
              transform="translate(10.327 4.192)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_269"
              data-name="Ellipse 269"
              cx="0.5"
              cy="0.5"
              r="0.5"
              transform="translate(17.327 5.192)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_265"
              data-name="Ellipse 265"
              cx="0.5"
              cy="0.5"
              r="0.5"
              transform="translate(6.327 6.192)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_268"
              data-name="Ellipse 268"
              cx="0.5"
              cy="0.5"
              r="0.5"
              transform="translate(13.327 6.192)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_267"
              data-name="Ellipse 267"
              cx="0.5"
              cy="0.5"
              r="0.5"
              transform="translate(14.327 3.192)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_266"
              data-name="Ellipse 266"
              cx="0.5"
              cy="0.5"
              r="0.5"
              transform="translate(10.327 8.192)"
              fill="#3f3d56"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ListingIngredients;
