import { IconButton, InputAdornment } from '@mui/material';
import React from 'react';
import { Form, TextField } from '../library/forms';
import { spacing } from '../library/theme';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddButton from '../library/AddButton';
import { Grid } from '../library';

const wrapperSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  marginBottom: spacing.m,
};

export default function ProductSearchForm(props) {
  const { clearHandler, nameCont, onAddButton } = props;
  return (
    <Form onSubmit={props.handleSubmit} error={props.error}>
      <Grid item xs={12} style={wrapperSx}>
        <TextField
          name="nameCont"
          placeholder="Pesquisar por nome..."
          width="100%"
          fluid
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: !!nameCont ? (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={clearHandler}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
        <AddButton onClick={onAddButton} />
      </Grid>
    </Form>
  );
}
