import { Drawer, IconButton, InputAdornment } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Form, TextField } from '../library/forms';
import theme, {
  decorationColors,
  spacing,
  utilityColors,
} from '../library/theme';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddButton from '../library/AddButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Grid, Modal, Separator, Title } from '../library';
import ToggleButtons from '../library/ToggleButtons';

const wrapperSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const paperSx = { '.MuiDrawer-paper': { borderRadius: spacing.s } };
const titleSx = { paddingBottom: spacing.xs };
const hintSx = {
  width: '100%',
  marginLeft: 'auto',
  marginTop: spacing.s,
  marginBottom: spacing.s,
  fontSize: '13px',
  fontWeight: 'bold',
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  borderRadius: '10px',
  padding: '13px 16px',
  backgroundColor: `${utilityColors.progress}20`,
  color: utilityColors.progress,
};
const iconSx = {
  height: 24,
  width: 24,
  color: decorationColors.decorationBrand,
};
const containerSx = {
  padding: spacing.s,
  display: 'flex',
};
const marginSx = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: spacing.xs,
};
const removeShadowSx = {
  '&:hover': {
    boxShadow: 'none',
  },
  width: '50%',
};
const filterButtonSx = {
  backgroundColor: theme.palette.add.main,
  ':hover': {
    backgroundColor: theme.palette.add.active,
  },
  ...removeShadowSx,
};

export default function SupplySearchForm(props) {
  const {
    clearHandler,
    nameCont,
    unitIdEq,
    typeEq,
    supplyCategoryIdEq,
    unitIdEqOptions,
    typeEqOptions,
    supplyCategoryIdEqOptions,
    hintEnabled = false,
    onAddButton,
    handleSubmit,
    error,
    onButtonsChange,
    onClearFilters,
  } = props;
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);
  const hideFilters = useCallback(() => {
    setShowFilters(false);
  }, []);

  const inputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    ),
    endAdornment: !!nameCont ? (
      <InputAdornment position="end">
        <IconButton edge="end" onClick={clearHandler}>
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    ) : null,
  };

  const hintText =
    hintEnabled &&
    'Clique no botão + para adicionar insumos manualmente ou importar vários insumos em lote.';

  return (
    <Form onSubmit={handleSubmit} error={error}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9}>
          <TextField
            name="nameCont"
            placeholder="Pesquisar por nome..."
            width="100%"
            fluid
            InputProps={inputProps}
          />
        </Grid>
        <Grid item xs={3} sx={wrapperSx}>
          <IconButton style={{ padding: 4 }} onClick={toggleFilters}>
            <FilterListIcon style={iconSx} />
          </IconButton>
          <AddButton onClick={onAddButton} type="button" />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div style={hintSx}>{hintText}</div>
      </Grid>

      <Modal
        anchor="right"
        open={showFilters}
        onClose={hideFilters}
        sx={paperSx}
        maxWidth="260px"
        title="Filtros"
      >
        <Grid container sx={containerSx}>
          <Grid item xs={12} sx={marginSx}>
            <Title sx={titleSx} variant="h7">
              Por unidade
            </Title>
            <ToggleButtons
              name="unitIdEq"
              onChange={onButtonsChange}
              buttons={unitIdEqOptions}
              fieldValue={unitIdEq}
            />
          </Grid>
          <Grid item xs={12}>
            <Separator />
          </Grid>
          <Grid item xs={12} sx={marginSx}>
            <Title sx={titleSx} variant="h7">
              Por tipo
            </Title>
            <ToggleButtons
              name="typeEq"
              onChange={onButtonsChange}
              buttons={typeEqOptions}
              fieldValue={typeEq}
            />
          </Grid>
          <Grid item xs={12}>
            <Separator />
          </Grid>
          <Grid item xs={12} sx={marginSx}>
            <Title sx={titleSx} variant="h7">
              Por categoria
            </Title>
            <ToggleButtons
              name="supplyCategoryIdEq"
              onChange={onButtonsChange}
              buttons={supplyCategoryIdEqOptions}
              fieldValue={supplyCategoryIdEq}
            />
          </Grid>
          <Grid item xs={12}>
            <Separator />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', gap: spacing.xs }}>
            <Button sx={removeShadowSx} onClick={onClearFilters}>
              Limpar filtros
            </Button>
            <Button sx={filterButtonSx} onClick={hideFilters}>
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Form>
  );
}
