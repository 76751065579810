import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import TaskSearchForm from './TaskSearchForm';
import { submitSearchFilter } from '../../processes/taskProcesses';
import { change, formValueSelector, reduxForm } from 'redux-form';
import debounce from '../../utils/debounce';

const FORM_NAME = 'taskSearch';

function TaskSearchFormContainer(props) {
  const { dispatch } = props;

  const statusEqOptions = [
    { name: 'Pendente', value: 'pending' },
    { name: 'Em progresso', value: 'in_progress' },
    { name: 'Finalizado', value: 'finished' },
  ];

  const clearHandler = useCallback(() => {
    dispatch(change(FORM_NAME, 'titleCont', ''));
  }, [dispatch]);

  return (
    <TaskSearchForm
      {...props}
      clearHandler={clearHandler}
      statusEq={statusEqOptions}
    />
  );
}

function mapStateToProps(state) {
  const formSelector = formValueSelector(FORM_NAME);
  const titleCont = formSelector(state, 'titleCont');

  return { initialValues: {}, titleCont };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onChange: debounce(submitSearchFilter, 500),
  })(TaskSearchFormContainer),
);
