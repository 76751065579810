import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OnboardingForm from './OnboardingForm';
import { getCurrentUser } from '../../selectors/userSelectors';
import PersonalForm from './PersonalForm';
import AvatarForm from './AvatarForm';
import CompanyForm from './CompanyForm';
import LevelForm from './LevelForm';
import CompanyGoalsForm from './CompanyGoalsForm';
import { change, formValueSelector, reduxForm } from 'redux-form';
import { updateProfile } from '../../processes/profileProcesses';
import InitialPage from './InitialPage';
import {
  getConfig,
  getConfigByName,
  getCountriesNames,
  getCountryById,
  getCurrenciesNames,
  getCurrencyByCode,
  getCurrencyById,
  getStateUfs,
} from '../../selectors/configSelectors';

const FORM_NAME = 'onboardingForm';

function OnboardingFormContainer(props) {
  const {
    dispatch,
    handleSubmit,
    valid,
    hasEmployees,
    selectedCountry,
    countryCurrency,
  } = props;
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (hasEmployees != 'yes') {
      dispatch(
        change(FORM_NAME, 'companiesAttributes[0][employeeQuantity]', '0'),
      );
    }
  }, [dispatch, hasEmployees, stepIndex]);

  useEffect(() => {
    dispatch(
      change(
        FORM_NAME,
        'companiesAttributes[0][currencyId]',
        countryCurrency?.id,
      ),
    );
  }, [dispatch, selectedCountry, stepIndex]);

  const nextStep = useCallback(() => {
    if (!valid) return;
    handleSubmit();
    setStepIndex(stepIndex + 1);
  }, [stepIndex, dispatch, handleSubmit, valid]);

  const previousStep = useCallback(() => {
    if (stepIndex > 0) setStepIndex(stepIndex - 1);
  }, [stepIndex, dispatch]);

  const navigationProps = {
    stepIndex: stepIndex,
    nextStep: nextStep,
    previousStep: previousStep,
  };

  const steps = [
    <InitialPage nextStep={nextStep} />,
    <PersonalForm {...navigationProps} {...props} />,
    <CompanyForm {...navigationProps} {...props} />,
    <LevelForm {...navigationProps} {...props} />,
    <CompanyGoalsForm {...navigationProps} {...props} />,
    <AvatarForm {...navigationProps} {...props} />,
  ];

  if (stepIndex == 0) {
    return steps[stepIndex];
  }

  return (
    <OnboardingForm
      currentStep={steps[stepIndex]}
      {...navigationProps}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);
  const selector = formValueSelector(FORM_NAME);

  const isForeignUser =
    selector(state, 'companiesAttributes[0][countryId]') !=
    getConfigByName(state, 'defaultCountryId');

  const selectedCountryId = selector(
    state,
    'companiesAttributes[0][countryId]',
  );
  const selectedCurrencyId = selector(
    state,
    'companiesAttributes[0][currencyId]',
  );

  const selectedCountry = getCountryById(state, selectedCountryId);
  const selectedCurrency = getCurrencyById(state, selectedCurrencyId);

  return {
    initialValues: {
      ...currentUser,
      levelId: currentUser?.levelId?.toString(),
    },
    currentUser,
    countries: getCountriesNames(state),
    currencies: getCurrenciesNames(state),
    states: getStateUfs(state),
    companyTimes: getConfig(state, 'companyTimes'),
    companyLocationTypes: getConfig(state, 'companyLocationTypes'),
    companyFlagshipProducts: getConfig(state, 'companyFlagshipProducts'),
    productCompetitions: getConfig(state, 'productCompetitions'),
    investmentCapitals: getConfig(state, 'investmentCapitals'),
    currentRevenues: getConfig(state, 'currentRevenues'),
    customerTypes: getConfig(state, 'customerTypes'),
    levels: getConfig(state, 'levels'),
    hasEmployees: selector(state, 'companiesAttributes[0][hasEmployees]'),
    countryCurrency: getCurrencyByCode(state, selectedCountry?.currency_code),
    selectedCountry,
    selectedCurrency,
    isForeignUser,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: updateProfile,
    enableReinitialize: true,
  })(OnboardingFormContainer),
);
