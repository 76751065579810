import React from 'react';
import Box from './Box';
import Grid from './Grid';
import { utilityColors } from './theme';
import Typography from './Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const containerSx = {
  borderRadius: '10px',
  padding: '13px 16px',
  backgroundColor: `${utilityColors.progress}20`,
  color: utilityColors.progress,
};
const contentSx = {
  color: utilityColors.progress,
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
};

export default function Info(props) {
  const { title, content } = props;
  return (
    <Box sx={containerSx}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={0.8}>
            <InfoOutlinedIcon fontSize="small" />
          </Grid>
          <Grid item>
            <Typography weight="bold !important" sx={contentSx}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography weight="bold !important" sx={contentSx}>
              {content}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
