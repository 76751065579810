import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  changeScenario,
  fetchPricing,
  pricingGenerated,
} from '../../processes/pricingProcesses';
import {
  getSelectedScenario,
  getPricingScenario,
  getPricingData,
} from '../../selectors/pricingSelector';
import Pricing from './Pricing';
import { fetchProducts } from '../../processes/productProcessess';
import {
  getCurrentUser,
  getCurrentUserCompany,
} from '../../selectors/userSelectors';
import { onboardingUser } from '../../processes/profileProcesses';
import { ActionCableConsumer } from 'react-actioncable-provider';
import {
  fetchBackgroundTasks,
  generatePricing,
} from '../../processes/backgroundTaskProcesses';
import { getLastPricingTask } from '../../selectors/backgroundTaskSelectors';
import { getCurrencyById } from '../../selectors/configSelectors';

function PricingContainer(props) {
  const { dispatch, scenario, currentUser, task } = props;
  const [isTasksFetched, setIsTasksFetched] = useState(false);
  const shouldFetchPricing =
    isTasksFetched && (!task || task?.status == 'finished');

  const remakeOnboarding = useCallback(() => {
    onboardingUser(dispatch, currentUser?.id);
  }, [dispatch, currentUser]);

  const changeScenarioHandler = useCallback(
    (_, selectedOption) => {
      const selectedScenario = selectedOption?.props?.value;

      changeScenario(dispatch, selectedScenario);
    },
    [dispatch],
  );

  const recalculatePricing = useCallback(() => {
    generatePricing(dispatch);
  }, [dispatch]);

  const onReceived = useCallback(
    (payload) => {
      pricingGenerated(dispatch, payload);
    },
    [dispatch],
  );

  useEffect(() => {
    if (!currentUser) return;
    if (!currentUser?.companiesAttributes[0]?.desiredSalary) {
      onboardingUser(dispatch, currentUser?.id);
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    fetchProducts(dispatch);
    fetchBackgroundTasks(dispatch).then(() => {
      setIsTasksFetched(true);
    });
  }, [dispatch]);

  useEffect(() => {
    if (shouldFetchPricing) {
      fetchPricing(dispatch);
    }
  }, [dispatch, shouldFetchPricing]);

  const channelOption = { channel: 'PricingsChannel' };

  return (
    <ActionCableConsumer channel={channelOption} onReceived={onReceived}>
      <Pricing
        scenario={scenario}
        changeScenarioHandler={changeScenarioHandler}
        remakeOnboarding={remakeOnboarding}
        recalculatePricing={recalculatePricing}
        {...props}
      />
    </ActionCableConsumer>
  );
}

function mapStateToProps(state) {
  const scenario = getSelectedScenario(state);
  const hasPricing = getPricingData(state)?.length !== 0;
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);

  return {
    currentUser: getCurrentUser(state),
    scenarioData: getPricingScenario(state, scenario),
    loading: state?.pricing?.loading,
    task: getLastPricingTask(state),
    scenario,
    hasPricing,
    currency,
  };
}

export default connect(mapStateToProps)(PricingContainer);
