import { normalize, schema } from 'normalizr';
import { apiDel, apiGet, apiPost, apiPut } from './helpers/api';
import { fetchSupply } from './supplyProcessess';
import toQueryString from '../utils/toQueryString';
import { notifySubmitSucceeded } from './notifierProcesses';

const inventory = new schema.Entity('inventories');

export const fetchInventories = (supplyIdEq, dispatch, props) => {
  const currentPage = props?.currentPage || 1;
  const filters = props?.filters || {};
  const params = toQueryString({ supplyIdEq, ...filters });

  dispatch({ type: 'INVENTORIES_FETCH_REQUESTED' });

  return apiGet(`/api/v1/inventories?${params}page=${currentPage}`).then(
    (response) => {
      dispatch({
        type: 'INVENTORIES_FETCHED',
        ...normalize(response.body.data, new schema.Array(inventory)),
        totalPages: response.body.totalPages,
        currentPage: response.body.currentPage,
      });
    },
  );
};

export const fetchInventory = (dispatch, id) => {
  return apiGet(`/api/v1/inventories/${id}`).then((response) => {
    dispatch({
      type: 'INVENTORY_FETCHED',
      ...normalize(response.body.data, inventory),
    });
  });
};

export const createOrUpdateInventory = (values, dispatch, props) => {
  const handler = !values.id ? createInventory : updateInventory;
  return handler(values, dispatch, props);
};

const createInventory = (values, dispatch, props) => {
  return apiPost('/api/v1/inventories')
    .send({ inventory: values })
    .then((response) => {
      dispatch({
        type: 'INVENTORY_CREATED',
        ...normalize(response.body.data, inventory),
      });
      fetchInventories(values.supplyId, dispatch);
      fetchSupply(dispatch, values.supplyId);
      props.closeInventoryModal();
      notifySubmitSucceeded(dispatch, 'success', 'Estoque adicionado!');
    });
};

const updateInventory = (values, dispatch, props) => {
  return apiPut(`/api/v1/inventories/${props?.inventory?.id}`)
    .send({ inventory: values })
    .then((response) => {
      dispatch({
        type: 'INVENTORY_UPDATED',
        ...normalize(response.body.data, inventory),
      });
      fetchSupply(dispatch, values.supplyId);
      props.closeInventoryModal();
      notifySubmitSucceeded(dispatch, 'success', 'Estoque atualizado!');
    });
};

export const deleteInventory = (dispatch, id) =>
  apiDel(`/api/v1/inventories/${id}`).then(() => {
    dispatch({
      type: 'INVENTORY_DELETED',
      id,
    });
  });

export const submitSearchFilter = (filters, dispatch) => {
  dispatch({
    type: 'INVENTORIES_FILTERS_CHANGED',
    filters,
  });
};
