import React, { useCallback, useEffect } from 'react';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import TaskPage from './TasksPage';
import {
  getTaskPageInfo,
  getTasksByFetchedIds,
} from '../../selectors/taskSelectors';
import { fetchTasks } from '../../processes/taskProcesses';
import { makeIsMobile } from '../../utils/useScreenWidth';
import { formValueSelector } from 'redux-form';

const FORM_NAME = 'taskSearch';

function TasksPageContainer(props) {
  const { dispatch, currentPage, filters } = props;
  const isMobile = makeIsMobile();

  useEffect(() => {
    fetchTasks(dispatch, { currentPage, filters, paged: true });
  }, [dispatch, currentPage, filters]);

  const onAdd = useCallback(() => {
    dispatch(replace('/tasks/new'));
  }, [dispatch]);

  const pageChangeHandler = useCallback(
    (_, currentPage = 1) => {
      dispatch({
        type: 'ORDERS_PAGE_CHANGED',
        currentPage,
      });
    },
    [dispatch],
  );

  return (
    <TaskPage
      onAdd={onAdd}
      onPageChange={pageChangeHandler}
      isMobile={isMobile}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const formSelector = formValueSelector(FORM_NAME);
  const titleCont = formSelector(state, 'titleCont');
  const { currentPage, totalPages, filters, loading } = getTaskPageInfo(state);

  return {
    tasks: getTasksByFetchedIds(state),
    titleCont,
    loading,
    currentPage,
    totalPages,
    filters,
  };
}

export default connect(mapStateToProps)(TasksPageContainer);
