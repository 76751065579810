import { createSelector } from 'reselect';

export const getOrdersItems = (state) => state.entities.ordersItems;
const getProps = (_, props) => props;

const getAllOrdersItems = createSelector(getOrdersItems, (ordersItems) =>
  Object.values(ordersItems),
);

export const getOrdersItemsByOrder = createSelector(
  getAllOrdersItems,
  getProps,
  (ordersItems, { orderId }) =>
    ordersItems.filter((ordersItem) => ordersItem.orderId == orderId),
);
