import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { dismissNotifier } from '../../processes/notifierProcesses';
import Toast from '../library/Toast';

function NotifierContainer(props) {
  const { dispatch } = props;
  const handleClose = useCallback(
    () => dispatch(dismissNotifier()),
    [dispatch],
  );
  return <Toast handleClose={handleClose} {...props} />;
}

function mapStateToProps(state) {
  return {
    message: state.notifier.message,
    type: state.notifier.type,
    icon: state.notifier.icon,
    variant: state.notifier.variant,
    hideDuration: state.notifier.hideDuration,
    visible: state.notifier.visible,
  };
}

export default connect(mapStateToProps)(NotifierContainer);
