import React from 'react';
import EmptyState from '../library/EmptyState';
import Page from '../page/Page';
import ProductListItemContainer from './ProductListItemContainer';
import ProductIcon from '../library/images/products.png';
import ProductSearchFormContainer from './ProductSearchFormContainer';
import Pagination from '../library/Pagination';

const paginationWrapper = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

export default function ProductsPage({
  products,
  onAdd,
  currency,
  loading,
  currentPage,
  totalPages,
  onPageChange,
  isMobile,
}) {
  return (
    <Page back="/" title="Produtos" headerType="secondary">
      <ProductSearchFormContainer onAddButton={onAdd} />
      {!loading && products?.length ? (
        <>
          {products.map((product) => (
            <ProductListItemContainer
              key={product.id}
              product={product}
              currency={currency}
            />
          ))}
          <div style={paginationWrapper}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
              short={isMobile}
            />
          </div>
        </>
      ) : (
        <EmptyState
          iconUrl={ProductIcon}
          loading={loading}
          title="Nenhum produto cadastrado"
          cta="/catalogs/products/new"
          ctaLabel="Cadastrar produto"
        />
      )}
    </Page>
  );
}
