import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import {
  createOrUpdateRecipe,
  fetchRecipe,
  fetchRecipes,
} from '../../processes/recipeProcessess';
import { fetchSupplies } from '../../processes/supplyProcessess';
import {
  getConfig,
  getCurrencyById,
  getUnits,
} from '../../selectors/configSelectors';
import {
  getRecipe,
  getRecipesCostErrors,
  getRecipesForConnections,
  getRecipesTotalCost,
} from '../../selectors/recipeSelectors';
import { getRecipesSuppliesByRecipe } from '../../selectors/recipesSupplySelectors';
import { getIngredients } from '../../selectors/supplySelectors';
import RecipeForm from './RecipeForm';
import { getCurrentUserCompany } from '../../selectors/userSelectors';
import { getRecipesConnectionsByRecipe } from '../../selectors/recipesConnectionSelectors';

const formName = 'recipe';

function RecipeFormContainer(props) {
  const { dispatch, recipeId, recipe } = props;

  useEffect(() => {
    if ((recipe && recipe?.cost) || !recipeId) return;
    fetchRecipe(dispatch, recipeId);
  }, [recipe, recipeId]);

  useEffect(() => {
    fetchSupplies(dispatch);
    fetchRecipes(dispatch);
  }, [dispatch]);

  return <RecipeForm {...props} />;
}

function mapStateToProps(state, { recipeId }) {
  const recipe = getRecipe(state, recipeId);
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);
  const selector = formValueSelector(formName);

  const { recipesSuppliesAttributes, parentConnectionsAttributes } = selector(
    state,
    'recipesSuppliesAttributes',
    'parentConnectionsAttributes',
  );

  return {
    initialValues: {
      ...recipe,
      estimatedProduce: recipe?.estimatedProduce || 1,
      unitId: recipe?.unitId || 1,
      recipesSuppliesAttributes: getRecipesSuppliesByRecipe(state, recipeId),
      parentConnectionsAttributes: getRecipesConnectionsByRecipe(state, {
        recipeId,
      }),
    },
    recipeCategories: getConfig(state, 'recipeCategories'),
    supplies: getIngredients(state),
    recipes: getRecipesForConnections(state, recipeId),
    units: getUnits(state),
    recipeId,
    recipe,
    currency,
    recipesSuppliesAttributes,
    parentConnectionsAttributes,
    costErrors: getRecipesCostErrors(state),
    cost: getRecipesTotalCost(state),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: formName,
    onSubmit: createOrUpdateRecipe,
    enableReinitialize: true,
  })(RecipeFormContainer),
);
