import { createSelector } from 'reselect';

export const getOrdersProducts = (state) => state.entities.ordersProducts;
const getProps = (_, props) => props;

const getAllOrdersProducts = createSelector(
  getOrdersProducts,
  (ordersProducts) => Object.values(ordersProducts),
);

export const getOrdersProductsByOrder = createSelector(
  getAllOrdersProducts,
  getProps,
  (ordersProducts, { orderId }) =>
    ordersProducts.filter((ordersProduct) => ordersProduct.orderId == orderId),
);
