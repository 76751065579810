import React from 'react';
import ExpensesList from './ExpensesList';
import Title from '../library/Title';
import Page from '../page/Page';
import Box from '../library/Box';
import Spinner from '../library/Spinner';
import AddButton from '../library/AddButton';
import StatementSearchFormContainer from './StatementSearchFormContainer';
import { Separator } from '../library';
import PizzaChart from '../library/PizzaChart';

const loadingSx = {
  textAlign: 'center',
};
const wrapperSx = {
  display: 'flex',
  marginBottom: '16px',
};

export default function ExpensesPage({
  expenses,
  expensesCategoriesRanking,
  onAdd,
  loading,
  loadingRanking,
}) {
  return (
    <Page>
      <Title>Pagamentos</Title>
      <StatementSearchFormContainer form="expenseSearch" typeEq="expense" />
      {loading ? (
        <Box pt={6} sx={loadingSx}>
          <Spinner inline />
        </Box>
      ) : (
        <>
          {loadingRanking && (
            <Box pt={6} sx={loadingSx}>
              <Spinner inline />
            </Box>
          )}
          {!loadingRanking && expensesCategoriesRanking?.length > 0 && (
            <>
              <PizzaChart
                title="Pagamentos por categoria"
                data={expensesCategoriesRanking}
                valueName="statementsCount"
              />
              <Separator />
            </>
          )}
          {expenses.length > 0 && (
            <div style={wrapperSx}>
              <AddButton
                onClick={onAdd}
                decrease
                wide
                text="Adicionar pagamento"
              />
            </div>
          )}
          <ExpensesList expenses={expenses} />
        </>
      )}
    </Page>
  );
}
