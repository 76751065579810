import React from 'react';
import { Typography as MuiTypography } from '@mui/material';

export default function Typography({ weight = 'normal', sx, ...props }) {
  const customSx = {
    ...sx,
    fontWeight: weight,
  };

  return (
    <MuiTypography sx={customSx} {...props}>
      {props.children}
    </MuiTypography>
  );
}
