import { connect } from 'react-redux';
import StatementSearchForm from './StatementSearchForm';
import { submitSearchFilter } from '../../processes/statementProcesses';
import { formValueSelector, reduxForm } from 'redux-form';
import moment from 'moment-timezone';

const FORM_NAME = 'statementSearch';

function mapStateToProps(state, ownProps) {
  const valueSelector = formValueSelector(FORM_NAME);

  const today = moment();
  const firstDay = moment(today)?.startOf('month');
  const lastDay = moment(today)?.endOf('month');

  const { typeEq, dateFrom, dateTo } = valueSelector(
    state,
    'typeEq',
    'dateFrom',
    'dateTo',
  );

  return {
    initialValues: {
      typeEq: ownProps.typeEq,
      dateFrom: firstDay,
      dateTo: lastDay,
    },
    typeEq,
    dateFrom,
    dateTo,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    onChange: submitSearchFilter,
  })(StatementSearchForm),
);
