import React, { useCallback } from 'react';
import { Card } from '../library';
import { decorationColors } from '../library/theme';
import { MdFormatListBulleted } from 'react-icons/md';
import { PiTag } from 'react-icons/pi';
import { change } from 'redux-form';

const cardStyle = {
  border: `1px solid ${decorationColors.decorationBrand}`,
  cursor: 'pointer',
};
const iconStyle = {
  color: decorationColors.decorationBrand,
  width: 24,
  height: 24,
};
const OrderTypeField = (props) => {
  const { handleNext, dispatch, form, name, value } = props;

  const toProducts = useCallback(() => {
    dispatch(change(form, name, value));
    handleNext();
  }, [dispatch, form, handleNext, name, value]);

  return <Card {...props} onClick={toProducts} />;
};
export default function OrderFormTypeFields(props) {
  const { dispatch, handleNext, form } = props;

  return (
    <>
      <OrderTypeField
        name="orderType"
        value="standard"
        title="Venda por Catálogo"
        description="Escolha produtos já cadastrados para realizar uma venda"
        sx={cardStyle}
        minHeight="90px"
        icon={<MdFormatListBulleted style={iconStyle} />}
        dispatch={dispatch}
        handleNext={handleNext}
        form={form}
      />
      <OrderTypeField
        name="orderType"
        value="quickSell"
        title="Venda Rápida (produto não cadastrado)"
        description="Faça uma venda de um produto que não foi cadastrado"
        sx={cardStyle}
        minHeight="90px"
        icon={<PiTag style={iconStyle} />}
        dispatch={dispatch}
        handleNext={handleNext}
        form={form}
      />
    </>
  );
}
