import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Logout = (props) => {
  const {color = '#fff', ...rest} = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...rest}
    >
      <g
        id="Group_1178"
        data-name="Group 1178"
        transform="translate(-2035 -284)"
      >
        <rect
          id="Rectangle_417"
          data-name="Rectangle 417"
          width="16"
          height="16"
          transform="translate(2035 284)"
          fill="none"
          opacity="0"
        />
        <g id="Group_284" data-name="Group 284" transform="translate(0 20)">
          <path
            id="XMLID_1889_"
            d="M11,13.9V15H1V1H11V2.65a2.78,2.78,0,0,1,.85.45l.15.15V1a1,1,0,0,0-1-1H1A1,1,0,0,0,0,1V15a1,1,0,0,0,1,1H11a1,1,0,0,0,1-1V13.3l-.15.15A2.78,2.78,0,0,1,11,13.9Z"
            transform="translate(2035 264)"
            fill={color}
          />
          <path
            id="XMLID_16901_"
            d="M16.35,8.8l-.65.8,2.95,2.55H10v1h8.65l-2.95,2.6.65.75,4.4-3.85Z"
            transform="translate(2030 259.6)"
            fill={color}
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Logout;
