import { createSelector } from 'reselect';
import { orderByAlphabetic } from '../utils/orderByAlphabetic';
import { getUnits } from './configSelectors';
import {
  getRecipeSuppliesCostErrors,
  getRecipesSuppliesTotalCost,
} from './recipesSupplySelectors';
import {
  getRecipeConnectionsCostErrors,
  getRecipesConnectionsTotalCost,
} from './recipesConnectionSelectors';

const getRecipes = (state) => state.entities.recipes;
const getId = (_, id) => id;
const getRecipesList = (state) => state.recipesList;
const getFetchedIds = (state) => state.recipesList.recipeIds;

export const getRecipePageInfo = createSelector(
  getRecipesList,
  (recipesList) => ({
    currentPage: recipesList.currentPage,
    totalPages: recipesList.totalPages,
    filters: recipesList.filters,
    loading: recipesList.loading,
  }),
);

export const getAllRecipes = createSelector(getRecipes, (recipes) =>
  Object.values(recipes).sort(orderByAlphabetic),
);

export const getRecipe = createSelector(
  getRecipes,
  getId,
  (recipes, id) => recipes[id],
);

export const getRecipesByFetchedIds = createSelector(
  getAllRecipes,
  getFetchedIds,
  (recipes, ids) =>
    recipes.filter((recipe) => ids.includes(recipe.id)).sort(orderByAlphabetic),
);

export const getProducedRecipes = createSelector(
  getAllRecipes,
  getUnits,
  (recipes, units) =>
    recipes.map(
      (recipe) =>
        (recipe = {
          ...recipe,
          name: !!recipe.unitId
            ? `${recipe.name} - ${recipe?.estimatedProduce} ${
                units.find((unit) => recipe.unitId == unit.id)?.abbreviation
              }`
            : recipe.name,
        }),
    ),
);

export const getRecipesForConnections = createSelector(
  getAllRecipes,
  getId,
  (recipes, id) =>
    recipes.filter((recipe) => {
      if (recipe.isParent) return false;
      if (!!id && recipe?.id == id) return false;

      return true;
    }),
);

export const getRecipesCostErrors = createSelector(
  getRecipeSuppliesCostErrors,
  getRecipeConnectionsCostErrors,
  (rS, rC) => {
    if (!rS && !rC) return false;

    const connectionsErrors = !!rC.length
      ? rC?.map(({ name }) => `\n- Receita: ${name}`)
      : '';

    const errors = !!rS.length
      ? rS?.map(({ name }) => `\n- Insumo: ${name}`)
      : '';

    let result = `O custo estimado da receita não pôde ser calculado devido à impossibilidade de calcular o custo dos seguintes itens de receitas ou insumos:\n${connectionsErrors}${errors}\n\nDica: Verifique se você já adicionou itens em estoque para este(s) insumo(s) com o preço correspondente.`;
    result = result.replace(/,/g, '');

    return result;
  },
);

export const getRecipesTotalCost = createSelector(
  getRecipesSuppliesTotalCost,
  getRecipesConnectionsTotalCost,
  (recipesCost, connectionsCost) => recipesCost + connectionsCost,
);
