import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { signOut } from '../../processes/sessionProcesses';
import { getCurrentUser } from '../../selectors/userSelectors';
import MainMenu from './MainMenu';

function MainMenuContainer(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const onSignOut = useCallback(() => signOut(dispatch), [dispatch]);

  return <MainMenu onSignOut={onSignOut} location={location} {...props} />;
}

function mapStateToProps(state) {
  return {
    currentUser: getCurrentUser(state),
  };
}

export default connect(mapStateToProps)(MainMenuContainer);
