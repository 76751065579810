import React from 'react';
import { spacing } from './theme';
import Box from './Box';
import Spinner from './Spinner';
import Button from './Button';
import { IoIosAdd } from 'react-icons/io';
import Link from './Link';

const container = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: spacing.xl,
  textAlign: 'center',
  gap: spacing.s,
};
const titleStyle = {
  marginBottom: spacing.m,
};
const iconStyle = {
  width: 'auto',
  height: 175,
};
const buttonStyle = {
  backgroundColor: '#41A11F',
  lineHeight: 1,
  width: '210px',
  height: '45px',
  borderRadius: spacing.s,
  fontSize: '14px',
};
const loadingSx = {
  textAlign: 'center',
};

export default function EmptyState({
  title,
  iconUrl,
  cta,
  ctaLabel,
  loading,
  onClick,
}) {
  return loading ? (
    <Box pt={6} sx={loadingSx}>
      <Spinner inline />
    </Box>
  ) : (
    <div style={container}>
      {iconUrl && <img src={iconUrl} style={iconStyle} />}
      {title && <h2 style={titleStyle}>{title}</h2>}
      {(cta || ctaLabel || onClick) && (
        <Link
          component={Button}
          startIcon={<IoIosAdd />}
          to={cta}
          onClick={onClick}
          style={buttonStyle}
        >
          {ctaLabel ?? 'MAIS INFORMAÇÕES'}
        </Link>
      )}
    </div>
  );
}
