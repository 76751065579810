import React from 'react';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { toCurrency } from '../../utils';
import theme from '../library/theme';

const chartStyle = { maxHeight: 170 };

const dataForIncome = (revenue, expense, currency) => ({
  labels: [
    `Despesas - ${toCurrency(expense, currency?.code)}`,
    `Receitas - ${toCurrency(revenue, currency?.code)}`,
  ],
  datasets: [
    {
      label: 'Quantia',
      data: [expense, revenue],
      backgroundColor: [theme.palette.danger.main, theme.palette.add.main],
      borderColor: ['#fff', '#fff'],
    },
  ],
});

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    tooltip: {
      callbacks: {
        title: () => '',
        label: (context) => {
          const title = context.label;
          return `${title}`;
        },
      },
    },
  },
};

export default React.memo(function IncomeDoughnutChart({
  revenue,
  expense,
  currency,
}) {
  return (
    <Doughnut
      data={dataForIncome(revenue, expense, currency)}
      options={options}
      style={chartStyle}
    />
  );
});
