import React from 'react';
import { Box, Title } from '../library';
import DropDownInput from '../library/forms/DropDownInput';
import PricingProductContainer from './PricingProductContainer';

const containerSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
const titleSx = {
  margin: 0,
  padding: 0,
};
const sortOptions = [
  { text: 'Ordenar por vendas', value: 'soldQuantityRanking' },
  { text: 'Ordenar por lucro', value: 'incomeRanking' },
];

export default function PricingProducts(props) {
  const { products, sortBy, scenario, sortChangeHandler, currency } = props;
  return products?.length ? (
    <>
      <Box sx={containerSx}>
        <Title sx={titleSx}>Produtos</Title>
        <DropDownInput
          value={sortBy}
          onChange={sortChangeHandler}
          data={sortOptions}
        />
      </Box>
      {products.map((item, index) => (
        <PricingProductContainer
          key={index}
          product={item}
          sortBy={sortBy}
          scenario={scenario}
          currency={currency}
        />
      ))}
    </>
  ) : null;
}
