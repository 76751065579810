import { Grid } from '@mui/material';
import React from 'react';
import HomeCard from './HomeCard';

export default function HomeCards({ items }) {
  return (
    <Grid container spacing={2}>
      {items.map(
        ({ sx, title, to, icon, iconBackgroundSx }, index) =>
          title && (
            <Grid key={index} item xs={6}>
              <HomeCard
                to={to}
                component={HomeCard}
                width="100%"
                title={title}
                icon={icon}
                iconBackgroundSx={iconBackgroundSx}
                sx={sx}
              />
            </Grid>
          ),
      )}
    </Grid>
  );
}
