import React from 'react';

export default function OrangeFigure(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="561.144"
      height="512.462"
      viewBox="0 0 461.144 412.462"
      {...props}
    >
      <path
        id="Path_2684"
        data-name="Path 2684"
        d="M293.572,112.841c0,58.247-67.059,134.005-142.081,105.465C81.658,191.74,9.409,171.088,9.409,112.841S85.965-24.67,151.49,7.376c69.7,34.091,142.081,47.218,142.081,105.465"
        transform="translate(29.391 132.398)"
        fill="#ef9456"
      />
    </svg>
  );
}
