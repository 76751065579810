import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Box, Card } from '../library';
import formatDate from '../../utils/formatDate';
import theme, { spacing } from '../library/theme';

const containerSx = {
  p: 3,
  borderRadius: spacing.m,
};
const listItemSx = {
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
};
const titleWrapper = {
  position: 'relative',
  lineHeight: 0.5,
};
const title = {
  margin: 0,
  display: 'flex',
  alignItems: 'center',
};
const created = {
  fontSize: 10,
};
const descriptionStyle = {
  paddingTop: spacing.s,
  color: theme.palette.typography.main,
  fontWeight: 'normal',
};
const unread = {
  minHeight: 8,
  maxHeight: 8,
  minWidth: 8,
  maxWidth: 8,
  marginLeft: spacing.xs,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
};

export default function NotificationListItem({
  notification,
  readNotification,
  expand,
  wrapped = false,
}) {
  const WrapperComponent = wrapped ? Card : 'div';
  return (
    <MenuItem key={notification.id} sx={containerSx} onClick={readNotification}>
      <WrapperComponent>
        <Box
          title={notification.title}
          sx={{ width: expand ? 'auto' : 300, ...listItemSx }}
        >
          <div style={titleWrapper}>
            <h4 style={title}>
              {notification.title}
              {!!!notification.readAt && <span style={unread} />}
            </h4>
            <span style={created}>
              {formatDate(notification.createdAt, 'DD/MM/yyyy HH:mm')}
            </span>
          </div>
          <p
            style={descriptionStyle}
            dangerouslySetInnerHTML={{ __html: notification.description }}
          />
        </Box>
      </WrapperComponent>
    </MenuItem>
  );
}
