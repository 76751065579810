import * as React from 'react';
import PasswordInput from './PasswordInput';

const decoratorStyle = {
  border: '1px solid red',
  height: '200px',
  width: '350px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
};

export default {
  title: 'Library/PasswordInput',
  component: PasswordInput,
  argTypes: {},
};

const defaultArgs = {
  placeholder: 'Senha',
};

const Template = (args) => (
  <div style={decoratorStyle}>
    <PasswordInput {...defaultArgs} {...args} />
  </div>
);

export const Fluid = Template.bind({});
Fluid.args = {
  fluid: true,
};
