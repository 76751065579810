import React from 'react';
import { css } from 'glamor';
import Link from '../library/Link';
import theme from '../library/theme';
import Box from '../library/Box';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';

const containerSx = {
  paddingTop: '90px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  ['@media (max-height:875px)']: {
    alignItems: 'end',
    paddingBottom: theme.spacing(4),
  },
};
const backLinkSx = {
  position: 'absolute',
  top: theme.spacing(3),
  left: theme.spacing(6),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3),
  fontWeight: 'bolder',
  cursor: 'pointer',
  color: theme.palette.typography.black,
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    left: theme.spacing(3),
  },
};
const backIconClass = css({
  color: theme.palette.primary.main,
  ':hover': {
    color: theme.palette.primary.active,
  },
});

export default function OnboardingForm(props) {
  const { currentStep } = props;

  return (
    <Box sx={containerSx}>
      <Link component={Box} sx={backLinkSx} onClick={props.previousStep}>
        <MdOutlineArrowBackIosNew className={backIconClass} size={18} />
        Onboarding
      </Link>
      {currentStep}
    </Box>
  );
}
