import React, { useCallback, useState } from 'react';
import PricingProducts from './PricingProducts';

export default function PricingProductsContainer(props) {
  const { products } = props;
  const [sortBy, setSortBy] = useState('soldQuantityRanking');
  const sortChangeHandler = useCallback(
    (_, { props }) => setSortBy(props.value),
    [],
  );
  const productList = products?.sort((a, b) => a[sortBy] - b[sortBy]);

  return (
    <PricingProducts
      {...props}
      sortBy={sortBy}
      products={productList}
      sortChangeHandler={sortChangeHandler}
    />
  );
}
