import React from 'react';

const MailSent = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="121.704"
      height="109.391"
      viewBox="0 0 121.704 109.391"
      {...props}
    >
      <g id="undraw_mail_sent_re_0ofv" transform="translate(0.5 0.539)">
        <path
          id="Path_2586"
          data-name="Path 2586"
          d="M439.374,235.6a.211.211,0,0,1-.121-.038l-58.564-40.786a1.062,1.062,0,0,0-1.213,0L321.333,235.56a.212.212,0,1,1-.243-.347l58.142-40.783a1.487,1.487,0,0,1,1.7,0l58.564,40.786a.212.212,0,0,1-.121.386Z"
          transform="translate(-319.729 -194.163)"
          fill="#0d3c6e"
          stroke="#0d3c6e"
          stroke-width="1"
        />
        <path
          id="Path_2587"
          data-name="Path 2587"
          d="M23.264,49.439,78.748,8.319l55.9,44.046L81.607,83.812l-28.8-6.565Z"
          transform="translate(-18.338 -6.558)"
          fill="#ef9456"
        />
        <path
          id="Path_2588"
          data-name="Path 2588"
          d="M382.459,641.17h-27.41a1.281,1.281,0,1,1,0-2.561h27.41a1.281,1.281,0,1,1,0,2.561Z"
          transform="translate(-345.558 -544.493)"
          fill="#ef9456"
        />
        <path
          id="Path_2589"
          data-name="Path 2589"
          d="M364.883,615.17h-9.834a1.281,1.281,0,1,1,0-2.561h9.834a1.281,1.281,0,0,1,0,2.561Z"
          transform="translate(-345.558 -523.999)"
          fill="#ef9456"
        />
        <path
          id="Path_2590"
          data-name="Path 2590"
          d="M474.326,280.58a1.6,1.6,0,0,1-.608-.119l-34.219-14.2V221.819a1.484,1.484,0,0,1,1.482-1.482h65.646a1.484,1.484,0,0,1,1.482,1.482v44.474l-.064.027-33.094,14.133A1.613,1.613,0,0,1,474.326,280.58Z"
          transform="translate(-413.136 -214.795)"
          fill="#fff"
          stroke="#0d3c6e"
          stroke-width="1"
        />
        <path
          id="Path_2591"
          data-name="Path 2591"
          d="M473.932,280.292a1.71,1.71,0,0,1-.648-.127L439,265.939V221.425a1.59,1.59,0,0,1,1.588-1.588h65.646a1.59,1.59,0,0,1,1.588,1.588v44.544L474.6,280.157A1.718,1.718,0,0,1,473.932,280.292Zm-34.508-14.635,34.022,14.117a1.3,1.3,0,0,0,.988-.006L507.4,265.69V221.425a1.166,1.166,0,0,0-1.165-1.165H440.588a1.166,1.166,0,0,0-1.165,1.165Z"
          transform="translate(-412.742 -214.4)"
          fill="#0d3c6e"
        />
        <path
          id="Path_2592"
          data-name="Path 2592"
          d="M434.221,387.837h-.042l-24.31,10.381-33.261,14.2a1.073,1.073,0,0,1-.822,0L341.47,398.188l-24.907-10.334-.038-.017h-.042A1.484,1.484,0,0,0,315,389.319v64.375a1.484,1.484,0,0,0,1.482,1.482H434.221a1.484,1.484,0,0,0,1.482-1.482V389.319A1.484,1.484,0,0,0,434.221,387.837Zm1.059,65.858a1.059,1.059,0,0,1-1.059,1.059H316.482a1.059,1.059,0,0,1-1.059-1.059V389.319a1.061,1.061,0,0,1,1.019-1.059l25.028,10.385,34.153,14.171a1.508,1.508,0,0,0,1.152-.006l33.094-14.133,24.395-10.416a1.062,1.062,0,0,1,1.016,1.059Z"
          transform="translate(-315 -346.825)"
          fill="#1b5c96"
          stroke="#1b5c96"
          stroke-width="1"
        />
        <path
          id="Path_2593"
          data-name="Path 2593"
          d="M531.4,286.358a5.823,5.823,0,0,1-3.5-1.164l-.063-.047-13.195-10.1a5.862,5.862,0,0,1,7.131-9.307l8.547,6.554,20.2-26.34a5.862,5.862,0,0,1,8.219-1.085l-.126.17.129-.168a5.869,5.869,0,0,1,1.085,8.219L536.067,284.07A5.864,5.864,0,0,1,531.4,286.358Z"
          transform="translate(-470.555 -233.175)"
          fill="#1b5c96"
        />
      </g>
    </svg>
  );
};

export default MailSent;
