import React from 'react';
import { Button, Grid } from '../library';
import CardActions from '../library/cards/CardActions';
import {
  CardForm,
  DateTimeField,
  DropDownField,
  TextField,
} from '../library/forms';
import { spacing } from '../library/theme';
import Page from '../page/Page';
import { makeDropDownOptions } from '../../utils';

const deliveryTimeSx = {
  marginBottom: spacing.s,
};

export default function TaskForm(props) {
  return (
    <Page back="/tasks" title="Tarefa" headerType="secondary" offsetHeader={0}>
      <CardForm {...props}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField name="title" label="Título" fluid />
          </Grid>
          <Grid item xs={12} sx={deliveryTimeSx}>
            <DateTimeField name="scheduleAt" required fluid />
          </Grid>
          <Grid item xs={12} sx={deliveryTimeSx}>
            <DropDownField
              name="status"
              label="Status"
              data={makeDropDownOptions(props?.status)}
              required
              fluid
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Detalhes (opcional)"
              rows={3}
              multiline
              fluid
            />
          </Grid>
        </Grid>
        <CardActions>
          <Button
            type="submit"
            color="success"
            size="large"
            disabled={props.pristine || props.submitting}
            fluid
          >
            {props.task ? 'Atualizar' : 'Adicionar'}
          </Button>
        </CardActions>
      </CardForm>
    </Page>
  );
}
