import { connect } from 'react-redux';
import SupplySearchForm from './SupplySearchForm';
import { submitSearchFilter } from '../../processes/supplyProcessess';
import { change, formValueSelector, reduxForm } from 'redux-form';
import debounce from '../../utils/debounce';
import React, { useCallback } from 'react';
import {
  getSupplyCategoryOptions,
  getUnitOptions,
} from '../../selectors/configSelectors';

const FORM_NAME = 'supplySearch';

function SupplySearchFormContainer(props) {
  const { dispatch } = props;

  const clearHandler = useCallback(() => {
    dispatch(change(FORM_NAME, 'nameCont', ''));
  }, [dispatch]);

  const onButtonsChange = (event, value, fieldName) =>
    onChangeHandler(event, value, fieldName);

  const onChangeHandler = useCallback(
    (_, value, fieldName) => {
      dispatch(change(FORM_NAME, fieldName, value));
    },
    [dispatch],
  );
  const onClearFilters = useCallback(() => {
    dispatch(change(FORM_NAME, 'unitIdEq', ''));
    dispatch(change(FORM_NAME, 'typeEq', ''));
    dispatch(change(FORM_NAME, 'supplyCategoryIdEq', ''));
  }, [dispatch]);

  return (
    <SupplySearchForm
      clearHandler={clearHandler}
      onButtonsChange={onButtonsChange}
      onClearFilters={onClearFilters}
      {...props}
    />
  );
}

const supplyTypes = [
  { text: 'Ingrediente', value: 0 },
  { text: 'Decoração', value: 1 },
  { text: 'Embalagem', value: 2 },
];

function mapStateToProps(state) {
  const formSelector = formValueSelector(FORM_NAME);
  const { nameCont, unitIdEq, typeEq, supplyCategoryIdEq } = formSelector(
    state,
    'nameCont',
    'unitIdEq',
    'typeEq',
    'supplyCategoryIdEq',
  );

  return {
    initialValues: {},
    nameCont,
    unitIdEq,
    typeEq,
    supplyCategoryIdEq,
    unitIdEqOptions: getUnitOptions(state),
    supplyCategoryIdEqOptions: getSupplyCategoryOptions(state),
    typeEqOptions: supplyTypes,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onChange: debounce(submitSearchFilter, 500),
  })(SupplySearchFormContainer),
);
