import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getUnitById } from '../../selectors/configSelectors';
import RecipeListItem from './RecipeListItem';
import {
  deleteRecipe,
  duplicateRecipe,
} from '../../processes/recipeProcessess';

function SuppyListItemContainer(props) {
  const { recipe, dispatch } = props;

  const onEdit = useCallback(
    () => dispatch(push(`/catalogs/recipes/${recipe.id}/edit`)),
    [recipe.id, dispatch],
  );
  const onDelete = useCallback(
    () => deleteRecipe(dispatch, recipe.id),
    [recipe.id],
  );
  const onDuplicate = useCallback(() => {
    duplicateRecipe(dispatch, recipe.id);
  }, [dispatch, recipe.id]);

  return (
    <RecipeListItem
      onDuplicate={onDuplicate}
      onEdit={onEdit}
      onDelete={onDelete}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    unit: getUnitById(state, ownProps.recipe.unitId),
  };
}

export default connect(mapStateToProps)(SuppyListItemContainer);
