import { normalize, schema } from 'normalizr';
import { apiDel, apiGet, apiPost, apiPut } from './helpers/api';
import { replace } from 'connected-react-router';
import toQueryString from '../utils/toQueryString';
import { notifySubmitSucceeded } from './notifierProcesses';

const recipe = new schema.Entity('recipes', {
  meta: {
    recipesSupplies: [new schema.Entity('recipesSupplies')],
    recipesConnections: [new schema.Entity('recipesConnections')],
  },
});

const onlyDeleted = (array) => array.filter((item) => item._destroy == 1);

const metaCleanUp = (dispatch, { recipesSupplies, recipesConnections }) => {
  onlyDeleted(recipesSupplies).map(({ id }) =>
    dispatch({ type: 'RECIPE_SUPPLY_DELETED', id }),
  );

  onlyDeleted(recipesConnections).map(({ id }) =>
    dispatch({ type: 'RECIPE_CONNECTION_DELETED', id }),
  );
};

export const fetchRecipes = (dispatch, props) => {
  const currentPage = props?.currentPage || 1;
  const pageParams = props?.paged == true ? `page=${currentPage}` : '';
  const filterParams = props?.filters
    ? toQueryString({ ...props.filters, page: currentPage })
    : '';
  dispatch({ type: 'RECIPES_FETCH_REQUESTED' });

  return apiGet(`/api/v1/recipes?${filterParams}${pageParams}`).then(
    (response) => {
      dispatch({
        type: 'RECIPES_FETCHED',
        ...normalize(response.body.data, new schema.Array(recipe)),
        totalPages: response.body.totalPages,
        currentPage: response.body.currentPage,
      });
    },
  );
};

export const fetchRecipe = (dispatch, id) => {
  return apiGet(`/api/v1/recipes/${id}`).then((response) => {
    dispatch({
      type: 'RECIPES_FETCHED',
      ...normalize(response.body.data, recipe),
    });
  });
};

export const createOrUpdateRecipe = (values, dispatch, props) => {
  const handler = !values.id ? createRecipe : updateRecipe;
  return handler(values, dispatch, props);
};

const createRecipe = (values, dispatch, props) => {
  const onModal = !!props?.closeCreateModal;

  return apiPost('/api/v1/recipes')
    .send({ recipe: values })
    .then((response) => {
      dispatch({
        type: 'RECIPE_CREATED',
        ...normalize(response.body.data, recipe),
      });
      onModal
        ? props.closeCreateModal()
        : dispatch(replace('/catalogs/recipes'));
      notifySubmitSucceeded(dispatch, 'success', 'Receita adicionada!');
    });
};

const updateRecipe = (values, dispatch, props) => {
  const recipesSupplies = values.recipesSuppliesAttributes;
  const recipesConnections = values.parentConnectionsAttributes;

  return apiPut(`/api/v1/recipes/${props.recipeId}`)
    .send({ recipe: values })
    .then((response) => {
      dispatch({
        type: 'RECIPE_UPDATED',
        ...normalize(response.body.data, recipe),
      });
      metaCleanUp(dispatch, {
        recipesSupplies,
        recipesConnections,
      });
      notifySubmitSucceeded(dispatch, 'success', 'Receita atualizada!');
    });
};

export const deleteRecipe = (dispatch, id) =>
  apiDel(`/api/v1/recipes/${id}`).then(() => {
    dispatch({
      type: 'RECIPE_DELETED',
      id,
    });
  });

export const duplicateRecipe = (dispatch, id) =>
  apiPost(`/api/v1/recipes/${id}/duplicate`).then(() => {
    fetchRecipes(dispatch);
    notifySubmitSucceeded(dispatch, 'success', 'Receita duplicada!');
  });

export const submitSearchFilter = (filters, dispatch) => {
  dispatch({
    type: 'RECIPES_FILTERS_CHANGED',
    filters,
  });
};
