import React from 'react';
import Page from '../page/Page';
import Tabs from '../library/Tabs';
import { spacing } from '../library/theme';

const contentStyle = {
  marginTop: spacing.m,
};

const tabs = [
  { label: 'Geral', to: '/statements' },
  { label: 'Recebimentos', to: '/statements/revenues' },
  { label: 'Pagamentos', to: '/statements/expenses' },
];

export default function StatementsPage(props) {
  return (
    <Page back="/" title="Financeiro" headerType="secondary">
      <Tabs data={tabs}></Tabs>
      <div style={contentStyle}>{props.children}</div>
    </Page>
  );
}
