import React from 'react';
import Typography from './Typography';
import theme from './theme';
import Box from './Box';
import Image from './Image';

const containerSx = {
  height: '80px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  opacity: 0.84,
  backgroundColor: '#FFF7F3',
  borderRadius: '20px',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
};
const imageStyle = {
  margin: 'auto 0',
  borderRadius: '50%',
  height: 48,
  width: 48,
};

export default function Greeting(props) {
  const { name, avatarUrl } = props;

  return (
    <Box sx={containerSx}>
      <Box>
        <Typography variant="h6" color="typography.black">
          Olá, {name}
        </Typography>
        <Typography>Vamos ao trabalho!</Typography>
      </Box>
      <Image style={imageStyle} src={avatarUrl} />
    </Box>
  );
}
