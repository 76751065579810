import { connect } from 'react-redux';
import RecipeSearchForm from './RecipeSearchForm';
import { submitSearchFilter } from '../../processes/recipeProcessess';
import { change, formValueSelector, reduxForm } from 'redux-form';
import debounce from '../../utils/debounce';
import React, { useCallback } from 'react';

const FORM_NAME = 'recipeSearch';

function RecipeSearchFormContainer(props) {
  const { dispatch } = props;
  const clearHandler = useCallback(() => {
    dispatch(change(FORM_NAME, 'nameCont', ''));
  }, [dispatch]);

  return <RecipeSearchForm {...props} clearHandler={clearHandler} />;
}

function mapStateToProps(state) {
  const formSelector = formValueSelector(FORM_NAME);
  const nameCont = formSelector(state, 'nameCont');
  return { initialValues: {}, nameCont };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onChange: debounce(submitSearchFilter, 500),
  })(RecipeSearchFormContainer),
);
