import React from 'react';
import { css } from 'glamor';
import { useCallback } from 'react';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { decorationColors, spacing } from '../theme';

const layout = css({
  position: 'relative',
  width: '100%',
  height: 100,
});
const wrapper = css({
  position: 'absolute',
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  height: '100%',
  boxSizing: 'border-box',
});
const imageInput = {
  position: 'relative',
  padding: '0 0 0 100%',
  opacity: 0,
  maxWidth: '100%',
  boxSizing: 'border-box',
  display: 'block',
  cursor: 'pointer',
};
const iconSx = {
  height: 24,
  width: 24,
  color: decorationColors.decorationBrand,
};
const iconWrapper = css({
  position: 'absolute',
  marginTop: spacing.m,
});
const previewBaseClass = css({
  position: 'absolute',
  borderRadius: '50%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

function freeMemory(url) {
  url && window.URL.revokeObjectURL(url);
}
const doFocus = (e) => e.target.focus();

export default function ImageInput(props) {
  const {
    id,
    value,
    onFileChange,
    clear,
    invalid,
    previewStyle,
    width = 100,
    height = 100,
    ...rest
  } = props;

  let previewUrl = '';
  let objectUrl = '';
  if (typeof value === 'string') {
    previewUrl = value;
  } else if (value) {
    objectUrl = previewUrl = URL.createObjectURL(value);
  }

  React.useEffect(() => () => freeMemory(objectUrl), [objectUrl]);

  const previewClass = css(previewBaseClass, previewStyle && previewStyle, {
    backgroundImage: `url(${previewUrl && previewUrl})`,
    width,
    height,
  });

  const onChangeHandler = useCallback(
    (e) => {
      e.preventDefault();
      onFileChange(e.target.files);
      e.target.value = '';
    },
    [onFileChange],
  );

  return (
    <div>
      <div className={layout}>
        <div className={wrapper}>
          <div className={previewUrl ? previewClass : iconWrapper}>
            {!previewUrl && <AddAPhotoIcon sx={iconSx} />}
          </div>
          <input
            id={props.name}
            accept="image/*"
            {...rest}
            title={value ? value.name : undefined}
            style={{ ...imageInput, height }}
            onClick={doFocus}
            onChange={onChangeHandler}
          />
        </div>
      </div>
    </div>
  );
}
