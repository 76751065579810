import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import {
  createOrUpdateCustomer,
  fetchCustomer,
} from '../../processes/customerProcessess';
import { getConfig } from '../../selectors/configSelectors';
import { getCustomer } from '../../selectors/customerSelectors';
import CustomerForm from './CustomerForm';

const FORM_NAME = 'customerForm';
const BRAZIL_PHONE_COUNTRY_CODE_ID = 32;

function CustomerFormContainer(props) {
  const { dispatch, customerId, customer } = props;

  useEffect(() => {
    if (customer || !customerId) return;
    fetchCustomer(dispatch, customerId);
  }, [customer, customerId]);

  return <CustomerForm {...props} />;
}

function mapStateToProps(state, { customerId }) {
  const valueSelector = formValueSelector(FORM_NAME);

  const customer = getCustomer(state, customerId);

  const { isCompany, phoneCountryCodeId } = valueSelector(
    state,
    'isCompany',
    'phoneCountryCodeId',
  );

  return {
    initialValues: {
      ...customer,
      isCompany: !!customer ? customer?.isCompany.toString() : 'false',
      phoneCountryCodeId:
        customer?.phoneCountryCodeId || BRAZIL_PHONE_COUNTRY_CODE_ID,
    },
    customerId,
    customer,
    customerCategories: getConfig(state, 'customerCategories'),
    customer,
    isCompany: isCompany == 'true',
    skipBrazilianPhoneMask: phoneCountryCodeId != BRAZIL_PHONE_COUNTRY_CODE_ID,
    phoneCountryCodes: getConfig(state, 'phoneCountryCodes'),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: createOrUpdateCustomer,
    enableReinitialize: true,
  })(CustomerFormContainer),
);
