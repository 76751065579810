import React from 'react';
import formatDate from '../../utils/formatDate';
import { Link, Typography } from '../library';
import Box from '../library/Box';
import { Card } from '../library/cards';
import HorizontalItems from '../library/HorizontalItems';
import MoneyInfo from '../library/MoneyInfo';
import { spacing } from '../library/theme';

const infoSx = {
  marginTop: 0,
  marginBottom: 3,
};
const customerNameSx = {
  marginBottom: spacing.m,
};

export default function RevenueListItem(props) {
  const { revenue, onEdit, onDelete, statementCategory, currency } = props;
  const { id, description } = revenue;
  const title = `#${revenue.id}`;

  return (
    <Card
      key={id}
      onEdit={onEdit}
      onDelete={onDelete}
      deleteConfirmText={title}
    >
      <Box sx={infoSx}>
        <Typography variant="p">{title}</Typography>
        <Typography variant="h5" sx={customerNameSx}>
          {revenue?.meta?.buyerName || statementCategory}
        </Typography>
        <HorizontalItems
          items={[
            { title: 'Data', description: formatDate(revenue.date) },
            { title: 'Descrição', description: description },
          ]}
        />
        {revenue?.orderId && (
          <Link to={`/orders/${revenue?.orderId}/edit`}>Ver Pedido</Link>
        )}
      </Box>
      <MoneyInfo label="Recebido" amount={revenue.amount} currency={currency} />
    </Card>
  );
}
