import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, change, reduxForm } from 'redux-form';
import { fetchCustomers } from '../../processes/customerProcessess';
import {
  createOrUpdateOrder,
  fetchOrder,
} from '../../processes/orderProcesses';
import { fetchProducts } from '../../processes/productProcessess';
import {
  getAllCustomers,
  getCustomer,
} from '../../selectors/customerSelectors';
import { getOrder } from '../../selectors/orderSelectors';
import { getAllProducts } from '../../selectors/productSelectors';
import OrderForm from './OrderForm';
import OrderFormTypeFields from './OrderFormTypeFields';
import OrderFormFields from './OrderFormFields';
import { getOrdersProductsByOrder } from '../../selectors/ordersProductSelectors';
import { getConfig, getCurrencyById } from '../../selectors/configSelectors';
import { getOrderStatements } from '../../selectors/statementSelectors';
import { fetchStatements } from '../../processes/statementProcesses';
import { getCurrentUserCompany } from '../../selectors/userSelectors';
import { getOrdersItemsByOrder } from '../../selectors/ordersItemSelectors';

const FORM_NAME = 'orderForm';

function OrderFormContainer(props) {
  const {
    dispatch,
    orderId,
    order,
    ordersProductsAttributes,
    ordersItemsAttributes,
    deliveryFee,
    deliveryType,
    discount,
    discountAmount,
    valid,
    orderType,
    amount,
  } = props;
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    fetchCustomers(dispatch);
    fetchProducts(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!order || !orderId) return;
    fetchStatements(dispatch, { filters: { orderIdEq: orderId } });
  }, [order, orderId]);

  useEffect(() => {
    if (order || !orderId) return;
    fetchOrder(dispatch, orderId);
  }, [order, orderId]);

  useEffect(() => {
    if (order || orderId) {
      setStepIndex(1);
    }
  }, [order, orderId]);

  const updateDiscountAmount = useCallback(
    (_, discount) => {
      const originalAmount = amount / (1 - discount / 100);
      const discountDifference = originalAmount - amount;

      if (discountAmount !== discountDifference) {
        dispatch(change(FORM_NAME, 'discountAmount', discountDifference));
      }
    },
    [amount, dispatch, discountAmount],
  );

  const updateDiscountPercentage = useCallback(
    (_, discountAmount) => {
      const originalAmount = amount / (1 - discount / 100);
      const discountPercentage = (discountAmount / originalAmount) * 100;
      if (discount !== discountPercentage && discountPercentage <= 100) {
        dispatch(change(FORM_NAME, 'discount', discountPercentage));
      }
    },
    [amount, dispatch, discount],
  );

  useEffect(() => {
    const getTotal = (b) => {
      if (b?._destroy) return 0;
      return b?.amount * b?.quantity || b?.price || 0;
    };
    const attributesForAmount =
      orderType == 'standard'
        ? ordersProductsAttributes
        : ordersItemsAttributes;
    const totalItemsAmount = (attributesForAmount || []).reduce(
      (a, b) => a + getTotal(b),
      0,
    );
    const deliveryAmount = deliveryType === 'delivery' ? deliveryFee : 0;
    const orderAmount = totalItemsAmount + deliveryAmount;
    const discountDifference = orderAmount * (discount / 100);
    const totalAmount = orderAmount - discountDifference;
    dispatch(change(FORM_NAME, 'amount', totalAmount));
  }, [
    dispatch,
    ordersProductsAttributes,
    ordersItemsAttributes,
    deliveryFee,
    deliveryType,
    discount,
    orderType,
  ]);

  const nextStep = useCallback(() => {
    if (!valid) return;
    setStepIndex(stepIndex + 1);
  }, [stepIndex, dispatch, valid]);

  const previousStep = useCallback(() => {
    if (stepIndex > 0) setStepIndex(stepIndex - 1);
  }, [stepIndex, dispatch]);

  const navigationProps = {
    stepIndex: stepIndex,
    handleNext: nextStep,
    handlePrevious: previousStep,
    isLastStep: stepIndex == 1,
    isFirstStep: order || orderId ? stepIndex == 1 : stepIndex == 0,
  };

  const steps = [
    <OrderFormTypeFields {...navigationProps} {...props} />,
    <OrderFormFields
      updateDiscountPercentage={updateDiscountPercentage}
      updateDiscountAmount={updateDiscountAmount}
      {...navigationProps}
      {...props}
    />,
  ];

  return (
    <OrderForm currentStep={steps[stepIndex]} {...navigationProps} {...props} />
  );
}

const statusOptions = [
  { name: 'Agendado', id: 'scheduled' },
  { name: 'Em produção', id: 'in_production' },
  { name: 'Finalizado', id: 'completed' },
  { name: 'Entregue', id: 'delivered' },
  { name: 'Cancelado', id: 'canceled' },
];

function mapStateToProps(state, ownProps) {
  const { orderId } = ownProps?.match?.params;
  const order = getOrder(state, orderId);

  const valueSelector = formValueSelector(FORM_NAME);

  const {
    paymentStatus,
    deliveryType,
    deliveryFee,
    amount,
    ordersProductsAttributes,
    ordersItemsAttributes,
    paymentMethodId,
    discount,
    discountAmount,
    orderType,
    customerId,
    discountType,
  } = valueSelector(
    state,
    'paymentStatus',
    'deliveryType',
    'amount',
    'amountPaid',
    'deliveryFee',
    'ordersProductsAttributes',
    'ordersItemsAttributes',
    'paymentMethodId',
    'discount',
    'discountAmount',
    'orderType',
    'customerId',
    'discountType',
  );

  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);
  const initialDiscountAmount =
    !!order && !!discount && discount > 0
      ? amount / (1 - order?.discount / 100) - amount
      : 0;

  return {
    initialValues: {
      ...order,
      ordersProductsAttributes: getOrdersProductsByOrder(state, { orderId }),
      ordersItemsAttributes: getOrdersItemsByOrder(state, { orderId }),
      paymentStatus: order?.paymentStatus || 'paid',
      deliveryType: order?.deliveryType || 'takeaway',
      status: order?.status || 'scheduled',
      amountPaidInitially: 0.0,
      deliveryFee: order?.deliveryFee || 0.0,
      discount: order?.discount || 0.0,
      discountAmount: initialDiscountAmount,
      discountType: 'percentage',
      orderType: order?.orderType || 'standard',
    },
    orderId,
    order,
    paymentStatus,
    paymentMethodId,
    deliveryType,
    deliveryFee,
    amount,
    discount,
    discountAmount,
    discountType,
    statements: getOrderStatements(state, orderId),
    paymentMethods: getConfig(state, 'paymentMethods'),
    customers: getAllCustomers(state),
    products: getAllProducts(state),
    orderReasons: getConfig(state, 'orderReasons'),
    status: statusOptions,
    ordersProductsAttributes,
    ordersItemsAttributes,
    currency,
    orderType,
    customer: getCustomer(state, customerId),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: createOrUpdateOrder,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
  })(OrderFormContainer),
);
