export default function makeSimpleDropDownOptions(
  collection = [],
  blank,
) {
  const options = [];
  if (blank) options.push({ text: blank, value: '' });
  collection
    .map((item) =>
      options.push({
        text: `${item.name || item.title}`,
        value: item.id,
      }),
    );
  return options;
}
