import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BlueHairCookWoman = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="206.354"
      height="349.402"
      viewBox="0 0 206.354 349.402"
      {...props}
    >
      <g
        id="Group_8903"
        data-name="Group 8903"
        transform="translate(-83.905 -80)"
      >
        <path
          id="Path_2684"
          data-name="Path 2684"
          d="M9.409,81.943c0,42.3,48.7,97.311,103.177,76.586C163.3,139.237,215.763,124.24,215.763,81.943S160.17-17.915,112.586,5.357C61.969,30.113,9.409,39.645,9.409,81.943"
          transform="translate(74.496 160.67)"
          fill="#ef9456"
        />
        <g id="undraw_birthday_cake_re_bsw5" transform="translate(93.123 80)">
          <path
            id="Path_2737"
            data-name="Path 2737"
            d="M422.264,187.944l.633,4.106a5.707,5.707,0,0,1-4.215,6.388l1.5,9.707.716-.11a.56.56,0,0,1,.639.469l1.636,10.616a1.845,1.845,0,0,1-3.647.562l-1.635-10.616a.56.56,0,0,1,.469-.639l.715-.11-1.5-9.707a5.706,5.706,0,0,1-5.942-4.822l-.632-4.1a5.706,5.706,0,0,1,4.529-6.458.545.545,0,0,1,.129-.023l.106-.019c.044-.007.087-.011.131-.017a.564.564,0,0,1,.142-.018,5.706,5.706,0,0,1,6.227,4.8m-1.754,21.283-1.431.221,1.55,10.062a.724.724,0,1,0,1.431-.22Zm-4.463-24.313-.089.472a16.782,16.782,0,0,0,1.695,11l.227.423.089-.472a16.782,16.782,0,0,0-1.695-11Zm-3.307,8.7a4.585,4.585,0,0,0,4.229,3.87l-.3-.568a17.907,17.907,0,0,1-1.808-11.739l.119-.633a4.585,4.585,0,0,0-2.868,4.964Zm8.416-5.5a4.585,4.585,0,0,0-4.2-3.868l.3.561a17.908,17.908,0,0,1,1.808,11.739l-.118.625a4.585,4.585,0,0,0,2.837-4.952Z"
            transform="translate(-410.933 -109.098)"
            fill="#19518e"
          />
          <path
            id="Path_2522"
            data-name="Path 2522"
            d="M851.367,545.376l4.783-36.281a118.073,118.073,0,0,1,56.074-9.124c-28.223,19.256-28.536,61.072-48.918,88.493a71.023,71.023,0,0,1-54.725,28.191l-23.225,11.587a119.008,119.008,0,0,1,33.334-93.906,114.956,114.956,0,0,1,22.746-17.729C845.508,530.991,851.367,545.376,851.367,545.376Z"
            transform="translate(-727.803 -280.658)"
            fill="#f2f2f2"
          />
          <path
            id="Path_2523"
            data-name="Path 2523"
            d="M807.774,225.511a20.264,20.264,0,0,1,13.644-11.6c6.685-1.739,16.474.91,21.5,4.713,9.193,6.957,11.437,30.857,5.121,38.41-1.256-.05-5.591-.088-6.863-.124l-1.8-4.552v4.507c-9.925-.233-20.257-.087-30.6.064C807.856,251.58,805.648,230.654,807.774,225.511Z"
            transform="translate(-735.43 -181.492)"
            fill="#19518e"
          />
          <path
            id="Path_2524"
            data-name="Path 2524"
            d="M617.31,454.88h-9.052l-4.307-34.916h13.36Z"
            transform="translate(-543.281 -122.665)"
            fill="#9e616a"
          />
          <path
            id="Path_2525"
            data-name="Path 2525"
            d="M734.123,446.866l-8.76,2.283-12.975-32.7,12.929-3.37Z"
            transform="translate(-580.852 -120.279)"
            fill="#9e616a"
          />
          <ellipse
            id="Ellipse_226"
            data-name="Ellipse 226"
            cx="14.337"
            cy="14.337"
            rx="14.337"
            ry="14.337"
            transform="translate(71.828 41.103)"
            fill="#f0b57d"
          />
          <path
            id="Path_2526"
            data-name="Path 2526"
            d="M809.75,219.463a18.008,18.008,0,0,1,27.379,15.716c-7.815,2-16.144,2.833-24.969.481l-2.018-4.938-1.19,4.941q-4.05,2.849-7.818-.01A18.388,18.388,0,0,1,809.75,219.463Z"
            transform="translate(-733.436 -182.692)"
            fill="#19518e"
          />
          <path
            id="Path_2527"
            data-name="Path 2527"
            d="M800.391,687.305h-6.424l-1.146-6.064-2.936,6.064H772.848a3.83,3.83,0,0,1-2.176-6.981l13.6-9.4V664.8l14.31.854Z"
            transform="translate(-722.312 -337.902)"
            fill="#2c462e"
          />
          <path
            id="Path_2528"
            data-name="Path 2528"
            d="M929.252,677.028l-6.215,1.62-2.639-5.579-1.312,6.609-16.486,4.3a3.829,3.829,0,0,1-3.866-6.206l10.794-12.524-1.546-5.932,14.062-2.783Z"
            transform="translate(-766.933 -335.038)"
            fill="#2c462e"
          />
          <path
            id="Path_2529"
            data-name="Path 2529"
            d="M879.85,545.442c-5.54-20.595-11.7-40.736-26.534-54.535l-4.084-59.928c5.743-10.666-9.169-38.816-9.169-38.816l-5.209-7.584-36.107,6.266h0c-8.45,23.048-12.57,28.731-12.085,72.01s1.665,88.232,1.665,88.232H787.8a3.268,3.268,0,0,0,0,6.535h16.338a3.268,3.268,0,0,0,0-6.535h-.421c9.59-26.957,11.3-68.417,11.729-112.247l13.638,55.391,32.957,51a3.259,3.259,0,0,0,.261,6.509h16.338a3.264,3.264,0,0,0,1.205-6.3Z"
            transform="translate(-727.689 -240.813)"
            fill="#19518e"
          />
          <path
            id="Path_2530"
            data-name="Path 2530"
            d="M845.947,362.317h0a2.605,2.605,0,0,0-2.356-1.957c-4.446-4.513-7.777-44.561-4.28-47.329l-.006-.049-1.042-22.258a3.292,3.292,0,0,0-3.073-3.131l-5.5-.36a.291.291,0,0,1-.281-.291,4.628,4.628,0,0,0-4.628-4.628H808.485a4.333,4.333,0,0,0-4.3,3.793.288.288,0,0,1-.293.259h0a7.741,7.741,0,0,0-7.787,6.42c-2.227,12.96-1.175,40.007,1.032,56.36.768,5.693-5.1,16.621-5.387,21.385a3.506,3.506,0,0,0,.873,2.542,2.609,2.609,0,0,0-1.808,3.142h0a2.614,2.614,0,0,0,3.174,1.9l50.063-12.62A2.614,2.614,0,0,0,845.947,362.317Z"
            transform="translate(-729.837 -205.381)"
            fill="#577c47"
          />
          <path
            id="Path_2531"
            data-name="Path 2531"
            d="M708.266,320.5a6.379,6.379,0,0,1,8.577,4.7l50.306,10.819-7.778,8.846-47.982-12.018a6.413,6.413,0,0,1-3.123-12.348Z"
            transform="translate(-699.836 -218.46)"
            fill="#f0b57d"
          />
          <path
            id="Path_2532"
            data-name="Path 2532"
            d="M781.417,337.216c-7.767,4.065-54.685-11.839-56.734-12.537l-.521-.178,9.649-8.869,37.121,5.436,1.125-21.735A11.627,11.627,0,1,1,794.7,303.6l-9.185,27.619a13.887,13.887,0,0,1-3.524,5.592A2.467,2.467,0,0,1,781.417,337.216Z"
            transform="translate(-706.771 -207.458)"
            fill="#577c47"
          />
          <path
            id="Path_2558"
            data-name="Path 2558"
            d="M849.393,393.647a6.379,6.379,0,0,0,6.074-7.666l18.811-12-10.6-5.126-16.2,12.2a6.413,6.413,0,0,0,1.924,12.591Z"
            transform="translate(-747.863 -235.367)"
            fill="#f0b57d"
          />
          <path
            id="Path_2559"
            data-name="Path 2559"
            d="M854.7,307.958s-11.042-8.929-6.66-15.264,15.571.563,15.571.563l32.571,37.024s-34.6,31.4-33.526,26.74-6.953-8.31-6.953-8.31l15.44-17.365-3.387-1.642,1.382-.795-1.453-3.4Z"
            transform="translate(-749.335 -208.05)"
            fill="#577c47"
          />
          <path
            id="Path_2561"
            data-name="Path 2561"
            d="M868.088,219.972c-1.417-2.173-2.979-4.469-5.384-5.439s-5.816.19-6.143,2.763c-.244,1.923,1.223,3.575,2.328,5.169s1.823,4,.38,5.293l-.812.419a8.111,8.111,0,0,0,6.589,3.144,5.842,5.842,0,0,0,5.38-4.524C870.841,224.365,869.435,222.036,868.088,219.972Z"
            transform="translate(-752.634 -181.778)"
            fill="#19518e"
          />
          <g
            id="Group_7823"
            data-name="Group 7823"
            transform="translate(69.093 49.545)"
          >
            <path
              id="Path_2863"
              data-name="Path 2863"
              d="M424.143,220.718a1.021,1.021,0,0,0,.981,1.039.978.978,0,0,0,1.071-.874c0-.014,0-.028,0-.042a1.024,1.024,0,0,0-.978-1.039.985.985,0,0,0-1.075.885c0,.011,0,.021,0,.032"
              transform="translate(-417.008 -213.937)"
              fill="#263238"
            />
            <path
              id="Path_2864"
              data-name="Path 2864"
              d="M415.377,208.431c.125.136.906-.433,2.009-.423s1.9.566,2.023.433c.057-.061-.068-.3-.419-.559a2.788,2.788,0,0,0-1.619-.512,2.7,2.7,0,0,0-1.6.5c-.329.254-.444.5-.394.555"
              transform="translate(-409.518 -203.337)"
              fill="#263238"
            />
            <path
              id="Path_2865"
              data-name="Path 2865"
              d="M496.439,221.959A1.021,1.021,0,0,0,497.42,223a.978.978,0,0,0,1.071-.874c0-.014,0-.028,0-.042a1.028,1.028,0,0,0-.978-1.039.982.982,0,0,0-1.075.878c0,.013,0,.026,0,.039"
              transform="translate(-478.678 -214.992)"
              fill="#263238"
            />
            <path
              id="Path_2866"
              data-name="Path 2866"
              d="M489.088,210.333c.129.136.91-.433,2.009-.422s1.905.566,2.023.433c.061-.061-.068-.3-.419-.559a2.782,2.782,0,0,0-1.619-.512,2.693,2.693,0,0,0-1.6.505c-.34.254-.458.5-.4.555"
              transform="translate(-472.393 -204.959)"
              fill="#263238"
            />
            <path
              id="Path_2867"
              data-name="Path 2867"
              d="M445.413,218.553a7.6,7.6,0,0,0-1.791-.333c-.279-.032-.548-.089-.591-.283a1.393,1.393,0,0,1,.193-.831c.269-.68.548-1.389.845-2.149a36.993,36.993,0,0,0,1.905-5.59,37.022,37.022,0,0,0-2.349,5.422c-.283.752-.555,1.465-.813,2.149a1.639,1.639,0,0,0-.154,1.1.716.716,0,0,0,.462.416,1.9,1.9,0,0,0,.476.068,6.725,6.725,0,0,0,1.816.029"
              transform="translate(-432.772 -205.039)"
              fill="#263238"
            />
            <path
              id="Path_2868"
              data-name="Path 2868"
              d="M464.11,285.728a1.988,1.988,0,0,1,1.791-.716,1.79,1.79,0,0,1,1.246.68,1.149,1.149,0,0,1,.079,1.311,1.34,1.34,0,0,1-1.432.358,4.2,4.2,0,0,1-1.4-.852,1.226,1.226,0,0,1-.312-.326.358.358,0,0,1,0-.412"
              transform="translate(-451.02 -269.555)"
              fill="#aa6550"
            />
            <path
              id="Path_2869"
              data-name="Path 2869"
              d="M463.185,277.23c-.179,0-.19,1.185-1.221,2.027a6.171,6.171,0,0,1-2.31.867c-.018.075.283.236.82.254a2.971,2.971,0,0,0,1.927-.659,2.576,2.576,0,0,0,.945-1.7c.043-.5-.09-.8-.161-.791"
              transform="translate(-447.299 -262.928)"
              fill="#263238"
            />
            <path
              id="Path_2870"
              data-name="Path 2870"
              d="M485.877,198.633c.107.3,1.207.165,2.507.329s2.335.527,2.507.258c.079-.129-.1-.412-.519-.716a4.241,4.241,0,0,0-1.855-.662,4.176,4.176,0,0,0-1.959.218c-.487.186-.727.419-.68.573"
              transform="translate(-469.664 -195.175)"
              fill="#263238"
            />
            <path
              id="Path_2871"
              data-name="Path 2871"
              d="M418.271,186.416c.193.251.953,0,1.866,0s1.683.179,1.862-.082c.079-.125-.039-.376-.358-.616a2.535,2.535,0,0,0-1.511-.419,2.575,2.575,0,0,0-1.493.491c-.34.247-.444.5-.365.627"
              transform="translate(-411.976 -184.503)"
              fill="#263238"
            />
            <path
              id="Path_2872"
              data-name="Path 2872"
              d="M479.128,185.768a9.752,9.752,0,0,0-.215-1.4,5.51,5.51,0,0,0-.645-1.518,5.728,5.728,0,0,0-6.851-2.421,5.4,5.4,0,0,0-1.432.774,5.251,5.251,0,0,0-1.2,1.21,5.647,5.647,0,0,0,0,6.715,5.246,5.246,0,0,0,1.2,1.211,5.4,5.4,0,0,0,1.432.774,5.73,5.73,0,0,0,2.987.254,5.655,5.655,0,0,0,2.4-1.074,5.731,5.731,0,0,0,1.461-1.615,5.468,5.468,0,0,0,.645-1.518,9.838,9.838,0,0,0,.215-1.4.354.354,0,0,1,0,.093v.276a2.723,2.723,0,0,1-.036.444,4.609,4.609,0,0,1-.108.605,5.548,5.548,0,0,1-.627,1.554,5.811,5.811,0,0,1-7,2.528,5.5,5.5,0,0,1-1.482-.791,6.631,6.631,0,0,1-.666-.566,6.122,6.122,0,0,1-.577-.677,5.823,5.823,0,0,1,0-6.937,6.129,6.129,0,0,1,.577-.677,6.642,6.642,0,0,1,.666-.566,5.5,5.5,0,0,1,1.482-.791,5.843,5.843,0,0,1,7.016,2.528,5.548,5.548,0,0,1,.627,1.554,4.726,4.726,0,0,1,.107.605,2.729,2.729,0,0,1,.036.444v.276a.389.389,0,0,1-.014.111"
              transform="translate(-453.978 -179.902)"
              fill="#263238"
            />
            <path
              id="Path_2873"
              data-name="Path 2873"
              d="M387.194,185.784a9.119,9.119,0,0,0-.215-1.4,5.5,5.5,0,0,0-.645-1.518,5.73,5.73,0,0,0-1.461-1.615,5.656,5.656,0,0,0-2.4-1.074,5.73,5.73,0,0,0-2.987.254,5.4,5.4,0,0,0-1.433.774,5.246,5.246,0,0,0-1.2,1.21,5.662,5.662,0,0,0,0,6.715,5.247,5.247,0,0,0,1.2,1.21,5.4,5.4,0,0,0,1.433.774,5.7,5.7,0,0,0,6.851-2.421,5.463,5.463,0,0,0,.645-1.518,9.188,9.188,0,0,0,.215-1.4.253.253,0,0,1,0,.093v.276a2.7,2.7,0,0,1-.039.458,4.642,4.642,0,0,1-.107.605,5.55,5.55,0,0,1-.609,1.543,5.811,5.811,0,0,1-7,2.528,5.5,5.5,0,0,1-1.483-.791,6.617,6.617,0,0,1-.666-.566,6.122,6.122,0,0,1-.577-.677,5.823,5.823,0,0,1,0-6.937,6.129,6.129,0,0,1,.577-.677,6.6,6.6,0,0,1,.666-.566,5.5,5.5,0,0,1,1.483-.791,5.812,5.812,0,0,1,7,2.539,5.549,5.549,0,0,1,.627,1.554,4.727,4.727,0,0,1,.107.605,2.753,2.753,0,0,1,.036.444v.276a.29.29,0,0,1-.014.1"
              transform="translate(-375.566 -179.919)"
              fill="#263238"
            />
          </g>
          <g
            id="Group_7845"
            data-name="Group 7845"
            transform="translate(72.196 0)"
          >
            <path
              id="Path_2875"
              data-name="Path 2875"
              d="M524.9,50.878c-3.408-5.816-8.174-11.224-14.731-12.791a24.139,24.139,0,0,0-18.905,3.169V29.789l8.184-26.417L505.822,0l15.064,8.617,3.9,31.775Z"
              transform="translate(-491.265 0.001)"
              fill="#ff725e"
            />
            <path
              id="Path_2876"
              data-name="Path 2876"
              d="M524.9,50.878c-3.408-5.816-8.174-11.224-14.731-12.791a24.139,24.139,0,0,0-18.905,3.169V29.789l8.184-26.417L505.822,0l15.064,8.617,3.9,31.775Z"
              transform="translate(-491.265 0.001)"
              fill="#577c47"
            />
            <path
              id="Path_2877"
              data-name="Path 2877"
              d="M491.879,126.34a2,2,0,0,0,.363-.169c.234-.115.562-.313,1-.5a21.9,21.9,0,0,1,3.91-1.463,25.527,25.527,0,0,1,6.119-.88,30.638,30.638,0,0,1,3.656.134,18.488,18.488,0,0,1,3.846.7,25.942,25.942,0,0,1,6.756,3.383,23.494,23.494,0,0,1,4.592,4.124,18.448,18.448,0,0,1,2.313,3.482c.189.433.344.776.463,1.035a1.874,1.874,0,0,0,.174.358,2.072,2.072,0,0,0-.129-.373c-.095-.244-.224-.607-.418-1.065a17.446,17.446,0,0,0-2.259-3.547,23.326,23.326,0,0,0-4.6-4.2,25.647,25.647,0,0,0-6.816-3.433,18.827,18.827,0,0,0-3.9-.706,29.983,29.983,0,0,0-3.681-.119,25.15,25.15,0,0,0-6.164.94,20.38,20.38,0,0,0-3.905,1.552c-.448.214-.771.428-.995.557a2.347,2.347,0,0,0-.318.189"
              transform="translate(-491.566 -96.132)"
              fill="#ef9456"
            />
            <path
              id="Path_2878"
              data-name="Path 2878"
              d="M577.347,27.617c.07,0,1.139-6.025,2.383-13.482S581.929.623,581.86.613,580.72,6.638,579.477,14.1s-2.2,13.512-2.13,13.522"
              transform="translate(-567.079 -0.478)"
              fill="#ef9456"
            />
            <path
              id="Path_2879"
              data-name="Path 2879"
              d="M553.044,37.683c.07,0-.04-5.726-.254-12.791s-.438-12.786-.5-12.786.045,5.731.254,12.8.423,12.786.5,12.781"
              transform="translate(-532.791 -9.457)"
              fill="#ef9456"
            />
            <path
              id="Path_2880"
              data-name="Path 2880"
              d="M516.908,55.715a1.052,1.052,0,0,0,0-.274c0-.209,0-.468-.03-.781-.035-.677-.1-1.662-.194-2.87-.189-2.418-.5-5.761-.995-9.452s-1-6.965-1.388-9.383c-.209-1.2-.388-2.164-.527-2.831-.065-.309-.12-.557-.159-.761a.947.947,0,0,0-.075-.264,1.129,1.129,0,0,0,0,.274c.035.2.07.458.114.771.11.706.264,1.667.443,2.841.373,2.4.851,5.716,1.313,9.388s.821,7,1.055,9.418c.114,1.184.209,2.149.274,2.861.035.318.059.572.084.781a1.019,1.019,0,0,0,.085.284"
              transform="translate(-488.155 -22.727)"
              fill="#ef9456"
            />
            <path
              id="Path_2881"
              data-name="Path 2881"
              d="M601.819,132.754a5.469,5.469,0,0,0,0,1.154c.06.806.13,1.736.2,2.766s.149,1.96.209,2.766a5.387,5.387,0,0,0,.144,1.144,4.752,4.752,0,0,0,.04-1.154c0-.711-.055-1.691-.134-2.776s-.184-2.06-.279-2.766a4.984,4.984,0,0,0-.184-1.134"
              transform="translate(-593.001 -103.68)"
              fill="#ef9456"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BlueHairCookWoman;
