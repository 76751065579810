import React from 'react';

const whiteList = ['reset-password', 'forgotten-password', 'pedidos'];

export default function RequireSignIn(props) {
  const { history, location } = props;

  React.useEffect(() => {
    const currentLocation = location.pathname.split('/', 2)[1];
    if (whiteList.indexOf(currentLocation) != -1) return;
    history.push(`/signin`);
  }, [history]);

  return <div />;
}
