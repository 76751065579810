import React from 'react';
import Box from '../library/Box';
import Spinner from '../library/Spinner';
import Title from '../library/Title';
import Page from '../page/Page';
import RevenuesList from './RevenuesList';
import StatementSearchFormContainer from './StatementSearchFormContainer';
import { Separator } from '../library';
import FutureRevenuesCard from './FutureRevenuesCard';
import { toCurrency } from '../../utils';
import AddButton from '../library/AddButton';

const loadingSx = {
  textAlign: 'center',
};

const wrapperSx = {
  display: 'flex',
  marginBottom: '16px',
};

export default function RevenuesPage({
  currency,
  revenues,
  futureRevenues,
  futureRevenuesAmount,
  onAdd,
  loading,
  loadingFutureRevenues,
}) {
  return (
    <Page>
      <Title>Recebimentos</Title>
      <StatementSearchFormContainer form="revenueSearch" typeEq="revenue" />
      {loadingFutureRevenues && (
        <Box pt={6} sx={loadingSx}>
          <Spinner inline />
        </Box>
      )}
      {!loadingFutureRevenues && futureRevenues?.length > 0 && (
        <>
          <FutureRevenuesCard
            title="Valores a receber"
            description={`${futureRevenues?.length} recebimentos`}
            amount={toCurrency(futureRevenuesAmount, currency?.code)}
          />
          <Separator />
        </>
      )}
      {loading ? (
        <Box pt={6} sx={loadingSx}>
          <Spinner inline />
        </Box>
      ) : (
        <>
          {revenues?.length > 0 && (
            <div style={wrapperSx}>
              <AddButton onClick={onAdd} wide text="Adicionar recebimento" />
            </div>
          )}
          <RevenuesList revenues={revenues} onAdd={onAdd} />
        </>
      )}
    </Page>
  );
}
