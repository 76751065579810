const { SubmissionError } = require('redux-form');
import { put } from 'superagent';
import middleware from './railsMiddleware';

const isFile = (obj) => obj && !!obj.lastModified;

export const updateFile = ({
  file,
  dispatch,
  fieldName,
  path,
  actionFunction,
}) => {
  if (!isFile(file) || !dispatch || !fieldName || !path || !actionFunction) {
    return;
  }

  const req = put(path).use(middleware);

  req.attach(fieldName, file);
  return req
    .then((response) => {
      actionFunction(dispatch, response);
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response?.body?.errors });
    });
};
