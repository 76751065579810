import { hot } from 'react-hot-loader/root';
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import appReducer from '../reducers/appReducer';
import applyDevTools from '../lib/applyDevTools';
import Routes from '../routes';
import { getSession } from '../processes/sessionProcesses';
import { ThemeProvider } from '@mui/material/styles';
import applyGlobalStyles from '../components/library/applyGlobalStyles';
import { CssBaseline } from '@mui/material';
import theme from '../components/library/theme';
import '../lib/i18n';

Bugsnag.start({
  apiKey: '52351a8c529c0c38f6553809e48941a8',
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const currentUserId = getSession()?.currentUserId;
const initialState = { currentUserId };
const browserHistory = createBrowserHistory();
const store = createStore(
  appReducer(browserHistory),
  initialState,
  compose(
    applyMiddleware(routerMiddleware(browserHistory)),
    applyDevTools(window.__REDUX_DEVTOOLS_EXTENSION__),
  ),
);

applyGlobalStyles();

function APP() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes history={browserHistory} />
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
}

if (module.hot) {
  module.hot.accept('../routes', () => {
    APP();
  });
}

export default hot(APP);
