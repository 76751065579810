import { replace } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPricing } from '../../processes/pricingProcesses';
import { downloadArrayAsCsv } from '../../utils/download';
import Spinner from '../library/Spinner';

const getCurrentDateTimeString = () => {
  let date = new Date();
  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let year = date.getFullYear().toString();
  let hour = date.getHours().toString().padStart(2, '0');
  let minute = date.getMinutes().toString().padStart(2, '0');

  return `${day}_${month}_${year}_${hour}h_${minute}m`;
};

const tranlasteScenarioName = (scenario) => {
  switch (scenario) {
    case 'current':
      return 'Cenario_Atual';
    case 'suggested_current':
      return 'Quantidade_Sugerida_E_Valor_Atual';
    case 'suggested':
      return 'Quantidade_Sugerida_E_Valor_Sugerido';
  }
};

const container = {
  display: 'flex',
  justifyContent: 'center',
};

export default function PricingDownload() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function downloadFiles(scenario) {
      const { productsPricing, products } = await fetchPricing(dispatch);

      let currentTime = getCurrentDateTimeString();

      if (scenario == 'intel') {
        return Promise.all([
          downloadArrayAsCsv(productsPricing, `Inteligencia_${currentTime}`),
        ]);
      }

      await Promise.all([
        downloadArrayAsCsv(
          products,
          `Cenario_${tranlasteScenarioName(scenario)}_${currentTime}`,
        ),
      ]);
    }

    const downloadAllFiles = async () => {
      await Promise.all([
        downloadFiles('intel'),
        downloadFiles('current'),
        downloadFiles('suggested_current'),
        downloadFiles('suggested'),
      ]);
    };

    downloadAllFiles();
    dispatch(replace('/pricing'));
  }, [dispatch]);

  return (
    <div style={container}>
      <Spinner />
    </div>
  );
}
