import React from 'react';
import Link from '../library/Link';
import theme from '../library/theme';
import Box from '../library/Box';
import Typography from '../library/Typography';
import MailSent from '../library/images/MailSent';

const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
};
const titleSx = {
  marginTop: theme.spacing(5),
};
const loginStyle = {
  marginTop: theme.spacing(5),
};
const contentSx = {
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 352,
};
const footerSx = {
  textAlign: 'center',
  paddingBottom: theme.spacing(5),
};

export default function EmailSent({resetForm}) {
  return (
    <Box sx={containerSx}>
      <Box sx={contentSx}>
        <MailSent />
        <Typography variant="h4" sx={titleSx}>
          Confira seu email
        </Typography>
        <Typography>Nós enviamos as instruções</Typography>
        <Typography>para recuperar sua senha.</Typography>
        <Link to="/signin" style={loginStyle}>
          VOLTAR PARA O LOGIN
        </Link>
      </Box>
      <Box sx={footerSx}>
        <Typography>Não recebeu o email? Confira sua caixa de spam</Typography>
        <Typography>
          ou <Link onClick={resetForm}>tente outro endereço de email</Link>
        </Typography>
      </Box>
    </Box>
  );
}
