import React from 'react';
import { css } from 'glamor';
import { spacing, styles, utilityColors } from '../theme';

const labelStyles = {
  default: {
    fontWeight: '500',
    textAlign: 'left',
    display: 'inline-block',
    cursor: 'pointer',
    ...styles.bodyText,
    fontSize: 14.5,
  },
  required: {
    ':after': {
      content: "'*'",
      color: utilityColors.danger,
      verticalAlign: 'top',
      marginLeft: spacing.xxs,
    },
  },
  disabled: {
    opacity: 0.5,
  },
};

export default function Label(props) {
  const { children, required, disabled, className, ...rest } = props;
  const label = css(
    labelStyles.default,
    required && labelStyles.required,
    disabled && labelStyles.disabled,
    className,
  );
  return (
    <label className={label} {...rest}>
      {children}
    </label>
  );
}
