import React from 'react';
import CardActions from '../library/cards/CardActions';
import { Grid, Button, Box, Paragraph, Link, Typography } from '../library';
import { CardForm, TextField, DateField, RadioField } from '../library/forms';
import { currencyMask } from '../../utils';
import { spacing, uiColors } from '../library/theme';
import { InputAdornment } from '@mui/material';

const wrapperStyle = {
  marginTop: spacing.m,
  paddingTop: spacing.m,
  borderTop: `1px solid ${uiColors.border}`,
};
const additionSx = {
  display: 'flex',
  gap: 3,
};
const linkStyle = {
  display: 'flex',
  marginTop: spacing.m,
  justifyContent: 'center',
};

export default function InventoryForm(props) {
  const {
    type,
    quantityPerPackage,
    quantity,
    inventory,
    supply,
    openInventoryManager,
    currency,
  } = props;
  const isAdding = type === 'add';
  const typeLabel = isAdding ? 'adicionando' : 'retirando';
  const amountOf = quantityPerPackage
    ? quantity * quantityPerPackage
    : quantity;
  const createText = isAdding ? 'Adicionar ao Estoque' : 'Baixar Estoque';
  const currencySymbol = currency?.symbol ? `(${currency?.symbol})` : '(R$)';
  return (
    <CardForm {...props}>
      <Grid container spacing={3}>
        {!inventory && (
          <Grid item xs={12}>
            <Box sx={additionSx}>
              <RadioField
                name="type"
                value="add"
                label="Adicionar ao Estoque"
              />
              <RadioField
                name="type"
                value="withdraw"
                label="Retirar do Estoque"
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            name="quantity"
            label="Quantidade"
            placeholder="ex: 1, 20, 300..."
            type="number"
            step={1}
            required
            fluid
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="quantityPerPackage"
            label="Quantidade por embalagem"
            disabled={!props.unit}
            type="number"
            fluid
            required
            hint={`Quantidade em ${
              props.unit?.name ? props.unit?.name.toLowerCase() : ''
            } que cada embalagem possui.`}
            InputProps={{
              endAdornment: props.unit ? (
                <InputAdornment position="end">
                  {props.unit?.abbreviation.toLowerCase()}
                </InputAdornment>
              ) : undefined,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {quantity && (
            <Paragraph>
              Você está {typeLabel}{' '}
              <strong>
                {amountOf}
                {props.unit?.abbreviation}
              </strong>{' '}
              de <strong>{supply?.name}.</strong>
            </Paragraph>
          )}
        </Grid>
      </Grid>
      {isAdding && (
        <div style={wrapperStyle}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="price"
                label={`Valor total ${currencySymbol}`}
                fluid
                required
                {...currencyMask(currency?.symbol)}
              />
            </Grid>
            <Grid item xs={12}>
              <DateField name="expiresAt" label="Validade (opcional)" fluid />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="supplier"
                label="Onde comprou (opcional)"
                fluid
              />
            </Grid>
            <Grid item xs={12}>
              <TextField name="brand" label="Marca (opcional)" fluid />
            </Grid>
          </Grid>
        </div>
      )}
      <CardActions>
        <Button
          type="success"
          color={isAdding ? 'success' : 'danger'}
          size="large"
          disabled={(inventory && props.pristine) || props.submitting}
          fluid
        >
          {inventory ? 'Atualizar Estoque' : createText}
        </Button>
      </CardActions>
      {openInventoryManager && (
        <Link
          component={Typography}
          style={linkStyle}
          variant="link"
          onClick={openInventoryManager}
        >
          Ver histórico
        </Link>
      )}
    </CardForm>
  );
}
