import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Calendar = (props) => {
  const {color = '#1b5c96', ...rest} = props;

  return (
    <SvgIcon
      id="Component_57_2"
      data-name="Component 57 – 2"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...rest}
    >
      <rect
        id="Rectangle_8033"
        data-name="Rectangle 8033"
        width="24"
        height="24"
        fill="none"
      />
      <g
        id="Group_7932"
        data-name="Group 7932"
        transform="translate(19698 19218)"
      >
        <g
          id="Group_7932-2"
          data-name="Group 7932"
          transform="translate(515 18.309)"
        >
          <g
            id="Union_1"
            data-name="Union 1"
            transform="translate(-20214.002 -19240.34)"
            fill="none"
          >
            <path
              d="M5,7.032H22a2,2,0,0,1,2,2v16a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2v-16A2,2,0,0,1,5,7.032Z"
              stroke="none"
            />
            <path
              d="M 5.001876831054688 9.031982421875 L 5.001876831054688 25.031982421875 L 22.00187683105469 25.031982421875 L 22.00187683105469 9.031982421875 L 5.001876831054688 9.031982421875 M 5.001876831054688 7.031982421875 L 22.00187683105469 7.031982421875 C 23.10644721984863 7.031982421875 24.00187683105469 7.927412033081055 24.00187683105469 9.031982421875 L 24.00187683105469 25.031982421875 C 24.00187683105469 26.13655281066895 23.10644721984863 27.031982421875 22.00187683105469 27.031982421875 L 5.001876831054688 27.031982421875 C 3.897306442260742 27.031982421875 3.001876831054688 26.13655281066895 3.001876831054688 25.031982421875 L 3.001876831054688 9.031982421875 C 3.001876831054688 7.927412033081055 3.897306442260742 7.031982421875 5.001876831054688 7.031982421875 Z"
              stroke="none"
              fill={color}
            />
          </g>
          <g
            id="Group_7933"
            data-name="Group 7933"
            transform="translate(-0.5 3.191)"
          >
            <line
              id="Line_5"
              data-name="Line 5"
              x2="1"
              transform="translate(-20205.5 -19227.5)"
              fill="none"
              stroke={color}
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="Line_8"
              data-name="Line 8"
              x2="1"
              transform="translate(-20200.504 -19227.5)"
              fill="none"
              stroke={color}
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="Line_9"
              data-name="Line 9"
              x2="1"
              transform="translate(-20195.508 -19227.5)"
              fill="none"
              stroke={color}
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
          <g
            id="Group_7934"
            data-name="Group 7934"
            transform="translate(-0.5 7.191)"
          >
            <line
              id="Line_5-2"
              data-name="Line 5"
              x2="1"
              transform="translate(-20205.5 -19227.5)"
              fill="none"
              stroke={color}
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="Line_8-2"
              data-name="Line 8"
              x2="1"
              transform="translate(-20200.504 -19227.5)"
              fill="none"
              stroke={color}
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="Line_9-2"
              data-name="Line 9"
              x2="1"
              transform="translate(-20195.508 -19227.5)"
              fill="none"
              stroke={color}
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
          <g id="Group_7935" data-name="Group 7935" transform="translate(0 1)">
            <rect
              id="Rectangle_8046"
              data-name="Rectangle 8046"
              width="3"
              height="4"
              rx="1"
              transform="translate(-20207 -19236.309)"
              fill={color}
            />
            <rect
              id="Rectangle_8047"
              data-name="Rectangle 8047"
              width="3"
              height="4"
              rx="1"
              transform="translate(-20197 -19236.309)"
              fill={color}
            />
          </g>
        </g>
        <rect
          id="Rectangle_8048"
          data-name="Rectangle 8048"
          width="19"
          height="3"
          transform="translate(-19695 -19214)"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
};

export default Calendar;
