import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getTask } from '../../selectors/taskSelectors';
import { fetchTask, createOrUpdateTask } from '../../processes/taskProcesses';
import TaskForm from './TaskForm';

const FORM_NAME = 'taskForm';

function TaskContainer(props) {
  const { dispatch, taskId, task } = props;

  useEffect(() => {
    if (task || !taskId) return;
    fetchTask(dispatch, taskId);
  }, [task, taskId]);

  return <TaskForm {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { taskId } = ownProps.match.params;
  const task = getTask(state, taskId);
  const statusOptions = [
    { name: 'Pendente', id: 'pending' },
    { name: 'Em progresso', id: 'in_progress' },
    { name: 'Finalizado', id: 'finished' },
  ];

  return {
    initialValues: { ...task, status: task?.status || 'pending' },
    taskId,
    status: statusOptions,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: createOrUpdateTask,
    enableReinitialize: true,
  })(TaskContainer),
);
