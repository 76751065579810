import React from 'react';
import { makeDropDownOptions } from '../../utils';
import { Form, DropDownField } from '../library/forms';
import AddButton from '../library/AddButton';
import { Grid } from '../library';
import { spacing } from '../library/theme';

const statusSx = {
  mb: 1,
};
const wrapperSx = {
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  marginBottom: spacing.m,
};

export default function InventorySearchForm(props) {
  const { typeEq, onAddButton } = props;
  return (
    <div style={wrapperSx}>
      <Form onSubmit={props.handleSubmit} error={props.error}>
        <Grid item xs={12}>
          <DropDownField
            name="typeEq"
            label="Filtrar por"
            data={makeDropDownOptions(typeEq, 'Exibir Todos')}
            width="100%"
            fluid
            sx={statusSx}
          />
        </Grid>
      </Form>
      <AddButton onClick={onAddButton} />
    </div>
  );
}
