import { createSelector } from 'reselect';

export const getCurrentUserId = (state) => state.currentUserId;
const getUsers = (state) => state.entities.users;

export const getCurrentUser = createSelector(
  getUsers,
  getCurrentUserId,
  (users, id) => users[id],
);

export const getCurrentUserCompany = createSelector(
  getCurrentUser,
  (user) => user?.companiesAttributes[0],
);

export const getCurrentUserTimezone = createSelector(
  getCurrentUser,
  (user) => user?.currentTimezone,
);

export const getCurrentUserAvailableTimezones = createSelector(
  getCurrentUser,
  (user) =>
    user?.availableTimezones?.map((timezone) => ({
      name: timezone.replace(/_/g, ' '),
      value: timezone,
    })),
);
