import React, { useCallback, useState } from 'react';
import { replace } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from './Calendar';
import { getCalendarEvents } from '../../selectors/eventSelectors';
import { makeIsMobile } from '../../utils/useScreenWidth';
import { fetchOrders } from '../../processes/orderProcesses';
import { fetchStatements } from '../../processes/statementProcesses';
import { getCurrentUserTimezone } from '../../selectors/userSelectors';

export default function CalendarContainer(props) {
  const dispatch = useDispatch();
  const events = useSelector((state) => getCalendarEvents(state));
  const timezone = useSelector((state) => getCurrentUserTimezone(state));

  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = useCallback(() => setOpenModal(true), []);
  const closeModalHandler = useCallback(() => setOpenModal(false), []);

  const isMobile = makeIsMobile();

  const eventClickHandler = useCallback(
    (info) => {
      const { objectEndpoint, objectId } = info.event.extendedProps;

      dispatch(replace(`${objectEndpoint}/${objectId}/edit`));
    },
    [dispatch],
  );

  const handleDatesSet = useCallback(
    (calendar) => {
      const { currentStart, currentEnd } = calendar.view;
      const betweenDates = [currentStart, currentEnd];

      fetchOrders(dispatch, {
        filters: { betweenDates, isDeletedEq: false },
      });
      fetchStatements(dispatch, {
        filters: { betweenDates, isDeletedEq: false },
      });
    },
    [dispatch],
  );

  return (
    <Calendar
      openModal={openModal}
      openModalHandler={openModalHandler}
      closeModalHandler={closeModalHandler}
      events={events}
      eventClickHandler={eventClickHandler}
      isMobile={isMobile}
      handleDatesSet={handleDatesSet}
      timezone={timezone}
      {...props}
    />
  );
}
