import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { importSupplies } from '../../processes/backgroundTaskProcesses';
import SuppliesImportForm from './SuppliesImportForm';
import React from 'react';
import { getConfigByName } from '../../selectors/configSelectors';

const FORM_NAME = 'suppliesImport';

const hintsForImport = `1. As unidades de medida devem ser escritas por extenso com a acentuação correta, ou por abreviações.
   Exemplo: Centímetros ou cm;
2. A data de validade do estoque atual é opcional, mas se for escrita, deve respeitar os modelos "dd/mm/aa" ou "dd/mm/aaaa".`;

function SuppliesImportFormContainer(props) {
  return <SuppliesImportForm {...props} />;
}

function mapStateToProps(state) {
  return {
    initialValues: {},
    importTemplateUrl: getConfigByName(state, 'importTemplateUrl'),
    hints: hintsForImport,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: importSupplies,
  })(SuppliesImportFormContainer),
);
