import React from 'react';
import { Pagination } from '@mui/material';
import { css } from 'glamor';
import Typography from './Typography';
import { spacing } from './theme';

const defaultClass = css({
  display: 'flex',
  justifyContent: 'center !important',
});
const paginationStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing.s,
};

export default function ({
  currentPage,
  totalPages,
  totalCount,
  onPageChange,
  className,
  short,
  itemsPerPage = 30,
  ...rest
}) {
  const paginationClass = className ? className : defaultClass;
  return (
    <div style={paginationStyle}>
      <Pagination
        page={currentPage}
        count={totalPages}
        onChange={onPageChange}
        shape="rounded"
        siblingCount={short ? 0 : 1}
        boundaryCount={short ? 0 : 1}
        classes={{ ul: paginationClass }}
        showFirstButton
        showLastButton
        color="primary"
        {...rest}
      />
      {!!totalCount && (
        <Typography>
          Mostrando {currentPage > 1 ? itemsPerPage * (currentPage - 1) + 1 : 1}{' '}
          a{' '}
          {itemsPerPage * currentPage <= totalCount
            ? itemsPerPage * currentPage
            : totalCount}{' '}
          de {totalCount} itens
        </Typography>
      )}
    </div>
  );
}
