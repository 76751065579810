import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getConfigById } from '../../selectors/configSelectors';
import CustomerListItem from './CustomerListItem';
import {
  deleteCustomer,
  sendMessage,
} from '../../processes/customerProcessess';

function CustomerListItemContainer(props) {
  const { customer, dispatch } = props;

  const onEdit = useCallback(
    () => dispatch(push(`/customers/${customer?.id}/edit`)),
    [customer?.id, dispatch],
  );
  const onOrderHistory = useCallback(
    () => dispatch(push(`/customers/${customer?.id}/orders`)),
    [customer?.id, dispatch],
  );
  const onDelete = useCallback(
    () => deleteCustomer(dispatch, customer?.id),
    [customer?.id],
  );
  const onWhatsapp = useCallback(() => sendMessage(customer), [customer]);

  return (
    <CustomerListItem
      onEdit={onEdit}
      onOrderHistory={onOrderHistory}
      onDelete={onDelete}
      onWhatsapp={onWhatsapp}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    customerCategory: getConfigById(
      state,
      'customerCategories',
      ownProps?.customer?.customerCategoryId,
    ),
  };
}

export default connect(mapStateToProps)(CustomerListItemContainer);
