import React from 'react';
import Box from '../library/Box';
import Grid from '../library/Grid';
import Separator from '../library/Separator';
import Title from '../library/Title';
import StatementsTableData from './StatementsTableData';

const containerSx = {
  mt: 3,
};
const titleSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
const lastWrapperSx = {
  marginTop: 1,
};

export default function TopStatements(props) {
  const { statements, type, currency } = props;
  const title = type == 'revenue' ? 'Recebimentos' : 'Pagamentos';
  return (
    <>
      <Separator />
      <Box sx={containerSx}>
        <Box sx={titleSx}>
          <Title p={0}>{`Top 5 ${title}`}</Title>
        </Box>
        <Grid container sx={lastWrapperSx} spacing={2}>
          <StatementsTableData
            statements={statements.slice(0, 5)}
            currency={currency}
            type={type}
          />
        </Grid>
      </Box>
    </>
  );
}
