import React from 'react';
import CardActions from '../library/cards/CardActions';
import { CardForm, DropDownField, TextField } from '../library/forms';
import { Grid, Button } from '../library';
import { makeDropDownOptions } from '../../utils';

const supplyTypes = [
  { text: 'Ingrediente', value: 'ingredient' },
  { text: 'Decoração', value: 'decoration' },
  { text: 'Embalagem', value: 'packaging' },
];

export default function SupplyForm(props) {
  return (
    <CardForm {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField name="name" label="Nome" fluid required />
        </Grid>
        <Grid item xs={12}>
          <DropDownField
            name="supplyCategoryId"
            label="Categoria"
            data={makeDropDownOptions(
              props.supplyCategories,
              'Selecione uma opção',
            )}
            fluid
            required
          />
        </Grid>
        <Grid item xs={12}>
          <DropDownField
            name="type"
            label="Tipo"
            data={supplyTypes}
            fluid
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="minimalQuantity"
            label="Quantidade mínima em estoque"
            fluid
          />
        </Grid>
        <Grid item xs={12}>
          <DropDownField
            name="unitId"
            label="Unidade de medida"
            data={makeDropDownOptions(props.units, 'Selecione uma opção')}
            fluid
            required
          />
        </Grid>
      </Grid>
      <CardActions>
        <Button
          type="button"
          onClick={props.handleSubmit}
          color="success"
          size="large"
          disabled={props.pristine || props.submitting}
          fluid
        >
          {props.supply ? 'Atualizar' : 'Adicionar'}
        </Button>
      </CardActions>
    </CardForm>
  );
}
