import React from 'react';
import {css} from 'glamor';
import Link from '../library/Link';
import Button from '../library/Button';
import {Form, TextField} from '../library/forms';
import theme from '../library/theme';
import Box from '../library/Box';
import PasswordField from '../library/forms/PasswordField';
import Typography from '../library/Typography';
import {MdOutlineArrowBackIosNew} from 'react-icons/md';
import DecoratingCake from '../library/images/DecoratingCake';

const containerSx = {
  paddingTop: '90px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  width: '100%',
  overflow: 'hidden',
};
const backLinkSx = {
  position: 'absolute',
  top: theme.spacing(3),
  left: theme.spacing(6),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3),
  fontWeight: 'bolder',
  cursor: 'pointer',
  color: theme.palette.typography.black,
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    left: theme.spacing(3),
  },
};
const backIconClass = css({
  color: theme.palette.primary.main,
  ':hover': {
    color: theme.palette.primary.active,
  },
});
const subtitleSx = {
  margin: 0,
};
const segmentSx = {
  width: 352,
};
const signInButtonSx = {
  margin: `${theme.spacing(3)} 0 ${theme.spacing(4)} 0`,
};
const footerImageSx = {
  position: 'absolute',
  bottom: '-190px',
  ['@media (max-height:700px)']: {
    display: 'none',
  },
};

export default function SignUpForm(props) {
  return (
    <Box sx={containerSx}>
      <Link component={Box} sx={backLinkSx} to="/signin">
        <MdOutlineArrowBackIosNew className={backIconClass} size={18} /> Voltar
      </Link>
      <Box sx={segmentSx}>
        <Form onSubmit={props.handleSubmit} error={props.error}>
          <Typography variant="h4">Cadastro</Typography>
          <Typography sx={subtitleSx}>
            Faça o cadastro para ter acesso.
          </Typography>
          <TextField
            name="email"
            type="email"
            placeholder="E-mail"
            required
            fluid
            hint="Insira um email válido"
          />
          <PasswordField
            name="password"
            placeholder="Senha"
            required
            fluid
            hint="A senha deve ter ao menos 8 caracteres"
          />
          <PasswordField
            name="passwordConfirmation"
            placeholder="Repetir Senha"
            required
            fluid
            hint="As duas senhas devem ser iguais"
          />
          <Box sx={signInButtonSx}>
            <Button
              onClick={props.handleSubmit}
              color="primary"
              variant="primary"
              disabled={props.pristine || props.submitting}
              fluid
            >
              Cadastrar
            </Button>
          </Box>
        </Form>
      </Box>
      <Box sx={footerImageSx}>
        <DecoratingCake />
      </Box>
    </Box>
  );
}
