import memoize from 'fast-memoize';
import React from 'react';
import { Field } from 'redux-form';
import buildFormField from './buildFormField';
import DateInput from './DateInput';
import moment from 'moment-timezone';

// this needs to be memoizable!
const buildValidator = memoize((required, other) => {
  return [
    (value) => {
      if (required && !value) return 'required';
      if (!!value && !moment(value).isValid()) return 'date';
      return undefined;
    },
  ].concat(other || []);
});

export default function DateField(props) {
  const FormField = buildFormField(DateInput, (input, meta, rest) => {
    return {
      ...input,
      ...rest,
      ...meta,
      invalid: `${meta.touched && (meta.invalid || rest.invalid)}`,
    };
  });

  return (
    <Field
      {...props}
      component={FormField}
      validate={buildValidator(props.required, props.validate)}
    />
  );
}
