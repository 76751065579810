import { SubmissionError } from 'redux-form';
import { normalize, schema } from 'normalizr';
import { apiPost, apiPut } from './helpers/api';
import { notifySubmitSucceeded } from './notifierProcesses';
import { replace } from 'connected-react-router';
import normalizeObject from '../lib/normalizeObject';
import { put } from 'superagent';
import middleware from './helpers/railsMiddleware';
import { setSession } from './sessionProcesses';

export const user = new schema.Entity('users');

const isFile = (obj) => obj && !!obj.lastModified;

const updatedUser = (dispatch, response) => {
  dispatch({
    type: 'USER_UPDATED',
    ...normalize(response.body.data, user),
  });
};

const updateAvatar = (avatar, dispatch) => {
  const req = put('/api/v1/auth').use(middleware);
  req.attach('avatar', avatar);
  return req
    .then((response) => {
      updatedUser(dispatch, response);
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response?.body?.errors });
    });
};

export const updateProfile = (values, dispatch, props) => {
  const { avatarUrl, ...attrs } = values;
  if (isFile(avatarUrl)) updateAvatar(avatarUrl, dispatch);
  return apiPut('/api/v1/auth')
    .send(normalizeObject(attrs, 'snake'))
    .then((response) => {
      updatedUser(dispatch, response);
      notifySubmitSucceeded(dispatch, 'success', 'Perfil atualizado!');
      props.reset();
    })
    .catch((err) => {
      throw new SubmissionError({
        _error: err.response.body.errors.full_messages[0],
      });
    });
};

export const signUp = (values, dispatch) => {
  return apiPost('/api/v1/auth')
    .send(values)
    .then((response) => {
      setSession(response);
      dispatch({
        type: 'USER_FETCHED',
        ...normalize(response.body.data, user),
      });
      dispatch({ type: 'CURRENT_USER_FETCHED', id: response.body.data.id });
      dispatch(replace('/'));
    })
    .catch((err) => {
      throw new SubmissionError({
        _error: err.response.body.errors.full_messages,
      });
    });
};

export const onboardingUser = (dispatch, currentUserId) => {
  return apiPost(`/api/v1/users/${currentUserId}/onboarding`).then(
    (response) => {
      updatedUser(dispatch, response);
    },
  );
};
