import React from 'react';
import EmptyState from '../library/EmptyState';
import HorizontalItems from '../library/HorizontalItems';
import { decorationColors } from '../library/theme';
import Page from '../page/Page';
import HorizontalItem from '../library/HorizontalItem';

const itemsTitleStyle = {
  fontSize: 14,
};
const containerStyle = {
  borderBottom: 'none',
};
const descriptionStyle = {
  color: decorationColors.decorationBrand,
  fontWeight: 'bold',
};

const formatProducts = (products) =>
  products.map((product) => ({
    title: product.name,
    description: product.soldQuantity,
  }));

export default function StatementsProducts({ products, loading }) {
  return (
    <Page
      title="Produtos vendidos por categorias"
      headerType="secondary"
      showMenu={false}
      back="/statements"
    >
      {!loading && products && Object.keys(products).length > 0 ? (
        <>
          {Object.entries(products).map(([categoryId, categoryData]) => (
            <>
              <HorizontalItem
                title={categoryData.name}
                description="Nº vendidos"
                containerStyle={containerStyle}
                titleStyle={{ color: 'black', fontWeight: 'bold' }}
                descriptionStyle={{ fontSize: 12, fontWeight: 'bold' }}
              />
              <HorizontalItems
                key={categoryId}
                titleStyle={itemsTitleStyle}
                containerStyle={containerStyle}
                descriptionStyle={descriptionStyle}
                items={formatProducts(categoryData.products)}
              />
            </>
          ))}
        </>
      ) : (
        <EmptyState loading={loading} title="Nenhum produto encontrado" />
      )}
    </Page>
  );
}
