import { Grid, IconButton, InputAdornment } from '@mui/material';
import React from 'react';
import { makeDropDownOptions } from '../../utils';
import { Form, DropDownField, TextField } from '../library/forms';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddButton from '../library/AddButton';

const statusSx = {
  mb: 1,
};
const wrapperSx = {
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
};

export default function TaskSearchForm(props) {
  const { clearHandler, titleCont, statusEq, onAddButton } = props;
  return (
    <Form onSubmit={props.handleSubmit} error={props.error}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="titleCont"
            label="Nome"
            placeholder="Pesquisar por nome..."
            fluid
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: !!titleCont ? (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={clearHandler}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={12} style={wrapperSx}>
          <DropDownField
            name="statusEq"
            label="Status"
            data={makeDropDownOptions(statusEq, 'Todos')}
            width="100%"
            fluid
            sx={statusSx}
          />
          <AddButton onClick={onAddButton} />
        </Grid>
      </Grid>
    </Form>
  );
}
