import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchBackgroundTasks } from '../../processes/backgroundTaskProcesses';
import SuppliesImportPage from './SuppliesImportPage';
import { getSuppliesImports } from '../../selectors/backgroundTaskSelectors';

function SuppliesImportPageContainer(props) {
  const { dispatch } = props;
  const [openImportModal, setOpenImportModal] = useState(false);

  useEffect(() => {
    fetchBackgroundTasks(dispatch);
  }, [dispatch]);

  const onAdd = useCallback(() => setOpenImportModal(true), []);
  const closeImportModal = useCallback(() => setOpenImportModal(false), []);

  return (
    <SuppliesImportPage
      onAdd={onAdd}
      closeImportModal={closeImportModal}
      openImportModal={openImportModal}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  return {
    backgroundTasks: getSuppliesImports(state),
  };
}

export default connect(mapStateToProps)(SuppliesImportPageContainer);
