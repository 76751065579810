import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import {
  getFetchedStatementsByType,
  getOrdersAverageAmount,
  getOrdersAverageProfitMargin,
  getStatementsTotal,
  getTopStatements,
  getTotalProductsStockAmount,
  getOrdersReasonRanking,
  getProductsCategoryRanking,
} from '../../selectors/statementSelectors';
import GeneralStatementsData from './GeneralStatementsData';
import { getCurrentUserCompany } from '../../selectors/userSelectors';
import { getCurrencyById } from '../../selectors/configSelectors';
import { fetchCustomers } from '../../processes/customerProcessess';
import { fetchProducts } from '../../processes/productProcessess';
import { fetchGeneralData } from '../../processes/statementProcesses';
import { getTopCustomers } from '../../selectors/customerSelectors';
import { getTopProducts } from '../../selectors/productSelectors';
import { toCurrency } from '../../utils';
import { decorationColors } from '../library/theme';
import { useState } from 'react';

const quantityStyle = {
  color: decorationColors.decorationBrand,
  fontWeight: 'bold',
};

function GeneralStatementsDataContainer(props) {
  const { dispatch, totalRevenues, totalExpenses, startDate, endDate } = props;
  const betweenDates = [startDate, endDate];

  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingGeneralData, setLoadingGeneralData] = useState(false);

  if (!totalExpenses && !totalRevenues) {
    return null;
  } else {
    useEffect(() => {
      const fetchCustomersDataAsync = async () => {
        setLoadingCustomers(true);
        await fetchCustomers(dispatch, {
          withAmountSpentInPeriod: betweenDates,
        });
        setLoadingCustomers(false);
      };

      const fetchProductsDataAsync = async () => {
        setLoadingProducts(true);
        await fetchProducts(dispatch, { betweenDates });
        setLoadingProducts(false);
      };

      const fetchGeneralDataAsync = async () => {
        setLoadingGeneralData(true);
        await fetchGeneralData(dispatch, { betweenDates });
        setLoadingGeneralData(false);
      };

      fetchCustomersDataAsync();
      fetchProductsDataAsync();
      fetchGeneralDataAsync();
    }, [dispatch, totalExpenses, totalRevenues]);
  }

  const openProductsDetails = useCallback(
    () => window.open(`/statements/products/${betweenDates}`, '_blank').focus,
    [startDate, endDate],
  );

  return (
    <GeneralStatementsData
      {...props}
      loadingCustomers={loadingCustomers}
      loadingGeneralData={loadingGeneralData}
      loadingProducts={loadingProducts}
      openProductsDetails={openProductsDetails}
    />
  );
}

function mapStateToProps(state) {
  const valueSelector = formValueSelector('statementSearch');

  const { dateFrom: startDate, dateTo: endDate } = valueSelector(
    state,
    'dateFrom',
    'dateTo',
  );
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);
  const customers = getTopCustomers(state);
  const products = getTopProducts(state);

  const topCustomers = customers?.map((customer, index) => ({
    title: `${index + 1}º - ${customer?.name}`,
    description: (
      <>
        <span style={quantityStyle}>{customer?.ordersCount} compras</span>
        <span>Total: {toCurrency(customer?.amountSpent, currency?.code)}</span>
      </>
    ),
  }));
  const topProducts = products?.map((product, index) => ({
    title: `${index + 1}º - ${product?.name}`,
    description: (
      <>
        <span style={quantityStyle}>{product?.soldQuantity} compras</span>
        <span>Total: {toCurrency(product?.soldAmount, currency?.code)}</span>
      </>
    ),
  }));

  return {
    startDate,
    endDate,
    currency,
    revenues: getFetchedStatementsByType(state, 'revenue'),
    expenses: getFetchedStatementsByType(state, 'expense'),
    totalRevenues: getStatementsTotal(state, 'revenue'),
    totalExpenses: getStatementsTotal(state, 'expense'),
    topRevenues: getTopStatements(state, 'revenue'),
    topExpenses: getTopStatements(state, 'expense'),
    topCustomers,
    topProducts,
    productsCategoryRanking: getProductsCategoryRanking(state),
    ordersReasonRanking: getOrdersReasonRanking(state),
    ordersAverageAmount: getOrdersAverageAmount(state),
    totalProductsStockAmount: getTotalProductsStockAmount(state),
    ordersAverageProfitMargin: getOrdersAverageProfitMargin(state),
  };
}

export default connect(mapStateToProps)(GeneralStatementsDataContainer);
