import { Grid } from '@mui/material';
import React from 'react';
import { Separator } from '../library';
import { Form, DateField } from '../library/forms';

export default function StatementSearchForm(props) {
  return (
    <>
      <Form onSubmit={props.handleSubmit} error={props.error}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DateField
              name="dateFrom"
              label="Data Inicial"
              width="100%"
              fluid
            />
          </Grid>
          <Grid item xs={6}>
            <DateField name="dateTo" label="Data Final" width="100%" fluid />
          </Grid>
        </Grid>
      </Form>
      <Separator />
    </>
  );
}
