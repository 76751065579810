import { connect } from 'react-redux';
import CustomerSearchForm from './CustomerSearchForm';
import { submitSearchFilter } from '../../processes/customerProcessess';
import { change, formValueSelector, reduxForm } from 'redux-form';
import React, { useCallback } from 'react';
import { debounce } from '@mui/material';

const FORM_NAME = 'customerSearch';

function CustomerSearchFormContainer(props) {
  const { dispatch } = props;
  const clearHandler = useCallback(() => {
    dispatch(change(FORM_NAME, 'nameCont', ''));
  }, [dispatch]);

  const onClearFilters = useCallback(() => {
    dispatch(change(FORM_NAME, 'birthdayFrom', ''));
    dispatch(change(FORM_NAME, 'birthdayTo', ''));
  }, [dispatch]);

  return (
    <CustomerSearchForm
      {...props}
      onClearFilters={onClearFilters}
      clearHandler={clearHandler}
    />
  );
}

function mapStateToProps(state) {
  const formSelector = formValueSelector(FORM_NAME);
  const { nameCont, birthdayFrom, birthdayTo } = formSelector(
    state,
    'nameCont',
    'birthdayFrom',
    'birthdayTo',
  );
  return { initialValues: {}, nameCont, birthdayFrom, birthdayTo };
}

const validateAndSubmit = (values, dispatch, props, previousValues) => {
  const { birthdayFrom, birthdayTo, nameCont } = values;

  const areBirthdaysValid =
    (!birthdayFrom && !birthdayTo) || (birthdayFrom && birthdayTo);

  const isNameContChanged = previousValues.nameCont !== nameCont;

  const haveBirthdaysChanged =
    birthdayFrom !== previousValues.birthdayFrom ||
    birthdayTo !== previousValues.birthdayTo;

  if (isNameContChanged || (areBirthdaysValid && haveBirthdaysChanged)) {
    submitSearchFilter(values, dispatch);
  }
};

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onChange: debounce(validateAndSubmit, 750),
  })(CustomerSearchFormContainer),
);
