import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import {
  createOrUpdateProduct,
  fetchProduct,
  fetchProducts,
} from '../../processes/productProcessess';
import { fetchRecipes } from '../../processes/recipeProcessess';
import { fetchSupplies } from '../../processes/supplyProcessess';
import { getConfig, getCurrencyById } from '../../selectors/configSelectors';
import {
  getProduct,
  getProductsCostErrors,
  getProductsForBundles,
  getProductsTotalCost,
} from '../../selectors/productSelectors';
import { getProductsRecipesByProduct } from '../../selectors/productsRecipeSelectors';
import { getProductsSuppliesByType } from '../../selectors/productsSupplySelectors';
import { getProducedRecipes } from '../../selectors/recipeSelectors';
import {
  getDecorations,
  getIngredients,
  getPackagings,
} from '../../selectors/supplySelectors';
import ProductForm from './ProductForm';
import { getCurrentUserCompany } from '../../selectors/userSelectors';
import { getProductsBundlesByProduct } from '../../selectors/productsBundleSelectors';

const formName = 'product';

function ProductFormContainer(props) {
  const { dispatch, productId, product } = props;

  useEffect(() => {
    fetchRecipes(dispatch);
    fetchSupplies(dispatch);
    fetchProducts(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if ((product && product?.cost) || !productId) return;
    fetchProduct(dispatch, productId);
  }, [product, productId]);

  return <ProductForm {...props} />;
}

function mapStateToProps(state, { productId }) {
  const product = getProduct(state, productId);
  const selector = formValueSelector(formName);
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);

  const {
    ingredientsAttributes,
    decorationsAttributes,
    packagingsAttributes,
    productsRecipesAttributes,
    productsBundlesAttributes,
  } = selector(
    state,
    'ingredientsAttributes',
    'decorationsAttributes',
    'packagingsAttributes',
    'productsRecipesAttributes',
    'productsBundlesAttributes',
  );

  return {
    initialValues: {
      ...product,
      newProduct: product?.newProduct || 'false',
      inStock: product?.inStock || 0,
      ingredientsAttributes: getProductsSuppliesByType(state, {
        productId,
        type: 'ingredient',
      }),
      decorationsAttributes: getProductsSuppliesByType(state, {
        productId,
        type: 'decoration',
      }),
      packagingsAttributes: getProductsSuppliesByType(state, {
        productId,
        type: 'packaging',
      }),
      productsRecipesAttributes: getProductsRecipesByProduct(state, {
        productId,
      }),
      productsBundlesAttributes: getProductsBundlesByProduct(state, {
        productId,
      }),
    },
    productCategories: getConfig(state, 'productCategories'),
    productId,
    product,
    recipes: getProducedRecipes(state),
    products: getProductsForBundles(state, productId),
    strategicRoles: getConfig(state, 'strategicRoles'),
    newProduct: selector(state, 'newProduct'),
    ingredientsAttributes,
    decorationsAttributes,
    packagingsAttributes,
    productsRecipesAttributes,
    productsBundlesAttributes,
    currency,
    ingredients: getIngredients(state),
    decorations: getDecorations(state),
    packagings: getPackagings(state),
    cost: getProductsTotalCost(state),
    costErrors: getProductsCostErrors(state, productId),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: formName,
    onSubmit: createOrUpdateProduct,
    enableReinitialize: true,
  })(ProductFormContainer),
);
