import React from 'react';

export default function ConciergeBellIcon(props) {
  const { color = '#FFFFFF' } = props;
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 22.4706V20.4706H23V22.4706H10ZM11 19.4706C11 18.1206 11.425 16.9414 12.275 15.9331C13.125 14.9247 14.2 14.3039 15.5 14.0706V13.4456C15.5 13.1622 15.5958 12.9247 15.7875 12.7331C15.9792 12.5414 16.2167 12.4456 16.5 12.4456C16.7833 12.4456 17.0208 12.5414 17.2125 12.7331C17.4042 12.9247 17.5 13.1622 17.5 13.4456V14.0706C18.7833 14.3039 19.8542 14.9247 20.7125 15.9331C21.5708 16.9414 22 18.1206 22 19.4706H11ZM1 13.4706H5V2.47058H1V13.4706ZM7 13.4706H8.975L16.025 10.8456C16.3083 10.7289 16.5417 10.5414 16.725 10.2831C16.9083 10.0247 17 9.73725 17 9.42058C17 9.15391 16.9083 8.92891 16.725 8.74558C16.5417 8.56225 16.3167 8.47058 16.05 8.47058H13L11.25 9.14558L10.9 8.22058L13 7.47058H20C20.5333 7.47058 21 7.27891 21.4 6.89558C21.8 6.51225 22 6.03725 22 5.47058L14 2.47058L7 4.42058V13.4706Z"
        fill={color}
      />
    </svg>
  );
}
