import React from 'react';
import { Card } from '../library/cards';
import HorizontalItems from '../library/HorizontalItems';
import { Box, MoneyInfo } from '../library';
import { spacing } from '../library/theme';

const marginTop = {
  marginTop: spacing.s,
};

export default function CustomerListItem(props) {
  const {
    customer,
    onEdit,
    onDelete,
    onWhatsapp,
    onOrderHistory,
    showOrderHistory = true,
    currency,
  } = props;

  return (
    <Card
      key={customer?.id}
      title={customer?.name}
      onEdit={onEdit}
      onDelete={showOrderHistory && onDelete}
      onWhatsapp={customer?.phone && onWhatsapp}
      onOrderHistory={showOrderHistory && onOrderHistory}
      whatsappActionText="Contato"
      receiver={customer?.phone}
    >
      <HorizontalItems
        compact
        items={[
          {
            title: 'Telefone',
            description:
              customer?.phone &&
              `${customer?.phoneCountryCode || '55'}${customer?.phone}`,
          },
          {
            title: 'Quantidade de pedidos',
            description: customer?.ordersCount,
          },
        ]}
      />
      <Box sx={marginTop}>
        <MoneyInfo
          label="Valor total gasto pelo cliente"
          amount={customer?.amountSpent}
          currency={currency}
        />
      </Box>
    </Card>
  );
}
