import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ptBR from '../locales/pt-BR/app.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      'pt-BR': { app: ptBR },
    },
    debug: process.env.NODE_ENV === 'development',
    lng: 'pt-BR',
    fallbackLng: 'pt-BR',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
