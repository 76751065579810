import { createSelector } from 'reselect';
import orderByDate from '../utils/orderByDate';

const getBackgroundTasks = (state) => state.entities.backgroundTasks;
const getBackgroundTasksList = (state) => state.backgroundTasksList;
export const getFetchedIds = (state) =>
  state.backgroundTasksList.backgroundTasksIds;

export const getBackgroundTasksInfo = createSelector(
  getBackgroundTasksList,
  (backgroundTasksList) => ({
    currentPage: backgroundTasksList.currentPage,
    totalPages: backgroundTasksList.totalPages,
    totalCount: backgroundTasksList.totalCount,
    filters: backgroundTasksList.filters,
    loading: backgroundTasksList.loading,
  }),
);

export const getAllBackgroundTasks = createSelector(
  getBackgroundTasks,
  (tasks) => Object.values(tasks).sort(orderByDate('createdAt')),
);

export const getBackgroundTasksByFetchedIds = createSelector(
  getAllBackgroundTasks,
  getFetchedIds,
  (tasks, ids) =>
    tasks
      ?.filter((task) => ids.includes(task.id))
      .sort(orderByDate('createdAt')),
);

export const getSuppliesImports = createSelector(
  getAllBackgroundTasks,
  (tasks) => tasks?.filter((task) => task.taskType == 'import_supplies'),
);

export const getPricings = createSelector(getAllBackgroundTasks, (tasks) =>
  tasks?.filter((task) => task.taskType == 'pricing'),
);

export const getReports = createSelector(
  getBackgroundTasksByFetchedIds,
  (tasks) =>
    tasks
      ?.filter((task) => task.taskType == 'orders_report')
      .sort(orderByDate('updatedAt', 'desc')),
);

export const getLastPricingTask = createSelector(getPricings, (pricings) =>
  pricings.pop(),
);
