import { toArray } from '../utils/toArray';

const initialState = {
  supplyIds: [],
  loading: false,
  totalPages: 0,
  currentPage: 1,
};

export default function suppliesListReducer(state = initialState, action) {
  switch (action.type) {
    case 'SUPPLIES_FETCH_REQUESTED':
      return { ...state, loading: true };
    case 'SUPPLIES_FETCHED':
      return {
        ...state,
        loading: false,
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        supplyIds: action.result
          ? toArray(action.result)
          : initialState.supplyIds,
      };
    case 'SUPPLIES_PAGE_CHANGED':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case 'SUPPLIES_FILTERS_CHANGED':
      return {
        ...state,
        filters: action.filters,
        currentPage: 1,
      };
    default:
      return state;
  }
}
