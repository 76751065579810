import React, { useCallback, useState } from 'react';
import Select from '@mui/material/Select';
import theme, { spacing, uiColors } from '../theme';
import { MenuItem, styled } from '@mui/material';
import Typography from '../Typography';
import Box from '../Box';

const CustomDropDownInput = styled(Select)({
  '& .MuiInputBase-input': {
    height: 48,
    boxSizing: 'border-box',
    borderRadius: '4px !important',
    border: 'none !important',
  },
  '&.MuiInputBase-root': {
    borderWidth: '1px',
    borderRadius: spacing.xxs,
    borderColor: theme.palette.border.main,
    '&:hover fieldset': {
      borderColor: theme.palette.border.hover,
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.primary.active,
    },
  },
});
const menuItemXs = {
  color: theme.palette.typography.black,
  fontWeight: 500,
};
const hintWrapperSx = {
  display: 'inline-block',
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  maxWidth: 220,
};
const hintStyle = {
  fontSize: 13,
  fontWeight: 'normal',
  color: theme.palette.typography.main,
};

export default function DropDownInput(props) {
  const { sx, fluid, data, ...rest } = props;
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);
  const customSx = {
    background: uiColors.fieldBackground,
    height: 48,
    width: fluid ? '100%' : 'auto',
  };

  return (
    <CustomDropDownInput
      open={open}
      onOpen={toggleOpen}
      onClose={toggleOpen}
      displayEmpty
      sx={{ ...customSx, ...sx }}
      {...rest}
    >
      {data.map((item) => (
        <MenuItem key={`${item.text}-${item.value}`} value={item.value}>
          <Box>
            <Typography sx={menuItemXs}>{item.text}</Typography>
            {!!item?.hint && open ? (
              <Box sx={hintWrapperSx}>
                <span variant="formTitle" style={hintStyle}>
                  {item.hint}
                </span>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </MenuItem>
      ))}
    </CustomDropDownInput>
  );
}
