import { toArray } from '../utils/toArray';

const initialState = {
  customerIds: [],
  loading: false,
  totalPages: 0,
  currentPage: 1,
  totalCount: 0,
};

export default function customersListReducer(state = initialState, action) {
  switch (action.type) {
    case 'CUSTOMERS_FETCH_REQUESTED':
      return { ...state, loading: true };
    case 'CUSTOMER_SEARCH_FETCHED':
    case 'CUSTOMERS_FETCHED':
      return {
        ...state,
        loading: false,
        totalPages: action.totalPages,
        totalCount: action.totalCount,
        currentPage: action.currentPage,
        customerIds: action.result
          ? toArray(action.result)
          : initialState.customerIds,
      };
    case 'CUSTOMERS_PAGE_CHANGED':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case 'CUSTOMERS_FILTERS_CHANGED':
      return {
        ...state,
        filters: action.filters,
        currentPage: 1,
      };
    default:
      return state;
  }
}
