import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCustomer } from '../../selectors/customerSelectors';
import {
  getOrderByUuid,
  getOrderItemsPreview,
  getOrderProductsPreview,
} from '../../selectors/orderSelectors';
import Budget from './Budget';
import { fetchBudget } from '../../processes/orderProcesses';
import { getCurrencyById } from '../../selectors/configSelectors';

function BudgetContainer(props) {
  const { dispatch } = props;
  const id = props?.id || props?.match?.params?.id;

  useEffect(() => {
    fetchBudget(dispatch, id);
  }, [id, dispatch]);

  return <Budget {...props} />;
}

function mapStateToProps(state, ownProps) {
  const id = ownProps?.id || ownProps?.match?.params?.id;
  const order = getOrderByUuid(state, id);
  const customer = order?.meta?.customer;
  const company = order?.meta?.company;
  const currency = getCurrencyById(state, company?.currencyId);

  return {
    order,
    company,
    currency,
    customer: getCustomer(state, customer),
    products: getOrderProductsPreview(state, order?.id),
    items: getOrderItemsPreview(state, order?.id),
  };
}

export default connect(mapStateToProps)(BudgetContainer);
