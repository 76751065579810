import { css } from 'glamor';
import { styles, typographyColors, uiColors } from './theme';

export default function applyGlobalStyles() {
  css.global('body', {
    ...styles.defaultFontFamily,
    fontSize: 12,
  });

  css.global('h1, h2, h3, h4, h5, h6, p', {
    ...styles.defaultFontFamily,
    color: typographyColors.defaultTitle,
    fontWeight: 'bolder',
  });

  css.global('.MuiOutlinedInput-input.MuiInputBase-input.Mui-disabled', {
    color: '#CCCCCC',
    background: '#F4F4F4',
    border: '1px solid #DDDDDD',
  });

  // CALENDAR STYLE
  css.global('.fc-event-main, .fc-daygrid-event-harness, .fc-list-event', {
    cursor: 'pointer',
  });

  css.global('.fc-event-title', {
    textOverflow: 'ellipsis',
  });

  css.global('.fc .fc-button-primary', {
    backgroundColor: uiColors.background,
    borderColor: uiColors.background,
  });

  css.global('.fc .fc-button-primary:hover', {
    backgroundColor: uiColors.background,
    borderColor: uiColors.background,
    opacity: 0.9,
  })

  css.global('.fc .fc-button-primary:not(:disabled).fc-button-active', {
    backgroundColor: uiColors.background,
    borderColor: uiColors.background,
  });

  css.insert('@media (max-width: 768px) { .fc .fc-toolbar { display: block; } }')
  css.insert('@media (max-width: 768px) { .fc .fc-toolbar-title { font-size: 1.25em; margin: 20px 0; } }')
}
