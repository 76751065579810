import { css } from 'glamor';
import React from 'react';
import { IoLogoWhatsapp } from 'react-icons/io';
import Button from './Button';

const linkWrapper = css({
  backgroundColor: 'white',
  borderRadius: 10,
});
const iconStyle = { color: '#40C351', width: 18, height: 18 };
const supportSx = {
  color: '#40C351',
  borderRadius: '40px',
  border: '0.5px solid #40C351',
  fontWeight: 500,
  lineHeight: 1,
  height: '32px',
};

export default function WhatsappButton({ phone }) {
  return (
    <div className={linkWrapper}>
      <Button
        href={`https://api.whatsapp.com/send/?phone=${phone}`}
        target="_blank"
        variant="outlined"
        startIcon={<IoLogoWhatsapp style={iconStyle} />}
        style={supportSx}
      >
        Fale com o nosso suporte
      </Button>
    </div>
  );
}
