import { orderByAlphabetic } from './orderByAlphabetic';
import { onlyNotSoftDeleted } from './onlyNotSoftDeleted';

export default function makeDropDownOptions(
  collection = [],
  blank,
  hintEnabled = false,
  sort = orderByAlphabetic,
) {
  const options = [];
  if (blank) options.push({ text: blank, value: '' });
  collection
    .filter(onlyNotSoftDeleted)
    .sort(sort)
    .map((item) =>
      options.push({
        text: `${item.name || item.title}`,
        hint: hintEnabled ? item.description : undefined,
        value: item.id || item.value,
        key: item.id || item.value,
      }),
    );
  return options;
}
