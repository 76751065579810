import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import { orderByAlphabetic } from '../utils/orderByAlphabetic';

const getProductsBundles = (state) => state.entities.productsBundles;
const getProducts = (state) => state.entities.products;
const getProps = (_, props) => props;

const getAllProducts = createSelector(getProducts, (products) =>
  Object.values(products).sort(orderByAlphabetic),
);

function formState(state) {
  return getFormValues('product')(state);
}

const getAllProductsBundles = createSelector(
  getProductsBundles,
  (productsBundles) => Object.values(productsBundles),
);

export const getProductsBundlesByProduct = createSelector(
  getAllProductsBundles,
  getProps,
  (productsBundles, { productId }) => {
    return productsBundles.filter(
      (productBundles) => productBundles.containerProductId == productId,
    );
  },
);

export const getProductBundles = createSelector(formState, (form) =>
  form?.productsBundlesAttributes?.filter((bI) => !!!bI?._destroy),
);

export const getProductBundlesCostErrors = createSelector(
  getProductBundles,
  getAllProducts,
  (productBundles, products) => {
    const productsIds = productBundles?.map(
      (productBundle) => productBundle?.bundledProductId,
    );
    const result = products?.filter(
      (product) => product?.cost <= 0 && productsIds?.includes(product?.id),
    );

    return !!result?.length ? result : false;
  },
);

export const getProductsBundlesTotalCost = createSelector(
  getProductBundles,
  getAllProducts,
  (productBundles, products) => {
    const getProduct = (id) => products.find((p) => p.id == id);

    return productBundles
      ?.map((item) => {
        const product = getProduct(item?.bundledProductId);
        return product?.cost * item?.quantity;
      })
      .reduce((acc, num) => (acc += num), 0);
  },
);

export const getProductBundlesTotalCost = createSelector(
  getProductsBundlesByProduct,
  getAllProducts,
  (productBundles, products) => {
    const getProduct = (id) => products.find((p) => p.id == id);

    return productBundles
      ?.map((item) => {
        const product = getProduct(item?.bundledProductId);
        return product?.cost * item?.quantity;
      })
      .reduce((acc, num) => (acc += num), 0);
  },
);
