const initialState = {};

export default function tasksReducer(state = initialState, action) {
  switch (action.type) {
    case 'TASKS_FETCHED':
    case 'TASK_FETCHED':
    case 'TASK_UPDATED':
    case 'TASK_CREATED':
      return { ...state, ...action.entities.tasks };
    case 'TASK_DELETED':
      if (state[action.id]) {
        let newState = { ...state };
        delete newState[action.id];
        return newState;
      }
    default:
      return state;
  }
}
