import { createNumberMask, createTextMask } from 'redux-form-input-masks';

const maskDefinitions = {
  0: {
    regExp: /[0-9]/,
  },
  9: {
    regExp: /[0-9]/,
  },
};

export const currencyMask = (prefix = 'R$') => {
  const maskOptions = {
    type: 'text',
    inputMode: 'numeric',
    ...createNumberMask({
      prefix: `${prefix} `,
      decimalPlaces: 2,
      locale: 'pt-BR',
      allowEmpty: true,
    }),
  };

  return maskOptions;
};

export const percentageMask = {
  type: 'text',
  inputMode: 'numeric',
  ...createNumberMask({
    suffix: '%',
    decimalPlaces: 2,
    locale: 'pt-BR',
    allowEmpty: true,
  }),
};

export const phoneMask = {
  type: 'tel',
  inputMode: 'numeric',
  ...createTextMask({
    pattern: '(00) 9 0000-0000',
    guide: false,
    allowEmpty: true,
    maskDefinitions,
  }),
};

export const cnpjMask = {
  type: 'cnpj',
  inputMode: 'numeric',
  ...createTextMask({
    pattern: '00.000.000/0000-00',
    guide: false,
    allowEmpty: true,
    maskDefinitions,
  }),
};

export const cpfMask = {
  type: 'cpf',
  inputMode: 'numeric',
  ...createTextMask({
    pattern: '000.000.000-00',
    guide: false,
    allowEmpty: true,
    maskDefinitions,
  }),
};
