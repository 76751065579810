import React from 'react';
import { css } from 'glamor';
import Link from '../library/Link';
import Button from '../library/Button';
import { Form, TextField } from '../library/forms';
import theme, { spacing, typographyColors } from '../library/theme';
import Box from '../library/Box';
import Typography from '../library/Typography';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import WithDoubt from '../library/images/WithDoubt';

const containerSx = {
  paddingTop: '90px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  width: '100%',
  overflow: 'hidden',
};
const backLinkSx = {
  position: 'absolute',
  top: theme.spacing(3),
  left: theme.spacing(6),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3),
  fontWeight: 'bolder',
  cursor: 'pointer',
  color: theme.palette.typography.black,
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    left: theme.spacing(3),
  },
};
const backIconClass = css({
  color: theme.palette.primary.main,
  ':hover': {
    color: theme.palette.primary.active,
  },
});
const segmentSx = {
  width: 352,
};
const footerImageSx = {
  position: 'absolute',
  bottom: '-190px',
  ['@media (max-height:750px)']: {
    display: 'none',
  },
};
const linkClass = css({
  color: typographyColors.inverseTitle,
  '&:hover': { color: 'rgba(0,0,0,0.5)' },
});
const buttonMargin = css({ marginTop: spacing.m });

export default function ForgottenPasswordForm(props) {
  return (
    <Box sx={containerSx}>
      <Link component={Box} sx={backLinkSx} to="/signin">
        <MdOutlineArrowBackIosNew className={backIconClass} size={18} /> Voltar
      </Link>
      <Box sx={segmentSx}>
        <Form error={props.error}>
          <Typography variant="h4" pb={3}>
            Nova senha
          </Typography>
          <TextField
            name="password"
            type="password"
            icon="lock"
            iconPosition="left"
            label="Nova Senha"
            placeholder="*******"
            required
            fluid
          />
          <TextField
            name="password_confirmation"
            type="password"
            icon="lock"
            iconPosition="left"
            label="Confirme a Nova Senha"
            placeholder="*******"
            required
            fluid
          />
          <div className={buttonMargin}>
            <Button
              onClick={props.handleSubmit}
              disabled={props.pristine || props.submitting}
              fluid
            >
              Criar nova senha
            </Button>
          </div>
          <Link to="/signin" className={linkClass}>
            Voltar para o login
          </Link>
        </Form>
      </Box>
      <Box sx={footerImageSx}>
        <WithDoubt />
      </Box>
    </Box>
  );
}
