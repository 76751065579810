import { toArray } from '../utils/toArray';

const initialState = {
  backgroundTasksIds: [],
  loading: false,
  totalPages: 0,
  currentPage: 1,
  totalCount: 0,
};

export default function backgroundTasksListReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case 'BACKGROUND_TASKS_FETCH_REQUESTED':
      return { ...state, loading: true };
    case 'BACKGROUND_TASKS_FETCHED':
      return {
        ...state,
        loading: false,
        totalPages: action.totalPages,
        totalCount: action.totalCount,
        currentPage: action.currentPage,
        backgroundTasksIds: action.result
          ? toArray(action.result)
          : initialState.backgroundTasksIds,
      };
    case 'BACKGROUND_TASKS_PAGE_CHANGED':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    default:
      return state;
  }
}
