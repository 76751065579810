import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import allLocales from '@fullcalendar/core/locales-all';
import Modal from '../library/Modal';
import Link from '../library/Link';
import { spacing } from '../library/theme';

const modalSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '.MuiPaper-root': {
    minWidth: 800,
  },
  '.css-xtcfs2': {
    ':focus-visible': {
      outline: 'none',
    },
  },
  '@media (max-width: 768px)': {
    '.MuiPaper-root': {
      minWidth: '95%',
    },
  },
};
const linkStyle = {
  cursor: 'pointer',
  paddingRight: spacing.m,
  fontSize: 15,
  fontWeight: '500',
};

const calendarOptions = {
  plugins: [dayGridPlugin, timeGridPlugin, listPlugin, momentTimezonePlugin],
  initialView: 'dayGridMonth',
  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
  },
  locale: 'pt-br',
  locales: allLocales,
  dayMaxEvents: true,
  nextDayThreshold: '01:00:00',
};

export default function Calendar(props) {
  const {
    events,
    openModal,
    eventClickHandler,
    openModalHandler,
    closeModalHandler,
    isMobile,
    handleDatesSet,
    timezone = 'local',
  } = props;

  return (
    <>
      <Link onClick={openModalHandler} fluid style={linkStyle}>
        Ver calendário completo
      </Link>
      <Modal
        sx={modalSx}
        title="Calendário"
        open={openModal}
        onClose={closeModalHandler}
      >
        <FullCalendar
          {...calendarOptions}
          events={events}
          timeZone={timezone}
          eventClick={eventClickHandler}
          aspectRatio={isMobile ? 1 : 1.35}
          views={
            isMobile && {
              timeGridWeek: {
                dayHeaderFormat: { weekday: 'short' },
              },
            }
          }
          datesSet={handleDatesSet}
        />
      </Modal>
    </>
  );
}
