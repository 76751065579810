import React, { useCallback } from 'react';
import Box from '../library/Box';
import Button from '../library/Button';
import Logo from '../library/images/logo-with-slogan-inverse.png';
import Logout from '../library/icons/Logout';
import Link from '../library/Link';
import theme from '../library/theme';
import { useDispatch } from 'react-redux';
import { signOut } from '../../processes/sessionProcesses';

const containerSx = {
  backgroundColor: theme.palette.primary.main,
  height: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};
const contentSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 352,
  color: theme.palette.typography.inverse,
};
const imageStyle = {
  margin: `${theme.spacing(6)} auto`,
};
const logoutStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(4),
  color: theme.palette.typography.inverse,
  cursor: 'pointer',
};

export default function InitialPage(props) {
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    signOut(dispatch);
  }, [dispatch]);

  return (
    <Box sx={containerSx}>
      <Box sx={contentSx}>
        <img src={Logo} style={imageStyle} />
        <Button
          type="button"
          variant="primary-inverse"
          fluid
          onClick={props.nextStep}
        >
          Começar
        </Button>
        <Link onClick={logout} style={logoutStyle}>
          SAIR <Logout />
        </Link>
      </Box>
    </Box>
  );
}
