import React from 'react';
import { Grid } from '@mui/material';
import Box from '../library/Box';
import { spacing, uiColors } from '../library/theme';
import WhatsappButton from '../library/WhatsappButton';
import { makeIsMobile } from '../../utils/useScreenWidth';

const footerBaseSx = {
  position: 'absolute',
  bottom: 0,
  borderTop: '1px solid #e4e4e4',
  backgroundColor: uiColors.fieldBackground,
  gap: spacing.s,
};
const mobileFooterSx = {
  ...footerBaseSx,
  '& > :first-child': { paddingTop: spacing.m },
  '& > :last-child': { paddingBottom: spacing.m },
};
const footerSx = { ...footerBaseSx, padding: spacing.m };

const whatsappPhone = process.env.WHATSAPP_PHONE;

export default function Footer({ hideSupportButton }) {
  const isMobile = makeIsMobile();

  return (
    <Grid
      container
      direction={isMobile ? 'column' : 'row-reverse'}
      justifyContent={isMobile ? 'center' : 'space-between'}
      alignContent="center"
      alignItems="center"
      sx={isMobile ? mobileFooterSx : footerSx}
    >
      <Box>
        {whatsappPhone && !hideSupportButton && (
          <WhatsappButton phone={whatsappPhone} />
        )}
      </Box>
      <Box>2024 © Todos os direitos reservados</Box>
    </Grid>
  );
}
