import React from 'react';
import { Grid, Box } from '../library';
import theme, { uiColors } from './theme';

const containerSx = {
  flexGrow: 1,
  alignItems: 'center',
  paddingBottom: 0,
  borderBottom: `1px solid ${uiColors.border}`,
  marginBottom: 1,
};
const titleSx = {
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.typography.main,
};
const descriptionSx = {
  fontWeight: 'medium',
  color: theme.palette.typography.black,
  letterSpacing: '0px',
  textAlign: 'right',
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
};
const largeDescription = {
  width: '100% !important',
};
export default function HorizontalItem({
  title,
  description,
  descriptionHtml = false,
  descriptionLarge = false,
  containerStyle,
  titleStyle,
  descriptionStyle,
}) {
  return (
    <Box sx={{ ...containerSx, ...containerStyle }}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item>
          <span style={{ ...titleSx, ...titleStyle }}>{title}</span>
        </Grid>
        <Grid item sx={descriptionLarge && largeDescription}>
          {descriptionHtml && (
            <span
              style={{ ...descriptionSx, ...descriptionStyle }}
              dangerouslySetInnerHTML={{ __html: description }}
            ></span>
          )}
          {!descriptionHtml && (
            <span style={{ ...descriptionSx, ...descriptionStyle }}>
              {description}
            </span>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
