const initialState = {};

export default function statementsReducer(state = initialState, action) {
  switch (action.type) {
    case 'STATEMENTS_FETCHED':
    case 'STATEMENT_FETCHED':
    case 'STATEMENT_UPDATED':
    case 'STATEMENT_CREATED':
    case 'STATEMENT_SEARCH_FETCHED':
      return { ...state, ...action.entities.statements };
    case 'STATEMENT_DELETED':
      if (state[action.id]) {
        let newState = { ...state };
        delete newState[action.id];
        return newState;
      }
    default:
      return state;
  }
}
