import React from 'react';
import EmptyState from '../library/EmptyState';
import Page from '../page/Page';
import SupplyListItemContainer from './SupplyListItemContainer';
import SupplyIcon from '../library/images/supply.png';
import SupplySearchFormContainer from './SupplySearchFormContainer';
import Pagination from '../library/Pagination';
import { Button, Grid, Modal } from '../library';
import { decorationColors, spacing } from '../library/theme';
import { IoIosAdd } from 'react-icons/io';

const paginationWrapper = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};
const createMethodsContainer = {
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.xs,
};
const secondaryButtonSx = {
  border: `0.5px solid ${decorationColors.decorationBrand} !important`,
};

export default function SuppliesPage({
  supplies,
  onAdd,
  onImport,
  loading,
  currentPage,
  totalPages,
  onPageChange,
  isMobile,
  showCreateMethods,
  toggleCreateMethods,
  hideCreateMethods,
}) {
  return (
    <Page back="/" title="Insumos" headerType="secondary">
      <SupplySearchFormContainer
        hintEnabled
        isMobile={isMobile}
        onAddButton={toggleCreateMethods}
      />
      {!loading && supplies?.length ? (
        <>
          {supplies.map((supply) => (
            <SupplyListItemContainer key={supply.id} supply={supply} />
          ))}
          <div style={paginationWrapper}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
              short={isMobile}
            />
          </div>
        </>
      ) : (
        <EmptyState
          iconUrl={SupplyIcon}
          loading={loading}
          title="Nenhum insumo cadastrado"
          ctaLabel="Cadastrar insumo"
          onClick={toggleCreateMethods}
        />
      )}

      <Modal
        title="Escolha uma opção"
        open={showCreateMethods}
        onClose={hideCreateMethods}
      >
        <Grid container sx={createMethodsContainer}>
          <Button onClick={onAdd} startIcon={<IoIosAdd />}>
            Adicionar insumo
          </Button>
          <Button onClick={onImport} variant="outlined" sx={secondaryButtonSx}>
            Importar em lote
          </Button>
        </Grid>
      </Modal>
    </Page>
  );
}
