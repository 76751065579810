import React from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion as CustomAccordion,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { spacing } from '../theme';
import Typography from '../Typography';
import Link from '../Link';

const summarySx = {
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.xxs,
};
const linkStyle = {
  display: 'flex',
  marginTop: spacing.xxs,
};

export default function Accordion({
  title,
  hint,
  children,
  parentProps,
  summaryProps,
  detailsProps,
  summaryAction,
  summaryActionTitle,
}) {
  return (
    <CustomAccordion {...parentProps}>
      <AccordionSummary {...summaryProps} expandIcon={<ArrowDropDownIcon />}>
        <div style={summarySx}>
          {title}
          {hint && <Typography variant="caption">{hint}</Typography>}
          {summaryAction && (
            <Link
              style={linkStyle}
              onClick={summaryAction}
              component={Typography}
              variant="link"
            >
              {summaryActionTitle || `Adicionar ${title.toLowerCase()}`}
            </Link>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails {...detailsProps}>{children}</AccordionDetails>
    </CustomAccordion>
  );
}
