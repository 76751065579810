import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Home = (props) => {
  const {color = '#fff', ...rest} = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...rest}
    >
      <g
        id="Group_7924"
        data-name="Group 7924"
        transform="translate(20156 19337)"
      >
        <rect
          id="Rectangle_8045"
          data-name="Rectangle 8045"
          width="24"
          height="24"
          transform="translate(-20156 -19337)"
          fill="none"
        />
        <g
          id="Group_7922"
          data-name="Group 7922"
          transform="translate(57 -96.691)"
        >
          <g
            id="Union_1"
            data-name="Union 1"
            transform="translate(-20214 -19241)"
            fill="none"
          >
            <path
              d="M5,24.691a2,2,0,0,1-2-2V10.13a2.848,2.848,0,0,1,.993-2.342l7.814-6.341a2.635,2.635,0,0,1,3.694,0L23,7.788a2.848,2.848,0,0,1,.993,2.345V22.691a2,2,0,0,1-2,2Z"
              stroke="none"
            />
            <path
              d="M 21.99060249328613 22.69060134887695 L 21.99040222167969 22.69079971313477 L 21.99040222167969 10.0699405670166 L 21.99435234069824 10.00718021392822 C 22.01157569885254 9.733591079711914 21.91065788269043 9.501321792602539 21.68593406677246 9.297652244567871 L 14.17765235900879 2.945350408554077 C 14.04626274108887 2.829310417175293 13.84889316558838 2.690980434417725 13.6548023223877 2.690980434417725 C 13.46071243286133 2.690980434417725 13.26334285736084 2.829310417175293 13.1319522857666 2.945350408554077 L 13.0683126449585 2.999240398406982 L 5.294447898864746 9.308230400085449 C 5.078174114227295 9.509003639221191 4.981057167053223 9.737521171569824 4.997672557830811 10.00588035583496 L 5.001502513885498 10.06764030456543 L 5.00220251083374 22.69060134887695 L 21.99060249328613 22.69060134887695 M 21.99060249328613 24.69060134887695 L 5.00220251083374 24.69060134887695 C 3.897002696990967 24.69060134887695 3.001502752304077 23.79510116577148 3.001502752304077 22.69079971313477 L 3.001502752304077 10.12952041625977 C 2.95032262802124 9.303210258483887 3.250722646713257 8.444670677185059 3.994202852249146 7.787700176239014 L 11.80800247192383 1.446300387382507 C 12.94830513000488 0.4391996562480927 14.36130428314209 0.439201146364212 15.50160312652588 1.446300387382507 L 22.99770355224609 7.787700176239014 C 23.74217224121094 8.445549964904785 24.04248237609863 9.305500030517578 23.99040222167969 10.13283061981201 L 23.99040222167969 22.69079971313477 C 23.99040222167969 23.79510116577148 23.09490203857422 24.69060134887695 21.99060249328613 24.69060134887695 Z"
              stroke="none"
              fill="#19518e"
            />
          </g>
          <line
            id="Line_5"
            data-name="Line 5"
            x2="8"
            transform="translate(-20204.5 -19227.5)"
            fill="none"
            stroke="#19518e"
            stroke-linecap="round"
            stroke-width="2"
          />
          <line
            id="Line_6"
            data-name="Line 6"
            x2="8"
            transform="translate(-20204.5 -19223.5)"
            fill="none"
            stroke="#19518e"
            stroke-linecap="round"
            stroke-width="2"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Home;
