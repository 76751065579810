import React from 'react';
import { Box } from '../library';
import { Card } from '../library/cards';
import HorizontalItems from '../library/HorizontalItems';
import MoneyInfo from '../library/MoneyInfo';

const moneyWrapperSx = {
  marginTop: 3,
};

export default function ExpenseListItem(props) {
  const {
    expense,
    onEdit,
    onDelete,
    statementCategory,
    paymentMethod,
    currency,
  } = props;

  return (
    <Card
      key={expense.id}
      title={expense.title || statementCategory}
      onEdit={onEdit}
      onDelete={onDelete}
    >
      <Box sx={moneyWrapperSx}>
        <MoneyInfo
          negative
          label="Pago"
          amount={expense.amount}
          currency={currency}
        />
        <Box pt={3}>
          <HorizontalItems
            items={[
              { title: 'Categoria', description: statementCategory },
              { title: 'Forma de pagamento', description: paymentMethod },
              { title: 'Pago a', description: expense.paidTo },
              { title: 'Descrição', description: expense.description },
            ]}
          />
        </Box>
      </Box>
    </Card>
  );
}
