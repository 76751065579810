import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PricingSuggestionsModal from './PricingSuggestionsModal';
import { getPricingScenario } from '../../selectors/pricingSelector';

function PricingSuggestionsModalContainer(props) {
  const { data } = props;
  const [open, setOpen] = useState(false);
  const toggleModal = useCallback(() => {
    setOpen(!open);
  }, [open]);

  if (!data || data?.errors) return null;

  return (
    <PricingSuggestionsModal open={open} toggleModal={toggleModal} {...props} />
  );
}

function mapStateToProps(state) {
  return {
    data: getPricingScenario(state),
  };
}

export default connect(mapStateToProps)(PricingSuggestionsModalContainer);
