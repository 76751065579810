import { toArray } from '../utils/toArray';

const initialState = {
  notificationIds: [],
  loading: false,
  totalPages: 0,
  currentPage: 1,
  unreadCount: 0,
  totalCount: 0,
};

export default function notificationsListReducer(state = initialState, action) {
  switch (action.type) {
    case 'NOTIFICATIONS_FETCH_REQUESTED':
      return { ...state, loading: true };
    case 'NOTIFICATIONS_FETCHED':
      return {
        ...state,
        loading: false,
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        unreadCount: action.unreadCount,
        totalCount: action.totalCount,
        notificationIds: action.result
          ? toArray(action.result)
          : initialState.notificationIds,
      };
    case 'NOTIFICATIONS_PAGE_CHANGED':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case 'NOTIFICATIONS_FILTERS_CHANGED':
      return {
        ...state,
        filters: action.filters,
        currentPage: 1,
      };
    default:
      return state;
  }
}
