export function orderByCreatedAtDesc(a, b) {
  return new Date(b.createdAt) - new Date(a.createdAt);
}

export function orderByCreatedAtAsc(a, b) {
  return new Date(a.createdAt) - new Date(b.createdAt);
}

export function orderByIdDesc(a, b) {
  return new Date(b.id) - new Date(a.id);
}

export function orderByIdAsc(a, b) {
  return new Date(a.id) - new Date(b.id);
}
