import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import { getAllSupplies } from './supplySelectors';

const getRecipesSupplies = (state) => state.entities.recipesSupplies;
const getRecipes = (state) => state.entities.recipes;
const getRecipeId = (_, recipeId) => recipeId;

function formState(state) {
  return getFormValues('recipe')(state);
}

export const getAllRecipesSupplies = createSelector(
  getRecipesSupplies,
  (recipesSupplies) => Object.values(recipesSupplies),
);

const getRecipe = createSelector(
  getRecipes,
  getRecipeId,
  (recipes, recipeId) => recipes[recipeId],
);

export const getRecipesSuppliesByRecipe = createSelector(
  getAllRecipesSupplies,
  getRecipe,
  (recipesSupplies, recipe) =>
    recipesSupplies?.filter((recipesSupply) =>
      recipe?.meta?.recipesSupplies?.includes(recipesSupply.id),
    ),
);

export const getRecipeSupplies = createSelector(formState, (form) =>
  form?.recipesSuppliesAttributes?.filter((rS) => !!!rS?._destroy),
);

export const getRecipeSuppliesCostErrors = createSelector(
  getRecipeSupplies,
  getAllSupplies,
  (recipeSupplies, supplies) => {
    const suppliesIds = recipeSupplies?.map(
      (recipeSupply) => recipeSupply?.supplyId,
    );

    const result = supplies?.filter(
      (supply) =>
        supply?.cost <= 0 &&
        suppliesIds?.includes(supply?.id) &&
        supply._destroy != 1,
    );

    return !!result?.length ? result : false;
  },
);

export const getRecipesSuppliesTotalCost = createSelector(
  getRecipeSupplies,
  getAllSupplies,
  (recipeSupplies, supplies) => {
    const getSupply = (id) => supplies.find((s) => s.id == id);

    return recipeSupplies
      ?.map((item) => {
        const supply = getSupply(item?.supplyId);
        return supply?.cost * item?.quantity;
      })
      .reduce((acc, num) => (acc += num), 0);
  },
);
