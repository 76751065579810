import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HamburguerMenu = (props) => {
  const {color = '#fff', ...rest} = props;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="14"
      viewBox="0 0 26 14"
      {...rest}
    >
      <g
        id="Group_7789"
        data-name="Group 7789"
        transform="translate(20426.5 19400.5)"
      >
        <line
          id="Line_1"
          data-name="Line 1"
          x2="24"
          transform="translate(-20425.5 -19399.5)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-width="2"
        />
        <line
          id="Line_3"
          data-name="Line 3"
          x2="24"
          transform="translate(-20425.5 -19393.5)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-width="2"
        />
        <line
          id="Line_2"
          data-name="Line 2"
          x2="12"
          transform="translate(-20425.5 -19387.5)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-width="2"
        />
      </g>
    </SvgIcon>
  );
};

export default HamburguerMenu;
