import React from 'react';
import { Chip } from '@mui/material';
import { css } from 'glamor';
import { spacing } from './theme';

const defaultSx = {
  display: 'flex',
  borderRadius: '10px',
  fontWeight: 'bolder',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
};

const iconPositions = {
  top: {
    flexDirection: 'column',
  },
  right: {
    flexDirection: 'row-reverse',
  },
  bottom: {
    flexDirection: 'column-reverse',
  },
};

const iconWrappStyle = {
  display: 'flex',
  paddingLeft: spacing.xxs,
  paddingRight: spacing.xxs,
};

export default function Label({
  variant = 'filled',
  color = 'primary',
  size = 'small',
  className,
  sx,
  icon,
  iconPosition,
  ...rest
}) {
  return (
    <Chip
      variant={variant}
      color={color}
      className={css(className).toString()}
      sx={{
        ...defaultSx,
        ...(iconPosition && iconPositions[iconPosition]),
        ...sx,
      }}
      size={size}
      {...rest}
    >
      {icon && <div style={iconWrappStyle}>{icon}</div>}
    </Chip>
  );
}
