import React from 'react';
import makeDropDownOptions from '../../utils/makeDropDownOptions';
import Button from '../library/Button';
import CardActions from '../library/cards/CardActions';
import {
  CardForm,
  DropDownField,
  TextField,
  DateField,
  SearchDropDownField,
} from '../library/forms';
import { currencyMask } from '../../utils/inputMasks';
import { Grid, Separator } from '../library';

export default function RevenueForm(props) {
  const isModal = !!props?.closeStatementModal;

  return (
    <CardForm {...props}>
      <Grid container spacing={3}>
        {!isModal && (
          <Grid item xs={12}>
            <SearchDropDownField
              name="statementCategoryId"
              label="Categoria"
              options={makeDropDownOptions(
                props.statementCategories,
                'Selecione uma categoria',
              )}
              fluid
              required
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DateField name="date" label="Data" fluid required />
        </Grid>
        <Grid item xs={12}>
          <DropDownField
            name="paymentMethodId"
            label="Forma de pagamento"
            data={makeDropDownOptions(
              props.paymentMethods,
              'Selecione uma opção',
            )}
            fluid
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField name="paidTo" label="Recebido por (opcional)" fluid />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Observação (opcional)"
            multiline={true}
            rows={3}
            fluid
          />
        </Grid>
        <Grid item xs={12}>
          <Separator />
          <TextField
            name="amount"
            label="Valor total a receber"
            fluid
            required
            {...currencyMask(props?.currency?.symbol)}
          />
          <Separator />
        </Grid>
      </Grid>
      <CardActions>
        <Button
          type="button"
          onClick={props.handleSubmit}
          color="primary"
          size="large"
          disabled={props.pristine || props.submitting}
          fluid
        >
          {props.statement ? 'Atualizar' : 'Adicionar'}
        </Button>
      </CardActions>
    </CardForm>
  );
}
