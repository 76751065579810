import React from 'react';
import { css } from 'glamor';
import { spacing } from './theme';

const hintStyles = css({
  fontSize: 12,
  whiteSpace: 'pre-line',
  marginTop: spacing.xxs,
});

const extraSpace = css({
  marginTop: spacing.xs,
});

export default function Hint(props) {
  const { children, spaced, italic, className, ...rest } = props;
  return (
    <div className={css(hintStyles, className, spaced && extraSpace)} {...rest}>
      {children}
    </div>
  );
}
