import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Close = (props) => {
  const {color = '#fff', ...rest} = props;

  return (
    <SvgIcon
      id="Group_7944"
      data-name="Group 7944"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...rest}
    >
      <rect
        id="Rectangle_407"
        data-name="Rectangle 407"
        width="16"
        height="16"
        fill="none"
        opacity="0"
      />
      <g id="Group_7982" data-name="Group 7982" transform="translate(2 2)">
        <line
          id="Line_16"
          data-name="Line 16"
          x2="12"
          y2="12"
          fill="none"
          stroke="#fff"
          stroke-width="2"
        />
        <line
          id="Line_17"
          data-name="Line 17"
          x1="12"
          y2="12"
          fill="none"
          stroke="#fff"
          stroke-width="2"
        />
      </g>
    </SvgIcon>
  );
};

export default Close;
