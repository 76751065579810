import { createSelector } from 'reselect';
import { orderByAlphabetic } from '../utils/orderByAlphabetic';
import orderByValue from '../utils/orderByValue';

const getCustomers = (state) => state.entities.customers;
const getId = (_, id) => id;
const getCustomersList = (state) => state.customersList;
const getFetchedIds = (state) => state.customersList.customerIds;

export const getCustomerPageInfo = createSelector(
  getCustomersList,
  (customersList) => ({
    currentPage: customersList.currentPage,
    totalPages: customersList.totalPages,
    totalCount: customersList.totalCount,
    filters: customersList.filters,
    loading: customersList.loading,
  }),
);

export const getAllCustomers = createSelector(getCustomers, (customers) =>
  Object.values(customers).sort(orderByAlphabetic),
);

export const getCustomer = createSelector(
  getCustomers,
  getId,
  (customers, id) => customers[id],
);

export const getActiveCustomers = createSelector(getAllCustomers, (customers) =>
  customers.filter((customer) => !customer.isDeleted),
);

export const getActiveCustomersByFetchedIds = createSelector(
  getActiveCustomers,
  getFetchedIds,
  (customers, ids) =>
    customers
      .filter((customer) => ids.includes(customer.id))
      .sort(orderByAlphabetic),
);

export const getTopCustomers = createSelector(
  getActiveCustomers,
  getFetchedIds,
  (customers, ids) =>
    customers
      .filter((customer) => ids.includes(customer.id))
      .sort(orderByValue('amountSpent'))
      .slice(0, 3),
);
