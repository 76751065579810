import { toArray } from '../utils/toArray';

const initialState = {
  recipeIds: [],
};

export default function recipesListReducer(state = initialState, action) {
  switch (action.type) {
    case 'RECIPES_FETCH_REQUESTED':
      return { ...state, loading: true };
    case 'RECIPES_FETCHED':
      return {
        ...state,
        loading: false,
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        recipeIds: action.result
          ? toArray(action.result)
          : initialState.recipeIds,
      };
    case 'RECIPES_PAGE_CHANGED':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case 'RECIPES_FILTERS_CHANGED':
      return {
        ...state,
        filters: action.filters,
        currentPage: 1,
      };
    default:
      return state;
  }
}
