import { createTheme } from '@mui/material/styles';

export const spacing = {
  xxs: '4px',
  xs: '8px',
  s: '12px',
  m: '20px',
  l: '32px',
  xl: '52px',
  xxl: '84px',
};

const colors = {
  brand: '#0B70EC',
  brandDark: '#ed9156',
  white: '#fff',
  greyLightest: '#f3f3f4',
  greyLighter: '#e7e9ea',
  greyLight: '#cfd2d4',
  greyDark: '#606A71',
  greyDarker: '#363E43',
  greyDarkest: '#1D2224',
  brown: '#b78751',
  orange: '#ef9456',
  danger: '#de3f2e',
  warning: '#e07900',
  success: '#41A11F',
  decorationSoft: '#fEf6f3',
  decorationHard: '#aadeca',
  shadow: 'rgba(0, 0, 0, 0.1)',
  overlay: 'rgba(2, 13, 66, 0.40)',
  blueLight: '#0288d1',
};

export const decorationColors = {
  decorationBrand: colors.brand,
  decorationBrandDark: colors.brandDark,
  decorationSoft: colors.decorationSoft,
  decorationHard: colors.decorationHard,
  decorationBrown: colors.brown,
  decorationOrange: colors.orange,
};

export const utilityColors = {
  information: colors.brand,
  danger: colors.danger,
  success: colors.success,
  warning: colors.warning,
  shadow: colors.shadow,
  overlay: colors.overlay,
  progress: colors.blueLight,
};

export const typographyColors = {
  defaultTitle: colors.greyDarkest,
  defaultBody: colors.greyDarker,
  inverseTitle: colors.white,
  inverseBody: colors.greyLightest,
};

export const uiColors = {
  background: colors.brand,
  backgroundDark: colors.brandDark,
  backgroundDarker: colors.greyDarker,
  backgroundDarkest: colors.greyDarkest,
  border: colors.greyLighter,
  borderInverse: colors.greyDark,
  fieldBackground: colors.white,
  contentCards: colors.greyLightest,
};

export const stateColors = {
  focus: colors.brand,
  hover: colors.greyDark,
  empty: colors.greyLight,
  filled: colors.greyLighter,
  disabled: colors.greyLightest,
};

export const buttonColors = {
  primary: colors.brand,
  default: colors.greyLighter,
  textPrimary: colors.white,
  textDefault: colors.greyDark,
};

export const styles = {
  defaultFontFamily: {
    fontFamily: 'Raleway',
    fontWeight: '400',
  },
  roundedCorners: { borderRadius: spacing.xxs },
  border: {
    boxShadow: `0 0 0 1px ${uiColors.border} inset`,
  },
  shadow: { boxShadow: `0 5px 5px ${utilityColors.shadow}` },
  innerShadow: { boxShadow: '0px 10px 20px #1B5C9629 inset' },
  transition: { transition: 'all 150ms ease-in' },
  bodyText: {
    color: typographyColors.defaultBody,
    fontSize: 14,
    letterSpacing: '-0.4px',
    lineHeight: 1,
  },
  wrappingText: {
    lineHeight: 1.5,
  },
  bodyTextBold: {
    fontWeight: 600,
  },
  bodyTextItalic: {
    fontStyle: 'italic',
  },
  monospace: {
    fontFamily: 'monospace',
    letterSpacing: 'normal',
  },
  bodyTextSmall: {
    fontSize: 12,
  },
  whiteText: {
    color: typographyColors.inverseTitle,
    textShadow: `0px 1px 2px ${utilityColors.shadow}`,
  },
  whiteBodyText: {
    color: typographyColors.inverseBody,
    textShadow: `0px 1px 2px ${utilityColors.shadow}`,
  },
};

//Material UI theme object:

export const defaultTheme = {
  spacing: (factor) => {
    const values = [0, 4, 8, 12, 20, 32, 52, 84];
    const index = Math.floor(factor);
    const currentSpace = values[index];
    const nextSpace = values[index + 1] || currentSpace * 2;
    const space = currentSpace + (nextSpace - currentSpace) * (factor - index);
    return `${space}px`;
  },
  palette: {
    primary: {
      main: colors.brand,
      active: '#3A96FB',
      contrastText: colors.white,
    },
    secondary: {
      main: colors.brandDark,
      contrastText: colors.white,
    },
    error: {
      main: colors.danger,
      contrastText: colors.white,
    },
    warning: {
      main: colors.warning,
      contrastText: colors.white,
    },
    add: {
      main: '#41A11F',
      active: '#80C06A',
      contrastText: colors.white,
    },
    danger: {
      main: '#E44A4A',
      active: '#FF6666',
      contrastText: colors.white,
    },
    success: {
      main: colors.success,
      contrastText: colors.white,
    },
    border: {
      main: '#CCCCCC',
      hover: '#888888',
    },
    typography: {
      main: '#707070',
      inverse: colors.white,
      black: '#333333',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    whiteSpace: 'pre-line',
    fontFamily: ['Raleway', 'Roboto', 'sans-serif'].join(','),
    body1: {
      color: '#707070',
      fontSize: 14,
      fontWeight: 'medium',
    },
    link: {
      color: colors.brand,
      ':hover': {
        color: '#3A96FB',
        cursor: 'pointer',
      },
    },
    formTitle: {
      fontSize: '16px',
      fontWeight: 'bold !important',
      color: '#CCCCCC',
      marginBottom: '16px',
    },
    h3: {
      fontWeight: 'bolder',
    },
    h4: {
      color: '#333333',
      fontWeight: 'bolder',
    },
  },
  components: {
    MuiFormControl: {
      variants: [
        {
          props: { variant: 'fluid' },
          style: {
            width: '100%',
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        size: 'large',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        ':disabled': {
          backgroundColor: '#E4E4E4',
        },
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            ':hover': {
              backgroundColor: '#3A96FB',
            },
            ':disabled': {
              backgroundColor: '#CCCCCC',
              color: colors.white,
            },
            fontSize: 16,
            color: colors.white,
            backgroundColor: colors.brand,
            fontWeight: 'bolder',
            height: 48,
          },
        },
        {
          props: { variant: 'primary-inverse' },
          style: {
            ':hover': {
              backgroundColor: '#3A96FB',
              color: colors.white,
            },
            fontSize: 16,
            color: colors.brand,
            backgroundColor: colors.white,
            fontWeight: 'bolder',
            height: 48,
          },
        },
        {
          props: { variant: 'add' },
          style: {
            ':hover': {
              backgroundColor: '#80C06A',
              color: colors.white,
            },
            color: colors.white,
            backgroundColor: '#41A11F',
            fontWeight: 'bolder',
            height: 48,
          },
        },
        {
          props: { variant: 'icon-squared' },
          style: {
            ':hover': {
              backgroundColor: '#3A96FB',
            },
            ':disabled': {
              backgroundColor: '#E4E4E4',
            },
            padding: 0,
            color: colors.white,
            backgroundColor: colors.brand,
            height: 48,
            minWidth: 48,
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            ':hover': {
              bordercolor: colors.brand,
            },
            borderColor: '#CCCCCC',
          },
        },
        {
          props: { variant: 'social' },
          style: {
            ':hover': {
              backgroundColor: '#f7e4dd',
              borderColor: '#f0b57d',
            },
            fontFamily: 'DM Serif Display',
            color: colors.brand,
            backgroundColor: '#fEf6f3',
            border: '1px solid #f7e4dd',
            fontWeight: '400',
            padding: `${spacing.s} ${spacing.m}`,
          },
        },
      ],
    },
  },
};

const theme = createTheme(defaultTheme);

export default theme;
