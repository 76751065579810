import React from 'react';
import EmptyState from '../library/EmptyState';
import Page from '../page/Page';
import CustomerListItemContainer from './CustomerListItemContainer';
import DefaultIcon from '../library/icons/DefaultIcon';
import CustomerSearchFormContainer from './CustomerSearchFormContainer';
import { Separator } from '../library';
import Pagination from '../library/Pagination';

const paginationWrapper = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

export default function CustomersPage({
  customers,
  onAdd,
  currency,
  loading,
  currentPage,
  totalPages,
  totalCount,
  onPageChange,
  isMobile,
}) {
  return (
    <Page back="/" title="Clientes" headerType="secondary">
      <CustomerSearchFormContainer onAddButton={onAdd} />
      <Separator />
      {!loading && customers?.length ? (
        <>
          {customers.map((customer) => (
            <CustomerListItemContainer
              key={customer.id}
              customer={customer}
              currency={currency}
            />
          ))}
          <div style={paginationWrapper}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalCount={totalCount}
              onPageChange={onPageChange}
              short={isMobile}
            />
          </div>
        </>
      ) : (
        <EmptyState
          iconUrl={DefaultIcon}
          loading={loading}
          title="Nenhum cliente cadastrado"
          cta="/customers/new"
          ctaLabel="Cadastrar cliente"
        />
      )}
    </Page>
  );
}
