import React from 'react';
import { Box, MoneyInfo, Typography } from '../library';
import { Card } from '../library/cards';
import HorizontalItems from '../library/HorizontalItems';
import { spacing } from '../library/theme';
import formatDate from '../../utils/formatDate';

const marginTop = {
  marginTop: spacing.s,
};
const notesSx = {
  whiteSpace: 'pre-line',
  fontWeight: 'normal',
};

export default function OrderListItem(props) {
  const {
    order,
    customer,
    onEdit,
    products,
    items,
    onDelete,
    onWhatsapp,
    budgetUrl,
    onExport,
    onThermalPrint,
    currency,
  } = props;
  const {
    id,
    sequentialId,
    paymentStatus,
    amount = 0,
    amountPaid,
    amountDifference,
  } = order;
  return (
    <Card
      key={id}
      title={`#${sequentialId}`}
      order={order}
      enableOrderLabels
      onEdit={onEdit}
      onDelete={onDelete}
      onThermalPrint={onThermalPrint}
      onWhatsapp={order?.uuid && budgetUrl && onWhatsapp}
      receiver={customer?.phone}
      onExport={order?.uuid && onExport}
    >
      <Typography variant="h6" style={{ marginTop: `-${spacing.xs}` }}>
        {customer?.name || order?.buyerName}
      </Typography>
      {customer?.phone && (
        <Typography>
          {customer?.phoneCountryCode || '55'}
          {customer?.phone}
        </Typography>
      )}
      <Typography>{customer?.address}</Typography>
      {order?.discount > 0 && (
        <Typography>Desconto de {+order?.discount.toFixed(2)}%</Typography>
      )}
      {formatDate(order.scheduleAt, 'DD/MM/yyyy - HH:mm')}
      {(products || items) && (
        <Typography variant="h6" sx={marginTop}>
          Itens do pedido
        </Typography>
      )}
      {products && <HorizontalItems items={products} />}
      {items && <HorizontalItems items={items} />}
      <Box sx={marginTop}>
        <MoneyInfo
          label="Valor do pedido"
          amount={amount}
          currency={currency}
        />
        {paymentStatus == 'unpaid' && (
          <MoneyInfo
            label="Valor pago"
            twinLabel="Resta pagar"
            info
            amount={amountPaid}
            twinAmount={amountDifference}
            sx={{ marginTop: spacing.xs }}
            currency={currency}
          />
        )}
      </Box>
      {order.notes && (
        <>
          <Typography variant="h6" sx={marginTop}>
            Observações
          </Typography>
          <p style={notesSx}>{order.notes}</p>
        </>
      )}
    </Card>
  );
}
