import React from 'react';
import { connect } from 'react-redux';
import { FieldArray } from 'redux-form';
import { getUnits } from '../../../selectors/configSelectors';
import SimpleMultipleItemsInput from './SimpleMultipleItemsInput';

function SimpleMultipleItemsField(props) {
  return <FieldArray component={SimpleMultipleItemsInput} {...props} />;
}

function mapStateToProps(state) {
  return {
    units: getUnits(state),
  };
}

export default connect(mapStateToProps)(SimpleMultipleItemsField);
