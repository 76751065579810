export function arrayToCsv(arr) {
  if (!arr || arr.length === 0) {
    return '';
  }

  const headers = Object.keys(arr[0]);
  const rows = arr.map((obj) => Object.values(obj));

  const csvHeader = headers.join(',') + '\n';
  const csvRows = rows.map((row) => row.join(',')).join('\n');
  const csvString = csvHeader + csvRows;

  return csvString;
}

export function arrayToCsvBlob(arr) {
  const blob = new Blob([arrayToCsv(arr)], { type: 'text/csv' });

  return blob;
}

export function blobDownload(blob, fileName = 'data') {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.csv`);

    document.body.appendChild(link);

    link.click();

    link.addEventListener('load', () => {
      URL.revokeObjectURL(url);
      link.remove();
      resolve();
    });

    link.addEventListener('error', () => {
      reject(new Error(`Failed to download ${fileName}`));
    });
  });
}

export function downloadArrayAsCsv(array, fileName) {
  return blobDownload(arrayToCsvBlob(array), fileName);
}
