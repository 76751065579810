import * as React from 'react';
import ConfirmationCard from './ConfirmationCard';

const decoratorStyle = {
  border: '1px solid red',
  padding: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export default {
  title: 'Library/ConfirmationCard',
  component: ConfirmationCard,
  argTypes: {
    text: {
      name: 'text',
      type: {name: 'string', required: false},
      defaultValue: 'Demo ConfirmationCard',
      description: 'ConfirmationCard content',
    },
  },
};

const Template = (args) => (
  <div style={decoratorStyle}>
    <ConfirmationCard {...args} />
  </div>
);

export const Default = Template.bind({});
Default.args = {
  children: <h5>Lorem Ipsum</h5>,
};
