import React, { useState } from 'react';
import Box from '../Box';
import Segment from '../Segment';
import { buttonColors, decorationColors, spacing } from '../theme';
import Typography from '../Typography';
import CardActions from './CardActions';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InventoryIcon from '@mui/icons-material/Inventory';
import PrintIcon from '@mui/icons-material/Print';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CardAction from './CardAction';
import Label from '../Label';
import { css } from 'glamor';
import { IconButton, ListItemIcon, Menu, MenuList } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '../MenuItem';

const segmentSx = {
  minHeight: '150px',
  marginBottom: spacing.m,
  borderRadius: spacing.xs,
};
const titleStyle = {
  marginBottom: 0,
};
const typographySx = {
  fontWeight: 'normal',
  marginTop: spacing.xs,
  fontSize: '12px',
};
const textWrapperSx = {
  flex: 1,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};
const iconWrapper = {
  display: 'flex',
  width: '48px',
  height: '48px',
  backgroundColor: `${decorationColors.decorationBrand}10`,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: spacing.xs,
};
const iconTextWrapperSx = {
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
};
const titleWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: spacing.xs,
};
const labelSx = {
  size: 'small',
  maxWidth: 'fit-content',
  marginTop: spacing.xxs,
};
const menuItemSx = {
  fontSize: '0.85rem',
  color: decorationColors.decorationBrand,
};
const labelWrapper = css({
  gap: spacing.xxs,
  display: 'flex',
});

const translatedStatus = (status) => {
  switch (status) {
    case 'scheduled':
      return 'Agendado';
    case 'in_production':
      return 'Em produção';
    case 'completed':
      return 'Finalizado';
    case 'delivered':
      return 'Entregue';
    case 'canceled':
      return 'Cancelado';
  }
};
const translatedPaymentStatus = (status) => {
  switch (status) {
    case 'unpaid':
      return 'Não pago';
    case 'paid':
      return 'Pago';
  }
};
const translatedDeliveryType = (deliveryType) => {
  switch (deliveryType) {
    case 'delivery':
      return 'Entrega';
    case 'takeaway':
      return 'Retirada';
  }
};
const setStatusLabelColor = (status) => {
  switch (status) {
    case 'scheduled':
      return 'primary';
    case 'in_production':
      return 'warning';
    case 'completed':
      return 'info';
    case 'delivered':
      return 'success';
    case 'canceled':
      return 'danger';
  }
};
const setPaymentLabelColor = (status) => {
  switch (status) {
    case 'paid':
      return 'success';
    case 'unpaid':
      return 'danger';
  }
};
const setDeliveryLabelColor = (deliveryType) => {
  switch (deliveryType) {
    case 'delivery':
      return 'success';
    case 'takeaway':
      return 'info';
  }
};

export default function Card({
  background,
  title,
  description,
  descriptionStyle,
  sx,
  children,
  minHeight = '150px',
  onClick,
  order,
  enableOrderLabels = false,
  onDuplicate,
  onEdit,
  onDelete,
  onManageInventory,
  onWhatsapp,
  whatsappActionText,
  onExport,
  onOrderHistory,
  onThermalPrint,
  receiver,
  cardActions,
  onDeleteConfirm = true,
  deleteConfirmText,
  icon,
  iconBackgroundSx,
}) {
  const customSx = {
    background:
      background && `url('${background}') no-repeat center right #fff`,
    minHeight,
  };
  const showCardActions = !cardActions && (onDuplicate || onEdit || onDelete);
  const TitleComponent = icon ? 'h5' : 'h3';
  const whatsappText = whatsappActionText || 'Compartilhar';
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Segment sx={{ ...segmentSx, ...customSx, ...sx }} onClick={onClick}>
      <Box sx={icon ? iconTextWrapperSx : { width: '100%' }}>
        {icon && <Box sx={{ ...iconWrapper, ...iconBackgroundSx }}>{icon}</Box>}
        <Box sx={textWrapperSx}>
          <Box>
            {title && (
              <div style={titleWrapper}>
                <TitleComponent style={titleStyle}>{title}</TitleComponent>
                {order && enableOrderLabels && (
                  <div className={labelWrapper}>
                    <Label
                      color={setStatusLabelColor(order?.status)}
                      label={translatedStatus(order?.status)}
                      sx={labelSx}
                    />
                    <Label
                      color={setDeliveryLabelColor(order?.deliveryType)}
                      label={translatedDeliveryType(order?.deliveryType)}
                      sx={labelSx}
                    />
                    <Label
                      color={setPaymentLabelColor(order?.paymentStatus)}
                      label={translatedPaymentStatus(order?.paymentStatus)}
                      sx={labelSx}
                    />
                  </div>
                )}
              </div>
            )}
            {description && (
              <Typography sx={typographySx} style={descriptionStyle}>
                {description}
              </Typography>
            )}
          </Box>
          {children && <>{children}</>}
        </Box>
      </Box>
      {cardActions && cardActions}
      {showCardActions && (
        <CardActions>
          {onEdit && (
            <CardAction title="Editar" onClick={onEdit} icon={<EditIcon />} />
          )}
          {onDuplicate && (
            <CardAction
              title="Duplicar"
              onClick={onDuplicate}
              icon={<ContentCopyIcon />}
            />
          )}
          {onWhatsapp && (
            <CardAction
              title={receiver ? whatsappText : 'Indisponível'}
              disabled={!receiver}
              onClick={onWhatsapp}
              icon={<WhatsAppIcon />}
            />
          )}
          {onExport && (
            <CardAction
              title="Exportar"
              icon={<PictureAsPdfIcon />}
              onClick={onExport}
            />
          )}
          {onManageInventory && (
            <CardAction
              title="Gerenciar estoque"
              onClick={onManageInventory}
              icon={<InventoryIcon />}
            />
          )}
          {onOrderHistory && (
            <CardAction
              title="Pedidos"
              onClick={onOrderHistory}
              icon={<ReceiptIcon />}
            />
          )}
          {onEdit && onWhatsapp && onExport && (onThermalPrint || onDelete) ? (
            <>
              <IconButton onClick={openMenu}>
                <MoreVertIcon sx={{ color: buttonColors.primary }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuList dense>
                  {onThermalPrint && (
                    <MenuItem
                      onClick={onThermalPrint}
                      onMenuClose={handleClose}
                    >
                      <ListItemIcon>
                        <PrintIcon sx={{ color: buttonColors.primary }} />
                      </ListItemIcon>
                      <Typography sx={menuItemSx} weight="bold">
                        Imp. Térmica
                      </Typography>
                    </MenuItem>
                  )}
                  {onDelete && (
                    <MenuItem
                      onClick={onDelete}
                      onMenuClose={handleClose}
                      confirmationText={
                        onDeleteConfirm &&
                        `Tem certeza que deseja excluir "${
                          deleteConfirmText || title
                        }"?`
                      }
                    >
                      <ListItemIcon>
                        <DeleteOutlineIcon
                          sx={{ color: buttonColors.primary }}
                        />
                      </ListItemIcon>
                      <Typography sx={menuItemSx} weight="bold">
                        Excluir
                      </Typography>
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </>
          ) : (
            <>
              {onThermalPrint && (
                <CardAction
                  title="Imp. Térmica"
                  icon={<PrintIcon />}
                  onClick={onThermalPrint}
                />
              )}
              {onDelete && (
                <CardAction
                  title="Excluir"
                  onClick={onDelete}
                  icon={<DeleteOutlineIcon />}
                  confirmationText={
                    onDeleteConfirm &&
                    `Tem certeza que deseja excluir "${
                      deleteConfirmText || title
                    }"?`
                  }
                />
              )}
            </>
          )}
        </CardActions>
      )}
    </Segment>
  );
}
