import { connect } from 'react-redux';
import InventorySearchForm from './InventorySearchForm';
import { submitSearchFilter } from '../../processes/inventoryProcessess';
import { reduxForm } from 'redux-form';

const FORM_NAME = 'inventorySearch';

function mapStateToProps() {
  const typeEqOptions = [
    { name: 'Reposição', value: 'add' },
    { name: 'Baixa', value: 'withdraw' },
  ];
  return {
    initialValues: {},
    typeEq: typeEqOptions,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onChange: submitSearchFilter,
  })(InventorySearchForm),
);
