import { toArray } from '../utils/toArray';

const initialState = {
  taskIds: [],
  loading: false,
  totalPages: 0,
  currentPage: 1,
};

export default function tasksListReducer(state = initialState, action) {
  switch (action.type) {
    case 'TASKS_FETCH_REQUESTED':
      return { ...state, loading: true };
    case 'TASKS_FETCHED':
      return {
        ...state,
        loading: false,
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        taskIds: action.result ? toArray(action.result) : initialState.taskIds,
      };
    case 'TASKS_PAGE_CHANGED':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case 'TASKS_FILTERS_CHANGED':
      return {
        ...state,
        filters: action.filters,
        currentPage: 1,
      };
    default:
      return state;
  }
}
