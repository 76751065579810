import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchBanners } from "../../processes/bannerProcesses";
import Banners from "./Banners";
import { getAllBanners } from "../../selectors/bannerSelectors";

function BannersContainer(props) {
  const { dispatch } = props;
  useEffect(() => fetchBanners(dispatch), [dispatch]);

  return <Banners {...props} />;
}

function mapStateToProps(state) {
  return {
    banners: getAllBanners(state),
  };
}

export default connect(mapStateToProps)(BannersContainer);
