import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchInventories } from '../../processes/inventoryProcessess';
import { fetchSupply } from '../../processes/supplyProcessess';
import { getCurrencyById, getUnitById } from '../../selectors/configSelectors';
import {
  getInventoriesByFetchedIds,
  getInventoryPageInfo,
} from '../../selectors/inventorySelectors';
import { getSupply } from '../../selectors/supplySelectors';
import InventoriesPage from './InventoriesPage';
import { makeIsMobile } from '../../utils/useScreenWidth';
import { getCurrentUserCompany } from '../../selectors/userSelectors';

function InventoriesPageContainer(props) {
  const { dispatch, currentPage, filters } = props;
  const { supplyId } = props.match.params;
  const [openInventoryModal, setOpenInventoryModal] = useState(false);
  const isMobile = makeIsMobile();

  useEffect(() => {
    fetchInventories(supplyId, dispatch, { currentPage, filters });
  }, [dispatch, currentPage, supplyId, filters]);
  useEffect(() => {
    fetchSupply(dispatch, supplyId);
  }, [dispatch, supplyId]);

  const onAdd = useCallback(() => setOpenInventoryModal(true), []);
  const closeInventoryModal = useCallback(
    () => setOpenInventoryModal(false),
    [],
  );

  const pageChangeHandler = useCallback(
    (_, currentPage = 1) => {
      dispatch({
        type: 'INVENTORIES_PAGE_CHANGED',
        currentPage,
      });
    },
    [dispatch],
  );

  return (
    <InventoriesPage
      onAdd={onAdd}
      closeInventoryModal={closeInventoryModal}
      openInventoryModal={openInventoryModal}
      onPageChange={pageChangeHandler}
      isMobile={isMobile}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { supplyId } = ownProps.match.params;
  const supply = getSupply(state, supplyId);
  const { currentPage, totalPages, filters } = getInventoryPageInfo(state);
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);

  return {
    inventories: getInventoriesByFetchedIds(state, supplyId),
    supply,
    unit: getUnitById(state, supply?.unitId),
    currentPage,
    totalPages,
    filters,
    loading: state.inventoriesList.loading,
    currency,
  };
}

export default connect(mapStateToProps)(InventoriesPageContainer);
