import React from 'react';

const RelaxedCook = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="236"
      height="218"
      viewBox="0 0 236 218"
      {...props}
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_8019"
            data-name="Rectangle 8019"
            width="236"
            height="218"
            transform="translate(0 -0.436)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clipPath-3">
          <rect
            id="Rectangle_8014"
            data-name="Rectangle 8014"
            width="18"
            height="23"
            transform="translate(0.332 -0.277)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clipPath-4">
          <rect
            id="Rectangle_8015"
            data-name="Rectangle 8015"
            width="20"
            height="21"
            transform="translate(-0.085 -0.133)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clipPath-5">
          <rect
            id="Rectangle_8016"
            data-name="Rectangle 8016"
            width="48"
            height="5"
            transform="translate(0.197 -0.301)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_7837" data-name="Group 7837" transform="translate(0 0.436)">
        <g id="Group_7805" data-name="Group 7805" clipPath="url(#clipPath)">
          <g id="Group_7804" data-name="Group 7804">
            <g id="Group_7803" data-name="Group 7803" clipPath="url(#clipPath)">
              <path
                id="Path_2684"
                data-name="Path 2684"
                d="M188.928,96.564c0,49.587-42.592,113.3-90,89-44.129-22.616-90-39.413-90-89s48.593-117.282,90-90c44.048,29.022,90,40.413,90,90"
                transform="translate(-6.928 0)"
                fill="#577c47"
              />
              <path
                id="Path_2685"
                data-name="Path 2685"
                d="M236,823.367c0,.075-52.881,0-118,0s-118,.075-118,0,52.859-1,118-1,118,.925,118,1"
                transform="translate(0 -605.803)"
                fill="#1b5c96"
              />
              <path
                id="Path_2686"
                data-name="Path 2686"
                d="M32.9,249.359l6-1,4-11-8-9-10-9,5-2-3-8a44.273,44.273,0,0,0-7,0c-3.845.305-8.219.236-12,1-2.071,3.122-1.471,7.552,0,11a32.181,32.181,0,0,0,6,9c2.97,3.3,5.915,5.127,10,10Z"
                transform="translate(-4.904 -153.795)"
                fill="#ffbe9d"
              />
              <path
                id="Path_2687"
                data-name="Path 2687"
                d="M46.025,219.7c.864.759,2.74,1.769,4,3l3,4a7.5,7.5,0,0,1,1,1c-.514-.345-.555-.569-1-1-.864-.764-2.74-1.769-4-3l-3-4a6.883,6.883,0,0,1-1-1c.513.349.554.568,1,1"
                transform="translate(-33.025 -161.134)"
                fill="#eb996e"
              />
              <path
                id="Path_2688"
                data-name="Path 2688"
                d="M17.8,223.141c1.282-.931,2.9-1.139,4,0,.4.42,1.052.977,1,1s-.592-.65-1-1a4.129,4.129,0,0,0-2-1c-.7-.051-1.4.641-2,1-.467.274.041.035,0,0-.023-.017-.467.339,0,0"
                transform="translate(-12.798 -163.577)"
                fill="#eb996e"
              />
              <path
                id="Path_2689"
                data-name="Path 2689"
                d="M78.223,169.035c.426.722.414,1.4,1,2a9.516,9.516,0,0,1,1,3c1.611,5.794,10.828,6.1,10,6l1-1c3.606-1.47,6.217-4.269,9-7l9-4c.77-.548.373-3.136,0-4a9.417,9.417,0,0,1-1-3c-.035-1.3,0-1.7,0-3,.015-1.443-.7-3.369-2-4a8.4,8.4,0,0,1-3-3c-.759-1.12-.938-2.154-2-3-1.307-1.033-3.571-.136-5-1-.665-.4-1.317-1.574-2-2a2.428,2.428,0,0,0-3,0c-1.208.659-2.769,1.387-4,2a29.413,29.413,0,0,0-5,3,8.87,8.87,0,0,0-3,5l-1,3c-.186.448.271.6,0,1-.55.644-1.584,1.262-2,2-.016.042.014-.042,0,0a3.05,3.05,0,0,0,2,4c-1.169,1.039-.645,2.575,0,4"
                transform="translate(-56.223 -106.471)"
                fill="#ef9456"
              />
              <path
                id="Path_2690"
                data-name="Path 2690"
                d="M99.374,209.779a36.787,36.787,0,0,0-2-8,14.668,14.668,0,0,0-5-7c-.245-.181.292-3.087,0-3-.4.134-1,3.58-1,4,.175,2.805,1.513,4.616,3,7a50.466,50.466,0,0,0,5,7"
                transform="translate(-67.374 -141.215)"
                fill="#ef9456"
              />
              <path
                id="Path_2691"
                data-name="Path 2691"
                d="M121.426,203.549c1.663-.519,4.64-5.88,3-10a22.364,22.364,0,0,1-1-3s6.142-2.622,4-9c-1.039-3.081-4.538-9.445-7-15-2.645-5.975-8.834-9.163-15-7h-1c-6.471,2.678-7.877,9.581-5,16l10,23s7.956,6.564,12,5Z"
                transform="translate(-72.426 -116.984)"
                fill="#ffbe9d"
              />
              <path
                id="Path_2692"
                data-name="Path 2692"
                d="M167.373,281.525c-6.139,2.789-9-1-9-1,3.022.728,6.063.018,9-1Z"
                transform="translate(-116.373 -205.961)"
                fill="#eb996e"
              />
              <path
                id="Path_2693"
                data-name="Path 2693"
                d="M176.429,206.754a4.1,4.1,0,0,0-2,0c-.515.176-.679.56-1,1-.233.338-.152.918,0,1s.025-.656,1-1a6.519,6.519,0,0,0,2-1c.035-.076.385.129,0,0"
                transform="translate(-127.429 -152.19)"
                fill="#263238"
              />
              <path
                id="Path_2694"
                data-name="Path 2694"
                d="M139.664,217.975a1.067,1.067,0,0,0-1,0,5.157,5.157,0,0,0-2,1,.924.924,0,0,0,0,1c.146.082,1.025-.656,2-1a6.439,6.439,0,0,0,2-1c.041-.07-.621.123-1,0"
                transform="translate(-100.664 -160.41)"
                fill="#263238"
              />
              <path
                id="Path_2695"
                data-name="Path 2695"
                d="M161.852,213.762c.142-.054.874.086,1,0,.167-.116-.009.2,0,0-.04-.358.264-.756,0-1l-2-2a23.67,23.67,0,0,0-4-4c-.111.076,2.028,1.666,4,4l1,2c.207.18.93.735,1,1,.018.181-.761-.111-1,0-.526.219-.542.661-1,1,.558-.121.493-.737,1-1"
                transform="translate(-115.852 -152.198)"
                fill="#263238"
              />
              <path
                id="Path_2696"
                data-name="Path 2696"
                d="M159.541,243.729a5.707,5.707,0,0,0,2,2,3.055,3.055,0,0,0,2,0c.473-.181.076-.854,0-1s-.827.432-2,0-1.843-1.064-2-1c-.076,0-.21-.426,0,0"
                transform="translate(-117.541 -179.164)"
                fill="#263238"
              />
              <path
                id="Path_2697"
                data-name="Path 2697"
                d="M127.876,196.631c-.626-.011-.438.725-1,1s-1.62.5-2,1a.879.879,0,0,0,0,1c.233.175.9-.463,2-1s2-.7,2-1c0-.146-.522-.965-1-1"
                transform="translate(-91.876 -145.066)"
                fill="#ef9456"
              />
              <path
                id="Path_2698"
                data-name="Path 2698"
                d="M166.467,184.916a1.131,1.131,0,0,0-1,0,5.517,5.517,0,0,0-2,1,1.023,1.023,0,0,0,0,1c.239.175,1.189-.7,2-1s1.907.286,2,0c.041-.134-.632-.912-1-1"
                transform="translate(-120.467 -136.351)"
                fill="#ef9456"
              />
              <path
                id="Path_2699"
                data-name="Path 2699"
                d="M104.676,246.7c.041.134-4.343,2.295-6-2s3.872-4,4-4c0,0,1.959,5.866,2,6"
                transform="translate(-72.676 -177.135)"
                fill="#ffbe9d"
              />
              <path
                id="Path_2700"
                data-name="Path 2700"
                d="M106.258,250.955c-.146.217-.74.977-1,1-.689.059-.638-1.142-1-2-.173-.408-1.039-.558-1-1,0-.391.674-.785,1-1,.139-.077-.152-.047,0,0,.111.033.934-.1,1,0,.1.169.023,0,0,0a3.368,3.368,0,0,1-1,0c-.142-.135.159-.114,0,0-.237.192-.026.7,0,1-.023.4-.154.631,0,1,.333.747.481,1,1,1,.208,0,.853.146,1,0h0c.018,0,.093-.14,0,0"
                transform="translate(-76.258 -182.391)"
                fill="#eb996e"
              />
              <path
                id="Path_2701"
                data-name="Path 2701"
                d="M109.924,157.535a5.7,5.7,0,0,1-1,3c-.747,1.243-6.251,1.9-9,2-1.731.064-3.208.46-4,2-.084.157.056.831,0,1-.158.8.5,1.363,1,2,.455.659.51,1.37,1,2a14.062,14.062,0,0,1,2,4,13.122,13.122,0,0,1,1,6,1.277,1.277,0,0,1-2,1,1.762,1.762,0,0,1-1-1c-.49-1.325-1.025-1.288-2-2-.749-.63-1.676-1.076-2-2a21.073,21.073,0,0,1-2-10c.408-3.163,2.468-5.057,5-7,2.089-1.61,6.966-2.152,10-2,2.007.093,4.543,1.346,4,2Z"
                transform="translate(-67.924 -114.971)"
                fill="#ef9456"
              />
              <path
                id="Path_2702"
                data-name="Path 2702"
                d="M94.2,162.176a9.026,9.026,0,0,1,5-2c1.715-.087,3.249.082,5,0,2.071-.088,3.418.336,5-1v-1c1.021-.963.858-1.874,0-3-.936-1.085-1.569-1.926-3-2-2.694-.2-5.968.22-8,2-2.01,1.776-3.151,4.456-4,7"
                transform="translate(-69.199 -112.611)"
                fill="#ef9456"
              />
              <path
                id="Path_2703"
                data-name="Path 2703"
                d="M87.164,196.893a12.009,12.009,0,0,0,8,4,2.253,2.253,0,0,1-1,0,15.076,15.076,0,0,1-4-1c-.993-.482-1.232-.208-2-1a10.536,10.536,0,0,1-1-2Z"
                transform="translate(-64.165 -145.329)"
                fill="#455a64"
              />
              <path
                id="Path_2704"
                data-name="Path 2704"
                d="M83.124,203.8a9.045,9.045,0,0,0,3,3,12.744,12.744,0,0,0,5,1c1.19.017,1-.059,1,0-.157.081.175.973,0,1a4.27,4.27,0,0,1-1,0,11.517,11.517,0,0,1-5-1,10.973,10.973,0,0,1-4-3c-.278-.388.2-1.569,0-2-.09-.151.029.173,0,0,.047-.029.259.084,1,1"
                transform="translate(-60.124 -149.237)"
                fill="#455a64"
              />
              <path
                id="Path_2705"
                data-name="Path 2705"
                d="M303.312,334.1s.925.443,4-3,9-13,9-13l5-10-4-7-21,21Z"
                transform="translate(-218.312 -221.539)"
                fill="#ffbe9d"
              />
              <path
                id="Path_2706"
                data-name="Path 2706"
                d="M379.284,275.42c-.373.286-7,6-7,6l1,3,4,4,5-2c.6.42,2-1,2-1a1.244,1.244,0,0,0,0,2h2l2-2v-2l-1-2-2-1h2a21.654,21.654,0,0,0,2,3c.062.1-.091-.076,0,0a1.745,1.745,0,0,0,2,0l-1-3,1-1-2-4-2-1c-.313-.207-.629-.056-1,0-1.441.2-5.93.953-6,1-.6.084-.45-.246-1,0"
                transform="translate(-274.284 -201.855)"
                fill="#ffbe9d"
              />
              <path
                id="Path_2707"
                data-name="Path 2707"
                d="M415.846,302.814c.019-.659-.517-.59,0-1h0a3.508,3.508,0,0,1,0-2,2.9,2.9,0,0,1,1-1c-.047.235.077.773,0,1a4.535,4.535,0,0,0,0,2h0c-.45.367-.921.425-1,1-.041.444.047,1,0,1a8.041,8.041,0,0,1,0-1"
                transform="translate(-305.846 -220.251)"
                fill="#eb996e"
              />
              <path
                id="Path_2708"
                data-name="Path 2708"
                d="M420.227,308.4a1.669,1.669,0,0,1,0-1c.1-.4-.249-.676,0-1,.14-.187.86-.837,1-1v1c-.2-.585-.45-1.573,0-2a1.027,1.027,0,0,1,1,0c.017.041-.743-.292-1,0-.341.391-.18.513,0,1v1h0c-.134.169.128-.181,0,0-.45.5-1.127,1.337-1,2,.093.262-.184.792,0,1a1.427,1.427,0,0,0,0-1"
                transform="translate(-309.226 -223.831)"
                fill="#eb996e"
              />
              <path
                id="Path_2709"
                data-name="Path 2709"
                d="M420.2,308.662c.309.193,1.052-.076,1,0a1.251,1.251,0,0,1-1,0c-.309-.193-.047.07,0,0s-.31-.192,0,0"
                transform="translate(-309.202 -227.098)"
                fill="#eb996e"
              />
              <path
                id="Path_2710"
                data-name="Path 2710"
                d="M416.95,312.77a.665.665,0,0,1,1,0c.029.082.368.586,0,1s-.912,0-1,0a.718.718,0,0,1,0-1"
                transform="translate(-306.95 -230.206)"
                fill="#eb996e"
              />
              <path
                id="Path_2711"
                data-name="Path 2711"
                d="M408.142,301.75a6.5,6.5,0,0,1,0-3,25.017,25.017,0,0,0,0,3c.187,1.43.082,1.959,0,2-.511-.744.07-1.1,0-2"
                transform="translate(-300.142 -220.186)"
                fill="#eb996e"
              />
              <path
                id="Path_2712"
                data-name="Path 2712"
                d="M430.738,287.818c.2.383-.275.665,0,1,.28.318,1.6-.128,2,0,.309.111,0-.035,0,0s-.627,1.047-1,1c-.475-.08-.687-.635-1-1s.155-.563,0-1c-.123-.327-1.041-1-1-1a5.1,5.1,0,0,1,1,1"
                transform="translate(-316.738 -211.253)"
                fill="#eb996e"
              />
              <path
                id="Path_2713"
                data-name="Path 2713"
                d="M417.952,294.016c-.163-.268-.088.017,0,0s.872-.216,1,0a1.854,1.854,0,0,1,0,1c-.047.07-.837-.732-1-1"
                transform="translate(-307.952 -216.451)"
                fill="#eb996e"
              />
              <path
                id="Path_2714"
                data-name="Path 2714"
                d="M754.437,236.174l18-14-18-22c-1.943,1.791,3,17,3,17l-11,8Z"
                transform="translate(-549.436 -147.61)"
                fill="#ff725e"
              />
              <path
                id="Path_2715"
                data-name="Path 2715"
                d="M754.437,236.174l18-14-18-22c-1.943,1.791,3,17,3,17l-11,8Z"
                transform="translate(-549.436 -147.61)"
                fill="#1b5c96"
              />
              <path
                id="Path_2716"
                data-name="Path 2716"
                d="M805.453,284.836c-.512-.323-1.3-1.476-1-2,.384-.481,1.5-.353,2,0,.447.362.34,1.535,0,2-.372.44-.521.32-1,0"
                transform="translate(-592.453 -208.272)"
                fill="#e0e0e0"
              />
              <g
                id="Group_7796"
                data-name="Group 7796"
                transform="translate(204.668 52.842)"
                opacity="0.6"
              >
                <g id="Group_7795" data-name="Group 7795">
                  <g
                    id="Group_7794"
                    data-name="Group 7794"
                    clipPath="url(#clipPath-3)"
                  >
                    <path
                      id="Path_2717"
                      data-name="Path 2717"
                      d="M776.535,200.125c-.759.455,0,2,0,2l16,21,2-1Z"
                      transform="translate(-776.203 -200.402)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
              <path
                id="Path_2718"
                data-name="Path 2718"
                d="M789.035,261.746a2.8,2.8,0,0,0,2,0c-.035-.128-1.241.239-2,0s-.889-1.082-1-1c-.111.058.09.7,1,1"
                transform="translate(-580.035 -192.182)"
                fill="#263238"
              />
              <path
                id="Path_2719"
                data-name="Path 2719"
                d="M785.545,250.32a2.972,2.972,0,0,0,2-1,11.329,11.329,0,0,0-2,0c-.729.006-.918-.1-1,0-.1.088.09,1,1,1"
                transform="translate(-577.545 -183.755)"
                fill="#263238"
              />
              <path
                id="Path_2720"
                data-name="Path 2720"
                d="M782.3,236.654c-.665.175-.948-.117-1,0s.206,1.2,1,1,1.1-.924,1-1c-.093-.093-.335-.175-1,0"
                transform="translate(-575.303 -174.089)"
                fill="#263238"
              />
              <path
                id="Path_2721"
                data-name="Path 2721"
                d="M779.3,271.059c.607.677.953,1.128,1,1s-.475-.463-1-1-.872-1.035-1-1c-.146.012.393.323,1,1"
                transform="translate(-573.299 -198.494)"
                fill="#263238"
              />
              <path
                id="Path_2722"
                data-name="Path 2722"
                d="M734.842,200.543l15-16-20-19c-1.751,2.019,5,16,5,16l-10,10Z"
                transform="translate(-533.842 -121.978)"
                fill="#1b5c96"
              />
              <path
                id="Path_2723"
                data-name="Path 2723"
                d="M782.812,245.947a2.136,2.136,0,0,1-1-2c.32-.527,1.455-.287,2,0,.487.308.277.495,0,1-.316.483-.485,1.26-1,1"
                transform="translate(-575.811 -179.383)"
                fill="#e0e0e0"
              />
              <g
                id="Group_7799"
                data-name="Group 7799"
                transform="translate(196.085 43.698)"
                opacity="0.6"
              >
                <g id="Group_7798" data-name="Group 7798">
                  <g
                    id="Group_7797"
                    data-name="Group 7797"
                    clipPath="url(#clipPath-4)"
                  >
                    <path
                      id="Path_2724"
                      data-name="Path 2724"
                      d="M743.566,165.592c-.7.513,0,2,0,2l19,19,1-2Z"
                      transform="translate(-743.651 -165.725)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
              <path
                id="Path_2725"
                data-name="Path 2725"
                d="M763.19,225.9a2.32,2.32,0,0,0,2-1c-.053-.111-1.218.146-2,0s-.907-.093-1,0c-.111.07.061.8,1,1"
                transform="translate(-561.19 -165.333)"
                fill="#263238"
              />
              <path
                id="Path_2726"
                data-name="Path 2726"
                d="M758.674,212.9c.9-.1,1.094-.9,1-1s-.271.877-1,1-.93-.117-1,0c-.088.093.1.1,1,0"
                transform="translate(-557.674 -156.34)"
                fill="#263238"
              />
              <path
                id="Path_2727"
                data-name="Path 2727"
                d="M754.467,200.7c-.63.251-.965-.123-1,0s.236.292,1,0,1.111-.936,1-1c-.105-.082-.37.749-1,1"
                transform="translate(-554.467 -147.137)"
                fill="#263238"
              />
              <path
                id="Path_2728"
                data-name="Path 2728"
                d="M755.34,235.967c.683.589,1,.134,1,0s-.411-.51-1-1-.872-1.047-1-1a3.509,3.509,0,0,0,1,2"
                transform="translate(-555.34 -172.403)"
                fill="#263238"
              />
              <path
                id="Path_2729"
                data-name="Path 2729"
                d="M428.065,253.178c-13.911,23.959-49.08,38.841-51,41s-38.682,29.362-43,32-34-1-34-1l1-7,6-12,13-9,41-22,13-5,6-1c5.759-.718,37-25,37-25Z"
                transform="translate(-221.066 -179.612)"
                fill="#ef9456"
              />
              <path
                id="Path_2730"
                data-name="Path 2730"
                d="M441.479,281.648l-55,28-55,13-6,11,33,16,27-15c8.966-5.318,18.286-9.218,28-13,15.6-6,37-27,37-27-.239-4.318-9-13-9-13"
                transform="translate(-239.478 -207.085)"
                fill="#ef9456"
              />
              <path
                id="Path_2731"
                data-name="Path 2731"
                d="M372.255,467.871c-2.407-1.989-2.936-5.021-2-8"
                transform="translate(-272.255 -338.306)"
                fill="#577c47"
              />
              <path
                id="Path_2732"
                data-name="Path 2732"
                d="M403.276,407.643l-2,1c-1.377.764-2.975,1.757-5,3s-3.693,3.154-5,4l-2,1c-.181.135.207-.09,0,0,.158-.164-.191-.877,0-1,.327-.268,1.364-.556,2-1,1.272-.9,3-1.757,5-3s3.623-2.27,5-3c.688-.368,1.609-.807,2-1,.2-.112-.217.067,0,0-.157.146.188-.1,0,0"
                transform="translate(-286.277 -300.079)"
                fill="#577c47"
              />
              <path
                id="Path_2733"
                data-name="Path 2733"
                d="M422.719,351.428h0l3-2,11-8,15-11,10-6,5-4c1.628-1.185,3.249-1.9,5-3,3.536-2.2,6.691-3.888,10-6s6.053-4.917,9-7,6.427-4.086,9-6c2.385-1.771,4.814-2.989,7-5,3.163-2.92,5.47-6.517,8-10,.449-.613.661-1.516,1-2s.778-.661,1-1l1-1h0c-.117.192-.778.662-1,1s-.656.492-1,1-.557,1.381-1,2c-2.5,3.52-4.839,7.059-8,10a87.01,87.01,0,0,1-7,6c-2.568,1.925-5.03,3.922-8,6s-6.7,3.864-10,6-6.47,3.806-10,6c-1.762,1.12-3.354,2.833-5,4l-5,3-10,7-15,11-11,7-3,2v1h-1c.094-.07.9-.942,1-1"
                transform="translate(-310.719 -205.863)"
                fill="#577c47"
              />
              <path
                id="Path_2734"
                data-name="Path 2734"
                d="M142.786,295.764l-9-1h-2l1,24,5,10,16,17c5.293,2.713,8.186,8.057,13,10l9,5a22.543,22.543,0,0,1,4-11c2.731-3.81,6.308-8.666,8-11a25.6,25.6,0,0,1,3-3c2.007-1.891,5.049-4.964,6-7,.543-1.115-13-5-13-5s-20.145-20.759-22-23c-2-2.4-13-9-13-9,1.319,2.958-6,4-6,4"
                transform="translate(-96.786 -215.2)"
                fill="#1b5c96"
              />
              <path
                id="Path_2735"
                data-name="Path 2735"
                d="M92.886,308.664a70.078,70.078,0,0,1-1,18s-5.561-2.675-8-4-5.269-6.878-8-10a10.742,10.742,0,0,1-3-6s-4.067-6.063-5-8c-.773-1.515-2.078-2.572-3-4,0,0,6.994,9.81,15,6s-1-15-1-15c2.042,1.548,3.156,3.22,5,5l8,3s.2,5.64,1,15"
                transform="translate(-47.886 -210.1)"
                fill="#1b5c96"
              />
              <path
                id="Path_2736"
                data-name="Path 2736"
                d="M120.651,301.561c.646-.781,1.82-2,2-3,.153-1.234-.681-1.8-1-3-.561-2.207-.764-5.087-2-7-.461-.753-1.726-.592-2-1-.158-.2.109-.77,0-1a12.686,12.686,0,0,1,1,1c.3.391.516.265,1,1a24.027,24.027,0,0,1,3,7,6.045,6.045,0,0,1,0,3,8.047,8.047,0,0,1-2,3c-.459.528-.457,1.558-1,2-.188.173-.777-.126-1,0a23.241,23.241,0,0,0,2-2"
                transform="translate(-86.651 -210.996)"
                fill="#577c47"
              />
              <path
                id="Path_2737"
                data-name="Path 2737"
                d="M411.578,187.395v4a5.4,5.4,0,0,0,4,6l-2,9h0c-.287-.044-.956.713-1,1l-2,9c-.145.944,1.057,1.855,2,2s1.855-.057,2-1l1-10c.044-.287.287-.956,0-1h-1l2-9a4.4,4.4,0,0,0,5-4l1-4a5.126,5.126,0,0,0-4-6c-.039-.012-.959,0-1,0h0c-.041-.006.041.005,0,0s.045,0,0,0c-2.832-.294-5.563,1.187-6,4m2,20h1l-1,10c-.057.37-.63.057-1,0s-1.057.37-1,0Zm4-23v1c.659,3.516.686,6.846-1,10l-1,1v-1c-.659-3.516.314-6.846,2-10Zm3,9c-.309,1.987-1.994,2.868-4,3h0a16.939,16.939,0,0,0,2-11v-1a4.98,4.98,0,0,1,3,5Zm-8-6c.307-1.975,2.006-2.854,4-3h0a16.94,16.94,0,0,0-2,11v1c-1.857-.739-2.3-3.024-2-5Z"
                transform="translate(-302.579 -134.83)"
                fill="#fff"
              />
              <path
                id="Path_2738"
                data-name="Path 2738"
                d="M127.283,326.182h1c1.038.322,1.913.981,3,1,.741,0,1.34-.663,2-1a5.143,5.143,0,0,0,2-2c.776-1.628.169-2.917,0-5a25.642,25.642,0,0,0-1-6,18.173,18.173,0,0,0-2-4c-.759-1.155-1.539-2.376-2-3l-1-1c-.066-.081.047.094,0,0,.012-.006-.163-.187,0,0s.767.726,1,1c.484.607,1.212.833,2,2,.946,1.34,1.4,3.475,2,5,.66,1.9,1.842,4,2,6a11.875,11.875,0,0,1-1,6c-.384.821-1.2.575-2,1-.7.347-1.223,1.01-2,1a9.752,9.752,0,0,1-3-1c-.368-.134-.813.076-1,0-.1-.036-.911.054-1,0,.1.014.91-.036,1,0"
                transform="translate(-93.283 -224.617)"
                fill="#577c47"
              />
              <path
                id="Path_2739"
                data-name="Path 2739"
                d="M139.544,303.814a21.535,21.535,0,0,1,3,3,15.432,15.432,0,0,1,2,3,11.525,11.525,0,0,1,1,2c-.322-.449-.732-1.516-1-2a21.01,21.01,0,0,0-2-3,28.394,28.394,0,0,0-3-3c-.449-.325-1.59-.626-2-1a11.95,11.95,0,0,1,2,1"
                transform="translate(-101.544 -223.249)"
                fill="#577c47"
              />
              <g
                id="Group_7802"
                data-name="Group 7802"
                transform="translate(46.802 119.865)"
                opacity="0.3"
              >
                <g id="Group_7801" data-name="Group 7801">
                  <g
                    id="Group_7800"
                    data-name="Group 7800"
                    clipPath="url(#clipPath-5)"
                  >
                    <path
                      id="Path_2740"
                      data-name="Path 2740"
                      d="M225.7,454.285h-48c8.811,1.068,17.247,2.588,26,4,3.361.519,6.639,1.525,10,1a36.5,36.5,0,0,0,12-5"
                      transform="translate(-177.497 -454.585)"
                    />
                  </g>
                </g>
              </g>
              <path
                id="Path_2741"
                data-name="Path 2741"
                d="M213.114,552.578a7.138,7.138,0,0,0-4,2,9.63,9.63,0,0,0-2,2c-.325.612.06,1.31,0,2a11.916,11.916,0,0,0,0,2c-.086-.155.034.174,0,0-.113-.472-1.016-1.516-1-2,.027-.726.677-1.349,1-2a8.883,8.883,0,0,1,2-2,6.452,6.452,0,0,1,4-2,2.609,2.609,0,0,1,1,0,5.664,5.664,0,0,1,1,0,12.5,12.5,0,0,0-2,0"
                transform="translate(-152.114 -407.014)"
                fill="#263238"
              />
              <path
                id="Path_2742"
                data-name="Path 2742"
                d="M274.6,559.205v1a5.981,5.981,0,0,1-6,6h-55a5.981,5.981,0,0,1-6-6v-1a5.981,5.981,0,0,1,6-6h55a5.981,5.981,0,0,1,6,6"
                transform="translate(-152.595 -407.64)"
                fill="#fff"
              />
              <path
                id="Path_2743"
                data-name="Path 2743"
                d="M73.139,340.5l40,59c1.46,2.2,1.2,5.54-1,7l-1,1c-2.2,1.46-5.54.2-7-2l-39-60c-1.46-2.2-1.2-4.54,1-6l1-1c2.2-1.46,4.54-.2,6,2"
                transform="translate(-47.139 -248.937)"
                fill="#fff"
              />
              <path
                id="Path_2744"
                data-name="Path 2744"
                d="M213.758,502.385a59.776,59.776,0,0,0-4,6c-1.435,3.046-2.661,9.646-3,11-.087.332.213.732,0,1-.652.825-1.949,2.016-3,2h-15l-13-20h-5l15,23a4.447,4.447,0,0,0,3,2h17c4.569-.21,6.224-3.656,7-7,.56-2.439,1.067-9.374,2-12s4.883-6,5-6Z"
                transform="translate(-125.758 -369.82)"
                fill="#577c47"
              />
              <path
                id="Path_2745"
                data-name="Path 2745"
                d="M298.648,583.582a.959.959,0,0,1,1-1h0a1,1,0,1,1-1,1"
                transform="translate(-219.648 -429.018)"
                fill="#fff"
              />
              <path
                id="Path_2746"
                data-name="Path 2746"
                d="M235.915,582.582h0a1,1,0,1,1-1,1,.956.956,0,0,1,1-1"
                transform="translate(-172.915 -429.018)"
                fill="#fff"
              />
              <path
                id="Path_2747"
                data-name="Path 2747"
                d="M212.983,491.455h0a2.521,2.521,0,0,1-2,2h-56a2.521,2.521,0,0,1-2-2h0c0-.819,1.182-1,2-1h56c.818,0,2,.181,2,1"
                transform="translate(-112.983 -360.89)"
                fill="#577c47"
              />
              <path
                id="Path_2748"
                data-name="Path 2748"
                d="M192.937,489.9h21c.484-.006,1.486-1,2-1,.529-.008.535.747,1,1,.25.145.87-.258,1,0a7.905,7.905,0,0,1,0,1c.006.152,0-.14,0,0-.131.566-.454,1.8-1,2a6.166,6.166,0,0,1-3,0h-54c.072-.008-.072-.008,0,0h54c1.055.09,2.006-.637,3-1a1.518,1.518,0,0,0,1-1c.1-.462-.588-.768-1-1-.834-.5-2.043,0-3,0h-3c-3.67.012-6.3,0-9,0h-9Z"
                transform="translate(-117.937 -360.335)"
                fill="#577c47"
              />
              <path
                id="Path_2749"
                data-name="Path 2749"
                d="M332.155,516.564a17.33,17.33,0,0,1,0-2,30.254,30.254,0,0,1,1-5,23.247,23.247,0,0,1,2-3c.461-.742.409-1.357,1-2a27.707,27.707,0,0,1,2-2c.16-.145-.185.111,0,0a3.185,3.185,0,0,1-1,2c-.553.656-1.555,1.266-2,2a20.221,20.221,0,0,0-1,3c-.641,1.812-1.568,3.127-2,5-.169.665.122,1.539,0,2-.043.211-.911-.2-1,0,0-.218.945.212,1,0"
                transform="translate(-244.155 -369.999)"
                fill="#455a64"
              />
              <rect
                id="Rectangle_8017"
                data-name="Rectangle 8017"
                width="4"
                height="48"
                transform="translate(85 158.564)"
                fill="#1b5c96"
              />
              <path
                id="Path_2750"
                data-name="Path 2750"
                d="M217.412,759.949l-39,6v-4l39-8Z"
                transform="translate(-131.413 -555.384)"
                fill="#1b5c96"
              />
              <path
                id="Path_2751"
                data-name="Path 2751"
                d="M185.413,800.4c0,1.914-1.086,4-3,4a4.448,4.448,0,0,1-4-4c0-1.914,2.086-3,4-3a2.724,2.724,0,0,1,3,3"
                transform="translate(-131.413 -586.833)"
                fill="#1b5c96"
              />
              <path
                id="Path_2752"
                data-name="Path 2752"
                d="M330.455,759.947l32,6v-4l-32-8Z"
                transform="translate(-243.455 -555.383)"
                fill="#1b5c96"
              />
              <path
                id="Path_2753"
                data-name="Path 2753"
                d="M431.462,800.4a4.448,4.448,0,0,1-4,4c-1.914,0-3-2.086-3-4a2.724,2.724,0,0,1,3-3c1.914,0,4,1.086,4,3"
                transform="translate(-312.462 -586.833)"
                fill="#1b5c96"
              />
              <path
                id="Path_2754"
                data-name="Path 2754"
                d="M325.053,800.4a4.448,4.448,0,0,1-4,4c-1.914,0-3-2.086-3-4a2.724,2.724,0,0,1,3-3c1.914,0,4,1.086,4,3"
                transform="translate(-234.053 -586.833)"
                fill="#1b5c96"
              />
              <path
                id="Path_2755"
                data-name="Path 2755"
                d="M315.488,684.539h8v30h-8Z"
                transform="translate(-232.488 -503.974)"
                fill="#1b5c96"
              />
              <path
                id="Path_2756"
                data-name="Path 2756"
                d="M320.3,684.244a26.874,26.874,0,0,1,4,0,26.817,26.817,0,0,1-4,0,15.087,15.087,0,0,1-3,0,15.115,15.115,0,0,1,3,0"
                transform="translate(-233.303 -503.679)"
                fill="#455a64"
              />
              <path
                id="Path_2757"
                data-name="Path 2757"
                d="M321.987,776.893c1.241-.049,1.767.85,3,1a15.1,15.1,0,0,1-3,0,26.809,26.809,0,0,1-4,0c1.233-.15,2.759-1.049,4-1"
                transform="translate(-233.987 -572.328)"
                fill="#1b5c96"
              />
              <path
                id="Path_2758"
                data-name="Path 2758"
                d="M379.944,285.105l-2,1a18.662,18.662,0,0,1-3,5c-1.634,1.634-7-2-7-2l7-6,5-1h5c1.692,2.976-5,3-5,3"
                transform="translate(-270.944 -207.542)"
                fill="#ffbe9d"
              />
              <path
                id="Path_2759"
                data-name="Path 2759"
                d="M379.431,285.535c.187-.163.726-.749,1-1l3-2,5-1h5c.159.273-.026.685,0,1s.2.746,0,1a5.063,5.063,0,0,1-2,1,10.313,10.313,0,0,1-3,0h0l-2,1h0a29.932,29.932,0,0,1-2,4c-.261.343-.7.686-1,1-.1.113.123-.089,0,0,.083-.128-.107.109,0,0a9.935,9.935,0,0,0,1-1,35.259,35.259,0,0,0,2-4h0l2-1h0c1.029-.011,2.011-.717,3-1,.516-.131,1.635.388,2,0a2.294,2.294,0,0,0,0-2h-5l-5,1h0l-3,3h-1c-.081.072.094-.052,0,0-.012-.012-.187.163,0,0"
                transform="translate(-279.431 -206.971)"
                fill="#eb996e"
              />
              <path
                id="Path_2760"
                data-name="Path 2760"
                d="M55.12,110.285c.755-6.293,1.935-12.187,7-16,4.977-3.806,11.818-6.021,18-5l-4-10-17-19-7-1-10,13,9,29Z"
                transform="translate(-31.12 -43.721)"
                fill="#ff725e"
              />
              <path
                id="Path_2761"
                data-name="Path 2761"
                d="M55.12,110.285c.755-6.293,1.935-12.187,7-16,4.977-3.806,11.818-6.021,18-5l-4-10-17-19-7-1-10,13,9,29Z"
                transform="translate(-31.12 -43.721)"
                fill="#1b5c96"
              />
              <path
                id="Path_2762"
                data-name="Path 2762"
                d="M99.924,137.531c-.126.01-.875.016-1,0-.244-.011-.558,0-1,0a21.406,21.406,0,0,0-4,0,18.533,18.533,0,0,0-5,1c-1.063.434-3,1.443-4,2a16.7,16.7,0,0,0-3,2,19.834,19.834,0,0,0-4,5,29.494,29.494,0,0,0-3,6c-.375,1.26.082,2.688,0,4,0,.444.006.732,0,1,.008.125.018-.124,0,0-.021-.122,0,.124,0,0-.01-.246,0-.533,0-1,.047-1.322-.362-2.727,0-4a28.749,28.749,0,0,1,3-6,19.234,19.234,0,0,1,4-5,16.574,16.574,0,0,1,3-2,30.928,30.928,0,0,1,3-2c1.819-.734,4.056-.736,6-1a19.652,19.652,0,0,1,4,0c.466.016.758-.027,1,0,.116.006.886-.023,1,0"
                transform="translate(-54.924 -100.967)"
                fill="#577c47"
              />
              <path
                id="Path_2763"
                data-name="Path 2763"
                d="M92.956,81.844c-.06.026-3.128-5.037-7-11s-7.056-10.965-7-11,3.128,5.037,7,11,7.056,10.965,7,11"
                transform="translate(-57.956 -44.279)"
                fill="#577c47"
              />
              <path
                id="Path_2764"
                data-name="Path 2764"
                d="M76.228,97.854c-.06.026-2.532-5.829-5-12s-4.051-10.978-4-11,1.528,4.831,4,11,5.066,11.976,5,12"
                transform="translate(-49.228 -55.289)"
                fill="#577c47"
              />
              <path
                id="Path_2765"
                data-name="Path 2765"
                d="M59.974,119.979c-.044-.074.024.083,0,0-.078-.18.092-.719,0-1-.224-.6-.628-1.922-1-3-.745-2.156-2.043-4.631-3-8s-1.431-6.768-2-9c-.27-1.112-.87-1.373-1-2-.06-.29.042-.809,0-1-.024-.083,0,.086,0,0,.044.074-.025-.082,0,0,.046.189-.079.713,0,1,.171.65.714.921,1,2,.579,2.208,1.021,5.661,2,9s2.3,5.832,3,8c.346,1.064.789,2.362,1,3,.09.288-.057.811,0,1,.025.09,0-.093,0,0"
                transform="translate(-38.973 -70.414)"
                fill="#577c47"
              />
              <path
                id="Path_2766"
                data-name="Path 2766"
                d="M139.657,146.268c.179.316-.109.654,0,1,.251.717.678,1.084,1,2s.749,2.283,1,3c.139.335-.064.643,0,1-.2-.306.115-.656,0-1a17.649,17.649,0,0,1-1-2c-.339-.965-.816-2.355-1-3-.129-.338.048-.642,0-1"
                transform="translate(-102.657 -107.702)"
                fill="#577c47"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RelaxedCook;
