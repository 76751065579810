import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { css } from 'glamor';
import theme, { spacing, typographyColors } from './theme';
import Button from './Button';
import { IoIosAdd } from 'react-icons/io';

const BUTTON_SIZE = 38;

const buttonClass = css({
  backgroundColor: theme.palette.add.main,
  width: BUTTON_SIZE,
  height: BUTTON_SIZE,
  border: 0,
  color: typographyColors.inverseTitle,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  ':hover': {
    backgroundColor: theme.palette.add.active,
  },
});
const decreaseSx = {
  backgroundColor: theme.palette.danger.main,
  ':hover': {
    backgroundColor: theme.palette.danger.active,
  },
};
const decreaseClass = css(decreaseSx);

const iconStyle = { width: 19, height: 19 };

export default function addButton(props) {
  const {
    onClick,
    decrease,
    icon,
    wide = false,
    text,
    sx,
    type = 'button',
  } = props;
  const wrapperSx = wide
    ? { width: '100%', height: '45px' }
    : {
        width: BUTTON_SIZE,
        height: BUTTON_SIZE,
      };
  const buttonStyle = {
    backgroundColor: theme.palette.add.main,
    lineHeight: 1,
    width: '100%',
    height: '45px',
    borderRadius: spacing.s,
    fontSize: '14px',
    ':hover': {
      backgroundColor: theme.palette.add.active,
    },
  };

  return (
    <div style={{ ...wrapperSx, ...sx }}>
      {wide ? (
        <Button
          startIcon={icon ? icon : <IoIosAdd />}
          onClick={onClick}
          style={{ ...buttonStyle, ...(decrease && decreaseSx) }}
        >
          {text}
        </Button>
      ) : (
        <button
          onClick={onClick}
          className={css(buttonClass, decrease && decreaseClass)}
          type={type}
        >
          {icon ? icon : <AddIcon style={iconStyle} />}
        </button>
      )}
    </div>
  );
}
