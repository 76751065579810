import React from 'react';
import Box from '../library/Box';
import { Card } from '../library/cards';
import HorizontalItems from '../library/HorizontalItems';
import { toCurrency } from '../../utils';
import { Typography } from '../library';
import { spacing } from '../library/theme';

const notesSx = {
  whiteSpace: 'pre-line',
  fontWeight: 'normal',
};
const marginTop = {
  marginTop: spacing.s,
};

export default function RecipeListItem(props) {
  const { recipe, onEdit, onDelete, onDuplicate, unit, currency } = props;
  const {
    id,
    name,
    preparationTime = 1,
    estimatedProduce,
    cost = 0,
    notes,
  } = recipe;

  return (
    <Card
      key={id}
      title={name}
      onDuplicate={onDuplicate}
      onEdit={onEdit}
      onDelete={onDelete}
    >
      <Box pt={2}>
        <HorizontalItems
          items={[
            {
              title: 'Tempo de preparação',
              description: `${preparationTime} minuto(s)`,
            },
            {
              title: 'Rendimento',
              description: `${estimatedProduce} ${unit?.abbreviation}`,
            },
            {
              title: 'Preço',
              description: toCurrency(cost, currency?.code),
            },
          ]}
        />
        {notes && (
          <>
            <Typography variant="h6" sx={marginTop}>
              Observações
            </Typography>
            <p style={notesSx}>{notes}</p>
          </>
        )}
      </Box>
    </Card>
  );
}
