import React from 'react';
import { css, select } from 'glamor';
import Link from './Link';
import { decorationColors, spacing, typographyColors } from './theme';

const iconClass = css({
  marginRight: spacing.m,
  opacity: 0.3,
});
const activeClass = css(
  {
    transition: '.3s all',
    color: `${decorationColors.decorationBrand} !important`,
  },
  select(`& .${iconClass}`, {
    transition: '.3s opacity',
    opacity: 1,
  }),
);
const item = css({
  display: 'flex',
  alignItems: 'center',
  padding: `${spacing.s} ${spacing.m}`,
  fontSize: 16,
  color: `${typographyColors.defaultBody} !important`,
  transition: '.3s all',
  height: 42,
});
const subItemClass = css({
  fontSize: 14,
  paddingLeft: 24,
  lineHeight: 1.5,
});

export default function SidebarItem(props) {
  const { label, icon, active, children, subItem, className, ...rest } = props;
  const baseClass = css(
    item,
    subItem && subItemClass,
    className && className,
    active && activeClass,
  );
  const Icon = icon;

  return (
    <Link className={baseClass} {...rest}>
      {icon && <Icon size={subItem ? 16 : 22} className={iconClass} />}
      {label || children}
    </Link>
  );
}
