import React from 'react';
import Page from '../page/Page';
import Title from '../library/Title';
import HomeCards from '../library/HomeCards';
import { spacing, utilityColors } from '../library/theme';
import WeeklyEventsCalendarContainer from '../calendar/WeeklyEventsCalendarContainer';
import BannersContainer from '../banner/BannersContainer';
import { PiCurrencyDollarSimpleFill, PiTagFill } from 'react-icons/pi';
import InventoryIcon from '@mui/icons-material/Inventory';
import ConciergeBellIcon from '../library/icons/ConciergeBellIcon';
import { Card, Grid, Link } from '../library';
import CalculateIcon from '@mui/icons-material/Calculate';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CakeIcon from '@mui/icons-material/Cake';
import PersonIcon from '@mui/icons-material/Person';
import BarChart from '../library/BarChart';

const pageSx = {
  paddingLeft: 0,
  paddingRight: 0,
};
const cardStyle = {
  borderRadius: '24px',
  boxShadow: 0,
  cursor: 'pointer',
};
const otherActionsCardStyle = {
  ...cardStyle,
  padding: 0,
  paddingLeft: spacing.s,
  paddingTop: spacing.s,
  marginBottom: 0,
};
const descriptionSpacing = {
  marginTop: spacing.xxs,
};
const iconStyle = {
  width: 24,
  height: 24,
};
const newOrderIconStyle = {
  ...iconStyle,
  color: utilityColors.success,
};
const newOrderIconBackground = {
  backgroundColor: `${utilityColors.success}1a`,
};
const newOrderBackground = {
  ...cardStyle,
  backgroundColor: '#61A6491A',
};
const suppliesIconStyle = {
  ...iconStyle,
  color: '#F47560',
};
const suppliesIconBackground = {
  backgroundColor: '#F475601a',
};
const suppliesBackground = {
  ...cardStyle,
  backgroundColor: '#F0B8AF4D',
};
const statementsIconStyle = {
  ...iconStyle,
  color: '#D89A6A',
};
const statementsIconBackground = {
  backgroundColor: '#D89A6A1A',
};
const statementsBackground = {
  ...cardStyle,
  backgroundColor: '#C9783A1A',
};
const ordersIconBackground = {
  backgroundColor: '#61CDBB1A',
};
const ordersBackground = {
  ...cardStyle,
  backgroundColor: '#C8E0DD4D',
};
const pricingIconStyle = {
  ...iconStyle,
  color: '#F1DB23',
};
const pricingIconBackground = {
  backgroundColor: '#F1DB231A',
};
const recipesIconStyle = {
  ...iconStyle,
  color: '#8B48E2',
};
const recipesIconBackground = {
  backgroundColor: '#8B48E21A',
};
const productsIconStyle = {
  ...iconStyle,
  color: '#327FF3',
};
const productsIconBackground = {
  backgroundColor: '#327FF31A',
};
const customersIconStyle = {
  ...iconStyle,
  color: '#FF9933',
};
const customersIconBackground = {
  backgroundColor: '#FF99331A',
};
const horizontalSpacing = { paddingLeft: spacing.m, paddingRight: spacing.m };
const homeCardsItems = [
  {
    title: 'Fazer uma venda',
    to: '/orders/new',
    icon: <PiTagFill style={newOrderIconStyle} />,
    iconBackgroundSx: { ...newOrderIconBackground },
    sx: { ...newOrderBackground },
  },
  {
    title: 'Insumos',
    to: '/catalogs/supplies',
    icon: <InventoryIcon style={suppliesIconStyle} />,
    iconBackgroundSx: { ...suppliesIconBackground },
    sx: { ...suppliesBackground },
  },
  {
    title: 'Meus pedidos',
    to: '/orders',
    icon: <ConciergeBellIcon color="#61CDBB" />,
    iconBackgroundSx: { ...ordersIconBackground },
    sx: { ...ordersBackground },
  },
  {
    title: 'Financeiro',
    to: '/statements',
    icon: <PiCurrencyDollarSimpleFill style={statementsIconStyle} />,
    iconBackgroundSx: { ...statementsIconBackground },
    sx: { ...statementsBackground },
  },
];

export default function Dashboard(props) {
  const { currentUser, weeklySummaryData, currency } = props;
  const weeklySummaryOptions = {
    groups: [''],
    data: weeklySummaryData,
    currency: currency,
  };

  return (
    <Page
      offsetHeader={0}
      sx={pageSx}
      showWeeklySales
      greeting={!!currentUser?.firstName && `Olá, ${currentUser?.firstName}!`}
    >
      <WeeklyEventsCalendarContainer />
      <div style={horizontalSpacing}>
        <BannersContainer />
      </div>
      <div style={horizontalSpacing}>
        <Title>Principais ações</Title>
        <HomeCards items={homeCardsItems} />
      </div>
      {weeklySummaryData && (
        <div style={horizontalSpacing}>
          <Title pt={3}>Resumo da semana</Title>
          <BarChart {...weeklySummaryOptions} />
        </div>
      )}
      <div style={horizontalSpacing}>
        <Title pt={3}>Outras ações</Title>
      </div>
      <Grid container style={horizontalSpacing}>
        <Grid item sm={6} xs={12}>
          <Link
            to="/pricing/calculator"
            component={Card}
            title="Calculadora de preço"
            description="Calcule o preço de um produto"
            descriptionStyle={descriptionSpacing}
            sx={otherActionsCardStyle}
            minHeight="80px"
            icon={<CalculateIcon style={pricingIconStyle} />}
            iconBackgroundSx={pricingIconBackground}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Link
            to="/catalogs/recipes"
            component={Card}
            title="Receitas"
            description="Faça o cadastro das suas receitas"
            descriptionStyle={descriptionSpacing}
            sx={otherActionsCardStyle}
            minHeight="80px"
            icon={<MenuBookIcon style={recipesIconStyle} />}
            iconBackgroundSx={recipesIconBackground}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Link
            to="/catalogs/products"
            component={Card}
            title="Produtos"
            description="Faça o cadastro dos seus produtos"
            descriptionStyle={descriptionSpacing}
            sx={otherActionsCardStyle}
            minHeight="80px"
            icon={<CakeIcon style={productsIconStyle} />}
            iconBackgroundSx={productsIconBackground}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Link
            to="/customers"
            component={Card}
            title="Clientes"
            description="Faça o cadastro dos seus clientes"
            descriptionStyle={descriptionSpacing}
            sx={otherActionsCardStyle}
            minHeight="80px"
            icon={<PersonIcon style={customersIconStyle} />}
            iconBackgroundSx={customersIconBackground}
          />
        </Grid>
      </Grid>
    </Page>
  );
}
