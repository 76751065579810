import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import {
  PiCheckCircleFill,
  PiWarningCircleFill,
  PiXCircleFill,
} from 'react-icons/pi';
import { spacing } from './theme';

const toastSx = {
  borderRadius: spacing.s,
  width: '100%',
  fontSize: '14px',
};
const iconSx = {
  width: '20px',
  height: '20px',
};

const iconType = (type) => {
  switch (type) {
    case 'success':
      return <PiCheckCircleFill style={iconSx} />;
    case 'warning':
      return <PiWarningCircleFill style={iconSx} />;
    case 'error':
      return <PiXCircleFill style={iconSx} />;
    default:
      return <PiWarningCircleFill style={iconSx} />;
  }
};

export default function Toast(props) {
  const {
    message,
    type = 'info',
    variant = 'filled',
    visible,
    handleClose,
    hideDuration = 3000,
  } = props;

  return (
    <Snackbar
      open={visible}
      autoHideDuration={hideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        icon={iconType(type)}
        severity={type}
        variant={variant}
        sx={toastSx}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
